import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DynamicLabelsService {

  private apiFinishedSubject = new Subject<any>();

  private apiDataSubject = new BehaviorSubject<any>(null); // Holds the API response data
  apiData$ = this.apiDataSubject.asObservable();
   //isApiFnishLoadind =  this.apiFinishedSubject.asObservable();
  // langData: any;

  constructor(private http: HttpClient) {}
  getTranslation(lang: string): Observable<any> {
  
   return this.http.get<any>(`https://trial-api.fieldex.com/custella-0.0.1-SNAPSHOT/api/mongo/dynamic-label-setup/${lang}/setup?source=WEB`).pipe(
    catchError(error => {
      console.error('Error fetching data:', error);
      return []; // Handle error (optional)
    })
  )


  // https://trial-api.fieldex.com/custella-0.0.1-SNAPSHOT/api/mongo/dynamic-label-setup/${lang}/setup?source=WEB

  // data.subscribe((res: any) =>{
  //   this.apiFinishedSubject.next( {
  //     status: 'API_FINISHED', // Include status
  //     data: res // Include response data
      
  //   });
  // })
  }

  updateData(data: any) {
    this.apiDataSubject.next(data); // Update the BehaviorSubject with new data
  }


  getCurrentLanguage() {
    console.log("API Called Finished22")
    return this.apiFinishedSubject.asObservable();
  }

  setCurrentLanguage(obj: any) {
    console.log("API Called Finished33", obj)
    return this.apiFinishedSubject.next(obj);
  }

}
