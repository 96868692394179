<div class="mt-56px">
    <div class="d-flex button-box-top-right">
        <ng-container>
            <p-button *ngIf="update" [label]="translate?.general.buttons.edit.singular" styleClass="ms-2 h-28" id="btn_ContaDetEdit" (onClick)="editContact('edit')"></p-button>

            <button type="button" class="btn-view-icon ms-2" [pTooltip]="translate?.general.buttons.more.singular" tooltipPosition="bottom" (click)="menu.toggle($event)" id="btn_ContaDetDropdown"  *ngIf="remove">
                <i class="fa-regular fa-ellipsis-vertical"></i>
            </button>
            <p-slideMenu [showTransitionOptions]="'0ms'"  [hideTransitionOptions]="'0ms'" #menu [model]="items" [popup]="true" class="options2"></p-slideMenu>
        </ng-container>
    </div>

    <div class="custella-form-container left-right-space" *ngIf="addContact">
        <!-- Contact Details -->
        <div class="custella-form-container-title" id="title_ContaDet">{{ translate?.contacts.sections.sec0.singular }}</div>
        <!-- Name and Email -->
        <div class="custella-form-container-content-border-bottom">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" id="label_ContaDetFullName">{{ translate?.contacts.fields.nameSalutation.singular }}</span>
                    <span class="custella-content-row-input-title-three" id="value_ContaDetFullName">{{ addContact?.contactLite?.title }} {{ addContact?.contactLite?.fullName }}</span>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" id="label_ContaDetEmail">{{ translate?.contacts.fields.email.singular }}</span>
                    <a [href]="'mailto:' + addContact?.contactLite?.email + ''" id="link_ContaDetEmail" *ngIf="addContact?.contactLite?.email" class="custella-content-row-input-title-three-link">{{ addContact?.contactLite?.email }}</a>
                    <span *ngIf="!addContact?.contactLite?.email" id="link_ContaDetEmail" class="custella-content-row-input-title-three">-</span>
                </div>
                
            </div>
        </div>
        <!-- Website and Date -->
        <div class="custella-form-container-content-border-bottom">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" id="label_ContaDetDesignation">{{ translate?.contacts.fields.designation.singular }}</span>
                    <a class="custella-content-row-input-title-three" id="value_ContaDetDesignation">{{ addContact?.contactLite?.designation ? addContact?.contactLite?.designation : '-' }}</a>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" id="label_ContaDetPhone">{{ translate?.contacts.fields.phone.singular }}</span>
                    <span class="custella-content-row-input-title-three text-capitalize" id="value_ContaDetPhone">{{ addContact?.contactLite?.phone ? addContact?.contactLite?.phone : '-' }}</span>
                </div>
            </div>
        </div>

        <!-- Website and Date -->
        <!-- <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" >{{'type'}}</span>
                    <span *ngIf="!addContact?.contactLite?.type" class="custella-content-row-input-title-three">-</span>
                    <span *ngIf="addContact?.contactLite?.type" class="custella-content-row-input-title-three">{{addContact?.contactLite?.type | titlecase }}</span>
                </div>
            </div>
        </div> -->

        <!-- General Details -->
        <div *ngIf="comingFrom == 'customer'">
            <div class="custella-form-container-title" *ngIf="addonFeature.addonSNL === 'SNL_TENANT' || addonFeature.addonAGRIBIO === 'AGRIBIO'" id="title_CusPortalDets">{{ translate?.contacts.sections.sec1.singular }}</div>
            <!-- Name and Email -->
            <div class="custella-form-container-content-border-bottom" *ngIf="addonFeature.addonSNL === 'SNL_TENANT' || addonFeature.addonAGRIBIO === 'AGRIBIO'">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title-two" id="label_ContaDetCusUsername">{{ translate?.contacts.fields.username.singular }}</span>
                        <span class="custella-content-row-input-title-three" id="value_ContaDetCusUsername">{{ addContact?.login }}</span>
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title-two" id="label_ContaDetPortalStatus">{{ translate?.contacts.fields.status.singular }}</span>
                        <span class="custella-content-row-input-title-three text-capitalize">
                            <p-tag *ngIf="addContact.status == 'ACTIVE'" id="tag_StatusActive" styleClass="p-mr-2 p-tag-success" severity="success" [value]="translate?.general.status.active.singular"></p-tag>
                            <p-tag *ngIf="addContact.status == 'DEACTIVE'" id="tag_StatusDeactive" styleClass="p-mr-2 p-tag-deactivate" [value]="translate?.general.status.deactivate.singular"></p-tag>
                            <p-tag *ngIf="addContact.status == 'SUSPENDED'" id="tag_StatusSuspend" styleClass="p-mr-2 p-tag-suspend" [value]="translate?.general.status.suspended.singular"></p-tag>
                            <p-tag *ngIf="addContact.status == 'PENDING_ACTIVATION'" id="tag_StatusPendingActivation" styleClass="p-mr-2 p-tag-pending" [value]="translate?.general.status.pendingActivation.singular"></p-tag>
                        </span>
                    </div>
                </div>
            </div>
         </div>

        <!-- System Information -->
        <app-system-information
            *ngIf="addContact"
            [createdByUser]="addContact?.contactLite?.createdByUser"
            [createdDate]="addContact?.contactLite?.createdDate"
            [modifiedByUser]="addContact?.contactLite?.modifiedByUser"
            [lastModifiedDate]="addContact?.contactLite?.lastModifiedDate"
        ></app-system-information>
    </div>
</div>
