import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DevicesService {
  public refresherdeviceList$: EventEmitter<any>;
  public refreshGroupList$: EventEmitter<any>;

  private ENDPOINT_CREATE_DEVICES = "/mac-addresses-all";
  private ENDPOINT_DEVICES_LIST = "/mac-addresses";

  //groups
  private ENDPOINT_USER_GROUPS_LIST = "/v2/user-groups/filter";
  private ENDPOINT_SAVE_GROUP = "/user-groups";

  constructor(private http: HttpClient) {
    this.refresherdeviceList$ = new EventEmitter();
    this.refreshGroupList$ = new EventEmitter();
  }

  public createNewDevice(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_CREATE_DEVICES, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherdeviceList$.emit(body);

          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public updateDevice(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_DEVICES_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherdeviceList$.emit(body);

          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getDevices() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_DEVICES_LIST)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteDrive(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_DEVICES_LIST + "/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherdeviceList$.emit("Deleted device");

          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  ////////////////////////////////////////// group tab /////////////////////////////////////

  public getActiveUsers() {
    return this.http.get(environment.base_url + "/v4/users/active").pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  //grid call(groups)
  public getGroupGrid(
    parameters: any,
    search: any,
    type: any
  ) {
    let params = new HttpParams();
    if (search !== "" && search != null) {
      params = params.set("search", search);
    }
    if (type) params = params.set("type", type);
    if (parameters)
    //console.log(parameters)
    params = params
      .set("startFrom", parameters.startRow)
      .set("perPage", "25")
      .set("columnName", parameters.sortModel[0].colId)
      .set("sortOrder", parameters.sortModel[0].sort);

    return this.http
      .get(environment.base_url + this.ENDPOINT_USER_GROUPS_LIST, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public createGroup(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_SAVE_GROUP, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshGroupList$.emit(body);

          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public editGroup(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_SAVE_GROUP, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshGroupList$.emit(body);

          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getGroup(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_SAVE_GROUP + "/" + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteGroup(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_SAVE_GROUP + "/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshGroupList$.emit("Deleted Group");

          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
