import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { JobOrdersService } from "src/app/_services/job-orders.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";

import * as _ from "lodash";
import * as moment from "moment-timezone";
import { ErrorUtil } from "src/app/_utilities/error";
import { TranslateService } from "@ngx-translate/core";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

@Component({
  selector: "app-add-edit-hpform",
  templateUrl: "./add-edit-hpform.component.html",
  styleUrls: ["./add-edit-hpform.component.scss"],
})
export class AddEditHPFormComponent  extends SubscriptionUtil implements OnInit, OnDestroy {
  defaultDate = new Date();

  @Output() submitEvent = new EventEmitter();

  jobOrderId: any;
  jobOrderNumber: any;
  data: any;
  hpForm: any = {
    customerName: "",
    jobOrderNumber: "",
    status: "",
    receivedDate: "",
    receivedTime: "",
    caseId: "",
    caseDetails: "",
    assetNumber: "",
    serialNumber: "",
    closureCode: { id: "" },
    delayCode: { id: "" },
    reason: "",
  };
  closureCodes: any;
  delayCodes: any;
  rejectedCodes: any;
  dateConfig: any;
  title: any;

  public statuses = [
    { value: "Accepted", label: "Accepted" },
    { value: "Rejected", label: "Rejected" },
    { value: "Requested Reassign", label: "Requested Reassign" },
    { value: "Confirm DT", label: "Confirm DT" },
    { value: "Rescheduled", label: "Rescheduled" },
    { value: "Customer Delay", label: "Customer Delay" },
    { value: "Parts Hold", label: "Parts Hold" },
    { value: "Travelling", label: "Travelling" },
    { value: "On Site", label: "On Site" },
    { value: "Request to Cancel", label: "Request to Cancel" },
    { value: "Request Escalation", label: "Request Escalation" },
    { value: "Complete", label: "Complete" },
    { value: "Request to Close", label: "Request to Close" },
  ];
  translate: any;

  constructor(
    private jobOrderService: JobOrdersService,
    private spinner: NgxSpinnerService,
    public modalRef: BsModalRef,
    private auth: AuthenticationService,
    private utilService: UtilServiceService,
    private errorUtil: ErrorUtil,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
    this.getLanguageData()}

  ngOnInit() {
    this.init();
  }

  /******************************************** init ******************************************/
  private init() {
    this.dateConfig = {
      dateInputFormat: localStorage.getItem("date_format")!.toUpperCase(),
      rangeInputFormat: localStorage.getItem("date_format")!.toUpperCase(),
    };
    this.jobOrderService.getCodes("Closure Code").subscribe((res: any) => {
      this.closureCodes = res;
    });

    this.jobOrderService
      .getViewHpForm(this.data.jobOrder.id, this.data.jobOrder.jobOrderNumber)
      .subscribe((res: any) => {
        this.hpForm = res;
        this.hpForm.receivedDate = this.hpForm.receivedDate
          ? moment(this.hpForm.receivedDate).toDate()
          : this.hpForm.receivedDate;

        this.hpForm.rescheduleDateTo = this.hpForm.rescheduleDateTo
          ? moment(this.hpForm.rescheduleDateTo).format(
              this.dateConfig.dateInputFormat
            )
          : this.hpForm.rescheduleDateTo;

        this.delayCodes = [];
        this.jobOrderService.getCodes("Delay Code").subscribe((res: any) => {
          if (this.data.slastatus == "VIOLATED") {
            //1-14
            for (var i = 1; i <= res.length - 1; i++) {
              this.delayCodes.push(res[i]);
            }
          } else {
            //0
            this.delayCodes.push(res[0]);
            this.hpForm.delayCode.id = this.delayCodes[0].id;
          }
        });

        this.jobOrderService
          .getCodes(this.hpForm.status)
          .subscribe((res: any) => {
            this.rejectedCodes = res;
          });

        this.hpForm.receivedTime = this.hpForm.receivedTime
          ? moment(this.hpForm.receivedTime).format("h:mm A")
          : "";

        if (!this.hpForm.closureCode) {
          this.hpForm.closureCode = { id: "" };
        }
        if (!this.hpForm.delayCode) {
          this.hpForm.delayCode = { id: "" };
        }
        if (!this.hpForm.reason) {
          this.hpForm.reason = "";
        }
        if (!this.hpForm.status) {
          this.hpForm.status = "";
        }
      });
  }

  /******************************************** on status code change ******************************************/
  public statusChange() {
    this.hpForm.reason = "";
    this.hpForm.otherReason = "";
    if (
      this.hpForm.status == "Complete" ||
      this.hpForm.status == "Request to Close"
    ) {
      this.delayCodes = [];
      this.jobOrderService.getCodes("Delay Code").subscribe((res: any) => {
        if (this.data.slastatus == "VIOLATED") {
          //1-14
          for (var i = 1; i <= res.length - 1; i++) {
            this.delayCodes.push(res[i]);
          }
        } else {
          //0
          this.delayCodes.push(res[0]);
          this.hpForm.delayCode.id = this.delayCodes[0].id;
        }
      });
    } else {
      this.hpForm.delayCode.id = "";
      this.jobOrderService
        .getCodes(this.hpForm.status)
        .subscribe((res: any) => {
          this.rejectedCodes = res;
        });
    }
    if (
      this.hpForm.status != "Rescheduled" &&
      this.hpForm.status != "Customer Delay"
    ) {
      this.hpForm.rescheduleDateTo = "";
    }
    if (
      this.hpForm.status != "Request Escalation" &&
      this.hpForm.status != "Complete"
    ) {
      this.hpForm.findings = "";
    }
    if (this.hpForm.status != "Request Escalation") {
      this.hpForm.escalatedTo = "";
      this.hpForm.additionalPart = "";
    }
    if (
      this.hpForm.status != "Request to Cancel" &&
      this.hpForm.status != "Request Escalation"
    ) {
      this.hpForm.followUp = "";
    }
  }

  /******************************************** on submit ******************************************/
  public onSubmit() {
    var formData;
    formData = _.cloneDeep(this.hpForm);

    if (!formData.closureCode.id) {
      formData.closureCode = null;
    }

    if (!formData.delayCode.id) {
      formData.delayCode = null;
    }

    let receivedDate = formData.receivedDate
      ? moment(
          formData.receivedDate,
          localStorage.getItem("date_format")!.toUpperCase()
        )
      : "";
    formData.receivedDate = receivedDate
      ? moment.tz(receivedDate, this.auth.getUserTimezone())
      : "";

    let rescheduleDateTo = formData.rescheduleDateTo
      ? moment(
          formData.rescheduleDateTo,
          localStorage.getItem("date_format")!.toUpperCase()
        )
      : "";
    formData.rescheduleDateTo = rescheduleDateTo
      ? moment.tz(rescheduleDateTo, this.auth.getUserTimezone())
      : "";

    const date = formData.receivedDate
      ? moment(formData.receivedDate).format("YYYY-MM-DD")
      : "";

    formData.receivedTime = formData.receivedTime
      ? this.utilService.timeFormatter(
          moment(formData.receivedTime, "h:mm A").format("h:mm A"),
          date
        )
      : "";

      if (formData.followUp && (formData.followUp === "" || formData.followUp.length === 0)) {
        formData.followUp = null;
      }

      if (formData.findings && (formData.findings === "" || formData.findings.length === 0)) {
        formData.findings = null;
      }

      if (formData.additionalPart && (formData.additionalPart === "" || formData.additionalPart.length === 0)) {
        formData.additionalPart = null;
      }

      if (formData.otherReason && (formData.otherReason === "" || formData.otherReason.length === 0)) {
        formData.otherReason = null;
      }

    this.jobOrderService
      .editHpForm(
        this.data.jobOrder.id,
        this.data.jobOrder.jobOrderNumber,
        formData
      )
      .subscribe((res: any) => {
        if (res.status === 200 || res.status === 201) {
          this.submitEvent.next({ status: "success" });

          this.modalRef.hide();

          this.errorUtil.setErrorMessage(
            200,
           this.translate?.general.messages.savedSuccessfully,
            null,
            "success",
            1000
          );
        } else {
          this.errorUtil.setErrorMessage(
            "",
            res.error.title,
            null,
            "error",
            2000
          );
        }
        this.spinner.hide();
      });
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    console.log("translate",this.translate)
  }

  push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
