import { MyDashboardComponent } from './my-dashboard/my-dashboard.component';
import { InventoryDashboardComponent } from './inventory-dashboard/inventory-dashboard.component';
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { TabView } from "primeng/tabview";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ModalServiceService } from 'src/app/_services/modal-service.service';
import { UsersService } from 'src/app/_services/admin/users.service';

import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { homeTabDetails, LanguageDataModelForTabs } from 'src/app/_models/tabs.data.model';
import * as _ from 'lodash';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent extends SubscriptionUtil implements OnInit, AfterViewInit {
  activeIndex: any;

  public toggleSection = {
    magnum: {
      jobOrderDashboard: false,
    },
    hills: {
      dashboard: false,
      national: false,
    },
    chubb: {
      journeyManagement: false,
      chubbDahsboard: false,
    },
    inventoryDashboard:false,
    pmDahsboard: false,
    myDashboard: false
  };

  public isHeaderValue = false;
  public headerValue = "";

  addonMagnum: any;
  addonHills: any;
  addonChubb: any;
  addonINVENTORY: any
  loadDataDashboard: any = 0

  @ViewChild(TabView, { static: false }) tabView!: TabView;
  translate: any;
  loadTabs:any[] = []
  contractLangData: any; // to load language translated data for
  tabMenu: any[];;

  constructor(
    private auth: AuthenticationService,
    private util: UtilServiceService,
    private spinner: NgxSpinnerService,
    private modalService: ModalServiceService,
    private userService : UsersService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
    //this.getLanguageData();
    }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init();
  }, 1000);
  }

  /******************************************** init ******************************************/
  private init() {
    this.loadTabs = homeTabDetails
    this.push(
      this.languageTranslateService.wordSrc$.subscribe(data => { 
        if(Object.keys(data).length != 0){
          this.translate = data
          //this.contractLangData= data?.contracts;
          this.loadTabs.forEach(element => {
            element.headerName =   this.languageTranslateService.getNestedValue(this.translate, element.translatePath)
            
          });
          
          console.log(this.loadTabs);
        }
      })
    )
//  We can simplify the code by removing the unnecessary code and making the code more readable. We can also remove the unused imports and variables.
    // this.userService.getWelcomeNotice(this.auth.getUserId()).subscribe((res: any) => {
    //   console.log(res,"----->>>>>>>")
    //   if(res.message == 'incompleted'){
    //    //    this.modalService.openModal('welcomeUpdate', null);
    //   }
    // })

    
    this.loadDataDashboard = 1
    // delay for 1sec to let login load after registration
    this.loadAddon().then(() => {
      const activeTab = this.util.getTabActive();

      if (activeTab) {
        let foundTab = false;

        for (let t = 0; t < this.tabView?.tabs?.length; t++) {
          if (this.tabView?.tabs[t].header === activeTab) {
            this.activeIndex = t;
            foundTab = true;
          }
        }
  
        if (foundTab === false) {
          this.activeIndex = 0;
        }

        this.loadData(this.activeIndex, this.tabView);
      } else {
        this.activeIndex =
          localStorage.getItem("activeIndex") == undefined
            ? 0
            : Number(localStorage.getItem("activeIndex"));

        setTimeout(() => {
          this.loadData(this.activeIndex, this.tabView);
        }, 500);
      }
    });

  }

  /******************************************** Load Addon ******************************************/
  private loadAddon() {
    return new Promise<void>((resolve) => {
      this.addonMagnum = this.auth.getAddonFeatureLists().addonMAGNUM;
      this.addonHills = this.auth.getAddonFeatureLists().addonHILLS;
      this.addonChubb = this.auth.getAddonFeatureLists().addonCHUBB;
        this.addonINVENTORY = this.auth.getAddonFeatureLists().addonINVENTORY;
        console.log(this.addonINVENTORY,"=====")
        setTimeout(() => {
          resolve();
        }, 1000);
      });
   
  }

  /******************************************** pre set tab ******************************************/
  private loadData(e: any, tabView: TabView) {
    if (this.tabView.tabs[e]) {
      const headerValue = this.tabView.tabs[e].header;
      this.handleChange(e,tabView);
    } else {
      if (this.tabView) {
        const headerValue = this.tabView.tabs[0]
          ? this.tabView.tabs[0].header
          : null;
        if (headerValue !== null) {
          this.handleChange(e,tabView);
        }
      }
    }
  }

  /******************************************** handle on tab change ******************************************/
  

  handleChange(e: any, tabView: TabView) {

    let changedTabIndex;
    if (e.index >= 0) {
      changedTabIndex = e.index
    }
    else {
      changedTabIndex = e
    }
    console.log(e, "handle CHnge", changedTabIndex)
    const currentTabHeader = this.tabView.tabs[changedTabIndex].header; // Access untranslated header
    localStorage.setItem("activeIndexJobOrder", changedTabIndex);
    console.log(changedTabIndex, "====", currentTabHeader);
   
    this.activeIndex = changedTabIndex;
    const tabHeader = currentTabHeader
   // console.log(tabHeader, "====", this.activeIndex);
    this.setTabHeader(tabHeader);
    
  }

  setTabHeader(headerValue: any) {
    this.getLanguageForMenu()
    var filteredModule = this.util.setTabHeaderData(headerValue , this.tabMenu, this.loadTabs, this.translate.dashboard.sections);
    console.log("Match=====,",filteredModule,"====================",this.tabMenu)
    
    // get Tab header name and comapare with the module name
    this.loadTabs.forEach(element => {
      if(filteredModule.plural == element.headerName || filteredModule.details == element.headerName || filteredModule.history == element.headerName || filteredModule.selectedSubTab == element.headerName){ element.highlight= true }
    });
    //this.handleEditVisibility()
    console.log(filteredModule)
    
  }



    /************************** Start Tab Highlight: Load Language Translated Dynamically (Preeti) ************************************/
getLanguageForMenu() {
  var data: any = new LanguageDataModelForTabs(this.translate);
  this.tabMenu = [...data.moduleData];
    _.remove(this.tabMenu, (n: any) => n == undefined);
   console.log(this.tabMenu);
}

  push(obs:any) {
    super.push(obs);
  }

}
