import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-machines-specialist',
  templateUrl: './machines-specialist.component.html'
})
export class MachinesSpecialistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
