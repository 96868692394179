<div class="non-scroll">
    <div class="custella-form-container left-right-space rounded-2">
        <div class="custella-form-container-title rounded-top" id="title_SetUserListViewDets">{{translate?.setup.modules.listView.sections.sec0.singular }}</div>
        <div class="custella-form-container-title-two-grey fst-italic text-12 ps-4" id="subtitle_SetUserListViewDets">{{translate?.setup.users.users.messages.listViewEachModule }}</div>
        <div class="custella-form-container-content p-0 m-0 rounded-bottom">
            <p-table responsiveLayout="scroll" [value]="profileTableColumnData"  sortField="module" [sortOrder]="1">
                <ng-template pTemplate="header">
                    <tr class="">
                        <th class="col-5 px-5" id="label_SetUserListViewModule">{{translate?.setup.users.users.fields.module.singular }}</th>
                        <th class="col-5" id="label_SetUserListViewLabel">{{translate?.setup.modules.picklist.fields.label.singular }}</th>
                        <th class="col-2"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-profileTableColumnData let-rowIndex="rowIndex">
                    <tr class="text-12 font-medium"  *ngIf="profileTableColumnData.moduleAccessShow">
                        <td class="col-5 px-5" id="value_SetUserListViewModule">{{profileTableColumnData.module}}</td>
                        <td class="col-5">
                            <span *ngIf="!profileTableColumnData.dropdownShow" class="font-medium" id="value_SetUserListViewLabel">{{profileTableColumnData.view}}</span>
                            <span *ngIf="!profileTableColumnData.dropdownShow" class="font-medium" id="value_SetUserListViewLabel">{{profileTableColumnData.template ? profileTableColumnData.template?.templateName:'-'}}</span>
                            <span *ngIf="profileTableColumnData.dropdownShow">
                                <p-dropdown  placeholder="Select Default Template" id="picklist_SetUserListViewField" [options]="profileTableColumnData.picklist" [(ngModel)]="profileTableColumnData.selectedTemp" optionLabel="templateName" [filter]="false" filterBy="templateName" optionValue="id" [showClear]="false"></p-dropdown>
                            </span>
                        </td>
                        <!-- <td class="col-2">
                            <div *ngIf="!profileTableColumnData.dropdownShow">
                                <a class="color-primary font-semiBold" (click)="editView(rowIndex)">{{'general.buttons.edit.singular'}}</a>
                                <a class="color-primary font-semiBold ms-4" (click)="redirectPage(profileTableColumnData)">{{'setup'}}</a>
                            </div>
                            <div class="df-lv-btn" *ngIf="profileTableColumnData.dropdownShow">
                              <button class="" (click)="editView(rowIndex)"><img src="../../../../../assets/svg/table/cancel-x.svg" alt="Cancel List View"></button>
                              <button class="ms-4" (click)="showConfirm(rowIndex)"><img src="../../../../../assets/svg/table/save-tick.svg" alt="Save List View"></button>
                            </div>
                        </td> -->
                        <td class="col-2">
                            <!-- Edit, Setup -->
                            <div class="flex-align-center justify-content-center gap-4" *ngIf="!profileTableColumnData.dropdownShow">
                                <span (click)="editView(rowIndex)" [pTooltip]="translate?.general.buttons.edit.singular" tooltipPosition="top" id="tooltip_SetUserListViewEdit">
                                    <i class="pi pi-pencil"></i>
                                </span>
                                <span class="ms-2" *ngIf="module" (click)="redirectPage(profileTableColumnData)" id="tooltip_SetUserListViewSetup" [pTooltip]="translate?.general.buttons.setup.singular" tooltipPosition="top">
                                    <i class="pi pi-cog"></i>
                                </span>
                            </div>
                            <!-- Cancel, Save -->
                            <div class="df-lv-btn flex-align-center justify-content-center" *ngIf="profileTableColumnData.dropdownShow">
                                <button class="" (click)="editView(rowIndex)" id="btn_SetUserListViewDiscardChanges" [pTooltip]="translate?.general.buttons.discardChanges.singular" tooltipPosition="top"><img src="../../../../../assets/svg/table/square-xmark-light-red.svg" alt="Cancel List View"></button>
                                <button class="ms-4" (click)="showConfirm(rowIndex)" id="btn_SetUserListViewSubmit" [pTooltip]="translate?.general.buttons.save.singular" tooltipPosition="top"><img src="../../../../../assets/svg/table/square-check-light-green.svg" alt="Save List View"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
        
</div>
