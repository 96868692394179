import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';

@Component({
  selector: 'app-accept-decline-task',
  templateUrl: './accept-decline-task.component.html',
  styleUrls: ['./accept-decline-task.component.scss']
})
export class AcceptDeclineTaskComponent extends SubscriptionUtil implements OnInit, OnDestroy {

  data: any;
  option: any;
  optionLabel: any;
  mode: any;
  reason: any;
  submitTouched: Boolean = false;
  userId: any;
  isGroupDelete: any = 'false';
  translate: any;

  constructor(public modalRef : BsModalRef,
    private taskService: TasksService,
    private auth : AuthenticationService,
    private errorUtil: ErrorUtil,
    private languageTranslateService: LanguageTranslateService,
    ) {
      super();
      this.getLanguageData();}

  ngOnInit() {
    console.log(this.data);
    if(this.mode === 'accept' || this.mode == 'acceptGroup'){
      this.option = 'Accept';
      this.optionLabel = 'accepted';
    }
    else if(this.mode === 'decline' || this.mode == 'declineGroup'){
      this.option = 'Decline';
      this.optionLabel = 'declined';
    }
  }

  onSubmit() {
    this.submitTouched = true;
    if(this.mode === 'accept' || this.mode == 'acceptGroup'){
      this.taskService.acceptTask(this.data.id, this.userId, this.isGroupDelete).subscribe((res:any)=>{
        if(res.status == 200 || res.status == 201){
          this.modalRef.hide();
          this.errorUtil.setErrorMessage(200, null , this.translate?.general.messages.acceptedSuccessfully  , 'success',1000);
          //this.toaster.success('', 'Task '+this.optionLabel+' successfully');
        }else{
          this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
        }
      });
    } else if(this.mode === 'decline' || this.mode == 'declineGroup'){
      var req = {
        id : this.data.id,
        body : {reason: this.reason}
      }
      this.taskService.rejectTask(req, this.userId, this.isGroupDelete).subscribe((res:any)=>{
        //console.log("Response ", res);
        if(res.status == 200 || res.status == 201){
          this.modalRef.hide();
          this.errorUtil.setErrorMessage(200, null ,  this.translate?.general.messages.declinedSuccessfully , 'success',1000);
          //this.toaster.success('', 'Task '+this.optionLabel+' successfully');
        }else{
          this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
        }
      });
    }
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    console.log("translate",this.translate)
  }
  
  push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
