<div class="custella-form-container w-100 h-100 ms-0 rounded-2 border" style="display: flex !important; flex-direction: column; box-shadow: none;">
    <div class="flex-between">
        <i class="fa-regular fa-xmark w-24px dialog-close-icon" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left" (click)="close()"></i>
    </div>
    
    <div class="box-content">
        <div class="px-4" style="border: 1px solid #eaeaea;">
            <div class="custella-content-row mb-2 pe-0">
                <div class="custella-content-row-input col-12">
                    <div class="flex-between">
                        <span class="p-input-icon-left w-100">
                            <i class="pi pi-search me-3"></i>
                            <input type="text" pInputText autocomplete="off" [placeholder]="translate?.general.buttons.search.singular " [(ngModel)]="searchValue" (keyup)="getSearchedUserList(searchValue)"/>
                            
                            <div class="custella-content-row mb-2 px-4 user-search-assign mt-0" *ngIf="showDropdown">
                                <div class="custella-content-row-input col-12" *ngIf="searchedUsers && searchedUsers.length > 0">
                                    <div class="user-assign-box" *ngFor="let user of searchedUsers">
                                        <p-avatar *ngIf="user.imageUrl" [image]="user.imageUrl" shape="circle"></p-avatar>
                                        <p-avatar *ngIf="!user.imageUrl" image="assets/svg/Avatar.svg" shape="circle"></p-avatar>
                    
                                        <span class="text-10">{{user.fullName}}</span>
                    
                                        <span class="text-10 custella-skills-box-link ms-auto pr-2" (click)="addUser(user)">{{translate?.general.buttons.select2.singular }}</span>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="h-100 overflow-scroll">
        <div class="custella-content-row mb-2 px-4">
            <div class="custella-content-row-input col-12" *ngIf="allTheUsers && allTheUsers.length > 0">
                <div class="user-assign-box" *ngFor="let user of allTheUsers">
                    <p-avatar *ngIf="user.imageUrl" [image]="user.imageUrl" shape="circle"></p-avatar>
                    <p-avatar *ngIf="!user.imageUrl" image="assets/svg/Avatar.svg" shape="circle"></p-avatar>

                    <span class="text-10">{{user.fullName}}</span>

                    <span class="text-10 custella-skills-box-link ms-auto pr-2" (click)="removeUser(user)">{{translate?.general.buttons.remove.singular }}</span>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer -->
    <div class="custella-form-container-footer mt-auto mb-0">
        <div class="d-flex align-items-center justify-content-end">
            <p-button [label]="translate?.general.buttons.save.singular" (onClick)="confirmSelection()" styleClass="ms-2 h-32"></p-button>
        </div>
    </div>
</div>
