<div [ngClass]="isLoadedDynamically ? 'height-92 overflow-scroll pb-56px' : 'bin mt-5 h-97'">
    <div class="d-flex button-box-top-right" [ngClass]="isLoadedDynamically ? 'dynamicEdit' : ''">
        <ng-container *ngIf="loadTabs[0].highlight">
            <ng-container *ngIf="canClaim">
                <p-button [label]="translate?.general.buttons.claim.singular" styleClass="ms-2 h-28 dark-alt" *ngIf="update" id="btn_TixClaim" (onClick)="claimTicket($event)"></p-button>
            </ng-container>
            <p-button type="button" *ngIf="update" [label]="translate?.general.buttons.edit.singular" styleClass="ms-2 h-28"  id="btn_TixEdit" (onClick)="editTicket()"></p-button>
            <button class="btn-view-icon ms-2" [pTooltip]="translate?.general.buttons.more.singular" tooltipPosition="bottom" *ngIf="delete" id="btn_TixDropdown" (click)="menu.toggle($event)">
                <i class="fa-regular fa-ellipsis-vertical"></i>
            </button>
            <p-slideMenu [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" #menu [model]="items" [popup]="true" class="options2"></p-slideMenu>
        </ng-container>
    </div>
    <p-tabView class="px-0" (onChange)="handleChange($event, tabView)" [(activeIndex)]="activeIndex">
        <p-tabPanel class="details" [header]="loadTabs[0].headerName"  id="title_TixDetails">
            <app-ticket-details *ngIf="loadTabs[0].highlight" [ticketsId]="id" [ticketDetails]="ticketData"></app-ticket-details>
        </p-tabPanel>
        <p-tabPanel class="details" [header]="loadTabs[1].headerName"  id="title_TixConv">
            <app-conversation *ngIf="loadTabs[1].highlight" [ticketId]="id"></app-conversation>
        </p-tabPanel>
        <p-tabPanel [header]="loadTabs[2].headerName"  id="title_TixJob" *ngIf="(jobsAccess && menuType === 'service' && servicePer) || (jobsAccess && menuType === 'sales' && servicePer)">
            <app-ticket-job-orders *ngIf="loadTabs[2].highlight" [loadedDymanically]="isLoadedDynamically" [ticketId]="id"></app-ticket-job-orders>
        </p-tabPanel>
        <p-tabPanel [header]="loadTabs[3].headerName" id="title_TixHistory">
            <app-ticket-history *ngIf="loadTabs[3].highlight" [loadedDymanically]="isLoadedDynamically" [ticketId]="id"></app-ticket-history>
        </p-tabPanel>
        <p-tabPanel [header]="loadTabs[4].headerName"  id="title_TixSla">
            <app-sla-table *ngIf="loadTabs[4].highlight" [loadedDymanically]="isLoadedDynamically" [ticketId]="id"></app-sla-table>
        </p-tabPanel>
        <p-tabPanel [header]="loadTabs[5].headerName"  id="title_TixAttachments">
            <app-ticket-attachment *ngIf="loadTabs[5].highlight" [ticketId]="id"></app-ticket-attachment>
        </p-tabPanel>
    </p-tabView>

    <template #componentContainer> </template>
</div>
