import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';

@Component({
  selector: 'app-request-assistance',
  templateUrl: './request-assistance.component.html',
  styleUrls: ['./request-assistance.component.scss']
})
export class RequestAssistanceComponent extends SubscriptionUtil implements OnInit, OnDestroy {

  submitTouched: boolean = false;
  mode: any;
  data : any;
  requestAssistanceForm: UntypedFormGroup;
  title: any;
  request : any = {};
  translate: any;

  constructor(public modalRef: BsModalRef,
    private taskService: TasksService,
    private auth: AuthenticationService,
    private errorUtil: ErrorUtil,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
    this.getLanguageData()
    this.requestAssistanceForm = new UntypedFormGroup({
      'reason': new UntypedFormControl(null, [Validators.required])
    });
  }

  ngOnInit() {
    //console.log("Data ", this.data);
  }

  onSubmit() {
    this.submitTouched = true;
    if (!this.requestAssistanceForm.valid) {
      for (const i in this.requestAssistanceForm.controls) {
        this.requestAssistanceForm.controls[i].markAsTouched();
      }
      this.submitTouched = false;
      return false;
    }

    //console.log('Form Submit ', this.requestAssistanceForm);
    var reqObj = {
      description: this.request.reason,
      status:0,
      assistance_type:"HELP",
      createdBy:{id:this.auth.getUserId()},
      requestedFrom:{id:this.data.assignedBy.id},
      task:{id:this.data.id}
    }
    this.taskService.createRequestAssistanceForTask(reqObj).subscribe((res:any)=>{
      //console.log("Response ", res);
      if(res.status == 200 || res.status == 201){
        this.modalRef.hide();
        this.errorUtil.setErrorMessage(200, this.translate?.general.messages.sentSuccessfully,  null, 'success',1000);
      } else {
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
    });
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    console.log("translate",this.translate)
   // this.mainLabel = this.translate?.accessories.label.plural
  }

  push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}

