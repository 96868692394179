<div class="position-relative fw-normal d-flex" style="height: calc(100% - 95px);">
    <div class="table-space ag-grid-table-full" style="height: 100%;" [hidden]="noData">
        <ag-grid-angular
            #agGrid
            style="height: 100%;"
            class="ag-theme-balham"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            [defaultColDef]="defaultColDef"
            [pagination]="false"
            [cacheOverflowSize]="2"
            [maxConcurrentDatasourceRequests]="-1"
            [suppressDragLeaveHidesColumns]="true"
            [allowContextMenuWithControlKey]="true"
            [frameworkComponents]="frameworkComponents"
            (cellClicked)="viewCell($event)"
            (gridReady)="onGridReady($event)"
            [rowData]="rowData"
            [getContextMenuItems]="getContextMenuItems"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        >
        </ag-grid-angular>

        <div class="col-12">
            <p class="ag-record-row-summary-panel padds text-end" id="label_ContaTableRec">
                {{ totalRecords }}&nbsp;{{translate?.general.messages.records}}
            </p>
        </div>
    </div>
</div>
