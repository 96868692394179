import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-checklist-table',
  templateUrl: './checklist-table.component.html',
  styleUrls: ['./checklist-table.component.scss']
})
export class ChecklistTableComponent implements OnInit {
  isFilterToggleOn: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  public filterToggle() {
    this.isFilterToggleOn = !this.isFilterToggleOn;
  }

}
