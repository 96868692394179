import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-no-permission",
  templateUrl: "./no-permission.component.html",
  styleUrls: ["./no-permission.component.scss"],
})
export class NoPermissionComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  public access = {
    module: "",
    action: "",
  };
  translate: any;

  constructor(private router: Router, private route: ActivatedRoute,
    private languageTranslateService: LanguageTranslateService
  ) {
    super()
    this.push( this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    this.preInit();
  }

  ngOnInit(): void {
    this.preInit();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    const extras = this.router.getCurrentNavigation()?.extras ?? null;

    console.log(extras);

    if (extras && extras.hasOwnProperty('state') && extras.state?.access && extras.state.access.module && extras.state?.access.action) {
      this.access.module = (extras.state?.access.module as string).replace(" ", "_");
      this.access.action = extras.state?.access.action;
    } else {
      this.access.module = "no_permission";
      this.access.action = "action";
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
