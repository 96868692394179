<div class="left-right-space">
    
    <div class="flex-between mb-2">
        <div class="main-list-header" *ngIf="!noJMP" id="title_ChubbJmp" >
            {{translate?.dashboard.chubb.sections.sec16.singular}}
        </div>

        <!-- Filter and New -->
        <div class="custella-dashboard-top-right d-flex ms-auto">
            <button class="border rounded-1 px-04 ms-2 btn-32" (click)="refresh()" *ngIf="!noJMP" id="btn_ChubbJmpRefresh">
                <img alt="logo" src="assets\svg\refresh.svg"/>
            </button>

            <span class="p-input-icon-left w-100 ms-2">
                <i class="pi pi-search me-3"></i>
                <input type="text" *ngIf="!noJMP" id="input_ChubbJmpSearchField" pInputText autocomplete="off" [placeholder]="translate?.general.buttons.search.singular" [(ngModel)]="searchValue" (keyup)="quickSearch($event)"/>
            </span>
        </div>
    </div>
</div>

<div *ngIf="noJMP" class="col-12 mt-5 py-4">
    <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
        <img src="assets\svg\empty-state.svg" id="img_NoRecordsFound" />
        <p class="text-6 my-1 font-weight-bold" >
            {{translate?.general.messages.noRecordsFound}}
        </p>
    </div>
</div>

<div class="left-right-space position-relative fw-normal d-flex" style="height: calc(100% - 95px);">
    <div class="table-space ag-grid-table-full" style="height: 100%;" [hidden]="noJMP">
        <ag-grid-angular
            #agGrid
            id="jmpTable"
            style="height: 100%;"
            class="ag-theme-balham"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            [defaultColDef]="defaultColDef"
            [cacheOverflowSize]="2"
            [maxConcurrentDatasourceRequests]="-1"
            [suppressDragLeaveHidesColumns]="true"
            [allowContextMenuWithControlKey]="true"
            [frameworkComponents]="frameworkComponents"
            (cellClicked)="view($event)"
            (gridReady)="onGridReady($event)"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            [getContextMenuItems]="getContextMenuItems"
        >
        </ag-grid-angular>

        <div class="col-12">
            <p class="ag-record-row-summary-panel padds text-end"  id="label_ChubbJmpTableRec">
                {{ totalRecord }} {{ translate?.general.messages.records }}
            </p>
        </div>
    </div>
</div>
