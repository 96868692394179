<div>
    <div class="flex-between">
      <i class="pi pi-times w-24px dialog-close-icon" id="btn_MapClose" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left" (click)="onAfterHide()"></i>
    </div>
    <div class="position-relative text-start">
        <div class="custella-content-row-input me-0">
            <p-gmap #gmap id="gmap" (onOverlayDragEnd)="markerDragEnd($event)" (onMapClick)="mapClicked($event)"
            [overlays]="overlays" [options]="options"
            [style]="{'height':'320px', 'border': '1px solid #e2e2e2'}"></p-gmap>
      </div>
  </div>


<!--=======-=======-=======-=======-=======-=======-=======-======= Deline Buttone Action -=======-=======-=======-=======-=======-->

<!-- <p-dialog class="location" [(visible)]="visible" [style]="{width: '50vw'}" [modal]="true" [draggable]="false">
    <ng-template pTemplate="header">
        <div class="text-16 font-medium" id="label_InvTransferByBin">Pin & Save Your Location</div>
    </ng-template>
   [style]="{'width':'calc(100% - 3px)','height':'320px', 'margin-left':'3px', 'border': '1px solid #e2e2e2'}"
 
    <p-footer>
        <div class="d-flex align-items-center justify-content-end">
            <button class="custella-btn-white text-12 ms-2" id="btn_ApproveNo" (click)="onAfterHide()">{{'close' |
                translate}}</button>
        </div>
    </p-footer>
</p-dialog> -->