<div class="position-relative fw-normal d-flex" style="height: calc(100vh - 132px);">
    <div class="table-space ag-grid-table-full" [ngClass]="{ 'ag-grid-table': filterView }" style="height: 100%;" [hidden]="noTickets">
        <ag-grid-angular
        #agGrid
        style="height: 100%;"
        class="ag-theme-balham"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
        [defaultColDef]="defaultColDef"
        [pagination]="false"
        [cacheOverflowSize]="2"
        [maxConcurrentDatasourceRequests]="-1"
        [suppressDragLeaveHidesColumns]="true"
        [allowContextMenuWithControlKey]="true"
        [frameworkComponents]="frameworkComponents"
        (cellClicked)="viewTickets($event)"
        (gridReady)="onGridReady($event)"
        [getContextMenuItems]="getContextMenuItems"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    >
    </ag-grid-angular>

    <div class="col-12">
        <p class="ag-record-row-summary-panel padds text-end" id="label_TixTableRec">
            {{ totalRecord }}&nbsp;{{translate?.general.messages.records}}
        </p>
    </div>
    </div>

    <!-- Filter -->
    <div class="custella-form-container-content-pane layout-filter" [ngClass]="{ 'layout-filter-active': filterView }" *ngIf="!noTickets">
        <div class="custella-form-container-content-pane-title">
            <span class="text-14 font-medium" id="label_TixFilterBy">{{ translate?.general.messages.filterBy }}</span>
            <i class="fa-regular fa-arrow-right-from-line" [pTooltip]="translate?.general.buttons.closeFilter.singular" tooltipPosition="left" id="btn_TixBack" (click)="toggleFilterView()"></i>
        </div>

        <div class="custella-content">
            <form [formGroup]="ticketQueryForm">
                <div class="custella-content-input col-12">
                    <span class="custella-content-input-title" id="label_TixDateRange">{{ translate?.ticket.fields.dateRange.singular }}</span>
                    <span class="p-input-icon-right w-100">
                        <i class="pi pi-calendar" style="z-index: 1;"></i>
                        <p-calendar formControlName="selectedDateRange"  placeholder="dd/mm/yyyy - dd/mm/yyyy" selectionMode="range" dateFormat="dd/mm/yy" yearRange="2000:2030" [yearNavigator]="true"
                        class="custella-calendar"
                        [showButtonBar]="false"
                        id="picklist_TixDateRangeField"
                        appendTo="body"></p-calendar>
                    </span>
                </div>

                <div class="custella-content-input col-12">
                    <span class="custella-content-input-title" id="label_TixStatus">{{ translate?.ticket.fields.status.singular }}</span>
                    <p-multiSelect
                    name="ticketStatus"
                    [options]="allTicketStatus"
                    [placeholder]="translate?.general.messages.none"
                    optionLabel="fieldLabel"
                    optionValue="id"
                    formControlName="selectedTicketStatus"
                    id="picklist_TixStatusField"
                >
                </p-multiSelect>
                </div>

               <div class="custella-content-input col-12">
                    <span class="custella-content-input-title" id="label_TixCust" >{{ translate?.customer.label.singular }}</span>
                    <div class="p-inputgroup search">
                        <span class="p-input-icon-right w-100">
                            <p-autoComplete
                                (onSelect)="onCustomerSelect($event)"
                                field="name"
                                [suggestions]="allCustomers"
                                (completeMethod)="getCustomer($event)"
                                [(ngModel)]="selectedCustomer"
                                [placeholder]="translate?.general.messages.none"
                                [ngModelOptions]="{ standalone: true }"
                                id="picklist_TixCustField"
                                styleClass="w-100"
                            >
                            </p-autoComplete>
                            <i *ngIf="selectedCustomer && selectedCustomer?.id" id="btn_TixCustClear" (click)="clearValue('customer')" class="p-autocomplete-clear-icon pi pi-times"></i>
                        </span>
                        <button type="button" pButton icon="fa-regular fa-magnifying-glass" [pTooltip]="translate?.customer.tooltips.customerLookup.singular" tooltipPosition="left"  id="btn_TixCustSearch" (click)="showTable('customer')"></button>
                    </div>
                </div>
            </form>

            <div>
                <span href="#" class="clear" (click)="clearAll()" id="subtitle_TixClear">{{translate?.general.buttons.clearAll.singular }}</span>
            </div>
        </div>
    </div>
</div>

<!-- FOR CENTER IN PAGE -->
<footer *ngIf="showingTable.customer" class="custella-form-container-footer">
    <p-dialog [header]="translate?.customer.sections.sec6.singular" id="title_CustDet" [(visible)]="showingTable.customer" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
        <app-custella-customers (selectCustomerEvent)="onCustomerSelect($event)" (closeCustomerEvent)="closeTable('customer')"></app-custella-customers>
    </p-dialog>
</footer>