import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SettingService } from 'src/app/_services/admin/setting.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';

import { EngLangData,MalayLangData, chineseLangData, languageOptions } from "src/app/_models/language.data.model";

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss']
})
export class ConnectComponent implements OnInit {
  connect : any = {};
  connectForm: UntypedFormGroup;
  submitTouched:boolean=false;
  currentLang: any = 'English'
 
  @ViewChild('chooseLang') chooseLangEl: ElementRef;

  langData: any;
  languageList: any;
  constructor(private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private settingService : SettingService) { 
    this.connectForm = new UntypedFormGroup({
      'clientId': new UntypedFormControl(null, [Validators.required]),
      'clientSecret': new UntypedFormControl(null, [Validators.required])
    });
  }

  ngOnInit() {
    // console.log("SalesForce Connect")
    this.langData = EngLangData   ;
    this.currentLang = "English"
    this.languageList = Object.values(languageOptions);
  }

  connectSalesforce(){
    // this.spinner.show();
    this.submitTouched=true;
    if(!this.connectForm.valid){
      for (var i in this.connectForm.controls) {
        this.connectForm.controls[i].markAsTouched();
      }
      // this.spinner.hide();
      return false;
    }
    
    var data = {
      "clientId": this.connectForm.value.clientId,
      "clientSecret": this.connectForm.value.clientSecret,
    }

    this.settingService.connectToSalesforce(data).subscribe(res=>{
      // console.log("SF Resp ", res);
      if(res.status == 200){
        window.location.href = res.body.webRedirectUrl;
      }
    });
  }

  onChangeLanguage(landKey: any){
      console.log(landKey)

      if(landKey == 'English'){
          this.currentLang = languageOptions.english
          this.langData = EngLangData
          this.chooseLangEl.nativeElement.classList.remove('show');
      }
      if(landKey == 'Malay'){
        this.currentLang = languageOptions.malay
        this.langData = MalayLangData
        this.chooseLangEl.nativeElement.classList.remove('show');
      
    }
    if(landKey == 'Chinese'){
      this.currentLang = languageOptions.chinese
      this.langData = chineseLangData
      this.chooseLangEl.nativeElement.classList.remove('show');
      
    }
  }

}
