<div class="mt-56px">
    <!-- Container -->
    <form [formGroup]="addContactForm">
        <div class="custella-form-container left-right-space">
            <!-- Container Content -->
            <div class="custella-form-container-title" id="title_ContaDetails">
                {{translate?.contacts.sections.sec0.singular}} </div>
            <div class="custella-form-container-content">
                <!-- Resting State and Invalid -->
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" id="label_ContaNameSalutation"
                            >{{translate?.contacts.fields.nameSalutation.singular}}</span>
                        <div class="d-flex">
                            <p-dropdown styleClass="p-dropdown-customerTitle" [(ngModel)]="addContact.title"
                                id="picklist_ContaNameSalutationField" [ngModelOptions]="{standalone: true}"
                                name="rTitle" [options]="customerTitle" optionLabel="label" [filter]="false"
                                filterBy="label" optionValue="value" [showClear]="false"
                                [placeholder]="translate?.general.messages.none"></p-dropdown>
                            <div class="col">
                                <input type="text" id="input_ContaNameSalutationField" pInputText autocomplete="off"
                                    formControlName="name" name="customer"
                                    [placeholder]="translate?.general.messages.none"
                                    style="border-top-left-radius: 0px !important; border-bottom-left-radius: 0px !important"
                                    [(ngModel)]="addContact.fullName">
                                <div
                                    *ngIf="addContactForm.controls['name'].touched && addContactForm.controls['name'].invalid">
                                    <span *ngIf="addContactForm.controls['name'].errors?.required"
                                        class="text-10 p-error" id="label_ContaNameSalutationReqField"
                                        >{{translate?.general.validationFE.requiredField}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="custella-content-row-input col-6">
                        <div class="flex-align-baseline">
                            <span class="custella-content-row-input-title mandatory" id="label_ContaEmail"
                                >{{translate?.contacts.fields.email.singular}}</span>
                            <i class="fa-regular fa-circle-info fa-xs ms-2"
                                [pTooltip]="translate?.contacts.tooltips.eitherPhoneEmail.singular"
                                tooltipPosition="right"></i>
                        </div>
                        <input type="email" pInputText autocomplete="off" id="input_ContaEmailField"
                            (keyup)="bindUsername(addContact.email)" (ngModelChange)="checkPhoneValidation()"
                            [(ngModel)]="addContact.email" class="w-100" formControlName="email" name="email">
                        <div
                            *ngIf="addContactForm.controls['email'].touched && addContactForm.controls['email'].invalid">
                            <span *ngIf="addContactForm.controls['email'].errors?.required" class="text-10 p-error"
                                id="label_ContaEmailReqField"
                                >{{translate?.general.validationFE.requiredField}}</span>
                            <span *ngIf="addContactForm.controls['email'].errors?.pattern" class="text-10 p-error"
                                id="label_ContaEmailInvalid"
                                >{{translate?.general.validationFE.invalidEmailFormat}}</span>
                        </div>
                    </div>
                </div>

                <!-- Hover and Multipicklist -->
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" id="label_ContaDesignation"
                            >{{translate?.contacts.fields.designation.singular}}</span>
                        <input type="text" pInputText autocomplete="off" [ngModelOptions]="{standalone: true}"
                            id="input_ContaDesignationField" [(ngModel)]="addContact.designation" class="form-control">
                    </div>
                    <div class="custella-content-row-input col-6">
                        <div class="flex-align-baseline">
                            <span class="custella-content-row-input-title mandatory" id="label_ContaPhone"
                                >{{translate?.contacts.fields.phone.singular}}</span>
                            <i class="fa-regular fa-circle-info fa-xs ms-2"
                                [pTooltip]="translate?.contacts.tooltips.eitherPhoneEmail.singular"
                                tooltipPosition="right"></i>
                        </div>
                        <input type="text" pInputText autocomplete="off" id="input_ContaPhoneField"
                            (ngModelChange)="checkPhoneValidation()" [(ngModel)]="addContact.phone" class="w-100"
                            formControlName="phone" name="phone" [maxlength]="20">
                        <div
                            *ngIf="addContactForm.controls['phone'].touched && addContactForm.controls['phone'].invalid">
                            <span *ngIf="addContactForm.controls['phone'].errors?.required" class="text-10 p-error"
                                id="label_ContaPhoneReqField"
                                >{{translate?.general.validationFE.requiredField}}</span>
                            <span *ngIf="addContactForm.controls['phone'].errors?.pattern" class="text-10 p-error"
                                id="label_ContaPhoneInvalid"
                                >{{translate?.general.validationFE.invalidPhoneNumber}}</span>
                        </div>
                    </div>
                </div>
                <!-- Hover and Multipicklist -->
                <!-- <div class="custella-content-row mb-3">
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title" > {{"contact_type" }} <i class="pi pi-info-circle"
                                    pTooltip="{{'turning_contact_to_public' }}"   [escape]="false" tooltipPosition="top"></i></span>
                                <p-dropdown formControlName="type" name="type" [(ngModel)]="addContact.type"
                                [options]="customerContactType" optionLabel="label" [filter]="false" filterBy="label"
                                optionValue="value" [showClear]="true" placeholder="Select Contact Type"></p-dropdown>
                            </div>
                        </div> -->

            </div>
            <!-- *ngIf="addonFeature.addonSNL==='SNL_TENANT'" -->
             <div *ngIf="otherData.accessFrom == 'customer'">
                    <div class="custella-form-container-title-two flex-between"  *ngIf="addonFeature.addonSNL==='SNL_TENANT' || addonFeature.addonAGRIBIO==='AGRIBIO'">{{translate?.contacts.sections.sec1.singular}}
                        <div class="flex-align-center">
                            <div class="p-field-checkbox">
                                <p-checkbox [binary]="true" [disabled]="enableCustomerPortal" id="check_AllowAccessToPortal" [(ngModel)]="valSwitch" (onChange)="checkValidity()" [ngModelOptions]="{standalone: true}"></p-checkbox>
                            </div>
                            <span class="text-12 ms-2 mt-1"
                                id="label_ContaAllowAccessPortal">{{translate?.contacts.fields.allowAccessToPortal.singular}}{{valSwitch}}</span>
                        </div>

                    </div>
                           
                            <div class="custella-form-container-content"  *ngIf="(addonFeature.addonSNL==='SNL_TENANT' || addonFeature.addonAGRIBIO==='AGRIBIO') && valSwitch">
                                <!-- Hover and Multipicklist -->
                                <div class="custella-content-row mb-3">
                                    <div class="custella-content-row-input col-6" *ngIf="addContact.enableCustomerPortal">
                                        <span class="custella-content-row-input-title"
                                            id="label_ContaCusUsername">{{translate?.contacts.fields.username.singular}}</span>
                                        <input type="text" pInputText autocomplete="off" class="w-100"
                                            id="input_ContaCusUsernameField" [(ngModel)]="addContact.login"
                                            formControlName="userName" name="userName">
                                        <div class="text-10 p-error"
                                            *ngIf="addContactForm.controls['userName'].touched && addContactForm.controls['userName'].invalid">
                                            <span *ngIf="addContactForm.controls['userName'].errors?.required"
                                                id="label_ContaCusUsernameReqField"
                                                >{{translate?.general.validationFE.requiredField}}</span>
                                            <span *ngIf="addContactForm.controls['userName'].errors?.pattern"
                                                id="label_ContaCusUsernameInvalid"
                                                >{{translate?.general.validationFE.invalidUsernameFormat}}</span>
                                        </div>
                                    </div>
                                    <div class="custella-content-row-input col-6">
                                        <div class="dropdown mt-25px" *ngIf="mode=='edit' && addContact?.status">
                                            <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown">
                                                <span class="flex-align-center" style="padding: 5px 6px;">
                                                    <span *ngIf="addContact?.status == 'ACTIVE'"
                                                        class="me-2 p-tag-success p-tag p-component" id="tag_StatusActive">
                                                        <span
                                                            class="p-tag-value">{{translate?.general.status.active.singular}}</span>
                                                    </span>
                                                    <span *ngIf="addContact?.status == 'SUSPENDED'"
                                                        class="me-2 p-tag-Suspended p-tag p-component" id="tag_StatusSuspend">
                                                        <span
                                                            class="p-tag-value">{{translate?.general.status.suspended.singular}}</span>
                                                    </span>
                                                    <span *ngIf="addContact?.status == 'DEACTIVE'"
                                                        class="me-2 p-tag-Deactivate p-tag p-component" id="tag_StatusDeactive">
                                                        <span
                                                            class="p-tag-value">{{translate?.general.status.suspended.singular}}</span>
                                                    </span>
                                                    <span *ngIf="addContact?.status == 'PENDING_ACTIVATION'"
                                                        class="me-2 p-tag-Pending-Activation p-tag p-component"
                                                        id="tag_StatusPendingActivation">
                                                        <span
                                                            class="p-tag-value">{{translate?.general.status.pendingActivation.singular}}</span>
                                                    </span>
                                                    <div role="button" aria-haspopup="listbox" class="p-dropdown-trigger"
                                                        id="btn_ContaTagStatus" *ngIf="addContact?.status != 'PENDING_ACTIVATION'">
                                                        <span class="p-dropdown-trigger-icon pi pi-chevron-down"></span>
                                                    </div>
                                                </span>
                                            </div>
                                            <ul class="dropdown-menu dropdown-status"
                                                *ngIf="addContact?.status != 'PENDING_ACTIVATION'">
                                                <div class="border-secondary p-2">
                                                    <div
                                                        *ngIf="addContact?.status == 'SUSPENDED' || addContact?.status == 'ACTIVE'">
                                                        <li class="dropdown-item px-1 py-2" id="tag_StatusActive"
                                                            *ngIf="addContact?.status == 'SUSPENDED'"
                                                            (click)="openModal('activateUser', addContact);$event.stopPropagation();">
                                                            <span
                                                                class="text-12">{{translate?.general.status.active.singular}}</span>
                                                        </li>
                                                        <li class="dropdown-item px-1 py-2" id="tag_StatusSuspend"
                                                            *ngIf="addContact?.status == 'ACTIVE'"
                                                            (click)="openModal('suspendUser', addContact);$event.stopPropagation();">
                                                            <span
                                                                class="text-12">{{translate?.general.status.suspended.singular}}</span>
                                                        </li>
                                                        <li class="dropdown-item px-1 py-2" id="tag_StatusDeactiveUser"
                                                            *ngIf="addContact?.status != 'DEACTIVE'"
                                                            (click)="openModal('deactivateUser', addContact);$event.stopPropagation();">
                                                            <span
                                                                class="text-12">{{translate?.general.status.deactivate.singular}}</span>
                                                        </li>
                                                    </div>
                                                    <li class="dropdown-item px-1" id="tag_StatusPendingActivation"
                                                        style="background-color:#f1f0f1 !important"
                                                        *ngIf="addContact?.status == 'PENDING_ACTIVATION'">
                                                        <span
                                                            class="text-12">{{translate?.general.status.pendingActivation.singular}}</span>
                                                    </li>
                                                    <li class="dropdown-item px-1" id="tag_StatusDeactive"
                                                        style="background-color:#f1f0f1 !important"
                                                        *ngIf="addContact?.status == 'DEACTIVE'">
                                                        <span
                                                            class="text-12">{{translate?.general.status.deactivate.singular}}</span>
                                                    </li>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="custella-content-row mb-3">
                                    <div class="custella-content-row-input col-6">
                                        <p-button type="button" *ngIf="addContact?.status == 'ACTIVE'" class="ms-2"
                                            id="btn_ResetPassword"
                                            (onClick)="changePassword(addContact)">{{translate?.general.buttons.resetPassword.singular}}</p-button>
                                        <p-button type="button" *ngIf="addContact?.status == 'PENDING_ACTIVATION'" class="ms-2"
                                            id="btn_ResendEmail"
                                            (onClick)="resendEmail(addContact)">{{translate?.general.buttons.resendEmail.singular}}</p-button>
                                    </div>
                                </div>
                            </div>
                   
                </div>
            <!-- Container Footer -->
            <div class="custella-form-container-footer">
                <div class="flex-align-center justify-content-end">
                    <button class="custella-btn-white ms-2" id="btn_ContaCancel"
                        (click)="util.goBack()">&nbsp;{{translate?.general.buttons.cancel.singular}}</button>
                    <p-button [label]="saveButtonLabel" (onClick)="onSubmitForm()" id="btn_ContaSubmit"
                        [disabled]="submitTouched" styleClass="ms-2 h-32"></p-button>
                </div>
            </div>
        </div>
    </form>
</div>