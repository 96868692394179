import { Component, OnDestroy, OnInit } from "@angular/core";
import { GridOptions } from "ag-grid-community";
import { BsModalRef } from "ngx-bootstrap/modal";

var statusesJS: any[] = [];

import * as moment from "moment-timezone";
import { environment } from "src/environments/environment";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { DashboardService } from "src/app/_services/dashboard.service";
import { ModalServiceService } from "src/app/_services/modal-service.service";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { JobOrdersService } from "src/app/_services/job-orders.service";
import { DevicesService } from "src/app/_services/devices.service";
import { AssetService } from "src/app/_services/asset.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
var translateData: any;
@Component({
  selector: "app-magnum-dashboard-table",
  templateUrl: "./magnum-dashboard-table.component.html",
  styleUrls: ["./magnum-dashboard-table.component.scss"],
})
export class MagnumDashboardTableComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  filterView = false;

  title: any;
  data: any;
  geofenceList: any[] = [];
  noGeoFence: Boolean = false;
  faultTypes: any;

  totalRecord: any;

  //aggrid
  gridApi: any;
  displayIcon: boolean = false;
  searchValue: any;
  public gridOptions!: Partial<GridOptions>;
  columnDefs: any;
  columnDefsAssignments: any;
  columnDefsServiceRequest: any;
  columnDefsWorkshopDaily: any;
  columnDefsMonitoring: any;
  columnDefsPreventiveMaintenance: any;
  defaultColDef: any;
  overlayNoRowsTemplate: any;
  frameworkComponents: any;
  pageSize: any = 25;
  gridParams: any;
  startFrom: any;
  parameters: any;
  jobOrders: any;
  displayicon: boolean = false;
  noJobOrder!: boolean;
  rowData1: any[] = [];
  statuses: any;
  assetstatuses: any;
  filter: any = { status: null, fault: null };
  //gridColumnApi: any;

  assets: any;
  displayIconAsset: boolean = false;
  noAssets!: boolean;

  allStatus = [
    {
      label: "Open",
      value: "Open",
    },
    {
      label: "In Progress",
      value: "In Progress",
    },
    {
      label: "Completed",
      value: "Completed",
    },
    {
      label: "Pending",
      value: "Pending",
    },
    {
      label: "In Complete",
      value: "In Complete",
    },
    {
      label: "Cancelled",
      value: "Cancelled",
    },
  ];

  allStatusPreventiveMaintenance = [
    {
      label: "Pending",
      value: "PENDING",
    },
    {
      label: "Completed",
      value: "COMPLETED",
    },
    {
      label: "Overdue",
      value: "OVER_DUE",
    },
  ];
  searchValueData: boolean= false;
  translate: any;

  constructor(
    public modalService: ModalServiceService,
    private devicesService: DevicesService,
    private router: Router,
    public modalRef: BsModalRef,
    private http: HttpClient,
    private dashboardService: DashboardService,
    private assetService: AssetService,
    private jobOrderService: JobOrdersService,
    private util: UtilServiceService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();

    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.push(
      this.devicesService.refreshGroupList$.subscribe((res: any) => {
        this.onGridReady(this.gridParams);
      })
    );

    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.gridOptions = {
      cacheBlockSize: 25,
      paginationPageSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.columnDefs = [
      {
        headerName: this.translate?.jobOrder.fields.orderName.singular,
        field: "jobOrderNumber",
        width: 200,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color"];
        },
        cellRenderer: function (params: any) {
          if (params.data) {
            if (params.data.jobOrderName) {
              return (
                params.data.jobOrderNumber + "-" + params.data.jobOrderName
              );
            } else {
              return params.data.jobOrderNumber;
            }
          }
        },
      },
      {
        headerName:  this.translate?.jobOrder.fields.orderTimeDate.singular,
        field: "installationDate",
        width: 170,
        headerTooltip: "#",
        valueFormatter: function (params: any) {
          if (params.data && params.data.installationTime) {
            return moment(params.data.installationTime).format(
              "hh:mm A" +
                ", " +
                localStorage.getItem("date_format")!.toUpperCase()
            );
          } else if (
            params.data &&
            !params.data.installationTime &&
            params.data.installationDate
          ) {
            return moment(params.value).format(
              localStorage.getItem("date_format")!.toUpperCase()
            );
          }
        },
      },
      {
        headerName:  this.translate?.jobOrder.fields.status.singular,
        field: "status",
        width: 130,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params && params.data && params.data.status) {
            var data = { colorCode: "", fieldLabel: "" };
            for (var i = 0; i < statusesJS.length; i++) {
              if (String(params.data.status) == String(statusesJS[i].name)) {
                data = statusesJS[i];
              }
            }

            return (
              ' <span class="p-mr-2  p-tag p-component" style="background-color:' +
              data.colorCode +
              "50 !important ;color:" +
              data.colorCode +
              '"><span class="p-tag-value text-dark">' +
              data.fieldLabel +
              "</span></span>"
            );
          }
        },
      },
      {
        headerName: this.translate?.jobOrder.fields.fault.singular,
        field: "valueFault.name",
        width: 150,
        headerTooltip: "#",
      },
      {
        headerName: this.translate?.jobOrder.fields.resolution.singular,
        field: "valueResolution.name",
        width: 150,
        headerTooltip: "#",
      },
      {
        headerName: this.translate?.jobOrder.fields.system.singular,
        field: "systemValue",
        width: 150,
        headerTooltip: "#",
      },
      {
        headerName: this.translate?.customer.label.singular,
        field: "customer.name",
        width: 150,
        headerTooltip: "#",
      },
      {
        headerName: this.translate?.setup.serviceZone.label.singular,
        field: "serviceZone.name",
        width: 150,
        headerTooltip: "#",
      },
    ];

    this.columnDefsAssignments = [
      {
        headerName: "Technicians ",
        field: "assignedTo",
        width: 170,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color"];
        },
        cellRenderer: function (params: any) {
          if (params && params.data) {
            return params.data.assignedTo;
          }
        },
      },
      {
        headerName: this.translate?.jobOrder.label.singular,
        field: "joNumber",
        width: 150,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color"];
        },
        cellRenderer: function (params: any) {
          if (params && params.data) {
            return params.data.joNumber;
          }
        },
      },
      {
        headerName: this.translate?.task.fields.taskName.singular,
        field: "taskName",
        width: 150,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color"];
        },
        cellRenderer: function (params: any) {
          if (params.data) {
            return "<u>" + params.data.taskName + "<u>";
          }
        },
      },
      {
        headerName: this.translate?.task.fields.time.singular,
        field: "taskStartDate",
        width: 170,
        headerTooltip: "#",
        valueFormatter: function (params: any) {
          if (params.data && params.data.taskStartDate) {
            return moment(params.data.taskStartDate).format(
              "hh:mm A" +
                ", " +
                localStorage.getItem("date_format")!.toUpperCase()
            );
          }
        },
      },
      {
        headerName: this.translate?.task.fields.status.singular,
        field: "status",
        width: 150,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data && params.data.status == "Pending") {
            return (
              '<span class="p-mr-2 p-tag p-component" style="background-color: #FF7655 !important ;color: #FF7655"><span class="p-tag-value text-dark">' +
              translateData.general.status.pending.singular+
              "</span></span>"
            );
          } else if (params.data && params.data.status == "In Progress") {
            return (
              '<span class="p-mr-2 p-tag p-component" style="background-color: #FECD55 !important ;color: #FECD55"><span class="p-tag-value text-dark">' +
              translateData.general.status.inProgress.singular+
              "</span></span>"
            );
          } else if (params.data && params.data.status == "Completed") {
            return (
              '<span class="p-mr-2 p-tag p-component" style="background-color: #3CD55F !important ;color: #3CD55F"><span class="p-tag-value text-dark">' +
              translateData.general.status.completed.singular+
              "</span></span>"
            );
          } else if (params.data && params.data.status == "In Completed") {
            return (
              '<span class="p-mr-2 p-tag p-component" style="background-color: #959595 !important ;color: #959595"><span class="p-tag-value text-dark">' +
              translateData.general.status.inCompleted.singular+
              "</span></span>"
            );
          } else if (params.data && params.data.status == "Cancelled") {
            return (
              '<span class="p-mr-2 p-tag p-component" style="background-color: #D77D7D !important ;color: #D77D7D"><span class="p-tag-value text-dark">' +
              translateData.general.status.cancelled.singular+
              "</span></span>"
            );
          } else {
            if (params.data) {
              return (
                '<span class="p-mr-2 p-tag p-component" style="background-color: #B4FFEE !important ;color: #B4FFEE"><span class="p-tag-value text-dark">' +
                params.data.status.charAt(0).toUpperCase() +
                params.data.status.slice(1).toLowerCase() +
                "</span></span>"
              );
            }
          }
        },
      },
    ];

    this.columnDefsServiceRequest = [
      {
        headerName: this.translate?.jobOrder.fields.orderName.singular,
        field: "jobOrderNumber",
        width: 200,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color"];
        },
        cellRenderer: function (params: any) {
          if (params.data) {
            if (params.data.jobOrderName) {
              return (
                params.data.jobOrderNumber + "-" + params.data.jobOrderName
              );
            } else {
              return params.data.jobOrderNumber;
            }
          }
        },
      },
      {
        headerName: this.translate?.jobOrder.fields.orderTimeDate.singular,
        field: "installationDate",
        width: 170,
        headerTooltip: "#",
        valueFormatter: function (params: any) {
          if (params.data && params.data.installationTime) {
            return moment(params.data.installationTime).format(
              "hh:mm A" +
                ", " +
                localStorage.getItem("date_format")!.toUpperCase()
            );
          } else if (
            params.data &&
            !params.data.installationTime &&
            params.data.installationDate
          ) {
            return moment(params.value).format(
              localStorage.getItem("date_format")!.toUpperCase()
            );
          }
        },
      },
      {
        headerName: this.translate?.jobOrder.fields.orderType.singular,
        field: "jobOrderType.name",
        width: 150,
        headerTooltip: "#",
      },
      {
        headerName: this.translate?.jobOrder.fields.status.singular,
        field: "status",
        width: 150,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data && params.data.status) {
            var data = { colorCode: "", fieldLabel: "" };
            for (var i = 0; i < statusesJS.length; i++) {
              if (String(params.data.status) == String(statusesJS[i].name)) {
                data = statusesJS[i];
              }
            }

            return (
              ' <span class="p-mr-2  p-tag p-component" style="background-color:' +
              data.colorCode +
              "50 !important ;color:" +
              data.colorCode +
              '"><span class="p-tag-value text-dark">' +
              data.fieldLabel +
              "</span></span>"
            );
          }
        },
      },
    ];

    //Workshop Progress
    this.columnDefsWorkshopDaily = [
      {
        headerName: this.translate?.jobOrder.fields.orderName.singular,
        field: "jobOrderNumber",
        width: 200,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color"];
        },
        cellRenderer: function (params: any) {
          if (params.data) {
            if (params.data.jobOrderName) {
              return (
                params.data.jobOrderNumber + "-" + params.data.jobOrderName
              );
            } else {
              return params.data.jobOrderNumber;
            }
          }
        },
      },
      {
        headerName: this.translate?.jobOrder.fields.orderTimeDate.singular,
        field: "installationDate",
        headerTooltip: "#",
        width: 150,
        valueFormatter: function (params: any) {
          if (params.data && params.data.installationTime) {
            return moment(params.data.installationTime).format(
              "hh:mm A" +
                ", " +
                localStorage.getItem("date_format")!.toUpperCase()
            );
          } else if (
            params.data &&
            !params.data.installationTime &&
            params.data.installationDate
          ) {
            return moment(params.value).format(
              localStorage.getItem("date_format")!.toUpperCase()
            );
          }
        },
      },
      {
        headerName: this.translate?.jobOrder.fields.status.singular,
        field: "status",
        width: 150,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data && params.data.status) {
            var data = { colorCode: "", fieldLabel: "" };
            for (var i = 0; i < statusesJS.length; i++) {
              if (String(params.data.status) == String(statusesJS[i].name)) {
                data = statusesJS[i];
              }
            }

            return (
              ' <span class="p-mr-2  p-tag p-component" style="background-color:' +
              data.colorCode +
              "50 !important ;color:" +
              data.colorCode +
              '"><span class="p-tag-value text-dark">' +
              data.fieldLabel +
              "</span></span>"
            );
          }
        },
      },
      {
        headerName: this.translate?.jobOrder.fields.system.singular,
        field: "systemValue",
        width: 150,
        headerTooltip: "#",
      },
    ];

    //workshop spare monitoring
    this.columnDefsMonitoring = [
      {
        headerName: "Asset ID",
        field: "runningNo",
        width: 210,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color"];
        },
        cellRenderer: function (params: any) {
          if (params.data) {
            return params.data.runningNo;
          }
        },
      },
      {
        headerName: this.translate?.asset.fields.assetName.singular,
        field: "name",
        width: 150,
        headerTooltip: "#",
      },
      {
        headerName: this.translate?.asset.fields.status.singular,
        field: "assetsStatus.name",
        width: 150,
        headerTooltip: "#",
      },
      {
        headerName: this.translate?.asset.fields.type.singular,
        field: "assetsType.name",
        width: 150,
        headerTooltip: "#",
      },
    ];

    //Preventive Maintenance
    this.columnDefsPreventiveMaintenance = [
      {
        headerName: "Scheduled Maintenance ID",
        field: "scheduleNumber",
        width: 190,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color"];
        },
        cellRenderer: function (params: any) {
          if (params.data) {
            return params.data.scheduleNumber;
          }
        },
      },
      {
        headerName: this.translate?.asset.fields.assetName.singular,
        field: "assets.name",
        width: 150,
        headerTooltip: "#",
      },
      {
        headerName: this.translate?.asset.fields.status.singular,
        field: "status",
        width: 150,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data) {
            if (params.data.status == "PENDING") {
              return (
                '<span class="p-mr-2 p-tag p-component" style="background-color: #FF7655 !important ;color: #FF7655"><span class="p-tag-value text-dark">' +
                translateData.general.status.pending.singular+
                "</span></span>"
              );
            } else if (params.data.status == "COMPLETED") {
              return (
                '<span class="p-mr-2 p-tag p-component" style="background-color: #3CD55F !important ;color: #3CD55F"><span class="p-tag-value text-dark">' +
                translateData.general.status.completed.singular +
                "</span></span>"
              );
            } else if (params.data.status == "OVER_DUE") {
              return (
                '<span class="p-mr-2 p-tag p-component" style="background-color: #ef4b4b !important ;color: #ef4b4b"><span class="p-tag-value text-dark">' +
                translateData.general.status.overdue.singular +
                "</span></span>"
              );
            }
          }
        },
      },
      {
        headerName:  this.translate?.preventiveMaintenance.fields.maintenanceDate.singular,
        field: "scheduledDateTime",
        width: 190,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data && params.data.scheduledDateTime) {
            return moment(params.value).format(
              localStorage.getItem("date_format")!.toUpperCase()
            );
          }
        },
      },
      {
        headerName: this.translate?.preventiveMaintenance.fields.types.singular,
        field: "type",
        width: 130,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data) {
            if (params.data.type == "BREAKDOWN") {
              return "Break Down";
            } else if (params.data.type == "PREVENTIVE") {
              return "Preventive";
            } else if (params.data.type == "ON_DEMAND") {
              return "On Demand";
            } else if (params.data.type == "OTHERS") {
              return "Others";
            }
          }
        },
      },
      {
        headerName: this.translate?.setup.serviceZone.label.plural.plural,
        field: "serviceZone.name",
        width: 150,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data && params.data.serviceZone) {
            return params.data.serviceZone.name;
          }
        },
      },
    ];

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true,
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      allowedAggFuncs: ["sum", "min", "max", "random"],
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.overlayNoRowsTemplate =
      '<span style="padding: 10px; font-size: 14px;">Record not found</span>';
  }

  /******************************************** Init ******************************************/
  private init() {
    this.getLanguageData();
    this.push(
      this.jobOrderService.getJobOrderStatuses().subscribe((res: any) => {
        this.statuses = res.sort((elementA: any, elementB: any) => {
          if (elementA?.order > elementB?.order) {
            return 1;
          }

          if (elementA?.order < elementB?.order) {
            return -1;
          }

          return 0;
        });

        res.forEach((element: any) => {
          statusesJS.push(element);
        });
      })
    );

    this.push(
      this.assetService.getStatuses(true).subscribe((res: any) => {
        this.assetstatuses = res.body.sort((elementA: any, elementB: any) => {
          if (elementA?.order > elementB?.order) {
            return 1;
          }

          if (elementA?.order < elementB?.order) {
            return -1;
          }

          return 0;
        });

      })
    );

    this.push(
      this.dashboardService.getMagnumFaultTypes().subscribe((res: any) => {
        this.faultTypes = res.sort((elementA: any, elementB: any) => {
          if (elementA?.order > elementB?.order) {
            return 1;
          }

          if (elementA?.order < elementB?.order) {
            return -1;
          }

          return 0;
        });

      })
    );

    if (this.title == "Total Assignments") {
      this.columnDefs = this.columnDefsAssignments;
    } else if (this.title == "Service Requests") {
      this.columnDefs = this.columnDefsServiceRequest;
    } else if (this.title == "Workshop Progress") {
      this.columnDefs = this.columnDefsWorkshopDaily;
    }
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** On Grid ******************************************/
  public onGridReady(params: any) {
    this.gridParams = params;
    this.searchValue = this.searchValue ? this.searchValue : "";
    this.gridApi = params.api;

    var dataSource = {
      getRows: (params: any) => {
        this.startFrom = params.startRow + 1;
        if (params.sortModel.length <= 0) {
          if (this.title == "Total Assignments") {
            this.parameters = { colId: "assignedTo", sort: "asc" };
          } else {
            this.parameters = { colId: "jobOrderNumber", sort: "asc" };
          }
          params.sortModel.push(this.parameters);
        }
        var fetchOnlyOverDueJobOrders;
        if (this.title == "Overdue Fault Calls") {
          fetchOnlyOverDueJobOrders = true;
        } else {
          fetchOnlyOverDueJobOrders = "";
        }
        var jobOrderTypeNames;
        if (this.title == "Service Requests") {
          jobOrderTypeNames =
            "Miscellaneous Technical,Repair service,Miscellaneous Non-Technical";
        } else if (
          this.title == "Workshop Fault Calls" ||
          this.title == "Data Line Fault Calls" ||
          this.title == "Overdue Fault Calls"
        ) {
          jobOrderTypeNames = "Fault Call";
        } else {
          jobOrderTypeNames = "";
        }
        var faultNotEmpty;
        if (this.title == "Data Line Fault Calls") {
          faultNotEmpty = true;
        } else {
          faultNotEmpty = "";
        }
        var systemNotEmpty;
        if (this.title == "Workshop Progress") {
          systemNotEmpty = true;
        } else {
          systemNotEmpty = "";
        }
        var systems;
        if (this.title == "Data Line Fault Calls") {
          systems =
            "IPVPN Router (Network),IPVPN Switch (Network),TM Line (Network)";
        } else {
          systems = "";
        }
        var statusData = "";
        var faultData = "";
        if (this.filter.status) {
          statusData = "&status=" + this.filter.status;
        }
        if (this.filter.fault) {
          faultData = "&faultId=" + this.filter.fault;
        }
        var roleId = this.data.roleId ? this.data.roleId : "";

        if (this.title == "Total Assignments") {
          this.push(
            this.http
              .get(
                environment.base_url +
                  "/dashboard/task-assignment-table-view?startFrom=" +
                  this.startFrom +
                  "&perPage=25&search=" +
                  this.searchValue +
                  statusData +
                  "&roleId=" +
                  roleId +
                  "&fromDate=" +
                  this.data.fromDate +
                  "&toDate=" +
                  this.data.toDate +
                  "&columnName=" +
                  params.sortModel[0].colId +
                  "&sortOrder=" +
                  params.sortModel[0].sort +
                  faultData
              )
              .subscribe((data: any) => {
                this.gridApi.hideOverlay();

                this.jobOrders = data.data;

                if (data.status === "NO_DATA") {
                  this.noJobOrder = false;
                  this.displayicon = true;
                } else {
                  this.noJobOrder = true;
                  this.displayicon = false;
                }

                if (this.jobOrders.length === 0) {
                  this.gridApi.showNoRowsOverlay();

                  this.totalRecord = 0;

                  params.successCallback([], 0);
                } else {
                  this.totalRecord = data.total;
                  params.successCallback(this.jobOrders, data.total);
                }
              })
          );
        } else {
          this.push(
            this.http
              .get(
                environment.base_url +
                  "/v3/job-orders?startFrom=" +
                  this.startFrom +
                  "&perPage=25&search=" +
                  this.searchValue +
                  statusData +
                  "&fetchOnlyOverDueJobOrders=" +
                  fetchOnlyOverDueJobOrders +
                  "&jobOrderTypeNames=" +
                  jobOrderTypeNames +
                  "&faultNotEmpty=" +
                  faultNotEmpty +
                  "&systemNotEmpty=" +
                  systemNotEmpty +
                  "&systems=" +
                  systems +
                  "&roleId=" +
                  roleId +
                  "&fromDate=" +
                  this.data.fromDate +
                  "&toDate=" +
                  this.data.toDate +
                  "&columnName=" +
                  params.sortModel[0].colId +
                  "&sortOrder=" +
                  params.sortModel[0].sort +
                  faultData
              )
              .subscribe((data: any) => {
                this.gridApi.hideOverlay();

                this.rowData1 = [];
                this.jobOrders = data.data;
                if (data.status === "NO_DATA") {
                  this.noJobOrder = false;
                  this.displayicon = true;
                } else {
                  this.noJobOrder = true;
                  this.displayicon = false;
                }
                if (this.jobOrders.length === 0) {
                  this.gridApi.showNoRowsOverlay();
                  this.totalRecord = 0;

                  params.successCallback([], 0);
                } else {
                  for (var i = 0; i <= data.data.length - 1; i++) {
                    this.rowData1.push(data.data[i].jobOrder);
                  }

                  this.totalRecord = data.total;

                  params.successCallback(this.rowData1, data.total);
                }
              })
          );
        }
      },
    };

    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDatasource(dataSource);
  }

  /******************************************** Workshop Spare Monitoring ******************************************/
  public onGridReadyMonitoring(params: any) {
    this.gridParams = params;
    this.searchValue = this.searchValue ? this.searchValue : "";
    this.gridApi = params.api;

    var dataSource = {
      getRows: (params: any) => {
        this.startFrom = params.startRow + 1;
        if (params.sortModel.length <= 0) {
          this.parameters = { colId: "name", sort: "asc" };
          params.sortModel.push(this.parameters);
        }
        var assetTypeNames =
          "Main Unit (Terminal), Printer (Terminal), Keyboard (Terminal), Monitor (Terminal), IPVPN Router (Network),IPVPN Switch (Network)";
        // no need to pass dates here

        this.push(
          this.assetService
            .getMagnumAllAssets(
              this.startFrom,
              25,
              this.searchValue,
              "",
              this.filter.status,
              "",
              "",
              this.data.roleId,
              assetTypeNames,
              params.sortModel[0].colId,
              params.sortModel[0].sort
            )
            .subscribe((data: any) => {
              this.gridApi.hideOverlay();
              console.log("check v5",data);
              this.assets = data.data;
              if (data.status === "NO_DATA") {
                this.noAssets = false;
                this.displayIconAsset = true;
              } else {
                this.noAssets = true;
                this.displayIconAsset = false;
              }
              if (this.assets.length === 0) {
                this.totalRecord = 0;

                params.successCallback([], 0);

                this.gridApi.showNoRowsOverlay();
              } else {
                this.totalRecord = data.total;

                params.successCallback(this.assets, data.total);
              }
            })
        );
      },
    };

    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDatasource(dataSource);
  }

  /******************************************** On Grid Preventative Maintenace ******************************************/
  public onGridReadyPreventiveMaintenance(params: any) {
    this.gridParams = params;
    this.searchValue = this.searchValue ? this.searchValue : "";
    this.gridApi = params.api;

    var dataSource = {
      getRows: (params: any) => {
        this.startFrom = params.startRow + 1;
        if (params.sortModel.length <= 0) {
          this.parameters = { colId: "scheduledDateTime", sort: "desc" };
          params.sortModel.push(this.parameters);
        }
        var roleId = this.data.roleId ? this.data.roleId : "";

        this.push(
          this.assetService
            .getMagnumScheduledMaintenanceGridAll(
              this.startFrom,
              25,
              this.searchValue,
              this.filter.status,
              "",
              this.data.fromD,
              this.data.toDate,
              "",
              roleId,
              params.sortModel[0].colId,
              params.sortModel[0].sort
            )
            .subscribe((data: any) => {
              this.gridApi.hideOverlay();

              this.assets = data.data;

              if (data.status === "NO_DATA") {
                this.noAssets = false;
                this.displayIconAsset = true;
              } else {
                this.noAssets = true;
                this.displayIconAsset = false;
              }
              if (this.assets.length === 0) {
                this.gridApi.showNoRowsOverlay();

                this.totalRecord = 0;

                params.successCallback([], 0);
              } else {
                this.totalRecord = data.total;

                params.successCallback(this.assets, data.total);
              }
            })
        );
      },
    };
    ///CS-4758 this.gridApi.sizeColumnsToFit();
    this.gridApi.setDatasource(dataSource);
  }

  /******************************************** Acts as Refresh ******************************************/
  public filterResults() {
    if (this.title == "Preventive Maintenance") {
      this.onGridReadyPreventiveMaintenance(this.gridParams);
    } else if (this.title == "Workshop Spare Monitoring") {
      this.onGridReadyMonitoring(this.gridParams);
    } else {
      this.onGridReady(this.gridParams);
    }
  }

  /******************************************** Search Table ******************************************/
  public onSearch() {
    if (this.searchValue && this.searchValue.length > 2) {
      this.searchValueData = true
      this.filterResults();
    }

    if (this.searchValue === "" && this.searchValue.length === 0) {
      this.filterResults();
    }
  }

  /******************************************** View ******************************************/
  public view(e: any) {
    let url = "";

    if (e.colDef.field === "jobOrderNumber") {
      localStorage.setItem("activeIndexJobOrder","0");
      url = this.router.serializeUrl(
        this.router.createUrlTree(["/dashboard/jobOrders/view/" + e.data.id], {
          queryParams: { jobOrderId: e.data.id },
        })
      );
    } else if (e.colDef.field === "assignedTo") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(["/dashboard/setup/allUser/view"], {
          queryParams: { id: e.data.technicianId,edit:false },
        })
      );
    } else if (e.colDef.field === "joNumber") {
      localStorage.setItem("activeIndexJobOrder","0");
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["/dashboard/jobOrders/view/" + e.data.jobOrderId],
          { queryParams: { jobOrderId: e.data.jobOrderId } }
        )
      );
    } else if (e.colDef.field === "taskName") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(["/dashboard/tasks/allMyTask"], {
          queryParams: { taskId: e.data.taskId ,requestAssistance: 'true' },
        })
      );
    


      this.util.openTaskVIewPage(e.data.taskId)
    }

    this.util.openItemToNewTab(url);
  }

  /******************************************** View Asset ******************************************/
  public viewAsset(e: any) {
    if (e.colDef.field === "runningNo") {
      localStorage.setItem("activeIndexAssetView", "0");
      var url = this.router.serializeUrl(
        this.router.createUrlTree(["/dashboard/assets/view/" + e.data.id], {
          queryParams: { id: e.data.id, serialNo: null },
        })
      );

      this.util.openItemToNewTab(url);
    }
  }

  /******************************************** View Maintenance ******************************************/
  public viewMaintenance(e: any) {
    if (e.colDef.field === "scheduleNumber") {
      var url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["/dashboard/preventative-maintenance/view/" + e.data.id],
          { queryParams: { id: e.data.id } }
        )
      );

      this.util.openItemToNewTab(url);
    }
  }

  /******************************************** Reset ******************************************/
  private reset() {
    this.searchValue = "";
    this.filter.status = null;
    this.filter.fault = null;
    if (this.title == "Preventive Maintenance") {
      this.onGridReadyPreventiveMaintenance(this.gridParams);
    } else if (this.title == "Workshop Spare Monitoring") {
      this.onGridReadyMonitoring(this.gridParams);
    } else {
      this.onGridReady(this.gridParams);
    }
  }

  /******************************************** Refresh ******************************************/
  public refresh() {
    this.filterResults();
  }

  /******************************************** Toggle Filters ******************************************/
  public toggleFilterView() {
    this.filterView = !this.filterView;
  }

  /******************************************** Clear All Filters ******************************************/
  public clearAll() {
    this.reset();
  }

  /******************************************** Right Click Context Menu ******************************************/
  public getContextMenuItems = (params: any) => {
    params.node.data.field = params.column.colId;
    let url = "";
    let data = "";

    let results = [];

    switch (params.column.colId) {
      case "componentNumber":
      case "scheduleNumber":
      case "runningNo":

      case "jobOrderNumber":
      case "assignedTo":
      case "joNumber":
      // case "taskName":
        const data = params.node.data;

        results.push({
          name: "Open link in new tab",
          action: () => this.redirectToNewTab(data),
        });

        break;

      default:
        break;
    }

    return results;
  };

  /******************************************** Redirect To ******************************************/
  private redirectToNewTab(data: any) {
    let url;
    if (data.field === "jobOrderNumber") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(["/dashboard/jobOrders/view/" + data.id], {
          queryParams: { jobOrderId: data.id },
        })
      );
    }

    if (data.field === "assignedTo") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(["/dashboard/setup/allUser/view"], {
          queryParams: { id: data.technicianId ,edit:false},
        })
      );
    }

    if (data.field === "joNumber") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["/dashboard/jobOrders/view/" + data.jobOrderId],
          { queryParams: { jobOrderId: data.jobOrderId } }
        )
      );
    }

    // if (data.field === "taskName") {
    //   url = this.router.serializeUrl(
    //     this.router.createUrlTree(["/dashboard/tasks/view"], {
    //       queryParams: { id: data.taskId },
    //     })
    //   );
    // }

    if (data.field === "runningNo") {
      localStorage.setItem("activeIndexAssetView", "0");
      url = this.router.serializeUrl(
        this.router.createUrlTree(["/dashboard/assets/view/" + data.id], {
          queryParams: { id: data.id, serialNo: null },
        })
      );
    }

    if (data.field === "scheduleNumber") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["/dashboard/preventative-maintenance/view/" + data.id],
          { queryParams: { id: data.id } }
        )
      );
    }

    if (url) {
      url = url.replace("%23", "#");
      this.util.openItemToNewTab(url);
    }
  }
   
  push(obs:any) {
    super.push(obs);
  }
  public onSearchCheck()
  {
    if(this.searchValue.length == 0)
    {
      this.searchValueData = false
      this.searchValue = '';
      this.filterResults();
    }
  }

  public onClear(){
    this.searchValueData = false
    this.searchValue = ''
    this.filterResults();
    
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        translateData = data;
      }
    }))
    console.log("translate",this.translate)
  }


}
