<div class="custella-modal">
    <form>
        <div class="custella-modal-header align-items-center">
            <span class="text-14 font-medium ms-2" id="title_SetSlaCondDets">{{translate?.setup.sla.packages.sections.sec3.singular}}</span>
          
            <i class="pi pi-times" [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left" (click)="modalRef.hide()" id="btn_SetSlaCondDetsClose"></i>
        </div>

        <div class="">
            <!-- Container -->
            <div class="custella-form-container height-80 overflow-scroll w-100 rounded-2 m-0"
                style="height: fit-content">
                <!-- Match All -->
                <form [formGroup]="addFilterForm">
                    <div class="custella-form-container-title-two-grey d-flex align-items-center">
                        <div class="text-14 font-medium me-2" id="title_SetSlaCondMatchAll">{{translate?.setup.sla.packages.sections.sec4.singular}}</div>
                        <div class="text-10" id="label_SetSlaCondMatchAll"><i>({{translate?.setup.sla.packages.sections.sec6.singular}})</i></div>
                    </div>
                    <div class="custella-form-container-content my-2">
                        <div class="custella-content-row-four col-12 justify-content-between">
                            <div class="d-flex col-11">
                                <!-- Attribute -->
                                <div class="custella-content-row-four-input col-3">
                                    <span class="custella-content-row-input-title" id="label_SetSlaCondAllAttr">{{translate?.setup.sla.packages.fields.attribute.singular}}</span>
                                    <p-dropdown (onChange)="setAttr($event)" [options]="filterAttrs" filterBy="valueName" optionGroupChildren="items" optionLabel="valueName"  formControlName="attr"   name="attr"  [placeholder]="translate?.general.messages.none "
                                        [(ngModel)]="selectedAttr" [group]="true" [filter]="true" id="picklist_SetSlaCondAllAttrField">
                                        <ng-template let-group pTemplate="group" id="value_SetSlaCondAllAttrGroup">
                                            {{group.valueName}}
                                        </ng-template>
                                        <ng-template let-item pTemplate="modelFieldList" id="item_SetSlaCondAllAttrGroup">
                                            {{item.valueName | titlecase}}
                                        </ng-template>
                                    </p-dropdown>
                                    <div *ngIf="addFilterForm.controls['attr'].touched && addFilterForm.controls['attr'].invalid">
                                        <span class="text-10 p-error" *ngIf="addFilterForm.controls['attr'].errors?.required" id="label_SetSlaCondAllAttrReqField" >{{translate?.generalvalidationFE.requiredField }}</span>
                                    </div>
                                </div>
                                <!--------------------------------------- Operator---------------------------------------------------------------->
                                <div class="custella-content-row-four-input col-2">
                                    <span class="custella-content-row-input-title" id="label_SetSlaCondAllOper">{{translate?.setup.sla.packages.fields.operator.singular}}</span>
                                    <p-dropdown (onChange)="setOperator($event)" [options]="displayOperators" id="picklist_SetSlaCondAllOperField"  optionValue="value"  optionLabel="name"  formControlName="operator"   name="operator"  [placeholder]="translate?.general.messages.none "
                                    [(ngModel)]="selectedOper" [filter]="true">
                                   </p-dropdown>
                                   <div  *ngIf="addFilterForm.controls['operator'].touched && addFilterForm.controls['operator'].invalid">
                                    <span class="text-10 p-error" *ngIf="addFilterForm.controls['operator'].errors?.required" id="label_SetSlaCondAllOperReqField" >{{translate?.generalvalidationFE.requiredField }}</span>
                                  </div>
                                </div>
                                <!--------------------------------------- Value Section---------------------------------------------------------------->
                                <div class="custella-content-row-four-input col-6" *ngIf="selectedAttr">
                                    <span class="custella-content-row-input-title" id="label_SetSlaCondAllValue">{{translate?.setup.sla.packages.fields.value.singular}}</span>
                                    <!--------------------------------------- Input Text---------------------------------------------------------------->
                                    <div class="col-6" *ngIf="selectedAttr?.type == 'INPUT_FIELD' && (selectedAttr?.operator != 'IS NOT NULL' && selectedAttr?.operator != 'IS NULL' )">  
                                        <input type="text" pInputText autocomplete="off" [(ngModel)]="selectedValue" formControlName="value" name="value" id="input_SetSlaCondAllValueField" >
                                        <div *ngIf="addFilterForm.controls['value'].touched && addFilterForm.controls['value'].invalid">
                                            <span class="text-10 p-error" *ngIf="addFilterForm.controls['value'].errors?.required" id="label_SetSlaCondAllValueReqField" >{{translate?.generalvalidationFE.requiredField }}</span>
                                        </div> 
                                    </div>
                                    <!--------------------------------------- Multi Choice---------------------------------------------------------------->
                                    <div class="col-6" *ngIf="selectedAttr?.type == 'MULTIPLE_CHOICE'">
                                        <p-multiSelect [options]="optionList" formControlName="value" [(ngModel)]="valueArray" id="picklist_SetSlaCondAllMultiValueField"  [maxSelectedLabels]="5" [selectedItemsLabel]="'{0} items selected'" optionLabel="itemName" (onChange)="onSelectAll($event)"></p-multiSelect>
                                        <div class="alert col px-0 py-0 text-right" *ngIf="addFilterForm.controls['value'].touched && addFilterForm.controls['value'].invalid">
                                            <span class="text-10 p-error" *ngIf="addFilterForm.controls['value'].errors?.required" id="label_SetSlaCondAllMultiValueReqField" >{{translate?.generalvalidationFE.requiredField }}</span>
                                        </div> 
                                    </div>
                                    <!--------------------------------------- Date Time && Not Between---------------------------------------------------------------->
                                   <div class="col-6" *ngIf="selectedAttr?.type == 'DATE_TIME' && selectedOper !== 'BETWEEN' && (selectedAttr?.operator != 'IS NOT NULL' && selectedAttr?.operator != 'IS NULL' )">
                                        <p-calendar  dateFormat="dd/mm/yy"    selectionMode="single" [placeholder]="translate?.general.messages.none " id="picklist_SetSlaCondAllValueDateTimeField" formControlName="value" name="selectedDate" [(ngModel)]="selectedDate" [showTime]="true" hourFormat="12" appendTo="body"></p-calendar>
                                        <div class="alert col px-0 py-0 text-right" *ngIf="addFilterForm.controls['value'].touched && addFilterForm.controls['value'].invalid">
                                            <span class="text-10 p-error" *ngIf="addFilterForm.controls['value'].errors?.required" id="label_SetSlaCondAllValueDateTimeReqField" >{{translate?.generalvalidationFE.requiredField }}</span>
                                        </div> 
                                    </div>
                                    <!--------------------------------------- Date Time &&  Between---------------------------------------------------------------->
                                   <div class="d-flex" *ngIf="selectedAttr?.type == 'DATE_TIME' && selectedOper === 'BETWEEN'">
                                        <div class="col">
                                            <p-calendar  dateFormat="dd/mm/yy" yearRange="2000:2030"   id="picklist_SetSlaCondAllValueDateStartBetField" selectionMode="single" [placeholder]="translate?.general.messages.none " formControlName="value" name="selectedDate" [(ngModel)]="selectedDate" [showTime]="true" hourFormat="12" appendTo="body"></p-calendar>
                                            <div class="alert col px-0 py-0 text-right" *ngIf="addFilterForm.controls['value'].touched && addFilterForm.controls['value'].invalid">
                                                <span class="text-10 p-error" *ngIf="addFilterForm.controls['value'].errors?.required" id="label_SetSlaCondAllValueDateStartBetReqField" >{{translate?.generalvalidationFE.requiredField }}</span>
                                            </div> 
                                        </div> 
                                        <div class="mx-2 mt-2" >-</div>
                                        <div class="col">
                                            <p-calendar  dateFormat="dd/mm/yy" yearRange="2000:2030" id="picklist_SetSlaCondAllValueDateEndBetField" selectionMode="single" [placeholder]="translate?.general.messages.none " formControlName="value2" name="selectedDate2" [(ngModel)]="selectedDate2" [showTime]="true" hourFormat="12" appendTo="body"></p-calendar>
                                            <div class="alert col px-0 py-0 text-right" *ngIf="addFilterForm.controls['value2'].touched && addFilterForm.controls['value2'].invalid">
                                                <span class="text-10 p-error" *ngIf="addFilterForm.controls['value2'].errors?.required" id="label_SetSlaCondAllValueDateEndBetReqField" >{{translate?.generalvalidationFE.requiredField }}</span>
                                            </div> 
                                        </div> 
                                    </div>
    
                                </div>
                            </div>
                            <!-- Remove and Add -->
                            <div class="custella-content-row-four-input col-1 d-flex justify-content-end">
                                <div class="text-10 cursor-pointer mt-3 pt-3 me-3" id="btn_SetSlaCondAllClear" *ngIf="selectedAttr" style="color: #6366F1; font-weight:500" (click)="removeSelectedOption()">Clear</div>
                               <div class="mt-3 pt-1"> <p-button icon="fa-regular fa-plus" id="btn_SetSlaCondAllAdd" (onClick)="addNewFilter()"></p-button></div>
                            </div>
                         

                        </div>
                        <div class="custella-content-row my-0">
                            <span  class="custella-content-row-input-sub p-error" id="value_SetSlaCondErrMsg">{{errorMessage}}</span>
                        </div>

                        <div class="custella-content-row pe-0">
                            <div class="custella-grey-box" style="background-color: white;">
                                <div class="">
                                    <div class="filter-item mr-2 mb-2 d-inline-block" *ngFor="let filter of filterArray">
                                        <div class="rounded-5 bg-purple px-2 py-1 mx-1 d-flex align-items-center">
                                            <div class="d-flex align-items-center">
                                                <span class="text-12 font-white px-1 text-nowrap" id="value_SetSlaCondValName">{{filter?.valueName}}</span>
                                                
                                                <div *ngIf="filter.type == 'MULTIPLE_CHOICE'" class="text-12 font-white px-1">
                                                    <span class="px-1" id="value_SetSlaCondAllOperName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" *ngFor="let value of filter?.modelFieldFilterList; let i = index;" id="value_SetSlaCondAllOperNameEach">
                                                        {{value?.stringValue}}
                                                        <span *ngIf="filter?.modelFieldFilterList.length > 1 && i < filter?.modelFieldFilterList.length -1" id="label_SetSlaCondAllOperNameGap">,</span>
                                                    </span>
                                                </div>


                                                <div class="text-12 font-white  px-1" *ngIf="filter.type == 'DATE_TIME' && filter.operator == 'BETWEEN'">
                                                    <span class="px-1" id="value_SetSlaCondAllBetOperName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" *ngFor="let value of filter?.modelFieldFilterList; let i = index;" id="value_SetSlaCondAllDateTimeBetNameEach">
                                                        {{value.value | momentDate : dateFormat : timezone}}  {{value.value | momentDate : 'hh:mm a' : timezone}}
                                                       <span *ngIf="filter?.modelFieldFilterList.length > 1 && i < filter?.modelFieldFilterList.length -1">-</span>
                                                    </span>
                                                </div>
                                                <div class="text-12 font-white px-1" *ngIf="filter.type == 'DATE_TIME' && filter.operator != 'BETWEEN'">
                                                    <span class="px-1" id="value_SetSlaCondAllDateTimeOperName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" id="value_SetSlaCondAllDateTimeNameEach">
                                                        {{filter?.modelFieldFilterList[0]?.value | momentDate : dateFormat : timezone}} {{filter?.modelFieldFilterList[0]?.value | momentDate : 'hh:mm a' : timezone}}
                                                    </span>
                                                </div>

                                                <div class="text-12 font-white px-1" *ngIf="filter.type == 'DATE' && filter.operator == 'BETWEEN'">
                                                    <span class="px-1" id="value_SetSlaCondAllDateBetOperName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" *ngFor="let value of filter?.modelFieldFilterList; let i = index;" id="value_SetSlaCondAllDateBetOperNameEach">
                                                        {{value.value | momentDate : dateFormat : timezone}} 
                                                       <span *ngIf="filter?.modelFieldFilterList.length > 1 && i < filter?.modelFieldFilterList.length -1">-</span>
                                                    </span>
                                                </div>
                                                <div class="text-12 font-white px-1" *ngIf="filter.type == 'DATE' && filter.operator != 'BETWEEN'">
                                                    <span class="px-1" id="value_SetSlaCondAllDateBetweenOperName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" id="value_SetSlaCondAllDateBetweenOperNameTime">
                                                        {{filter?.modelFieldFilterList[0]?.value | momentDate : dateFormat : timezone}}
                                                    </span>
                                                </div>


                                                <div class="text-12 font-white px-1" *ngIf="filter.type != 'DATE' && filter.type != 'DATE_TIME' && filter.type != 'MULTIPLE_CHOICE' && (filter.operator !== 'BETWEEN')">
                                                    <span class="px-1">{{filter?.operatorName}}</span>
                                                    <span class="px-1">{{filter?.modelFieldFilterList[0]?.value}}</span>
                                                </div>
                                            </div>
                                        
                                            <i class="pi pi-times-circle font-white" id="btn_SetSlaCondMultiAllClear" (click)="removeFilterOption(filter)"></i>
                                        </div>
                                    </div>
                                    

                                </div>                          
                            </div>
                        </div>
                    </div>
                </form>

                <!-- Match Any -->
                <div class="custella-form-container-title-two-grey d-flex align-items-center">
                    <div class="text-14 font-medium me-2" id="title_SetSlaCondMatchAny">{{translate?.setup.sla.packages.sections.sec5.singular}}</div>
                    <div class="text-10" id="label_SetSlaCondMatchAny"><i>({{translate?.setup.sla.packages.sections.sec7.singular}})</i></div>
                </div>
                <form [formGroup]="addAnyFilterForm">
                    <div class="custella-form-container-content my-2">
                        <div class="custella-content-row-four col-12 justify-content-between">

                            <div class="d-flex col-11">
                                <!-- Attribute -->
                                <div class="custella-content-row-four-input col-3">
                                    <span class="custella-content-row-input-title" id="label_SetSlaCondAnyAttr">{{translate?.setup.sla.packages.fields.attribute.singular}}</span>
                                    <p-dropdown (onChange)="setAttrAny($event)" [options]="filterAttrs" id="picklist_SetSlaCondAnyAttrField" filterBy="valueName" optionGroupChildren="items" optionLabel="valueName"  formControlName="attrAny"   name="attrAny"  [placeholder]="translate?.general.messages.none "
                                            [(ngModel)]="selectedAttrAny" [group]="true" [filter]="true">
                                            <ng-template let-group pTemplate="group" id="value_SetSlaCondAnyAttrGroup">
                                                {{group.valueName}}
                                            </ng-template>
                                            <ng-template let-item pTemplate="modelFieldList" id="item_SetSlaCondAnyAttrGroup">
                                                {{item.valueName | titlecase}}
                                            </ng-template>
                                    </p-dropdown>
                                    <div *ngIf="addAnyFilterForm.controls['attrAny'].touched && addAnyFilterForm.controls['attrAny'].invalid">
                                        <span class="text-10 p-error" *ngIf="addAnyFilterForm.controls['attrAny'].errors?.required" id="label_SetSlaCondAnyAttrReqField" >{{translate?.generalvalidationFE.requiredField }}</span>
                                    </div>
                                </div>

                                <!-- Operator -->
                                <div class="custella-content-row-four-input col-2">
                                    <span class="custella-content-row-input-title" id="label_SetSlaCondAnyOper">{{translate?.setup.sla.packages.fields.operator.singular}}</span>
                                    <p-dropdown (onChange)="setOperatorAny($event)" id="picklist_SetSlaCondAnyOperField" [options]="displayOperatorsAny"  optionValue="value"  optionLabel="name"  formControlName="operatorAny"   name="operatorAny"  [placeholder]="translate?.general.messages.none "
                                        [(ngModel)]="selectedOperAny" [filter]="true">
                                    </p-dropdown>
                                    <div  *ngIf="addAnyFilterForm.controls['operatorAny'].touched && addAnyFilterForm.controls['operatorAny'].invalid">
                                        <span class="text-10 p-error" *ngIf="addAnyFilterForm.controls['operatorAny'].errors?.required" id="label_SetSlaCondAnyOperReqField" >{{translate?.generalvalidationFE.requiredField }}</span>
                                    </div>
                                </div>

                                <!--------------------------------------- Value Section---------------------------------------------------------------->
                                <div class="custella-content-row-four-input col-6" *ngIf="selectedAttrAny">
                                    <span class="custella-content-row-input-title" id="label_SetSlaCondAnyValue">{{translate?.setup.sla.packages.fields.value.singular}}</span>
                                    <!--------------------------------------- Input Text---------------------------------------------------------------->
                                    <div class="col-6" *ngIf="selectedAttrAny?.type == 'INPUT_FIELD' && (selectedAttrAny?.operator != 'IS NOT NULL' && selectedAttrAny?.operator != 'IS NULL' )">  
                                        <input type="text" pInputText autocomplete="off" [(ngModel)]="selectedValueAny" formControlName="valueAny" name="valueAny"  id="input_SetSlaCondAnyValueField">
                                        <div  *ngIf="addAnyFilterForm.controls['valueAny'].touched && addAnyFilterForm.controls['valueAny'].invalid">
                                            <span class="text-10 p-error" *ngIf="addAnyFilterForm.controls['valueAny'].errors?.required" id="label_SetSlaCondAnyValueReqField" >{{translate?.generalvalidationFE.requiredField }}</span>
                                        </div> 
                                    </div>
                                    <!--------------------------------------- Multi Choice---------------------------------------------------------------->
                                <div class="col-6" *ngIf="selectedAttrAny?.type == 'MULTIPLE_CHOICE'">
                                
                                <p-multiSelect [options]="optionListAny" formControlName="valueAny" [(ngModel)]="valueArrayAny" id="picklist_SetSlaCondAnyMultiValueField" [maxSelectedLabels]="5" [selectedItemsLabel]="'{0} items selected'"
                                optionLabel="itemName" (onChange)="onSelectAllAny($event)"></p-multiSelect>

                                    <div class="alert col px-0 py-0 text-right" *ngIf="addAnyFilterForm.controls['valueAny'].touched && addAnyFilterForm.controls['valueAny'].invalid">
                                        <span class="text-10 p-error" *ngIf="addAnyFilterForm.controls['valueAny'].errors?.required" id="label_SetSlaCondAnyMultiValueReqField" >{{translate?.generalvalidationFE.requiredField }}</span>
                                </div> 
                                </div>
                                    <!--------------------------------------- Date Time && Not Between---------------------------------------------------------------->
                                <div class="col-6" *ngIf="selectedAttrAny?.type == 'DATE_TIME' && selectedOperAny !== 'BETWEEN' && (selectedAttrAny?.operator != 'IS NOT NULL' && selectedAttrAny?.operator != 'IS NULL' )">
                                        <p-calendar  dateFormat="dd/mm/yy" id="picklist_SetSlaCondAnyValueDateTimeField"   selectionMode="single" [placeholder]="translate?.general.messages.none " formControlName="valueAny" name="selectedDateAny" [(ngModel)]="selectedDateAny" [showTime]="true" hourFormat="12" appendTo="body"></p-calendar>
                                        <div class="alert col px-0 py-0 text-right" *ngIf="addAnyFilterForm.controls['valueAny'].touched && addAnyFilterForm.controls['valueAny'].invalid">
                                            <span class="text-10 p-error" *ngIf="addAnyFilterForm.controls['valueAny'].errors?.required" id="label_SetSlaCondAnyValueDateTimeReqField"  >{{translate?.generalvalidationFE.requiredField }}</span>
                                    </div> 
                                    </div>
                                    <!--------------------------------------- Date Time &&  Between---------------------------------------------------------------->
                                <div class="d-flex" *ngIf="selectedAttrAny?.type == 'DATE_TIME' && selectedOperAny === 'BETWEEN'" class="d-flex">
                                    <div class="col">
                                        <p-calendar  dateFormat="dd/mm/yy"   selectionMode="single" id="picklist_SetSlaCondAnyValueDateStartBetField" [placeholder]="translate?.general.messages.none " formControlName="valueAny" name="selectedDateAny" [(ngModel)]="selectedDateAny" [showTime]="true" hourFormat="12" appendTo="body"></p-calendar>
                                        <div class="alert col px-0 py-0 text-right" *ngIf="addAnyFilterForm.controls['valueAny'].touched && addAnyFilterForm.controls['valueAny'].invalid">
                                            <span class="text-10 p-error" *ngIf="addAnyFilterForm.controls['valueAny'].errors?.required" id="label_SetSlaCondAnyValueDateStartBetReqField" >{{translate?.generalvalidationFE.requiredField }}</span>
                                        </div> 
                                    </div> 
                                    <div class="mx-2 mt-2" >-</div>
                                    <div class="col">
                                        <p-calendar  dateFormat="dd/mm/yy"   selectionMode="single" id="picklist_SetSlaCondAnyValueDateEndBetField" [placeholder]="translate?.general.messages.none " formControlName="valueAny2" name="selectedDate2" [(ngModel)]="selectedDateAny2" [showTime]="true" hourFormat="12" appendTo="body"></p-calendar>
                                        <div class="alert col px-0 py-0 text-right" *ngIf="addAnyFilterForm.controls['valueAny2'].touched && addAnyFilterForm.controls['valueAny2'].invalid">
                                            <span class="text-10 p-error" *ngIf="addAnyFilterForm.controls['valueAny2'].errors?.required" id="label_SetSlaCondAnyValueDateEndBetReqField" >{{translate?.generalvalidationFE.requiredField }}</span>
                                        </div> 
                                    </div> 
                                </div>

                                </div>
                            </div>

                            <div class="custella-content-row-four-input col-1 d-flex justify-content-end">
                                <div class="text-10 cursor-pointer mt-3 pt-3 me-3" *ngIf="selectedAttrAny" id="btn_SetSlaCondAnyClear" style="color: #6366F1; font-weight:500" (click)="removeSelectedOptionAny()">Clear</div>
                                <div class="mt-3 pt-1"> <p-button icon="fa-regular fa-plus" id="btn_SetSlaCondAnyAdd" (onClick)="addNewFilterAny()"></p-button></div>
                            </div>
                        </div>



                        <div class="custella-content-row my-0">
                            <span  class="custella-content-row-input-sub p-error" id="value_SetSlaCondErrMsg2">{{errorMessageAny}}</span>
                        </div>
                        <div class="custella-content-row pe-0">
                            <div class="custella-grey-box" style="background-color: white;">
                                <div class="">
                                    <div class="filter-item mr-2 mb-2 d-inline-flex" *ngFor="let filter of filterArrayAny">
                                        <div class="rounded-5 bg-purple px-2 py-1 mx-1 d-flex align-items-center">
                                            <div class="d-flex align-items-center">
                                                <span class="text-12 font-white text-nowrap px-1" id="value_SetSlaCondAnyValName">{{filter?.valueName}}</span>
                                                
                                                <div *ngIf="filter.type == 'MULTIPLE_CHOICE'" class="text-12 font-white text-nowrap px-1">
                                                    <span class="px-1"id="value_SetSlaCondAnyMultiOperName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" *ngFor="let value of filter?.modelFieldFilterList; let i = index;" id="value_SetSlaCondAnyMultiOperNameEach">
                                                        {{value?.stringValue}}
                                                        <span *ngIf="filter?.modelFieldFilterList.length > 1 && i < filter?.modelFieldFilterList.length -1" id="value_SetSlaCondAnyMultiOperNameGap">,</span>
                                                    </span>
                                                </div>


                                                <div class="text-12 font-white text-nowrap px-1" *ngIf="filter.type == 'DATE_TIME' && filter.operator == 'BETWEEN'">
                                                    <span class="px-1" id="value_SetSlaCondAnyDateBetOperName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" *ngFor="let value of filter?.modelFieldFilterList; let i = index;" id="value_SetSlaCondAnyDateBetOperNameEach">
                                                        {{value.value | momentDate : dateFormat : timezone}}  {{value.value | momentDate : 'hh:mm a' : timezone}}
                                                    <span *ngIf="filter?.modelFieldFilterList.length > 1 && i < filter?.modelFieldFilterList.length -1" id="value_SetSlaCondAnyDateBetOperNameGap">-</span>
                                                    </span>
                                                </div>
                                                <div class="text-12 font-white text-nowrap px-1" *ngIf="filter.type == 'DATE_TIME' && filter.operator != 'BETWEEN'">
                                                    <span class="px-1" id="value_SetSlaCondAnyDateTimeOperName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" id="value_SetSlaCondAnyDateTimeOperNameEach">
                                                        {{filter?.modelFieldFilterList[0]?.value | momentDate : dateFormat : timezone}} {{filter?.modelFieldFilterList[0]?.value | momentDate : 'hh:mm a' : timezone}}
                                                    </span>
                                                </div>

                                                <div class="text-12 font-white text-nowrap px-1" *ngIf="filter.type == 'DATE' && filter.operator == 'BETWEEN'">
                                                    <span class="px-1" id="value_SetSlaCondAnyDateTimeBetOperName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" *ngFor="let value of filter?.modelFieldFilterList; let i = index;" id="value_SetSlaCondAnyDateTimeBetOperNameEach">
                                                        {{value.value | momentDate : dateFormat : timezone}} 
                                                    <span *ngIf="filter?.modelFieldFilterList.length > 1 && i < filter?.modelFieldFilterList.length -1">-</span>
                                                    </span>
                                                </div>
                                                <div class="text-12 font-white text-nowrap px-1" *ngIf="filter.type == 'DATE' && filter.operator != 'BETWEEN'">
                                                    <span class="px-1" id="value_SetSlaCondAnyDateOperName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" id="value_SetSlaCondAnyDateOperNameEach">
                                                        {{filter?.modelFieldFilterList[0]?.value | momentDate : dateFormat : timezone}}
                                                    </span>
                                                </div>


                                                <div class="text-12 font-white text-nowrap px-1" *ngIf="filter.type != 'DATE' && filter.type != 'DATE_TIME' && filter.type != 'MULTIPLE_CHOICE' && (filter.operator !== 'BETWEEN')">
                                                    <span class="px-1" id="value_SetSlaCondAnyOperName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" id="value_SetSlaCondAnyOperNameEach">{{filter?.modelFieldFilterList[0]?.value}}</span>
                                                </div>
                                            </div>
                                        
                                            <i class="pi pi-times-circle font-white" (click)="removeFilterOptionAny(filter)"></i>
                                        </div>
                                    </div>
                                    

                                </div>                          
                            </div>
                        </div>
                    </div>
                 </form>
                <div class="custella-form-container-footer-two pb-0">
                    <div class="d-flex align-items-center justify-content-end">
                        <button class="custella-btn-white text-12 ms-2" id="btn_SetSlaCondAnyCancel" (click)="modalRef.hide()">{{translate?.general.buttons.cancel.singular}}</button>
                        <p-button [label]="translate?.general.buttons.save.singular" (onClick)="checkValidation()" id="btn_SetSlaCondAnySubmit" styleClass="ms-2 h-32"></p-button>
                    </div>
                </div>
            </div>

        </div>
    </form>
</div>
