<div class="custella-form-container left-right-space rounded-2">
    <div class="custella-form-container-title flex-between">
        <span class="text-14 font-medium" id="title_HillsThisMonth">{{translate?.dashboard.national.sections.sec3.singular }}</span>
        <button class="border rounded-1 px-04 ms-2 btn-32" id="btn_HillsThisMonthRefresh"><img alt="logo" src="assets\svg\refresh.svg" (click)="$event.stopPropagation(); firstClick()" /></button>
    </div>

    <div class="custella-content-row">
        <div class="d-flex align-items-center w-100 ps-2 pe-1 ms-2">
            <div class="d-flex align-items-center" *ngFor="let box of boxChart" style="width: 24.5%; margin-left: 5px; margin-right: 5px;">
                <div class="d-flex align-items-center col-5 px-0" [ngStyle]="{ 'background-color': box.bottomColors }" style="height: 62px; border-radius: 10px 0px 0px 10px;">
                    <p class="mb-0 text-white ps-4 pe-4 text-20 font-medium" id="label_HillsThisMonthBoxName">{{ box.name }}</p>
                </div>
                <div class="flex-between col-7 px-0" style="height: 62px; border-radius: 0px 10px 10px 0px;" [ngStyle]="{ 'background-color': box.color }">
                    <div class="px-2 border-right text-center col-6">
                        <p class="mb-0 text-white text-10" id="label_HillsMonthOpenJobs">
                            <i>{{translate?.dashboard.national.fields.openJobs.singular }}</i>
                        </p>
                        <p class="mb-0 text-white me-2 font-medium text-20" id="value_HillsMonthOpenJobs">
                            {{ box.openJobs }}
                        </p>
                    </div>
                    <div class="px-2 text-center col-6">
                        <p class="mb-0 text-white text-10" id="label_HillsMonthCloseJobs">
                            <i>{{translate?.dashboard.national.fields.closedJobs.singular }}</i>
                        </p>
                        <p class="mb-0 text-white me-2 font-medium text-20" id="value_HillsMonthCloseJobs">
                            {{ box.closedJobs }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="custella-content-row pb-3">
        <div class="col-8">
            <div class="custella-dash-chart ms-4 p-4">
                <span class="mb-3 text-14 font-semiBold" id="label_HillsNoFaultSyst">{{translate?.dashboard.national.fields.noFaultsBySystem.singular }}</span>
                <div>
                    <ngx-charts-bar-vertical-2d [view]="viewVerticalBar" [scheme]="colorSchemeBar" [results]="multiBar" [xAxis]="showXAxis" [yAxis]="showYAxis" [legendPosition]="LegendPosition.Below" [legend]="true" [legendTitle]="''">
                    </ngx-charts-bar-vertical-2d>
                </div>
            </div>
        </div>

        <div class="col-4">
            <div class="custella-dash-chart p-4">
                <p class="mb-3 text-14 font-semiBold" id="label_HillsJobCloseSyst">{{translate?.dashboard.national.fields.totalJobsClosedBySystem.singular }}</p>

                <ngx-charts-pie-chart [view]="viewPie" [scheme]="colorSchemePie" [results]="multiPie" [legend]="true" [legendPosition]="LegendPosition.Below" [legendTitle]="''"> </ngx-charts-pie-chart>
            </div>
        </div>
    </div>
</div>

<div class="custella-form-container left-right-space rounded-2">
    <div class="custella-form-container-title flex-between">
        <span class="text-14 font-medium " id="title_HillsFaultTech">{{translate?.dashboard.national.sections.sec4.singular }}</span>
        <button class="border rounded-1 px-04 ms-2 btn-32" id="btn_HillsFaultTechRefresh"><img alt="logo" src="assets\svg\refresh.svg" (click)="$event.stopPropagation(); thirdClick()" /></button>
    </div>

    <div class="p-4">
        <div class="flex-between">
            <span class="text-14 font-semiBold" *ngIf="tableHeaderSeries" id="label_HillsFaultCloseMonthTech">{{translate?.dashboard.national.fields.faultsClosedPerMonth.singular }}</span>
            <div class="d-flex" *ngIf="!noData">
                <!-- <button class="custella-btn-icon border rounded-1 p-1 mx-2 h-35">
                    <img alt="logo" src="assets\svg\refresh.svg" (click)="refresh()" />
                </button> -->
                <span class="p-input-icon-right w-100 ms-2">
                    <input type="text" pInputText autocomplete="off" [placeholder]="translate?.general.buttons.search.singular "
                    [(ngModel)]="searchValue" (keyup)="onSearchCheck()" (keydown.enter)="quickSearch(searchValue)" />
                    <i *ngIf="searchValueData" class="p-autocomplete-clear-icon pi pi-times"  (click)="onClear()"></i>
                </span>
                <button type="button" class="border rounded-1 px-04 ms-2 btn-32"  (click)="quickSearch(searchValue)">
                    <img alt="logo" src="assets\svg\search-dark.svg" class="Search" />
                </button>

             </div>
        </div>
        <div class="custella-content-row pe-0">
            <div class="col-12 align-items-center">
                <div class="position-relative fw-normal d-flex">
                    <div class="table-space ag-grid-table-full" style="height: 350px;" [hidden]="noData">
                        <ag-grid-angular
                            #agGrid
                            style="height: 100%;"
                            class="ag-theme-balham"
                            [columnDefs]="columnDefs"
                            [gridOptions]="gridOptions"
                            [defaultColDef]="defaultColDef"
                            [cacheOverflowSize]="2"
                            [maxConcurrentDatasourceRequests]="-1"
                            [rowData]="rowData"
                            (rowDataChanged)="onFirstDataRendered($event)"
                            (gridReady)="onGridReady($event)"
                        ></ag-grid-angular>
                        <div class="col-12">
                            <p class="ag-record-row-summary-panel padds text-end" id="label_FaultCloseTechTableRec">
                                {{ totalRecord }} {{ translate?.general.messages.records  }}
                            </p>
                        </div>
                    </div>
    
                    <ng-container *ngIf="noData">
                        <div class="text-14 justify-content-center w-100">
                            <div class="px-0 my-2 text-center mt-2 mb-2">
                                <img src="assets\svg\empty-state.svg" alt="No Recods Found" id="img_NoRecordsFound" />
                                <p class="text-6 my-1 font-weight-bold">
                                   {{translate?.general.messages.noRecordsFound}}
                                </p>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="custella-form-container left-right-space rounded-2" style="margin-top: 0.8rem">
    <div class="custella-form-container-title flex-between">
        <span class="text-14 font-medium" id="title_HillsLastSixMth">{{translate?.dashboard.national.sections.sec5.singular }}</span>
        <button class="border rounded-1 px-04 ms-2 btn-32" id="btn_HillsLastSixMthRefresh"><img alt="logo" src="assets\svg\refresh.svg" (click)="$event.stopPropagation(); secondClick()" /></button>
    </div>

    <div class="">
        <div class="col-12">
            <div class="custella-form-container-content">
                <div class="custella-dash-chart-no-min mx-0 p-4" style="height: 270px;">
                    <span class="mb-3 text-14 font-semiBold" id="label_HillsJobCloseLastSixMth">{{translate?.dashboard.national.fields.jobsClosedLastSixMonths.singular }}</span>
                    <ngx-charts-line-chart [view]="viewLine" [scheme]="colorSchemeLine" [legend]="false" [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis" [yAxis]="yAxis" [timeline]="timeline" [results]="multi"
                        [legendPosition]="LegendPosition.Below">
                    </ngx-charts-line-chart>
                </div>
            </div>
        </div>
    </div>
    <div class="">
        <div class="col-12">
            <div class="custella-form-container-content">
                <div class="custella-dash-chart mx-0 p-4">
                    <span class="mb-3 text-14 font-semiBold" id="label_HillsJobCloseByState">{{translate?.dashboard.national.fields.jobsClosedPerMonthByState.singular }}</span>
                    <ngx-charts-bar-horizontal-stacked [view]="viewHorizontalBar1" [scheme]="colorSchemeHorizontal"
                        [results]="multiHorizontal1" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="showXAxis"
                        [yAxis]="showYAxis" [legend]="true" [legendPosition]="LegendPosition.Below">
                    </ngx-charts-bar-horizontal-stacked>
                </div>
            </div>
        </div>
    </div>
    <div class="">
        <div class="col-12">
            <div class="custella-form-container-content">
                <div class="custella-dash-chart mx-0 p-4">
                    <span class="mb-3 text-14 font-semiBold" id="label_HillsRedFlagByState">{{translate?.dashboard.national.fields.redflagsClosedPerMonthState.singular }}</span>
                    <ngx-charts-bar-horizontal-stacked [view]="viewHorizontalBar2" [scheme]="colorSchemeHorizontal"
                    [results]="multiHorizontal2" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="showXAxis"
                    [yAxis]="showYAxis" [legend]="true" [legendPosition]="LegendPosition.Below">
                </ngx-charts-bar-horizontal-stacked>
                </div>
            </div>
        </div>
    </div>
    <div class="">
        <div class="col-12">
            <div class="custella-form-container-content">
                <div class="custella-dash-chart mx-0 p-4">
                    <span class="mb-3 text-14 font-semiBold" id="label_HillsJobCloseSystTime">{{translate?.dashboard.national.fields.jobsClosedPerSystemTimeframe.singular }}</span>
                    <ngx-charts-bar-vertical-stacked [view]="viewStackedBar1" [scheme]="colorSchemeVertical" [results]="multiVertical1"
                    [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="showXAxis" [yAxis]="showYAxis"
                    [legend]="true" [animations]="animations" [legendPosition]="LegendPosition.Below">
                    </ngx-charts-bar-vertical-stacked>
                </div>
            </div>
        </div>
    </div>
    <div class="">
        <div class="col-12">
            <div class="custella-form-container-content pb-1">
                <div class="custella-dash-chart mx-0 mb-3 p-4">
                    <span class="mb-3 text-14 font-semiBold" id="label_HillsJobCloseStateTime">{{translate?.dashboard.national.fields.jobsClosedPerStateTimeframe.singular }}</span>
                    <ngx-charts-bar-vertical-stacked [view]="viewStackedBar2" [scheme]="colorSchemeVertical" [results]="multiVertical2"
                        [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="showXAxis" [yAxis]="showYAxis"
                        [legend]="true" [animations]="animations" [legendPosition]="LegendPosition.Below">
                    </ngx-charts-bar-vertical-stacked>
                </div>
            </div>
        </div>
    </div>
</div>
