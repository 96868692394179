import {Component, ViewEncapsulation} from '@angular/core';
import {ITooltipAngularComp} from "ag-grid-angular";

@Component({
  selector: 'plan-tooltip-component',
  template: ` <div class="custom-tooltip mb-4" *ngIf="data?.deliveryOrderLineItemVMS">
          <div class="custella-form-container m-0 h-100 rounded-2 py-0" style="min-width:300px">
                <div class="custella-form-container-title" style="padding-left: 0.75rem; padding-right: 0.75rem;">
                    <div class="flex-between">
                      <span class="text-12 font-semiBold me-2">Address</span>
                     
                    </div>
                </div>
                <!-- Name and Email -->
                <div class="custella-form-container-content font-black px-4 m-2" *ngFor="let card of data.deliveryOrderLineItemVMS;index as i">
                <div class="custella-content-row p-0">
                        <div class="custella-content-row-input col-12 pt-1 mx-0">
                          <div class="d-flex align-items-center">
                              <span class="text-12 font-medium color-var-500 col-2" >Pick up:</span>
                              <span class="text-12 font-medium ms-2">{{ card.newPick }}</span>
                          </div>
                        </div>
                    </div>
                    <div class="custella-content-row p-0">
                            <div class="custella-content-row-input col-12 pt-1 mb-3">
                              <div class="d-flex align-items-center">
                                <span class="text-12 font-medium color-var-500 col-2" >Drop Off:</span>
                                <span class="text-12 font-medium ms-2">{{ card.newDrop }}</span>
                              </div>
                            </div>
                        </div>
                  </div>
                </div>
  </div>`,
  styles: [
    `
       :host {
        position: absolute;
        overflow: visible;
        pointer-events: none;
        transition: opacity 1s;
        background-color:#fff;
        width:500px;

      }
     

    `,
  ],
})
export class PlaninghubTooltip implements ITooltipAngularComp {
   params: any;
   data: any;

  agInit(params: any): void {
    console.log(params)
    this.params = params;
    console.log(params.api.context.beanWrappers.beanInstance)
   // params.api.context.beanWrappers.tooltipManager.beanInstance.MOUSEOVER_SHOW_TOOLTIP_TIMEOUT = 20000;
    this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
    console.log(this.data)
    this.data.color = this.params.color || 'white';
    if(this.data.deliveryOrderLineItemVMS){
    this.data.deliveryOrderLineItemVMS.forEach((element :any)=> {



      if(element.deleveryOrderLineItem && element.deleveryOrderLineItem.dropOffAddress){
        element.newDrop = element.deleveryOrderLineItem.dropOffAddress
      }
      else if(element.deleveryOrderLineItem && element.deleveryOrderLineItem.dropOffBay){
        element.newDrop = element.deleveryOrderLineItem.dropOffBay.name

      }else if(element.deleveryOrderLineItem && element.deleveryOrderLineItem.dropOffDepot){
        element.newDrop = element.deleveryOrderLineItem.dropOffDepot.name
      }

      /*** Pickup Data */
      if(element.deleveryOrderLineItem && element.deleveryOrderLineItem.pickupAddress){
        element.newPick = element.deleveryOrderLineItem.pickupAddress
      }
      else if(element.deleveryOrderLineItem && element.deleveryOrderLineItem.pickupBay){
        element.newPick = element.deleveryOrderLineItem.pickupBay.name

      }else if(element.deleveryOrderLineItem && element.deleveryOrderLineItem.pickupDepot){
        element.newPick = element.deleveryOrderLineItem.pickupDepot.name
      }


    });
  }
  }


}

