<div class="custella-modal">
    <form>
        <div class="custella-modal-header align-items-center">
            <span class="text-14 font-medium ms-2" id="title_SetImportFiles">{{translate?.setup.importExport.sections.sec2.singular }}</span>
            <i class="pi pi-times" id="btn_SetImportFilesClose" [pTooltip]="translate?.general.buttons.close.singular" (click)="modalRef.hide()"></i>
        </div>
        <!-- Container -->
        <div class="custella-form-container position-relative d-block w-100 rounded-2 pb-3 m-0"
            style="height: fit-content;">
            <!--  -->
            <div class="py-2">
                <div class="d-flex align-items-center justify-content-center m-4">
                    <div class="custella-import-checkpoint ms-4"id="label_SetImportFilesCheck1"></div>
                    <div class="custella-import-path"id="label_SetImportFilesGap1"></div>
                    <div class="custella-import-checkpoint"id="label_SetImportFilesCheck2"></div>
                    <div class="custella-import-path-dash"id="label_SetImportFilesGap2"></div>
                    <div class="custella-import-uncheckpoint"id="label_SetImportFilesUncheck3"></div>
                    <div class="custella-import-path-dash"id="label_SetImportFilesGap3"></div>
                    <div class="custella-import-uncheckpoint me-4"id="label_SetImportFilesUncheck1"></div>
                </div>
                <div class="d-flex align-items-center justify-content-center m-4">
                    <span class="text-12 font-medium ms-5"id="label_SetImportFilesSelect" >{{translate?.setup.importExport.fields.select2.singular }}</span>
                    <div class="custella-import-path-none" id="label_SetImportFilesNon1"></div>
                    <span class="text-12 font-medium"  id="label_SetImportFilesUpload" >{{translate?.setup.importExport.fields.upload.singular }}</span>
                    <div class="custella-import-path-none"id="label_SetImportFilesNon2"></div>
                    <span class="text-12 font-medium" id="label_SetImportFilesMapping" >{{translate?.setup.importExport.fields.mapping.singular }}</span>
                    <div class="custella-import-path-none"id="label_SetImportFilesNon3"></div>
                    <span class="text-12 font-medium me-5" id="label_SetImportFilesListImport" >{{translate?.setup.importExport.sections.sec0.singular }}</span>
                </div>
            </div>
            <!-- Match Any -->
            <div class="custella-form-container-title-two-grey d-flex justify-content-between align-items-center">
                <span class="text-14 font-medium" id="title_SetImportFilesUpload" >{{translate?.setup.importExport.sections.sec4.singular }}</span>
            </div>
            <div class="custella-form-container-content">
                <div class="custella-content-row mb-3 pe-0">
                    <div class="custella-content-row-input col-12">
                        <div class="custella-content-row-input col-12">
                            <p-fileUpload [chooseLabel]="translate?.general.buttons.uploadFile.singular " [chooseIcon]="'pi pi-upload'"
                                [customUpload]="true" [auto]="true" [maxFileSize]="25000000"
                                invalidFileSizeMessageSummary=""
                                invalidFileSizeMessageDetail="Maximum upload file size is 25mb"
                                (uploadHandler)="uploadFileStep1($event.files)" accept=".csv" id="btn_SetImportFilesUpload">
                                <ng-template pTemplate="content">
                                    <div *ngIf="selectedFileName">
                                        <div class="p-fileupload-files custella-files">
                                            <div class="p-fileupload-row">
                                                <div
                                                    class="custella-file-notification flex-between rounded-2 w-100">
                                                    <!-- <img [src]="file.attachmentUrl" class="w-24px"> -->
                                                    <div class="d-flex align-items-center">
                                                        <i class="pi pi-file" id="label_SetImportFilesFileName"> {{selectedFileName}}</i>
                                                        <span
                                                            class="custella-content-row-input-title ms-2 pt-1 cursor-pointer"></span>
                                                    </div>
                                                    <!-- <span class="custella-file-notification-remove color-primary text-12 cursor-pointer">Remove</span> -->
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </ng-template>
                            </p-fileUpload>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-footer-two">
                <div class="d-flex align-items-center justify-content-end">
                    <button class="custella-btn-white text-12 ms-2" id="btn_SetImportFilesCancel" (click)="goBack()">{{translate?.general.buttons.back.singular }}</button>
                    <p-button [label]="buttonLabel" (onClick)="next()" id="btn_SetImportFilesSubmit" [disabled]="!validFile" styleClass="ms-2 h-32"></p-button>
                </div>
            </div>
        </div>
    </form>
</div>