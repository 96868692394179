import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BsModalRef } from "ngx-bootstrap/modal";
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { UsersService } from 'src/app/_services/admin/users.service';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';


@Component({
  selector: 'app-add-edit-notes',
  templateUrl: './add-edit-notes.component.html'
})
export class AddEditNotesComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  formData: any = {};
  addTask: any = {};
  submitTouched: boolean = false;
  mode: any;
  data : any;
  addNotesForm!: UntypedFormGroup;
  title: any;
  file : any;
  attachmentsArrayModal : any[] = [];
  translate: any;

  constructor(public modalRef: BsModalRef,
    private taskService: TasksService,
    private auth: AuthenticationService,
    private userService : UsersService,
    private errorUtil: ErrorUtil,
    private deleteRecordService: DeleteRecordService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
    this.getLanguageData()
    this.addNotesForm = new UntypedFormGroup({
      'notes': new UntypedFormControl(null, [Validators.required])
    });
  }

  ngOnInit() {
    //console.log("Task Data ", this.mode);
    if(this.mode == 'edit'){
      this.addNotesForm.controls['notes'].clearValidators();
      this.addNotesForm.controls['notes'].updateValueAndValidity();
      this.addTask.notes = this.data.note;
      this.attachmentsArrayModal = this.data.attachments;
    }
    if(this.mode == 'editUserNote'){
      this.addTask.notes = this.data.note;
    }
  }

  onSubmit() {
    this.submitTouched = true;
    var attachmentsArrayModal : any[] = [];
    if (!this.addNotesForm.valid) {
      for (const i in this.addNotesForm.controls) {
        this.addNotesForm.controls[i].markAsTouched();
      }
      this.submitTouched = false;
      return false;
    }
    if(this.attachmentsArrayModal.length){
      this.attachmentsArrayModal.forEach((a)=> {
        var attachment = 
        {
          attachmentUrl : a.attachmentUrl,
          fileName: a.fileName,
          contentContentType : a.contentContentType
        }
        attachmentsArrayModal.push(attachment);
      });
    }

    if(this.mode == 'add'){
      this.formData = {
        note: this.addTask.notes,
        task: {
          id: this.data.id
        },
        owner : {
          id : this.auth.getUserId()
        },
        attachments: attachmentsArrayModal
      }
    } else if(this.mode == 'edit'){
      this.formData = {
        id : this.data.id,
        note: this.addTask.notes,
        task: {
          id: this.data.task.id
        },
        owner : {
          id : this.data.owner.id
        },
        attachments: attachmentsArrayModal
      }
    } else if(this.mode == 'addUserNote'){
      this.formData = {
        note: this.addTask.notes,
        user: {
          id: this.auth.getUserId()
        }
      }
    } else if(this.mode == 'editUserNote'){
      this.formData = {
        note: this.addTask.notes,
        id : this.data.id,
        user: {
          id: this.auth.getUserId()
        }
      }
    }

    if (this.formData.note && (this.formData.note === "" || this.formData.note.length === 0)) {
      this.formData.note = null;
    }

    if(this.mode === 'add'){
      this.taskService.createTaskNotes(this.formData).subscribe((res:any)=>{
        //console.log("Response ", res);
        if(res.status === 200 || res.status === 201){
          this.modalRef.hide();
          this.errorUtil.setErrorMessage( 200,  null, this.translate?.general.messages.savedSuccessfully, "success", 3000 );

        }
        else {

          var errorTitle = res.error.title
          if (res.error.type == 'dynamic-lang-error') {
            var data = res.error.instance + ".validationBE." + res.error.detail;
            var message = this.languageTranslateService.getNestedValue(this.translate, data);
            errorTitle = message
          }

          this.errorUtil.setErrorMessage(
            res.status,
            null,
            errorTitle,
            "error",
            2000
          );
        }
      });
    }
    else if(this.mode === 'edit'){
      this.taskService.editTaskNotes(this.formData).subscribe((res:any)=>{
        //console.log("Response ", res);
        if(res.status === 200 || res.status === 201){
          this.modalRef.hide();
          this.errorUtil.setErrorMessage( 200,  null, this.translate?.general.messages.updatedSuccessfully, "success", 3000 );
        }
        else {

          var errorTitle = res.error.title
          if (res.error.type == 'dynamic-lang-error') {
            var data = res.error.instance + ".validationBE." + res.error.detail;
            var message = this.languageTranslateService.getNestedValue(this.translate, data);
            errorTitle = message
          }

          this.errorUtil.setErrorMessage(
            res.status,
            null,
            errorTitle,
            "error",
            2000
          );
        }
      });
    } else if(this.mode == 'addUserNote'){
      this.userService.createUserNote(this.formData).subscribe((res:any)=>{
        //console.log("Response ", res);
        if(res.status === 200 || res.status === 201){
          this.modalRef.hide();
          this.errorUtil.setErrorMessage( 200,  null,this.translate?.general.messages.savedSuccessfully, "success", 3000 );
          
        }
        else {

          var errorTitle = res.error.title
          if (res.error.type == 'dynamic-lang-error') {
            var data = res.error.instance + ".validationBE." + res.error.detail;
            var message = this.languageTranslateService.getNestedValue(this.translate, data);
            errorTitle = message
          }

          this.errorUtil.setErrorMessage(
            res.status,
            null,
            errorTitle,
            "error",
            2000
          );
        }
      });
    } else if(this.mode == 'editUserNote'){
      this.userService.editUserNote(this.formData).subscribe((res:any)=>{
        //console.log("Response ", res);
        if(res.status === 200 || res.status === 201){
          this.modalRef.hide();
          this.errorUtil.setErrorMessage( 200,  null, this.translate?.general.messages.updatedSuccessfully,"success", 3000 );
        }
        else {

          var errorTitle = res.error.title
          if (res.error.type == 'dynamic-lang-error') {
            var data = res.error.instance + ".validationBE." + res.error.detail;
            var message = this.languageTranslateService.getNestedValue(this.translate, data);
            errorTitle = message
          }

          this.errorUtil.setErrorMessage(
            res.status,
            null,
            errorTitle,
            "error",
            2000
          );
        }
      });
    }
  }

  public uploadFile($event: any) {
  console.log($event.target.files);
  
    this.parse($event.target.files);
  }

  parse(files: FileList): void {
    const file: File = files[0] as File;
    var self = this;
    const formData = new FormData();
    formData.append('file', file);
    this.taskService.uploadTaskAttachment(formData).subscribe((res:any)=>{
      var body = JSON.parse(res._body);
      body.fileName = file.name;
      //console.log("File Upload CSV Res 2", body);
      this.attachmentsArrayModal.push(body);
    });
  }


  openFileUploader(){
    $("#fileUploader2").click();
  }

  deleteAttachment(attachment : any){
    this.taskService.deleteTaskAttachment(attachment.id).subscribe((resp:any) => {
      if(resp.status == 200){
        this.attachmentsArrayModal = _.remove(this.attachmentsArrayModal, (o:any) => {
          return o.id !== attachment.id;
        });
      }
    });
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    console.log("translate",this.translate)
    //this.mainLabel = this.translate?.accessories.label.plural
  }

  push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

