import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UsersService } from 'src/app/_services/admin/users.service';
import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent extends SubscriptionUtil implements OnInit, OnDestroy {

  users : any[] = [];
  showDropdown!: boolean;
  showNoUser!: boolean;
  selectedUsers : any[] = [];
  searchUserVal: any;
  submitTouched : boolean = false;
  data: any;
  title: any
  translate: any;
  
  constructor(private userService: UsersService,
    private taskService : TasksService,
    public modalRef : BsModalRef,
    private errorUtil: ErrorUtil,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
    this.getLanguageData()}

  ngOnInit() {
  }

  getSearchedUserList(event: any){
    console.log(event, "===",this.searchUserVal)
    if(event){
      this.userService.getUsersBySearch(event.query).subscribe(users => {
        this.users = users;
        if(this.users.length){
          this.showDropdown = true;
          this.showNoUser = false;
        } else {
          this.showNoUser = true;
        }
      });
    } else{
      this.showDropdown = false;
    }
  }  

  addUser(user: any){
    console.log(user)
    this.searchUserVal = user
    this.selectedUsers = [];
    this.selectedUsers.push(user);
  }

  removeUser(){
    console.log("we are removing data")
    this.selectedUsers = [];
  }

  onSubmit(){
    this.submitTouched == true;
    this.taskService.addMemberToTask(this.data, this.selectedUsers[0]).subscribe((res: any) => {
      if(res.status == 200 || res.status == 201){
        this.modalRef.hide();
       this.errorUtil.setErrorMessage(200, null ,  this.translate?.general.messages.savedSuccessfully, 'success',1000);
        } else {
          this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
    },
    (err) => {
     console.log(err,"===>>>")
     this.errorUtil.setErrorMessage(400, null ,  err.error.title, 'error',3000);
    
  });
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    console.log("translate",this.translate)
   // this.mainLabel = this.translate?.accessories.label.plural
  }

  push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
