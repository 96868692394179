import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  ColDef,
  GridApi,
  GridOptions,
  GridParams,
  IGetRowsParams,
} from "ag-grid-community";
import { forkJoin } from "rxjs";
import { PermissionService } from "src/app/auth/permission.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import {
  PERSISTANT_ITEM,
  ServerRequest,
} from "src/app/_models/global.data.model";
import { CustomersService } from "src/app/_services/customers.service";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { ProjectService } from "src/app/_services/project.service";
import { SiteService } from "src/app/_services/site.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { ServiceZoneService } from "src/app/_services/service-zone.service";
import { DynamicTableColumnsService } from "src/app/_services/dynamic-table-column.service";
import { Global } from "src/app/_utilities/global";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

@Component({
  selector: "app-projects-table",
  templateUrl: "./projects-table.component.html",
  styleUrls: ["./projects-table.component.scss"],
})
export class ProjectsTableComponent
  extends SubscriptionUtil
  implements OnInit, OnChanges, OnDestroy
{
  // table
  public frameworkComponents!: any;
  public gridOptions!: GridOptions;
  public defaultColDef!: ColDef;
  public columnDefs: any[] = [];
  private gridParams!: GridParams;
  private gridApi!: GridApi;
  public overlayNoRowsTemplate : any;
  // '<span style="padding: 10px; font-size: 14px;">Record not found</span>';

  public totalRecords: any;

  public noData: boolean | null = null;
  public isFilterViewToggle = false;

  @Input() searchValue = "";
  public filters = this.returnFilterObject();

  @Output() noDataEvent = new EventEmitter();

  @Input() additionalData: any;

  public allProjectType: any[] = [];
  public allServiceZones: any[] = [];
  public allProjectCategorys: any[] = [];
  public allGeofences: any[] = [];
  public allSites: any[] = [];

  ids = {
    customerId: null,
  };

  private filterAPILoaded = false;

  private deleteHasPushed = false;
  loadTable: any = 0;
  sortBy: any;
  sortOrder: any;
  @Input() translate: any;
  tableContent: any;
  actionData: { headerName: any; filter: boolean; sortable: boolean; width: number; headerTooltip: string; headerClass: string; cellClass: string; cellRenderer: string; cellRendererParams: { onClick: any; permissions: { update: boolean; remove: boolean; }; hidden: { update: boolean; remove: boolean; }; }; };

  constructor(
    private perm: PermissionService,
    private deleteRecordService: DeleteRecordService,
    private router: Router,
    private util: UtilServiceService,
    private projectService: ProjectService,
    private errorUtil: ErrorUtil,
    private siteService: SiteService,
    private customerService: CustomersService,
    private serviceZoneService: ServiceZoneService,
    private dynamicTableColumnsService:DynamicTableColumnsService,
    private auth: AuthenticationService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
  
    this.preInit();
  }

  ngOnInit(): void {
    console.log(this.searchValue,"===")
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes && changes.searchValue &&
      changes.searchValue.currentValue !== changes.searchValue.previousValue
    ) {
      ////debugger;
      this.refresh();
    }

    if (
      changes.additionalData &&
      changes.additionalData.currentValue !==
        changes.additionalData.previousValue
    ) {
      ////debugger;
      this.refresh();
    }
    /************************** Check translate object changed or not:  Language Translated Dynamically (Preeti) ************************************/
   if (
    changes &&
    changes.translate && changes.translate.previousValue != undefined && Object.keys(changes.translate.previousValue).length !== 0 &&
    changes.translate.previousValue !== changes.translate.currentValue
  ) {
   this.setOnlyColumn();
  }
  }
  /************************** Set Table Header:  Language Translated Dynamically (Preeti) ************************************/
private setOnlyColumn() {
  // Reset columnDefs to avoid duplication
  this.gridOptions.columnDefs = [];
      console.log(this.tableContent);
         var columnDefs: any = this.gridOptions.columnDefs;
         this.tableContent.tableColumnSetups.forEach((element: any) => {
           this.cellRenderer(element);
            columnDefs.push(element);
           this.gridOptions.api!.setColumnDefs(columnDefs);
         });
         this.actionData.headerName = this.translate?.general.buttons.action.singular;
         columnDefs.push(this.actionData);
         this.gridOptions.api!.setColumnDefs(columnDefs);
         setTimeout(() => { this.gridApi.sizeColumnsToFit(); },500);
}


  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.defaultColDef = {
      enableRowGroup: false, //ag-Grid-Enterprise
      enablePivot: false, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };


  }

  /******************************************** Init ******************************************/
  private init() {
    this.getLanguageData()
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Configure Columns ******************************************/
  private cellRenderer(tc: any) {
    console.log(this.additionalData);
   

    if(tc.translatePath){
      tc.headerName = this.languageTranslateService.getNestedValue(this.translate, tc.translatePath);
     } 

     tc.headerTooltip = "#";
    let from = this.additionalData?.data.from;

      if (tc.field === "projectNumber") {
        tc.headerComponentParams =  Global.setHeaderComponentParams(tc,'Tbl_HeadProjNo'),
        tc.cellRenderer = (params: any) => {
          if(params.data){
            return  Global.setCellRenderer(params,params.data.rowId, params.data.projectNumber)
          }
        };

      }

      if (tc.field === "customer.name") {
        tc.headerComponentParams =  Global.setHeaderComponentParams(tc,'Tbl_HeadProjCust'),
        tc.cellRenderer= (params: any) => {
          if(params.data){
            return  Global.setCellRenderer(params,params.data.rowId2, params.data.customer?.name)
          }
        };

        // if (from === "customer") {
        //   column.hide = true;
        // }
      }

   

      if (tc.field === "active") {
        tc.cellRenderer = (params: any) => {
          if (params.data) {
            if (params.data.active) {
              return '<span class="p-mr-2  p-tag p-component p-tag-success" ><span class="p-tag-value">'+this.translate?.general.status.active.singular+ '</span></span>';
            } else {
              return '<span class="p-mr-2  p-tag p-component p-tag-danger" ><span class="p-tag-value text-dark">'+this.translate?.general.status.inactive.singular+'</span></span>';
            }

          }
        };
      }

      // if (tc.field === "project_status.name") {
      //   column.hide = true;
      // }

      // if (tc.field === "projectStatus.name") {
      //   column.hide = true;
      // }
      var dateFormat: any = localStorage.getItem("date_format");
      if (tc.dataType == 'date'  || tc.dataType == "text" || tc.dataType == "datetime") {
       
        tc = Global.setColumnByDataType(tc,this.auth.getUserTimezone(),dateFormat)
        console.log(tc,"=====>>>>");
       }
      
  
  }

  /******************************************** Actions ******************************************/
  public edit(e: any) {
    if (e.action === "edit") {
      this.util.setPersistantItem(PERSISTANT_ITEM.PROJECTS, e.rowData);

      this.router.navigate(["/dashboard/projects/update/" + e.rowData.id], {
        queryParams: { id: e.rowData.id },
      });
    }

    if (e.action === "delete") {
      console.log(e);

      const item = {
        deleteHeader : this.translate.project.label.singular,
        deleteType: "Project",
        id: e.rowData.id,
        message:  this.translate.general.messages.confirmDelete+" "+`${e.rowData.projectNumber} - ${e.rowData.name}?`,
      };

      if (!this.deleteHasPushed) {
        this.push(
          this.deleteRecordService.getItems(item).subscribe((resp) => {
            // refresh the table

            if (resp.status === 200) {
              this.refresh();
            }
          })
        );

        this.deleteHasPushed = true;
      } else {
        this.deleteRecordService.getItems(item);
      }
    }
  }

  /******************************************** On Table Ready ******************************************/
  public onGridReady(params: any) {
    console.log("grid ready inside")
    if (params != undefined) {
      this.gridParams = params;
      
      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      console.log("params undifined")
      var dataSource = {
        getRows: (params: IGetRowsParams) => {
          if (this.loadTable == 1) {
          this.getServerSideData(params);
          }
        },
      };

      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  /******************************************** Get Data ******************************************/
  private getServerSideData(agGridGetRowsParams: IGetRowsParams) {
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    var count: any=serverRequest.startRow;

    if (this.additionalData && this.additionalData.customer) {
      this.ids.customerId = this.additionalData.customer.id;
    }

    if (
      this.additionalData &&
      this.additionalData.data &&
      this.additionalData.data.siteId
    ) {
      this.filters.site = this.additionalData.data.siteId;
    }

    let from = null;

    if (
      this.additionalData &&
      this.additionalData.data &&
      this.additionalData.data.from
    ) {
      from = this.additionalData.data.from;
    }

    if (
      this.filters.category_type ||
      this.filters.geofence ||
      this.filters.service_zone ||
      this.filters.type
    ) {
      from = null;
    }

    if (this.searchValue && this.searchValue.length > 0) {
      from = null;
    } else {
      from = this.additionalData?.data?.from;
    }

    this.push(
      this.projectService
        .getAllProjects(
          serverRequest,
          this.searchValue,
          this.filters.type,
          this.filters.service_zone,
          this.filters.category_type,
          this.filters.geofence,
          this.filters.site,
          this.ids.customerId,
          from
        )
        .subscribe(
          (resp) => {
            if (resp) {
              resp.data.forEach((element: any) => {
                count++
                element.rowId='Tbl_RecProjNo'+count
                element.rowId2='Tbl_RecProjCust'+count
                element.buttonEdit='Btn_ProjEdit'+count
                element.buttonDelete='Btn_ProjDel'+count
              });
              this.setGridData(
                agGridGetRowsParams,
                resp.data,
                resp.total,
                resp.status
              );
            }
          },
          (err) => {
            this.errorUtil.setErrorMessage(err);
          }
        )
        
    );
  }

  /******************************************** Get Params ******************************************/
  private getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      const parameters = { colId: this.sortBy, sort: this.sortOrder };
      agGridRequest.sortModel.push(parameters);
    }
   //this.currentRecordNumber = agGridRequest.startRow;
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }

  /******************************************** Set Data ******************************************/
  private setGridData(
    agGridGetRowsParams: IGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    this.gridApi.hideOverlay();

    this.totalRecords = totalCount;

    if (status === "SEARCH_NO_RESULTS") {
      this.noData = true;
      this.gridApi.showNoRowsOverlay();
    } else {
      this.noData = false;

      if (resultItems.length === 0) {
        this.gridApi.showNoRowsOverlay();
      } else {
        console.log(totalCount);
        agGridGetRowsParams.successCallback(resultItems, totalCount);
      }

      ///CS-4758 this.gridApi.sizeColumnsToFit();
    }

    this.noDataEvent.emit(this.noData);
  }

  /******************************************** Refresh Table ******************************************/
  public refresh() {
    console.log("resresh inside")
    this.onGridReady(this.gridParams);
  }

  /******************************************** Toggle Filter ******************************************/
  public toggleFilter() {
    this.isFilterViewToggle = !this.isFilterViewToggle;

    if (this.filterAPILoaded === false) {
      const sources = [
        this.projectService.getAllProjectTypes(null),
        this.serviceZoneService.getAllServiceZones(),
        this.projectService.getAllProjectCategories(true, true),
        this.customerService.getGeofencePickList(),
        this.siteService.getSitePicklist(null),
      ];

      this.push(
        forkJoin(sources).subscribe(
          ([
            allProjectTypes,
            allServiceZones,
            allProjectCategories,
            allGeofences,
            allSites,
          ]) => {
            this.allProjectType = allProjectTypes;
            this.allServiceZones = allServiceZones.sort((elementA: any, elementB: any) => {
              if (elementA?.order > elementB?.order) {
                return 1;
              }
    
              if (elementA?.order < elementB?.order) {
                return -1;
              }
    
              return 0;
            });
    
            this.allProjectCategorys = allProjectCategories;
            this.allGeofences = allGeofences;
            this.allSites = allSites;
          }
        )
      );

      this.filterAPILoaded = true;
    }
  }

  /******************************************** Clear All ******************************************/
  public clearAll() {
    this.filters = this.returnFilterObject();

    this.refresh();
  }

  /******************************************** View Cell ******************************************/
  public viewCell(e: any) {
    this.util.resetTabView();

    if (e.colDef.field === "projectNumber") {
      this.router.navigate(["/dashboard/projects/view/" + e.data.id], {
        queryParams: { id: e.data.id },
      });
    }else{

    // if (e.colDef.field === "customer.name") {
    //   this.router.navigate(
    //     ["/dashboard/customers/view/" + e.data.customer.id],
    //     {
    //       queryParams: { id: e.data.customer.id },
    //     }
    //   );
    // }
    this.dynamicTableColumnsService.redirectURL(e);
    }
  }

  /******************************************** Table Context Menu ******************************************/
  public getContextMenuItems = (params: any) => {
    debugger
    params.node.data.field = params.column.colId;

    return this.dynamicTableColumnsService.rightClick(params);
  };
  /******************************************** Returns Filter Object ******************************************/
  private returnFilterObject() {
    return {
      type: null,
      service_zone: null,
      category_type: null,
      geofence: null,
      site: null,
    };
  }
  /******************************************** Add ALl the Subscription ******************************************/
  push(obs: any) {
    super.push(obs);
  }

  getLanguageData() {
   
        this.overlayNoRowsTemplate = "<div class='not-found'><span>"+this.translate?.general.messages.noRecordsFound+"</span> </div>";

        this.push(
          this.projectService.getListColumn("project").subscribe((resp) => {
            if (resp && resp.tableColumnSetups) {
              this.tableContent = resp;
              this.sortBy = resp.sortBy
              this.sortOrder = resp.sortOrder
              var columnDefs: any = []
              resp.tableColumnSetups.forEach((element: any) => {
                this.cellRenderer(element);
          
                columnDefs.push(element);
                this.gridOptions.api!.setColumnDefs(columnDefs);
              });
             
    
              this.actionData = {
                headerName: this.translate?.general.buttons.action.singular,
                filter: false,
                sortable: false,
                width: 150,
                headerTooltip: "#",
                headerClass: "marging-auto hide-action-border",
                cellClass: "text-center",
                cellRenderer: "buttonRenderer",
                cellRendererParams: {
                  onClick: this.edit.bind(this),
                  permissions: {
                    update: this.perm.capable("project", "update"),
                    remove: this.perm.capable("project", "remove"),
                  },
                  hidden: {
                    update: !this.perm.capable("project", "update"),
                    remove: !this.perm.capable("project", "remove"),
                  },
                },
              }


              // const index = this.columnDefs.indexOf(
              //   (cd: any) => cd.headerName === "Action"
              // );
              // if (index === -1) {
                columnDefs.push(this.actionData); 
                this.gridOptions.api!.setColumnDefs(columnDefs);
                
             // }
    
              this.loadTable = 1;
              this.onGridReady(this.gridParams);
              
            }
          })
        );
     
    console.log("translate",this.translate)
    //this.mainLabel = this.translate.project.label.plural
  }
}
