import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService } from "primeng/api";
import { AppComponent } from "src/app/app.component";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { PERSISTANT_ITEM } from "src/app/_models/global.data.model";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { CustomersService } from "src/app/_services/customers.service";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { ModalServiceService } from "src/app/_services/modal-service.service";
import { ProjectService } from "src/app/_services/project.service";
import { SignUpService } from "src/app/_services/sign-up.service";
import { SiteService } from "src/app/_services/site.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { TranslateService } from "@ngx-translate/core";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

@Component({
  selector: "app-add-edit-contact",
  templateUrl: "./add-edit-contact.component.html",
  styleUrls: ["./add-edit-contact.component.scss"],
  providers: [ConfirmationService],
})
export class AddEditContactComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  formData: any;
  addContactForm!: UntypedFormGroup;
  submitTouched: boolean = false;
  data: any;
  addContact: any = {
    title: null,
    type: "PUBLIC",
    enableCustomerPortal: false,
    gender: "",
    geoFenceId: "",
  };
  saveButtonLabel: any;
  defaultCountry: any;
  geofences: any[] = [];
  modalState: any;
  selectedConponent: any;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "modal-md",
  };
  enableCustomerPortal: boolean = false;
  addonFeature: any;
  data2: any;
  mode: any;
  customerId: any;
  contactId: any;
  customerTitle = [
    { value: "Mr.", label: "Mr." },
    { value: "Ms.", label: "Ms." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Tun", label: "Tun" },
    { value: "Tan Sri", label: "Tan Sri" },
    { value: "Puan Sri", label: "Puan Sri" },
    { value: "Dato", label: "Dato" },
    { value: "Dato' Sri", label: "Dato' Sri" },
    { value: "Datin", label: "Datin" },
    { value: "Datuk", label: "Datuk" },
    { value: "Datin Paduka", label: "Datin Paduka" },
    { value: "Tuan", label: "Tuan" },
    { value: "Puan", label: "Puan" },
    { value: "Cik", label: "Cik" },
  ];

  customerContactType = [
    { value: "PRIVATE", label: "Private" },
    { value: "PUBLIC", label: "Public" },
  ];
  pageTitle!: string;
  valSwitch: boolean = false;

   otherData = {
    accessFrom: "",
    site: {
      id: <number | null>null,
      label: <any | null>null,
    },
    projects: {
      id: <number | null>null,
      label: <any | null>null,
    },
  };
  mainLabel :any;
  translate: any;
  constructor(
    public util: UtilServiceService,
    private spinner: NgxSpinnerService,
    private customerService: CustomersService,
    private errorUtil: ErrorUtil,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthenticationService,
    private confirmationService: ConfirmationService,
    private signUpService: SignUpService,
    private modalService2: ModalServiceService,
    private deleteRecordService: DeleteRecordService,
    private siteService: SiteService,
    private projectService: ProjectService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
   this.spinner.show();
    this.preInit();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
   // this.util.clearPersistantItem(PERSISTANT_ITEM.CONTACTS);
    super.ngOnDestroy();
  }

  private preInit() {
   
    this.addContactForm = new UntypedFormGroup({
      // 'title': new FormControl(null, [Validators.required]),
      name: new UntypedFormControl(null, [Validators.required]),
      phone: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(AppComponent.phonePattern),
      ]),
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(AppComponent.emailPattern),
      ]),
      type: new UntypedFormControl(null),
      timezone: new UntypedFormControl(null),
      userName: new UntypedFormControl(null),
    });
  }

  private init() {
    const data = this.util.getPersistantItem(PERSISTANT_ITEM.CONTACTS);
  
    console.warn(data);

    if (data) {
      if (data && data.id && data.accessFrom) {
        this.otherData.accessFrom = data.accessFrom;

        if (this.otherData.accessFrom === "site" || this.otherData.accessFrom === "sites") {
          this.otherData.site.id = Number(data.id);
          this.otherData.site.label = data.label;
        }

        if (this.otherData.accessFrom === "project" || this.otherData.accessFrom === "projects") {
          this.otherData.projects.id = Number(data.id);
          this.otherData.projects.label = Number(data.label);
        }
      }

      console.log(this.otherData);
    }

    
    this.addonFeature = this.auth.getAddonFeatureLists();
    if (this.route.snapshot.data["mode"]) {
      this.mode = this.route.snapshot.data["mode"];
     
    }

    this.route.queryParams.subscribe((params) => {
      this.customerId = params["custId"];
      this.contactId = params["cid"];
      
      if (!this.contactId) {
        this.contactId = params["id"];
      }
      this.getLanguageData()
      
    });
  }

  getContactDetails() {
    if (this.mode === "edit") {
      this.pageTitle = "Edit ";
      this.saveButtonLabel = this.translate?.general.buttons.update.singular
      //this.addContact = this.data;

      if (this.otherData.accessFrom === "site" || this.otherData.accessFrom === "site") {
        this.customerService.getUserId(this.contactId).subscribe((res: any) => {
          const data = res.body;

          this.enableCustomerPortal = data.contactLite.enableCustomerPortal;
          this.addContact.email = data.contactLite.email;
          this.addContact.fullName = data.contactLite.fullName;
          this.addContact.phone = data.contactLite.phone;
          this.addContact.title = data.contactLite.title;
          this.addContact.type = data.contactLite.type;
          this.addContact.designation = data.contactLite.designation;

          this.addContact.enableCustomerPortal =
            data.contactLite.enableCustomerPortal;
          if (!this.addContact.enableCustomerPortal) {
            this.addContactForm.controls["userName"].clearValidators();
            this.addContactForm.controls["userName"].updateValueAndValidity();
            this.valSwitch = false;
          } else {
            this.valSwitch = true;
          }
        });
      } else if (this.otherData.accessFrom === "project" || this.otherData.accessFrom === "projects") {
        this.customerService.getUserId(this.contactId).subscribe((res: any) => {
          const data = res.body;

          this.enableCustomerPortal = data.contactLite.enableCustomerPortal;
          this.addContact.email = data.contactLite.email;
          this.addContact.fullName = data.contactLite.fullName;
          this.addContact.phone = data.contactLite.phone;
          this.addContact.title = data.contactLite.title;
          this.addContact.type = data.contactLite.type;
          this.addContact.designation = data.contactLite.designation;

          this.addContact.enableCustomerPortal =
            data.contactLite.enableCustomerPortal;
          if (!this.addContact.enableCustomerPortal) {
            this.addContactForm.controls["userName"].clearValidators();
            this.addContactForm.controls["userName"].updateValueAndValidity();
            this.valSwitch = false;
          } else {
            this.valSwitch = true;
          }
        });
      } else {
        this.customerService.getUserId(this.contactId).subscribe((res: any) => {
          console.log("res", res)
          this.addContact = res.body;
          this.enableCustomerPortal =
            this.addContact.contactLite.enableCustomerPortal;
          this.addContact.email = this.addContact.contactLite.email;
          this.addContact.fullName = this.addContact.contactLite.fullName;
          this.addContact.phone = this.addContact.contactLite.phone;
          this.addContact.title = this.addContact.contactLite.title;
          this.addContact.type = this.addContact.contactLite.type;
          this.addContact.designation = this.addContact.contactLite.designation;
          this.addContact.geoFenceId = this.addContact.geoFence
            ? this.addContact.geoFence.id
            : null;
          this.addContact.enableCustomerPortal =
            this.addContact.contactLite.enableCustomerPortal;
          if (!this.addContact.enableCustomerPortal) {
            this.addContactForm.controls["userName"].clearValidators();
            this.addContactForm.controls["userName"].updateValueAndValidity();
            this.valSwitch = false;
          } else {
            this.valSwitch = true;
          }
          this.customerId = this.addContact.contactLite.customer?.id;
        });
      }
    } else {
      this.pageTitle = "New ";
      this.defaultCountry = "my";
    }
   this.loadBreadcrumbs();
  }

  /******************************************** Load Breadcrumbs ******************************************/
  private loadBreadcrumbs() {
    this.spinner.hide();
    this.addonFeature = this.auth.getAddonFeatureLists();
    // if(this.addonFeature.addonMS == 'MACHINE_SPECIALIST'){
    //   this.mainLabel = 'Estate'
    // }
//debugger
    if (this.otherData.accessFrom === "site" || this.otherData.accessFrom === "sites") {
     
      this.breadcrumbService.setItems([
        { label: this.translate?.site.label.plural, routerLink: ["/dashboard/sites"]  },
        {
              label: this.otherData.site.label,
              command: (e) => {
                this.router.navigate(
                  ["dashboard/sites/view/" + this.otherData.site.id],
                  { queryParams: { id: this.otherData.site.id } }
                );
              },
            },
        { label:  this.mode == "add" ?  this.translate?.general.buttons.new.singular + " " +this.translate?.contacts.label.singular :  this.translate?.general.buttons.edit.singular + " " +this.translate?.contacts.label.singular  },
       
      ]);


    } else if (this.otherData.accessFrom === "project" || this.otherData.accessFrom === "projects") {
      // this.breadcrumbService.setItems([
      //   { label: "Projects", routerLink: ["/dashboard/projects"] },
      //   {
      //     label: "View Project",
      //     command: (e) => {
      //       this.router.navigate(
      //         ["dashboard/projects/view/" + this.otherData.projects.id],
      //         { queryParams: { id: this.otherData.projects.id } }
      //       );
      //     },
      //   },
      //   { label: this.pageTitle + " Contact" },
      // ]);

      this.breadcrumbService.setItems([
        { label: this.translate?.project.label.plural, routerLink: ["/dashboard/projects"]  },
        {
              label: this.otherData.projects.label,
              command: (e) => {
                this.router.navigate(
                  ["dashboard/projects/view/" + this.otherData.projects.id],
                  { queryParams: { id: this.otherData.projects.id } }
                );
              },
            },
        { label:  this.mode == "add" ?  this.translate?.general.buttons.new.singular + " " +this.translate?.contacts.label.singular :  this.translate?.general.buttons.edit.singular + " " +this.translate?.contacts.label.singular  },
       
      ]);

    } else {
      // this.breadcrumbService.setItems([
      //   { label: "Customer", routerLink: ["/dashboard/customers/all"] },
      //   // {
      //   //   label: "View Customer",
      //   //   command: (e) => {
      //   //     this.router.navigate(
      //   //       ["dashboard/customers/view/" + this.customerId],
      //   //       { queryParams: { id: this.customerId } }
      //   //     );
      //   //   },
      //   // },
      //   { label: this.pageTitle + " Contact" },
      // ]);

      this.breadcrumbService.setItems([
        { label: this.translate?.customer.label.plural, routerLink: ["/dashboard/customers/all"]  },
        { label:  this.mode == "add" ?  this.translate?.general.buttons.new.singular + " " +this.translate?.contacts.label.singular :  this.translate?.general.buttons.edit.singular + " " +this.translate?.contacts.label.singular  },
       
      ]);

    }
  }

  checkValidity() {
    this.addContact.enableCustomerPortal = this.valSwitch;
    if (!this.addContact.enableCustomerPortal) {
      this.addContactForm.controls["userName"].clearValidators();
      this.addContactForm.controls["userName"].updateValueAndValidity();
    } else {
      //this.addContactForm.controls['userName'].setValidators([Validators.required]);
      this.addContactForm.controls["userName"].setValidators([
        Validators.required,
        Validators.pattern(AppComponent.emailPattern),
      ]);
      this.addContactForm.controls["userName"].updateValueAndValidity();
    }
  }
  checkPhoneValidation() {
    setTimeout(() => {
      if (!this.addContact.email) {
        this.addContactForm.controls["email"].setValidators([
          Validators.required,
          Validators.pattern(AppComponent.emailPattern),
        ]);
        this.addContactForm.controls["email"].updateValueAndValidity();
      } else if (!this.addContact.phone) {
        this.addContactForm.controls["phone"].setValidators([
          Validators.required,
          Validators.pattern(AppComponent.phonePattern),
        ]);
        this.addContactForm.controls["phone"].updateValueAndValidity();
      }

      if (
        this.addContact.phone &&
        this.addContact.phone.match(AppComponent.phonePattern)
      ) {
        this.addContactForm.controls["email"].setValidators([
          Validators.pattern(AppComponent.emailPattern),
        ]);
        this.addContactForm.controls["email"].updateValueAndValidity();
      } else if (
        this.addContact.email &&
        this.addContact.email.match(AppComponent.emailPattern)
      ) {
        this.addContactForm.controls["phone"].setValidators([
          Validators.pattern(AppComponent.phonePattern),
        ]);
        this.addContactForm.controls["phone"].updateValueAndValidity();
      }
    });
  }

  bindUsername(email: any) {
    this.addContact.login = email;
  }

  onSubmitForm() {
    this.submitTouched = true;

    if (!this.addContactForm.valid) {
      for (var i in this.addContactForm.controls) {
        this.addContactForm.controls[i].markAsTouched();
      }
      if (!this.addContact.enableCustomerPortal) {
        this.addContactForm.controls["userName"].clearValidators();
        this.addContactForm.controls["userName"].updateValueAndValidity();
      }
      this.submitTouched = false;
      return false;
    }

    this.spinner.show();
    console.log(this.data);

    const id = this.contactId ? this.contactId : null;
    const customerId = this.customerId
      ? this.customerId
      : this.addContact.customer
      ? this.addContact.contactLite.customer.id
      : null;

    this.formData = {
      contactLite: {
        customer: {
          id: customerId,
        },
        email: this.addContact.email,
        fullName: this.addContact.fullName,
        phone: this.addContact.phone,
        title: this.addContact.title,
        type: this.addContact.type,
        id: id,
        designation: this.addContact.designation,
        enableCustomerPortal: this.addContact.enableCustomerPortal,
      },
      login: this.addContact.login,
      gender: this.addContact.gender,
      dateFormatString: this.addContact.dateFormatString,
      timezone: this.addContact.timezone,
    };
    if (this.addContact.geoFenceId) {
      this.formData.geoFence = { id: this.addContact.geoFenceId };
    } else {
      this.formData.geoFence = null;
    }

    let copy = JSON.parse(JSON.stringify(this.formData));

    console.log(copy);

    if (this.otherData.accessFrom === "site" || this.otherData.accessFrom === "sites") {
      copy = Object.assign(copy, { site: { id: this.otherData.site.id } });
      copy = Object.assign(copy, copy.contactLite);
      delete copy.contactLite;
      delete copy.geoFence;
      delete copy.customer;
    }

    if (this.otherData.accessFrom === "project" || this.otherData.accessFrom === "projects") {
      copy = Object.assign(copy, {
        project: { id: this.otherData.projects.id },
      });
      copy = Object.assign(copy, copy.contactLite);
      delete copy.contactLite;
      delete copy.geoFence;
      delete copy.customer;
    }

    if (this.mode === "edit") {
      this.formData.id = this.addContact.id;

      if (this.otherData.accessFrom === "site" || this.otherData.accessFrom === "sites") {
        this.siteService.updateSiteContact(copy).subscribe(
          (res: any) => {
            console.log(res,"resssss")
            this.errorUtil.setErrorMessage(
              200,
              this.translate?.general.messages.updatedSuccessfully,
              null,
              "success",
              3000
            );
            this.router.navigate(["dashboard/sites/contacts/view/" + res.id], {
              queryParams: { id: res.id },
            });

            this.spinner.hide();
          },
          (error) => {
            this.submitTouched = false;
            this.spinner.hide();

            this.errorUtil.setErrorMessage(
              error.status,
              null,
              error.error.title,
              "error",
              3000
            );
          }
        );
      } else if (this.otherData.accessFrom === "project" || this.otherData.accessFrom === "projects") {
        this.projectService.updateProjectContact(copy).subscribe(
          (res: any) => {
            this.errorUtil.setErrorMessage(
              200,
              this.translate?.general.messages.updatedSuccessfully,
              null,
              "success",
              3000
            );

            this.router.navigate(
              ["dashboard/projects/contacts/view/" + res.id],
              {
                queryParams: { id: res.id },
              }
            );

            this.spinner.hide();
          },
          (error) => {
            this.submitTouched = false;
            this.spinner.hide();

            this.errorUtil.setErrorMessage(
              error.status,
              null,
              error.error.title,
              "error",
              3000
            );
          }
        );
      } else {
        this.customerService.editContactUser(copy).subscribe((res: any) => {
          if (res.status == 200 || res.status == 201) {
            this.errorUtil.setErrorMessage(
              200,
              this.translate?.general.messages.updatedSuccessfully,
              null,
              "success",
              3000
            );

            this.router.navigate(
              ["dashboard/customers/contact/view/" + res.body.contactLite.id],
              { queryParams: { id: res.body.contactLite.id } }
            );
          } else {
            this.submitTouched = false;
            this.spinner.hide();

            this.errorUtil.setErrorMessage(
              res.status,
              null,
              res.error.title,
              "error",
              3000
            );
          }

          this.spinner.hide();
        });
      }
    } else if (this.mode === "add") {
      if (this.otherData.accessFrom === "site" || this.otherData.accessFrom === "sites") {
        this.siteService.createSiteContact(copy).subscribe(
          (res: any) => {
            this.errorUtil.setErrorMessage(
              200,
              this.translate?.general.messages.savedSuccessfully,
              null,
              "success",
              3000
            );

            this.router.navigate(["dashboard/sites/contacts/view/" + res.id], {
              queryParams: { id: res.id },
            });

            this.spinner.hide();
          },
          (error) => {
            this.submitTouched = false;
            this.spinner.hide();

            this.errorUtil.setErrorMessage(
              error.status,
              null,
              error.error.title,
              "error",
              3000
            );
          }
        );
      } else if (this.otherData.accessFrom === "project" || this.otherData.accessFrom === "projects") {
        this.projectService.createProjectContact(copy).subscribe(
          (res: any) => {
            this.errorUtil.setErrorMessage(
              200,
              this.translate?.general.messages.savedSuccessfully,
              null,
              "success",
              3000
            );

            this.router.navigate(
              ["dashboard/projects/contacts/view/" + res.id],
              {
                queryParams: { id: res.id },
              }
            );

            this.spinner.hide();
          },
          (error) => {
            this.submitTouched = false;
            this.spinner.hide();

            this.errorUtil.setErrorMessage(
              error.status,
              null,
              error.error.title,
              "error",
              3000
            );
          }
        );
      } else {
        this.customerService.createContactUser(copy).subscribe((res: any) => {
          if (res.status == 200 || res.status == 201) {
            this.errorUtil.setErrorMessage(
              200,
              this.translate?.general.messages.savedSuccessfully,
              null,
              "success",
              3000
            );

            this.router.navigate(
              ["dashboard/customers/contact/view/" + res.body.contactLite.id],
              { queryParams: { id: res.body.contactLite.id } }
            );
          } else {
            this.submitTouched = false;
            this.spinner.hide();

            this.errorUtil.setErrorMessage(
              res.status,
              null,
              res.error.title,
              "error",
              3000
            );
          }

          this.spinner.hide();
        });
      }
    }
    

  }

  resendEmail(data: any) {
    this.addContact.deleteType = "Resend Activation Email";
    this.addContact.deleteIconType = "confirm";
    this.addContact.type = "resendCustomerUserEmail";
    this.addContact.message = this.translate?.contacts.messages.confirmResendActivationEmail

    this.deleteRecordService.getItems(this.addContact);
    //this.modalService2.openModal('resendActivationEmail', data);
  }

  changePassword(data: any) {
    if (data.id == this.auth.getUserId()) {
      this.modalService2.openModal("resetContactPassword", data);
    } else {
      this.modalService2.openModal("confirmContactResetPassword", data);
    }
  }
  openModal(modalView: any, data: any) {
    if (modalView === "activateUser") {
      this.addContact.deleteType = "Activate User";
      this.addContact.deleteIconType = "confirm";
      this.addContact.message = this.translate?.contacts.messages.confirmActivateUser
      console.log(this.addContact);
      this.deleteRecordService.getItems(this.addContact);
    } else if (modalView === "deactivateUser") {
      this.addContact.deleteType = "Deactivate User";
      this.addContact.deleteIconType = "confirm";
      this.addContact.message =
      this.translate?.contacts.messages.confirmDeactivateUser
      console.log(this.addContact);
      this.deleteRecordService.getItems(this.addContact);
    } else if (modalView === "suspendUser") {
      this.addContact.deleteType = "Suspend User";
      this.addContact.deleteIconType = "confirm";
      this.addContact.message =
      this.translate?.contacts.messages.confirmSuspendUser
      console.log(this.addContact);
      this.deleteRecordService.getItems(this.addContact);
    }
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        this.getContactDetails();
        this.saveButtonLabel = this.translate?.general.buttons.save.singular
      }
    }))
    console.log("translate",this.translate)
    //this.mainLabel = this.translate?.contacts.label.singular
  }

  push(obs:any) {
    super.push(obs);
  }
}
