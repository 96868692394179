import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { UsersService } from "src/app/_services/admin/users.service";
import { TasksService } from "src/app/_services/tasks.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";

import * as _ from "lodash";
import * as moment from "moment-timezone";

import { TeamService } from "src/app/_services/team.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { ConfirmActionsService } from "src/app/_services/confirm-actions.service";
import { AppEvents } from "src/app/_models/global.data.model";
import { AddEditTaskComponent } from "../add-edit-task/add-edit-task.component";
import { ModalServiceService } from "src/app/_services/modal-service.service";
import { DateHelper, Scheduler, StringHelper , Popup, EventModel } from '@bryntum/scheduler';
import { BryntumSchedulerComponent } from "@bryntum/scheduler-angular";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { event } from "jquery";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

@Component({
  selector: "app-smart-assign",
  templateUrl: "./smart-assign.component.html",
  styleUrls: ["./smart-assign.component.scss"],
})
export class SmartAssignComponent extends SubscriptionUtil implements OnInit {
  showNoUser!: boolean;
  // refreshAssignTask: Subscription;
  userNames: any[] = [];
  submitTouched: boolean = false;
  assignMode: any = "user";
  skillsString!: string;
  suggestedMembers: any;
  timezone: any;
  checkUsers: any;
  teamMembers: any[] = [];
  showTeamNames!: boolean;
  showUserNames!: boolean;
  teamSelected: boolean = false;
  teams: any;
  users: any[] = [];

  disableShowOptions: boolean = true;
  first: boolean = true;
  data: any;
  assignTask: any = { teamName: null, userNames: [], teamCategory: {} };
  configMd: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "modal-md",
  };
  configCustomMd: any = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: true,
    class: "custom-modal-md",
  };
  selectedComponent: any;
  selectedConfig: any;
  modalState: any;
  mode!: string;
  parentMode!: string;
  tempTeam: any = null;
  dateFormat: any;
  showDropdown!: boolean;
  searchUserVal: any;
  selectedUsers: any[] = [];
  selectedTime: any;
  ownerSelected: any;
  // selectedTimeEmitter: Subscription;
  teamCategories!: string;

  html: string = `
      <div>
        <div *ngFor="let skill of data?.userDynamicSkillList;let i = index">
          <div class="d-flex align-items-center drl my-2" *ngIf="i > 1">
            <p class="text-1 mb-0">{{skill?.label}}</p>
          </div>
        </div>
      </div>`;

  // @HostListener("click", ["$event"])
  // onClick(event: any): void {
  //   // 
  //   var match;
  //   var findDropDown;
  //   event.path.forEach((element: any) => {
  //     findDropDown = _.find(element.classList, (o) => {
  //       return o == "userDropDown";
  //     });
  //     if (findDropDown) {
  //       match = true;
  //     }
  //   });
  //   if (!match) {
  //     this.searchUserVal = null;
  //     this.showDropdown = false;
  //   }
  // }

  public allNumberOfUsers: any[] = [];

  ///////////////////////////////////////////////////////////////
  @ViewChild(BryntumSchedulerComponent, { static : true }) schedulerComponent: BryntumSchedulerComponent | undefined;

  resources: any[] = [];
  events: any = [];
  byTeamOnlyChk: boolean = true;
  employees: any;
  notTeamMember: Boolean = false;
  teamsChkShow: boolean = false;
  leaves: any;
  team: any;
  schedulerConfig: any
  scheduler!: Scheduler;
  viewPreset = 'hourAndDay';
  features: any;
  colors : any[] = ['#AD1457','#AB1A58','#D62161','#D30915','#E47D75','#F1522C','#ED6C1F','#EE9224','#F5BE3B','#E3C34D','#C0C842','#7DB249','#3BB57B','#167F45','#159588','#1B9CE2','#4688F1','#4054B2','#7A88C9','#B39ED9','#9D6BAD','#8D2CA8','#785549','#616161','#A79B8F']
  data2: any;
  eventRenderer: any;
  translate: any;
  
  constructor(
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private userService: UsersService,
    private teamService: TeamService,
    private taskService: TasksService,
    private utilService: UtilServiceService,
    private auth: AuthenticationService,
    private router: Router,
    private errorUtil: ErrorUtil,
    private confirmActionService: ConfirmActionsService,
    private modalService1: ModalServiceService,
    private util: UtilServiceService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();

    this.timezone = auth.getUserTimezone();
   
   this.push( this.utilService.getData().subscribe((data: any) => {
      if (data) {
        if (data.action && data.action === AppEvents.ASSIGN_TASK) {
          console.log(data);
          if (data.data && data.data.assignMode === "assign") {
            this.onSubmit(data.data.assignMode);
          } else if (data.data && data.data.assignMode === "assignToTeam") {
            this.assignTaskToTeamMember(data.data, data.data.type);
          }
        }
      }
    })
   )


  //  this.push( this.selectedTimeEmitter = this.taskService.selectTime$.subscribe((val) => {
  //     if (val) {
  //       this.selectTime(val);
  //     }
  //   }))

    this.getBryEvent()
  }
  
  ngOnInit() {
    console.log("Assign Task ", this.data);
    this.parentMode = this.data.parentMode
    this.dateFormat = localStorage.getItem("date_format")!;

    if (this.data.adhoc) {
      this.assignTask.teamCategory.available = true;
    }
    if (this.data.team != undefined) {
      this.assignMode = 
      this.callTeamApi('team')
    }
    this.getLanguageData()

  }
  


  ngOnDestroy() {
    super.ngOnDestroy();
    // this.refreshAssignTask.unsubscribe();
    // this.selectedTimeEmitter.unsubscribe();
  }

  doSomething(data: any) {
    if (data.teamName && data.teamName.length) {
      this.disableShowOptions = false;
    } else {
      this.teamSelected = false;
      this.disableShowOptions = true;
    }
  }

  getSearchedUserList(event: any) {
    if (event) {
      var team = null;
      if (this.assignMode == "team") {
        team = this.tempTeam;
      }
      this.userService.getUsersBySearch(event).subscribe((users) => {
        this.users = users;
        if (this.users.length) {
          this.showDropdown = true;
          this.showNoUser = false;
        } else {
          this.showNoUser = true;
        }
      })
    } else {
      this.showDropdown = false;
    }
  }

  getClickedEvent(event: any) {
    
  }

  async selectTeam(team: any) {
    console.log(team);
    this.data.displayTeamName = team.teamName;

    this.assignTask.userNames = [];
    this.data.team = { id: team.id, teamCategory: team.categories };
    this.data.teamSettings = team.teamSettingsVM;
    this.tempTeam = team;
    this.teamSelected = true;
    //////this.assignTask.teamCategory = {};
    if (this.data.teamSettings.teamCategory.indexOf("LOCATION") >= 0) {
      this.assignTask.teamCategory.location = true;
    }
    if (this.data.teamSettings.teamCategory.indexOf("SKILL") >= 0) {
      this.assignTask.teamCategory.skills = true;
    }
    if (this.data.adhoc) {
      this.assignTask.teamCategory.available = true;
    }

    let findOwner = _.find(this.tempTeam.userList, (o) => {
      return o.owner;
    });
    // console.log(findOwner)
    this.tempTeam.userList.forEach((user: any, i: any) => {
      if (i == 0 && findOwner == undefined) {
        user.owner = true;
      }
      this.assignTask.userNames.push(user);
    });

    // const allTeams = await this.teamService.getEmployeeByTeam(team.id, true, true).toPromise() as any[];
    // const allFilterTeams = [];

    // // console.log(allTeams, this.tempTeam.userList);

    // for (let tu = 0; tu < this.tempTeam.userList.length; tu++) {
    //   for (let at = 0; at < allTeams.length; at++) {
    //     // console.log(this.tempTeam.userList[tu], '--', allTeams[at])
 
    //       if (this.tempTeam.userList[tu].id === allTeams[at].userId) {
    //         if (allTeams[at].poistion === 'MANAGER') {
    //           if (this.tempTeam.teamSettingsVM.includeManagerForTaskAssignment) {
    //             allFilterTeams.push(this.tempTeam.userList[tu]);
    //           }
    //         } else {
    //           allFilterTeams.push(this.tempTeam.userList[tu]);
    //         }
    //       }
    //   }
    // }

    // // console.log(allFilterTeams);

    // this.assignTask.userNames = allFilterTeams;
    
    if (this.assignTask.userNames.length < 6) {
      this.assignTask.numberOfUsers = "ALL";
    } else {
      this.assignTask.numberOfUsers ="1"
    }
    if (!this.first) {
      this.onSubmit("showTeamMemberOptions");
    }

    setTimeout(() => {
      this.modifyNumbersOfUsers();
    }, 1000);
  }

  getTeamApi() {
    this.push(   this.teamService
      .getTeamsByUserIdForSmartAssign(this.auth.getUserId())
      .subscribe((teams) => {
        this.teams = teams;
        if (this.data.team) {
          var matchTeam = _.filter(this.teams, (o) => {
            return o.id == this.data.team.id;
          });
          if (matchTeam.length) {
            this.resetSelection("team");
            this.tempTeam = matchTeam[0];
            this.assignTask.teamName = this.tempTeam;
            this.selectTeam(this.tempTeam);
          }
        }

        console.log("Teams Search ", this.teams);
      }))
  }
  count = 0;

  callTeamApi(val: any) {
    if (this.count == 0) {
      this.getTeamApi();
    } else {
      this.resetSelection("team");
    }
    this.count++;
    console.log(this.count, "=====", val);
  }

  resetSelection(val: any) {
    this.teamSelected = false;
    this.assignTask.userNames = [];
    this.assignMode = val;
    if (this.data.team && this.data.team.id) {
      let matchTeam = _.filter(this.teams, (o) => {
        return o.id == this.data.team.id;
      });
      if (matchTeam.length) {
        this.assignTask.teamName = matchTeam[0];
      }
    }
    if (this.assignMode == "user") {
      let matchTeam = _.filter(this.teams, (o) => {
        return o.id == this.data.team.id;
      });

      if (matchTeam) {
        this.tempTeam = matchTeam[0];
        this.assignTask.teamName = this.tempTeam;
        this.assignTask.teamName = null;
      }
    } else {
      if (this.tempTeam) {
        let matchTeam = _.filter(this.teams, (o) => {
          return o.id == this.data.team.id;
        });
        if (matchTeam.length) {
          this.tempTeam = matchTeam[0];
          this.assignTask.teamName = this.tempTeam;
          this.selectTeam(this.tempTeam);
        }
      }
    }
  }

  onSubmit(value: any) {
    // this.submitTouched = true;
    const date = moment(this.data.date).format("YYYY-MM-DD");
    this.teamCategories = "";
    if (this.assignTask.teamCategory.available) {
      this.teamCategories = "AVAILABILITY,";
    }
    if (this.assignTask.teamCategory.location) {
      this.teamCategories += "LOCATION,";
    }
    if (this.assignTask.teamCategory.skills) {
      this.teamCategories += "SKILL";
    }
    if (value === "assign" || value === "assignToTeam") {
      var usersArray: any[] = [];

      this.assignTask.userNames.forEach((element: any) => {
        if (!element.owner) {
          element.owner = false;
        }
        var user = {
          owner: element.owner,
          assignedTo: {
            id: element.id,
          },
        };
        usersArray.push(user);
      });

      var numberOfUsers = null;
      if (this.assignTask.numberOfUsers == "ALL") {
        numberOfUsers = this.assignTask.userNames.length;
      } else {
        numberOfUsers = this.assignTask.numberOfUsers;
      }

      var formData: any = {
        numberOfUsers: numberOfUsers,
        startTime: moment(this.data.startDateTime).format("HH:mm"),
        endTime: moment(this.data.endDateTime).format("HH:mm"),
        date: moment(this.data.startDateTime).format("YYYY-MM-DD"),
        lattitude: this.data.latitude,
        longitude: this.data.longitude,
        requiredSkill: this.data.requiredSkill,
        teamCategory: this.teamCategories,
        isAdhoc: this.data.adhoc,
        hasBuffer: this.data.hasBuffer,
        startBuffer: this.data.startBuffer,
        endBuffer: this.data.endBuffer,
        timezone: this.auth.getUserTimezone(),
        taskDuration: this.data.duration,
        userList: usersArray,
        userDynamicSkillList: this.data.userDynamicSkillList,
        task: {
          assignStatus: this.data.assignStatus,
          assignedTo: this.data.assignedTo,
          description: this.data.description,
          endDateTime: this.data.endDateTime,
          fixed: this.data.fixed,
          hasBuffer: this.data.hasBuffer,
          location: this.data.location,
          locationName: this.data.locationName,
          latitude: this.data.latitude,
          longitude: this.data.longitude,
          startBuffer: this.data.startBuffer,
          endBuffer: this.data.endBuffer,
          placeId: this.data.placeId,
          priority: this.data.priority,
          startDateTime: this.data.startDateTime,
          taskName: this.data.taskName,
          taskStatus: this.data.taskStatus,
          requiredSkill: this.data.requiredSkill,
          taskType: this.data.taskType,
          userDynamicSkillList: this.data.userDynamicSkillList,
          repeatFrequency: this.data.repeatFrequency,
          repeatTillDate: this.data.repeatTillDate
            ? moment(this.data.repeatTillDate).format("YYYY-MM-DD")
            : this.data.repeatTillDate,
          generateJobOrder: this.data.generateJobOrder,
          showServiceChecklist: this.data.showServiceChecklist,
          serviceChecklistRequired: this.data.serviceChecklistRequired,
          showCustomerServiceReport: this.data.showCustomerServiceReport,
          customerServiceReportRequired:
            this.data.customerServiceReportRequired,
          showHandoverCertificate: this.data.showHandoverCertificate,
          handoverCertificateRequired: this.data.handoverCertificateRequired,
          site: this.data?.site ?? null,
          project: this.data?.project ?? null,
        },
      };

      if (this.data.customer && this.data.customer !== null) {
        formData.task.customer = this.data.customer;
        formData.task.customerName = this.data.customerName;
      }
      if (this.data.jobOrder && this.data.jobOrder.id !== null) {
        formData.task.jobOrder = { id: this.data.jobOrder.id };
      }

      if (this.data.team == undefined) {
        formData.teamId = null;
      } else {
        formData.teamId = this.data.team.id;
      }

      if (this.data && this.data.site && this.data.site.id) {
        formData = Object.assign(formData, {
          site: { id: this.data.site.id },
        });
      } else {
        formData.site = null;
      }

      if (this.data && this.data.project && this.data.project.id) {
        formData = Object.assign(formData, {
          project: { id: this.data.project.id },
        });
      } else {
        this.data.project = null;
      }

      // console.log(formData, this.assignTask.userNames);
      // return;

      if (this.data.id) {
        formData.id = this.data.id;

        this.push(    this.taskService.editTask(formData).subscribe((taskRes: any) => {
          if (taskRes.status === 200 || taskRes.status === 201) {
            this.modalRef.hide();

            this.errorUtil.setErrorMessage(
              200,
              null,
              this.translate?.general.messages.updatedSuccessfully,
              "success",
              2000
            );

            this.submitTouched = false;
            this.util.openTaskVIewPage(taskRes.body[0].id)
          } else {
            // this.toaster.error("", taskRes.error.title);
          }
          // }
        }))
      } else {
        console.log(formData);
        if (this.data.jobOrder && this.data.jobOrder.id !== null) {
          formData.task.jobOrder = { id: this.data.jobOrder.id };
        }
        if (!moment(formData.task.repeatTillDate, "YYYY-MM-DD").isValid()) {
          formData.task.repeatTillDate = "";
        }
        this.taskService.assignTask(formData).subscribe((taskRes: any) => {
          console.log("Response ", taskRes);

          if (taskRes.status === 200 || taskRes.status === 201) {
            this.modalService.hide();

            this.errorUtil.setErrorMessage(
              200, null,
              this.translate?.general.messages.savedSuccessfully,
              "success",
              3000
            );

            this.submitTouched = false;
            this.util.openTaskVIewPage(taskRes.body.id)
          } else {
            this.errorUtil.setErrorMessage( taskRes.status, null, taskRes.error.title, "error", 3000);
            
          }
        });
      }
    } else if (value === "showTeamMemberOptions") {
      this.selectedUsers = [];
      if (!this.data.requiredSkill) {
        this.skillsString = "Not Specified";
      } else {
        this.skillsString = "";
        var i = 0;
        for (var key in this.data.skills) {
          if (this.data.skills.hasOwnProperty(key)) {
            if (this.data.skills[key]) {
              if (i > 0) {
                this.skillsString += ", ";
              }
              if (key == "general") {
                this.skillsString += "Novice";
              } else if (key == "skill1") {
                this.skillsString += "Intermediate";
              } else if (key == "skill2") {
                this.skillsString += "Advanced";
              } else if (key == "skill3") {
                this.skillsString += "Expert";
              }
            }
          }
          i++;
        }
      }
      if (this.assignMode == "team") {
        if (!this.data.team.teamCategory) {
          this.data.team.teamCategory = "";
        }
      }
      this.teamCategories = "";
      if (this.assignTask.teamCategory.available) {
        this.teamCategories = "AVAILABILITY,";
      }
      if (this.assignTask.teamCategory.location) {
        this.teamCategories += "LOCATION,";
      }
      if (this.assignTask.teamCategory.skills) {
        this.teamCategories += "SKILL";
      }
      var userIdArray: any[] = [];
      // if(this.assignMode === 'user'){
      this.assignTask.userNames.forEach((element: any) => {
        if (!element.owner) {
          element.owner = false;
        }
        var user = {
          owner: element.owner,
          assignedTo: {
            id: element.id,
          },
        };
        userIdArray.push(user);
      });

      console.log(this.assignTask.userNames, userIdArray)

      var req: any = {
        startTime: moment(this.data.startDateTime).format("HH:mm"),
        endTime: moment(this.data.endDateTime).format("HH:mm"),
        date: moment(this.data.startDateTime).format("YYYY-MM-DD"),
        lattitude: this.data.latitude,
        longitude: this.data.longitude,
        requiredSkill: this.data.requiredSkill,
        teamCategory: this.teamCategories,
        isAdhoc: this.data.adhoc,
        hasBuffer: this.data.hasBuffer,
        startBuffer: this.data.startBuffer,
        endBuffer: this.data.endBuffer,
        timezone: this.auth.getUserTimezone(),
        taskDuration: this.data.duration,
        userList: userIdArray,
        userDynamicSkillList: this.data.userDynamicSkillList,
      };

      if (this.data.team == undefined) {
        req.teamId = null;
      } else {
        req.teamId = this.data.team.id;
      }
      console.log("Req ", req);
      this.teamService.recommendedUsersByTeam(req).subscribe((resp: any) => {
        console.log('RECOMMENDED USERS BY TEAM ', resp)
        var users = resp.body
        this.teamMembers = [];
        this.checkUsers = users;
        users.matchedUsers.forEach((element: any) => {
          element.available = true;
          
          this.teamMembers.push(element);
        });

        // users.nonMatchedUsers.forEach((element: any) => {
        //   element.available = false;
        //   this.teamMembers.push(element);
        // });

        var taskCopyStartTime = Object.assign({}, this.data.startDateTime);
        var taskCopyEndTime = Object.assign({}, this.data.endDateTime);

        this.teamMembers.forEach((element) => {
          if (element.toNewTaskDistanceMatrix) {
            element.toNewTaskDistanceMatrix.totalTimeInSeconds =
              element.toNewTaskDistanceMatrix.startBuffer +
              element.toNewTaskDistanceMatrix.endBuffer +
              element.toNewTaskDistanceMatrix.travelTimeInSec;
            element.toNewTaskDistanceMatrix.formattedEta = moment
              .tz(
                moment(taskCopyStartTime).format("YYYY-MM-DD") +
                  " " +
                  element.toNewTaskDistanceMatrix.eta,
                "GMT"
              )
              .format();
          }
          if (element.fromNewTaskDistanceMatrix) {
            element.fromNewTaskDistanceMatrix.totalTimeInSeconds =
              element.fromNewTaskDistanceMatrix.startBuffer +
              element.fromNewTaskDistanceMatrix.endBuffer +
              element.fromNewTaskDistanceMatrix.travelTimeInSec;
            element.fromNewTaskDistanceMatrix.formattedEta = moment
              .tz(
                moment(taskCopyStartTime).format("YYYY-MM-DD") +
                  " " +
                  element.fromNewTaskDistanceMatrix.eta,
                "GMT"
              )
              .format();
          }

          if (!element.toNewTaskDistanceMatrix) {
            element.toNewTaskDistanceMatrix = {};
            element.toNewTaskDistanceMatrix.formattedEta =
              moment(taskCopyStartTime).format();
          }

          if (
            Object.keys(element.toNewTaskDistanceMatrix).length > 1 &&
            element.previousTask
          ) {
            if (
              this.utilService.pastFinder2(
                moment(element.previousTask.endDateTime).add(
                  element.toNewTaskDistanceMatrix.totalTimeInSeconds,
                  "s"
                ),
                moment.tz(taskCopyStartTime, "GMT")
              )
            ) {
              element.reachNewTaskOnTime = true;
              element.reachNewTaskPastTime = false;
            } else {
              element.reachNewTaskOnTime = false;
              element.reachNewTaskPastTime = true;
            }
          } else if (
            Object.keys(element.toNewTaskDistanceMatrix).length > 1 &&
            !element.previousTask
          ) {
            if ( this.utilService.pastFinder2( moment.tz(element.toNewTaskDistanceMatrix.formattedEta, "GMT"),   moment.tz(taskCopyStartTime, "GMT") )
            ) {
              element.reachNewTaskOnTime = true;
              element.reachNewTaskPastTime = false;
            } else {
              element.reachNewTaskOnTime = false;
              element.reachNewTaskPastTime = true;
            }
          } else {
            element.reachNewTaskOnTime = true;
            element.reachNewTaskPastTime = false;
          }

          if (element.fromNewTaskDistanceMatrix && element.nextTask) {
            if (
              this.utilService.pastFinder2(
                moment(taskCopyEndTime)
                  .tz("GMT")
                  .add(
                    element.fromNewTaskDistanceMatrix.totalTimeInSeconds,
                    "seconds"
                  ),
                moment(element.nextTask.startDateTime)
              )
            ) {
              element.reachNextTaskOnTime = true;
              element.reachNextTaskPastTime = false;
            } else {
              element.reachNextTaskOnTime = false;
              element.reachNextTaskPastTime = true;
            }
          } else {
            element.reachNextTaskOnTime = true;
            element.reachNextTaskPastTime = false;
          }
        });
        this.first = false;

        console.log(formData, this.assignTask.userNames, this.teamMembers);
        
        $('.modal-dialog').addClass( "modal-dialog-lg" );
        $('.modal-dialog-lg').removeClass( "modal-dialog" );
        this.getTeamTasks(formData)
        // if (!this.datatableHelper) {
        //   this.datatableHelper.initDatatable(".assign-task-datatable");
        // }
        // if (this.datatableHelper) {
        //   this.datatableHelper.reInitDatatable();
        // }
      });
      // this.modalService1.openModal('checkAvailability', formData)
    }
  }

  showConfirmation(val: any, mode: any) {
    console.log(mode,"-----",this.selectedUsers);

    if (mode == "assignToTeam") {
      if (!this.selectedUsers.length) {
        this.errorUtil.setErrorMessage(
          200,
          null,
          this.translate?.task.messages.selectUserTask,
          "warn",
          3000
        );
        return;
      }
    console.log(this.selectedUsers)
      let findOwner = _.find(this.selectedUsers, (o) => {
        return o.owner;
      });
      console.log(findOwner)
      if (!findOwner) {
        this.errorUtil.setErrorMessage(
          200,
          null,
          this.translate?.task.messages.selectOwnerTask,
          "warn",
          3000
        );
        return;
      }

      if (!this.selectedTime && this.data.adhoc) {
        this.errorUtil.setErrorMessage(
          200,
          null,
          this.translate?.task.messages.selectTimeTask,
          "warn",
          3000
        );
        return;
      }
    }

    if (mode == "assign") {
      let findOwner = _.find(this.assignTask.userNames, (o) => {
        return o.owner;
      });

      if (!findOwner) {
        this.errorUtil.setErrorMessage(
          200,
          null,
          this.translate?.task.messages.selectOwnerTask,
          "warn",
          3000
        );

      return;
    }
  }

   
    const data = {
      mode: "assignTask",
      assignMode: mode,
      data: val,
      message:  this.translate?.task.messages.confirmAssignTask,
    };
    console.log(data);

    this.confirmActionService.passItem(data);
    this.submitTouched = false
  }

  assignTaskToTeamMember(user: any, type: any) {
    console.log(user)
    this.submitTouched = true;
    const date = moment(this.data.date).format("YYYY-MM-DD");
   //// this.data.assignedTo = { id: user.id };
    if (this.data.customer) {
      if (!this.data.customer.id) {
        this.data.customer = { id: this.data.customer };
      }
    }
    if (!this.data.startDateTime) {
      var finalStartTime = this.utilService.timeFormatter(
        this.data.startTime,
        date
      );
      var finalEndTime = this.utilService.timeFormatter(
        this.data.endTime,
        date
      );
      this.data.startDateTime = finalStartTime;
      this.data.endDateTime = finalEndTime;
    } else if (this.data.startDateTime && type == "adhoc") {
      this.data.startDateTime = user.recommendedStartTime;
      this.data.endDateTime = user.recommendedEndTime;
    }
    var userIdArray: any[] = [];
    this.selectedUsers.forEach((element) => {
      if (!element.owner) {
        element.owner = false;
      }
      var user = {
        owner: element.owner,
        assignedTo: {
          id: element.id,
        },
      };
      userIdArray.push(user);
    });
    var formData: any = {
      assignStatus: this.data.assignStatus,
      description: this.data.description,
      endDateTime: this.data.endDateTime,
      fixed: this.data.fixed,
      hasBuffer: this.data.hasBuffer,
      location: this.data.location,
      locationName: this.data.locationName,
      latitude: this.data.latitude,
      longitude: this.data.longitude,
      priority: this.data.priority,
      placeId: this.data.placeId,
      startDateTime: this.data.startDateTime,
      taskName: this.data.taskName,
      taskStatus: this.data.taskStatus,
      requiredSkill: this.data.requiredSkill,
      startBuffer: this.data.startBuffer,
      endBuffer: this.data.endBuffer,
      taskType: this.data.taskType,
      assignedUsersList: userIdArray,
      userDynamicSkillList: this.data.userDynamicSkillList,
      repeatFrequency: this.data.repeatFrequency,
      repeatTillDate: this.data.repeatTillDate
        ? moment(this.data.repeatTillDate).format("YYYY-MM-DD")
        : this.data.repeatTillDate,
      generateJobOrder: this.data.generateJobOrder,
      showServiceChecklist: this.data.showServiceChecklist,
      serviceChecklistRequired: this.data.serviceChecklistRequired,
      showCustomerServiceReport: this.data.showCustomerServiceReport,
      customerServiceReportRequired: this.data.customerServiceReportRequired,
      showHandoverCertificate: this.data.showHandoverCertificate,
      handoverCertificateRequired: this.data.handoverCertificateRequired,
    };

    if (this.data.adhoc) {
      formData.startDateTime = this.selectedUsers[0].recommendedStartTime;
      formData.endDateTime = this.selectedUsers[0].recommendedEndTime;
    }

    if (this.data.team) {
      formData.team = {
        id: this.data.team.id,
      };
    }
    if (this.data.customer && this.data.customer !== null) {
      formData.customer = this.data.customer;
      formData.customerName = this.data.customerName;
    }
    if (this.data.jobOrder && this.data.jobOrder.id !== null) {
      formData.jobOrder = { id: this.data.jobOrder.id };
    }
    if (!moment(formData.repeatTillDate, "YYYY-MM-DD").isValid()) {
      formData.repeatTillDate = "";
    }
    if (this.data.id) {
      formData.id = this.data.id;
      this.taskService.editTask(formData).subscribe((taskRes: any) => {
      console.log("Response ", taskRes);
        if (taskRes.status === 200 || taskRes.status === 201) {
           this.modalRef.hide();
           this.errorUtil.setErrorMessage(200, this.translate?.general.messages.updatedSuccessfully,  null, 'success',1000);
          this.submitTouched = false;
         
          this.util.openTaskVIewPage(taskRes.body.id)
        } else {
          this.errorUtil.setErrorMessage( taskRes.status, null, taskRes.error.title, "error", 3000);
          // this.toaster.error("", taskRes.error.title);
        }
      });
    } else {
      this.taskService.createTask(formData).subscribe((taskRes: any) => {
        console.log("Response ", taskRes);
        if (taskRes.status === 200 || taskRes.status === 201) {
           this.modalRef.hide();
           this.errorUtil.setErrorMessage(200, this.translate?.general.messages.savedSuccessfully,  null, 'success',1000);
           
          this.submitTouched = false;
          this.util.openTaskVIewPage(taskRes.body.id)
        } else {
          this.errorUtil.setErrorMessage( taskRes.status, null, taskRes.error.title, "error", 3000);
          // this.toaster.error("", taskRes.error.title);
        }
      });
    }
  }

  goBackToCreateTask() {

    $('.modal-dialog-lg').addClass( "modal-dialog" );
    $('.modal-dialog').removeClass( "modal-dialog-lg" );
    if (this.first) {
      this.modalRef.hide();
      console.log(this.data);
      if (this.tempTeam) {
        this.data.team.id = this.tempTeam.id;
      }
      if (this.parentMode == "add" || this.parentMode == "add&back") {
        this.modalState = {
          title: "New Task",
          mode: "add&back",
          data: this.data,
        };
      } else {
        this.modalState = {
          title: "Edit Task",
          mode: "edit&back",
          data: this.data,
        };
      }
      // this.modalRef2.hide();
      this.ownerSelected = null;
      this.configMd.initialState = this.modalState;
       this.modalRef = this.modalService.show(AddEditTaskComponent, this.configMd);
    } else {     
      this.ownerSelected = null;
      this.first = true;
      this.submitTouched = false;
    }
  }

  openAccordion(member: any, event: any) {
    if (!member.available) {
      event.stopPropagation();
    }
    if (member.isOpen) {
      member.isOpen = false;
    } else {
      member.isOpen = true;
    }
  }

  selectMember(member: any) {

    var count = this.selectedUsers.length +1
    console.log(this.assignTask.numberOfUsers,"======",this.selectedUsers.length,"====",count)
    // if(this.assignTask.numberOfUsers == 'ALL'){
    //   this.addMemeberFromChecklist(member)
    // }else if(this.assignTask.numberOfUsers != this.selectedUsers.length){
    //   this.addMemeberFromChecklist(member)

    //  }
    if(count < 6){
      this.addMemeberFromChecklist(member)
    }
  }

  addMemeberFromChecklist(member: any){
    member.selected = member.selected == true ? false : true;
    var findUser = _.find(this.selectedUsers, (o) => {
      return o.id == member.id;
    });
    if (!findUser) {
      this.selectedUsers.push(member);
    }
  }

  selectOwner(member: any) {
    this.selectedUsers.forEach((element) => {
      element.owner = false;
    });
    var findUser = _.find(this.selectedUsers, (o) => {
      return o.id == member.id;
    });
    if (!findUser) {
      member.owner = true;
      member.selected = true;
      this.selectedUsers.push(member);
    } else {
      findUser.owner = true;
    }
  }

  removeSelectedMember(user: any) {
    var findCurrentOwner = _.find(this.teamMembers, (o) => {
      return o.id == user.id;
    });
    if (findCurrentOwner) {
      findCurrentOwner.selected = false;
      if (findCurrentOwner.owner) {
        findCurrentOwner.owner = false;
        this.ownerSelected = false;
      }
    }
    _.remove(this.selectedUsers, (o: any) => {
      return o.id == user.id;
    });
  }

  selectTime(time: any) {
    time.selected = time.selected == true ? false : true;
    if (time.selected) {
      this.selectedTime = time.recommendedStartTime;
      this.teamMembers.forEach((element) => {
        var findAllMatchTimes = _.find(element.recommendedTImeSlots, (o) => {
          return o.recommendedStartTime == this.selectedTime;
        });
        if (findAllMatchTimes) {
          findAllMatchTimes.selected = true;
          element.nextTask = findAllMatchTimes.nextTask;
          element.previousTask = findAllMatchTimes.previousTask;
        } else {
          element.available = false;
          element.nextTask = time.nextTask;
          element.previousTask = time.previousTask;
        }

        element.toNewTaskDistanceMatrix = time.toNewTaskDistanceMatrix;
        element.fromNewTaskDistanceMatrix = time.fromNewTaskDistanceMatrix;
        element.recommendedStartTime = time.recommendedStartTime;
        element.recommendedEndTime = time.recommendedEndTime;
      });
    } else {
      this.selectedTime = null;
      this.teamMembers.forEach((element) => {
        var findAllMatchTimes = _.find(element.recommendedTImeSlots, (o) => {
          return o.selected;
        });
        if (findAllMatchTimes) findAllMatchTimes.selected = false;

        if (element.userAvailableForTask) {
          element.toNewTaskDistanceMatrix =
            element.recommendedTImeSlots[0].toNewTaskDistanceMatrix;
          element.fromNewTaskDistanceMatrix =
            element.recommendedTImeSlots[0].fromNewTaskDistanceMatrix;
          element.nextTask = element.recommendedTImeSlots[0].nextTask;
          element.previousTask = element.recommendedTImeSlots[0].previousTask;
          element.recommendedStartTime =
            element.recommendedTImeSlots[0].recommendedStartTime;
          element.recommendedEndTime =
            element.recommendedTImeSlots[0].recommendedEndTime;
          element.available = true;
        }
      });
    }
  }

  addUser(user: any) {
    var checkExistingUser = _.find(this.assignTask.userNames, (o) => {
      return o.id == user.id;
    });
    if (!checkExistingUser) {
      if (!this.assignTask.userNames.length) {
        user.owner = true;
      }
      this.assignTask.userNames.push(user);
    }
    if (this.assignTask.userNames.length < 6) {
      this.assignTask.numberOfUsers = "ALL";
    } else {
      this.assignTask.numberOfUsers = "1";
    }
    this.searchUserVal = null;
    this.showDropdown = false;

    setTimeout(() => {
      this.modifyNumbersOfUsers();
    }, 1000);
  }

  public modifyNumbersOfUsers() {
    this.allNumberOfUsers = [];

    if (this.assignTask.userNames.length !== 0) {
      if (
        this.assignTask.userNames.length < 6 &&
        !this.allNumberOfUsers.find((val) => val.value === "ALL")
      ) {
        let option = {
          value: "",
        };

        option.value = "ALL";

        this.allNumberOfUsers.push(option);
      }

      console.log(this.assignTask.userNames);

      this.assignTask.userNames.forEach((val: any, index: number) => {
        console.log(val, index);

        let option = {
          value: "",
        };

        option.value = String(index + 1);
        console.log(this.allNumberOfUsers)
        if (this.allNumberOfUsers.length < 5) {
          this.allNumberOfUsers.push(option);
        }
      });
    }
  }
  makeOwnerclick(user: any) {
    this.assignTask.userNames.forEach((element : any) => {
      element.owner = false;
      console.log(element)
    });
    var findCurrentOwner = _.find(this.assignTask.userNames, (o) => {
      return o.owner;
    });
    if (findCurrentOwner) {
      findCurrentOwner.owner = false;
    }
    user.owner = true;
  }

  makeOwner(user: any) {
    console.log(user);
    this.selectedUsers.forEach((element : any) => {
      element.owner = false;
      console.log(element)
    });
    var findCurrentOwner = _.find(this.assignTask.userNames, (o) => {
      return o.owner;
    });
    if (findCurrentOwner) {
      findCurrentOwner.owner = false;
    }
    user.owner = true;

    // reassign the actual owner
    this.assignTask.userNames.forEach((theUser: any) => {
      if (user.userId === theUser.id) {
        theUser.owner = true;
      }
    })
  }

  removeUser(user: any) {
    _.remove(this.assignTask.userNames, (o: any) => {
      return o.id == user.id;
    });
    if (this.assignTask.userNames.length < 6) {
      this.assignTask.numberOfUsers = "ALL";
    } else {
      this.assignTask.numberOfUsers = "1";
    }
  }

  viewMoreTimeSlots(data: any) {
    this.modalState = {
      title: "",
      data: data,
    };
    this.configCustomMd.initialState = this.modalState;
    // this.modalRef = this.modalService.show(
    //   TimeSlotsComponent,
    //   this.configCustomMd
    // );
  }


  getTeamTasks(formData: any){
    console.log(this.data)
    this.data2 = formData
    var resources: any[] = [];
    var events: any[] = [];
    this.teamMembers.forEach((element : any,index: any)=> {
      console.log(element)
      
          if(element.colorCode){
            var color: any = element.colorCode;
          } else {
            var color: any = this.utilService.getRandomColor();
          }
          if(element.imageUrl && element.imageUrl !== ''){
            element.photoUrl = element.imageUrl;
          } else {
            element.photoUrl = "../../../../../assets/svg/Avatar.svg"
          }

          var resource : any = { 
            id: element.id, 
            userId: element.id, 
            title: element.fullName,
            photoUrl : element.imageUrl,
            imageUrl : element.imageUrl,
            color : color,
            online: element.online,
            fullName : element.fullName,
            utilization:element.utilization
          };

          resources.push(resource);
          
          /************************************** Event Need to Add in Brynthum *****************************/
          
              // PREVIOUS TASK
              if(element.previousTask){
                var temp : any = { }
                temp.title = element.previousTask.taskName,
                temp.start = moment.tz(element.previousTask.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
                temp.end = moment.tz(element.previousTask.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
                temp.color = "#ffffff",
                temp.borderColor  = "#000000",
                temp.textColor = "#ffff",
                temp.resourceId = element.id
                temp.taskId = element.previousTask.id    
                temp.id = element.id
                //temp.eventStyle = "border"
                temp.eventColor = "#000000"
                temp.startDate = moment.tz(element.previousTask.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
                temp.endDate = moment.tz(element.previousTask.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm')
                temp.startTime = moment.tz(element.previousTask.startDateTime, this.auth.getUserTimezone()).format('hh:mm A')
                temp.endTime = moment.tz(element.previousTask.endDateTime, this.auth.getUserTimezone()).format('hh:mm A')
                temp.name = element.previousTask.taskName
                temp.location = element.previousTask.location
                events.push(temp);

                let bufferBox: any = {}

                bufferBox.name = `${element.previousTask.taskName} End Buffer`;
                bufferBox.title = `${element.previousTask.taskName} End Buffer`;
                bufferBox.id = Math.random() + 1;
                bufferBox.eventId =  element.id;
                bufferBox.resourceId = element.id;
                bufferBox.startDate = moment.tz(element.previousTask.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm');
                bufferBox.endDate = moment.tz(element.previousTask.endDateTime, this.auth.getUserTimezone()).add(element.previousTask.endBuffer, 'minutes').format('YYYY-MM-DD HH:mm');

                bufferBox.endBuffer = this.data.endBuffer;

                bufferBox.color = "#ffffff";
                bufferBox.borderColor  = "#FF0000";
                bufferBox.textColor = "#ffffff";
                bufferBox.eventColor = "#FF0000";
                bufferBox.eventStyle = 'taskBufferEndNext';
                bufferBox.isBuffer = true;

                let isOverlap = false;

                if (element.toNewTaskDistanceMatrix) {
                  let prevStartDate = moment.tz(element.previousTask.startDateTime, this.auth.getUserTimezone()).toDate();
                  let prevEndDate = moment.tz(element.previousTask.endDateTime, this.auth.getUserTimezone()).add(element.previousTask.endBuffer, "seconds").toDate();

                  let currentElemStartDate = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).subtract(this.data.startBuffer, 'minutes').subtract(element.toNewTaskDistanceMatrix.travelTimeInSec, "seconds").toDate();
                  let currentElemEndDate = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).toDate();

                  isOverlap = this.dateRangeOverlaps(prevStartDate, prevEndDate, currentElemStartDate, currentElemEndDate);
                }

                if (isOverlap === false) {
                  events.push(bufferBox);
                }

                // var temp : any = { }
                // temp.title = 'line',
                // temp.start = moment.tz(element.previousTask.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
                // temp.end = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
                // temp.color = "#ffffff",
                // temp.borderColor  = "#000000",
                // temp.textColor = "#ffff",
                // temp.resourceId = element.id
                // temp.taskId = "r"+index   
                // temp.id = "r"+element.id
                // temp.eventColor = "#000000"
                // temp.eventStyle = "line"
                // temp.resizable= false
                // temp.draggable=false
                // temp.orderEvent = false
                // temp.startDate = moment.tz(element.previousTask.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
                // temp.endDate = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm')
                // temp.startTime = moment.tz(element.previousTask.endDateTime, this.auth.getUserTimezone()).format('hh:mm A')
                // temp.endTime =moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm')
                // temp.name = 'line'
                // temp.location = null
               
                // events.push(temp);
              }

              // START BUFFER
              if (element.toNewTaskDistanceMatrix && element.toNewTaskDistanceMatrix.hasOwnProperty('startBuffer') && element?.previousTask) {
                let bufferBox: any = {}
                let timeLine: any = {}

                // -- travel line -- //
                timeLine.name = `${this.data.taskName} Travel Time`;
                timeLine.title = `${this.data.taskName} Travel Time`;
                timeLine.id = Math.random() + 2;
                timeLine.eventId =  element.id;
                timeLine.resourceId = element.id;

                const travelTime = element.toNewTaskDistanceMatrix.travelTimeInSec + element.toNewTaskDistanceMatrix.startBuffer;
                
                timeLine.startDate = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).subtract(travelTime, 'seconds').format('YYYY-MM-DD HH:mm');
                timeLine.endDate = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).subtract(element.toNewTaskDistanceMatrix.startBuffer, 'seconds').format('YYYY-MM-DD HH:mm');

                timeLine.totalTravelTime = element.toNewTaskDistanceMatrix.travelTimeInSec;

                timeLine.color = "#ffffff";
                timeLine.borderColor  = "#C8E6C9";
                timeLine.textColor = "#ffffff";
                timeLine.eventColor = "#C8E6C9";
                timeLine.eventStyle = "line";
                timeLine.isTimeLine = true;
                timeLine.resizable = false;

                let isOverlapTimeLine = false;

                let prevStartDate = moment.tz(element.previousTask.startDateTime, this.auth.getUserTimezone()).toDate();
                let prevEndDate = moment.tz(element.previousTask.endDateTime, this.auth.getUserTimezone()).toDate();

                let timelineStart = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).subtract(travelTime, 'seconds').toDate();
                let timelineEnd = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).subtract(element.toNewTaskDistanceMatrix.startBuffer, 'seconds').toDate();

                isOverlapTimeLine = this.dateRangeOverlaps(prevStartDate, prevEndDate, timelineStart, timelineEnd);
                
                if (isOverlapTimeLine === false) {
                  events.push(timeLine);
                }
                
                bufferBox.name = `${this.data.taskName} Start Buffer`;
                bufferBox.title = `${this.data.taskName} Start Buffer`;
                bufferBox.id = Math.random() + 33;
                bufferBox.eventId =  element.id;
                bufferBox.resourceId = element.id;
                bufferBox.startDate = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).subtract(this.data.startBuffer, 'minutes').format('YYYY-MM-DD HH:mm');
                bufferBox.endDate = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm');

                bufferBox.startBuffer = this.data.startBuffer;

                bufferBox.color = "#ffffff";
                bufferBox.borderColor  = "#FF0000";
                bufferBox.textColor = "#ffffff";
                bufferBox.eventColor = "#FF0000";
                bufferBox.eventStyle = 'taskBufferStart';
                bufferBox.isBuffer = true;

                let isOverlap = false;

                if (element?.previousTask) {
                  let prevStartDate = moment.tz(element.previousTask.startDateTime, this.auth.getUserTimezone()).toDate();
                  let prevEndDate = moment.tz(element.previousTask.endDateTime, this.auth.getUserTimezone()).toDate();

                  let currentElemStartDate = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).subtract(this.data.startBuffer, 'minutes').toDate();
                  let currentElemEndDate = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).toDate();

                  isOverlap = this.dateRangeOverlaps(prevStartDate, prevEndDate, currentElemStartDate, currentElemEndDate);
                }
                
                if (isOverlap === false) {
                  events.push(bufferBox);
                }
              } else {
                if (element?.previousTask) {
                  let bufferBox: any = {}

                  bufferBox.name = `${this.data.taskName} Start Buffer`;
                  bufferBox.title = `${this.data.taskName} Start Buffer`;
                  bufferBox.id = Math.random() + 4;
                  bufferBox.eventId =  element.id;
                  bufferBox.resourceId = element.id;
                  bufferBox.startDate = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).subtract(this.data.startBuffer, 'minutes').format('YYYY-MM-DD HH:mm');
                  bufferBox.endDate = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm');
  
                  bufferBox.startBuffer = this.data.startBuffer;
  
                  bufferBox.color = "#ffffff";
                  bufferBox.borderColor  = "#FF0000";
                  bufferBox.textColor = "#ffffff";
                  bufferBox.eventColor = "#FF0000";
                  bufferBox.eventStyle = 'taskBufferStart';
                  bufferBox.isBuffer = true;
  
                  let isOverlap = false;
  
                  if (element?.previousTask) {
                    let prevStartDate = moment.tz(element.previousTask.startDateTime, this.auth.getUserTimezone()).toDate();
                    let prevEndDate = moment.tz(element.previousTask.endDateTime, this.auth.getUserTimezone()).toDate();
  
                    let currentElemStartDate = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).subtract(this.data.startBuffer, 'minutes').toDate();
                    let currentElemEndDate = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).toDate();
  
                    isOverlap = this.dateRangeOverlaps(prevStartDate, prevEndDate, currentElemStartDate, currentElemEndDate);
                  }
                  
                  if (isOverlap === false) {
                    events.push(bufferBox);
                  }
                }
              }

          // current task
          var temp : any = { }
          temp.title = this.data.taskName,
          temp.start = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
          temp.end = moment.tz(this.data.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
          temp.color = "#ffffff",
          temp.borderColor  = "#6366F1",
          temp.textColor = "#ffffff",
          temp.resourceId = element.id
          temp.taskId = element.id    
          temp.id = Math.random() + 5
          //temp.eventStyle = "border"
          temp.eventColor = "#6366F1"
          temp.startDate = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
          temp.endDate = moment.tz(this.data.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm')
          temp.startTime = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).format('hh:mm A')
          temp.endTime = moment.tz(this.data.endDateTime, this.auth.getUserTimezone()).format('hh:mm A')
          temp.name = this.data.taskName
          temp.location = this.data.location
          events.push(temp);

          if (element.fromNewTaskDistanceMatrix && element.fromNewTaskDistanceMatrix.hasOwnProperty('endBuffer') && element?.nextTask) {
            let bufferBox: any = {};
            let timeLine: any = {};

            bufferBox.name = `${this.data.taskName} End Buffer`;
            bufferBox.title = `${this.data.taskName} End Buffer`;
            bufferBox.id = Math.random() + 6;
            bufferBox.eventId =  element.id;
            bufferBox.resourceId = element.id;
            bufferBox.startDate = moment.tz(this.data.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm');
            bufferBox.endDate = moment.tz(this.data.endDateTime, this.auth.getUserTimezone()).add(this.data.endBuffer, 'minutes').format('YYYY-MM-DD HH:mm');

            bufferBox.endBuffer = this.data.endBuffer;

            bufferBox.color = "#ffffff";
            bufferBox.borderColor  = "#FF0000";
            bufferBox.textColor = "#ffffff";
            bufferBox.eventColor = "#FF0000";
            bufferBox.eventStyle = 'taskBufferEnd';
            bufferBox.isBuffer = true;

            let isOverlap = false;

            if (element?.nextTask) {
              let prevStartDate = moment.tz(element.nextTask.startDateTime, this.auth.getUserTimezone()).toDate();
              let prevEndDate = moment.tz(element.nextTask.endDateTime, this.auth.getUserTimezone()).toDate();

              let currentElemStartDate = moment.tz(this.data.endDateTime, this.auth.getUserTimezone()).toDate();
              let currentElemEndDate = moment.tz(this.data.endDateTime, this.auth.getUserTimezone()).add(this.data.endBuffer, 'minutes').toDate();

              isOverlap = this.dateRangeOverlaps(prevStartDate, prevEndDate, currentElemStartDate, currentElemEndDate);
            }
            
            if (isOverlap === false) {
              events.push(bufferBox);
            }

            timeLine.name = `${this.data.taskName} Travel Time`;
            timeLine.title = `${this.data.taskName} Travel Time`;
            timeLine.id = Math.random() + 7;
            timeLine.eventId =  element.id;
            timeLine.resourceId = element.id;

            const travelTime = element.fromNewTaskDistanceMatrix.travelTimeInSec + element.fromNewTaskDistanceMatrix.endBuffer;

            const endTime = moment.tz(this.data.endDateTime, this.auth.getUserTimezone()).add(element.fromNewTaskDistanceMatrix.endBuffer, 'seconds');
                
            timeLine.startDate = moment.tz(this.data.endDateTime, this.auth.getUserTimezone()).add(element.fromNewTaskDistanceMatrix.endBuffer, 'seconds').format('YYYY-MM-DD HH:mm');
            timeLine.endDate = moment.tz(endTime, this.auth.getUserTimezone()).add(travelTime, 'seconds').format('YYYY-MM-DD HH:mm');

            timeLine.totalTravelTime = element.fromNewTaskDistanceMatrix.travelTimeInSec;

            timeLine.color = "#ffffff";
            timeLine.borderColor  = "#C8E6C9";
            timeLine.textColor = "#ffffff";
            timeLine.eventColor = "#C8E6C9";
            timeLine.eventStyle = "line";
            timeLine.isTimeLine = true;
            timeLine.resizable = false;

            let isOverlapTimeLine = false;

            let nextStartDate = moment.tz(element.nextTask.startDateTime, this.auth.getUserTimezone()).toDate();
            let nextEndDate = moment.tz(element.nextTask.endDateTime, this.auth.getUserTimezone()).toDate();

            let timelineStart = moment.tz(this.data.endDateTime, this.auth.getUserTimezone()).add(element.fromNewTaskDistanceMatrix.endBuffer, 'seconds').toDate();
            let timelineEnd = moment.tz(endTime, this.auth.getUserTimezone()).add(travelTime, 'seconds').toDate();

            isOverlapTimeLine = this.dateRangeOverlaps(nextStartDate, nextEndDate, timelineStart, timelineEnd);

            console.log('Current Task Over Lapping Next Task ', isOverlapTimeLine)
            
            if (isOverlapTimeLine === false) {
              events.push(timeLine);
            }

          } else {
            if (element?.nextTask) {
              let bufferBox: any = {};

              bufferBox.name = `${this.data.taskName} End Buffer`;
              bufferBox.title = `${this.data.taskName} End Buffer`;
              bufferBox.id = Math.random() + 8;
              bufferBox.eventId =  element.id;
              bufferBox.resourceId = element.id;
              bufferBox.startDate = moment.tz(this.data.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm');
              bufferBox.endDate = moment.tz(this.data.endDateTime, this.auth.getUserTimezone()).add(this.data.endBuffer, 'minutes').format('YYYY-MM-DD HH:mm');
  
              bufferBox.endBuffer = this.data.endBuffer;
  
              bufferBox.color = "#ffffff";
              bufferBox.borderColor  = "#FF0000";
              bufferBox.textColor = "#ffffff";
              bufferBox.eventColor = "#FF0000";
              bufferBox.eventStyle = 'taskBufferEnd';
              bufferBox.isBuffer = true;
  
              let isOverlap = false;
  
              if (element?.nextTask) {
                let prevStartDate = moment.tz(element.nextTask.startDateTime, this.auth.getUserTimezone()).toDate();
                let prevEndDate = moment.tz(element.nextTask.endDateTime, this.auth.getUserTimezone()).toDate();
  
                let currentElemStartDate = moment.tz(this.data.endDateTime, this.auth.getUserTimezone()).toDate();
                let currentElemEndDate = moment.tz(this.data.endDateTime, this.auth.getUserTimezone()).add(this.data.endBuffer, 'minutes').toDate();
  
                isOverlap = this.dateRangeOverlaps(prevStartDate, prevEndDate, currentElemStartDate, currentElemEndDate);
              }
              
              if (isOverlap === false) {
                events.push(bufferBox);
              }
            }
          }

          // next task
              if(element.nextTask){
                // var temp2 : any = { }
                // temp2.title = 'line',
                // temp2.start = moment.tz(this.data.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
                // temp2.end = moment.tz(element.nextTask.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
                // temp2.color = "#ffffff",
                // temp2.borderColor  = "#000000",
                // temp2.textColor = "#ffff",
                // temp2.resourceId = element.id
                // temp2.taskId = "r"+element.nextTask.id    
                // temp2.id = Math.random()
                // temp2.eventColor = "#000000"
                // temp2.eventStyle = "line"
                // temp2.resizable= false
                // temp2.draggable=false
                // temp2.orderEvent = false
                // temp2.startDate = moment.tz(this.data.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
                // temp2.endDate = moment.tz(element.nextTask.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm')
                // temp2.startTime = moment.tz(this.data.endDateTime, this.auth.getUserTimezone()).format('hh:mm A')
                // temp.endTime =moment.tz(element.nextTask.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm')
                // temp2.name = 'line'
                // temp2.location = null
                // events.push(temp2);

                var temp : any = { }
                temp.title = element.nextTask.taskName,
                temp.start = moment.tz(element.nextTask.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
                temp.end = moment.tz(element.nextTask.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
                temp.color = "#ffffff",
                temp.borderColor  = "#000000",
                temp.textColor = "#000000",
                temp.resourceId = element.id
                temp.taskId = element.nextTask.id    
                temp.id = Math.random() + 9
               // temp.eventStyle = "border"
                temp.eventColor = "#000000"
                temp.startDate = moment.tz(element.nextTask.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
                temp.endDate = moment.tz(element.nextTask.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm')
                temp.startTime = moment.tz(element.nextTask.startDateTime, this.auth.getUserTimezone()).format('hh:mm A')
                temp.endTime = moment.tz(element.nextTask.endDateTime, this.auth.getUserTimezone()).format('hh:mm A')
                temp.name = element.nextTask.taskName
                temp.location = element.nextTask.location
                events.push(temp);

                let bufferBox: any = {};

                bufferBox.name = `${element.nextTask.taskName} Start Buffer`;
                bufferBox.title = `${element.nextTask.taskName} Start Buffer`;
                bufferBox.id = Math.random() + 10;
                bufferBox.eventId =  element.id;
                bufferBox.resourceId = element.id;
                bufferBox.startDate = moment.tz(element.nextTask.startDateTime, this.auth.getUserTimezone()).subtract(element.nextTask.startBuffer, "minutes").format('YYYY-MM-DD HH:mm');
                bufferBox.endDate = moment.tz(element.nextTask.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm');

                bufferBox.startBuffer = this.data.startBuffer;

                bufferBox.color = "#ffffff";
                bufferBox.borderColor  = "#FF0000";
                bufferBox.textColor = "#ffffff";
                bufferBox.eventColor = "#FF0000";
                bufferBox.eventStyle = 'taskBufferStartNext';
                bufferBox.isBuffer = true;

                let isOverlap = false;

                let nextStartDate = moment.tz(element.nextTask.startDateTime, this.auth.getUserTimezone()).toDate();
                let nextEndDate = moment.tz(element.nextTask.startDateTime, this.auth.getUserTimezone()).add(element.nextTask.startBuffer, "seconds").toDate();

                let currentElemStartDate = moment.tz(this.data.startDateTime, this.auth.getUserTimezone()).toDate();
                let currentElemEndDate = moment.tz(this.data.endDateTime, this.auth.getUserTimezone()).add(this.data.endBuffer, 'minutes').toDate();

                isOverlap = this.dateRangeOverlaps(nextStartDate, nextEndDate, currentElemStartDate, currentElemEndDate);

                if (isOverlap === false) {
                  events.push(bufferBox);
                }
              }
        });
        console.log(events)
        this.resources = resources;
        this.events = events;


        setTimeout(() => {
          this.setMakeOwner();
        }, 1000);

        console.log(resources,'teams eventsssssssss' , this.events)
        // this.tasks = team.tasks;
      
      }
    /***************************************** Bryntum Event **********************************************/
    getBryEvent(){
      this.features = {
        timeRanges : {
          showCurrentTimeLine : true,
        },
        cellEdit : {  disabled : true},
        eventDrag : {
            constrainDragToResource : true,
            showExactDropPosition   : true
        },
      }
    }
    getBryConfig(){
      this.eventRenderer = function (event: { eventRecord:any }, resource: any, tplData: any, row: any, col: any, ds: any) {
        // console.log('EVENT RENDERER', event);

        if (event?.eventRecord?.originalData?.isBuffer) {

          if (event?.eventRecord.originalData?.startBuffer) {
            const buffer = event?.eventRecord.originalData?.startBuffer;
            return `${buffer} min`;
          }

          if (event?.eventRecord.originalData?.endBuffer) {
            const buffer = event?.eventRecord.originalData?.endBuffer;
            return `${buffer} min`;
          }

        }
      
        if(event.eventRecord.eventStyle != "line" && event.eventRecord.title != "line"){
           return event.eventRecord.title
        }

      }
      
      this.events = [ ];
      this.schedulerConfig = {
      columns : [
        {
          type     : 'template',
          field    : 'checkbox',
          text     : '',
          width    : 50,
          align: 'center',
          ref: 'check2',
          template : ({ record }:{ record: EventModel }): any => {
            const recordData = (record as any).data

            let isOwner = false;

            this.assignTask.userNames.forEach((user: any) => {
             if (user.id === recordData.userId) {
               if (user.owner) {
                 isOwner = true;
               }
             }
            })

            console.log(record);

            if (isOwner) {
              return   `<label class="p-checkbox planinghub-button">
              <input type="checkbox" id="${record.id}" name="${record.id}" checked>
              <span class="c-checkbox-icon-two"></span>
              </label>`;
            }
            
            return   `<label class="p-checkbox planinghub-button">
                      <input type="checkbox" id="${record.id}" name="${record.id}">
                      <span class="c-checkbox-icon-two"></span>
                    </label>`;
          }
        },
        { text: this.translate?.task.fields.user.plural, field: 'title',align: 'left', width: '13em', 
        leafIconCls            : null,
        htmlEncode             : false,
        renderer({ cellElement,record, value, size }: {cellElement:any,record: any,value: any , size: any}) {
              
            var img="../../../../../assets/svg/Avatar.svg"
            var status=''
            if(record.imageUrl !== null){
              img=record.imageUrl
            }
            
            if(record.online){
              status='<div class="custella-online-mini" style="top: 12px;left: -15px;position: relative;" ></div>';
            }else{
              status=' <div class="custella-offline-mini" style="top: 12px;left: -15px;position: relative;" ></div>';
            }
            return '<div style="height: 32px; padding: 2px 0; display: flex; align-items: center;"><div class="mr-2 p-avatar p-component p-avatar-image p-avatar-circle task-avatar" >'+
            '<img src="'+img+'" >'+
            '</div>'+status + '<span class="text-12" id="value_TaskCheckAvailName">'+ value +'</span>' +'</div>';
        }
      },
      { text: this.translate?.task.fields.utilization.plural, field: 'utilization',align: 'center', width: '7em', 
        leafIconCls            : null,
        htmlEncode             : false,
        renderer({ cellElement,record, value, size }: {cellElement:any,record: any,value: any , size: any}) {
          if (record.utilization == "0") {
            return '<span class="p-mr-2  p-tag-info p-tag p-component" id="tag_TaskCheckUtilizeZero"><span class="p-tag-value">'+record.utilization+'%</span></span>'
          }else if (record.utilization < 40) {
            return  ' <span class="p-mr-2 p-tag-success p-tag p-component" id="tag_TaskCheckUtilizeBel40"><span class="p-tag-value">'+record.utilization+'%</span></span>'
          } else if (record.utilization >= 40 && record.utilization < 80 ) {
            return '<span class="p-mr-2 p-tag-warning p-tag p-component" id="tag_TaskCheckUtilizeBel80"><span class="p-tag-value">'+record.utilization+'%</span></span>'
          }else if ( record.utilization >= 80) {
            return ' <span class="p-mr-2 p-tag-danger p-tag p-component" id="tag_TaskCheckUtilizeAbove80"><span class="p-tag-value">'+record.utilization+'%</span></span>';
          }
            
        }
      },
      {
        type     : 'template',
        field    : 'makeOwner',
        text     : this.translate?.task.fields.makeOwner.plural,
        width    : 130,
        align    : 'center', 
        template : ({ record }:{ record: any }): any => {
         console.log(record.data,"====")
         console.log(this.assignTask.userNames);

         let isOwner = false;

         this.assignTask.userNames.forEach((user: any) => {
          if (user.id === record.data.userId) {
            if (user.owner) {
              isOwner = true;
            }
          }
         })

         if (isOwner) {
          return   `<button class="custella-btn-white text-12 mx-2 makeownerClass" id="button-${record.id}" style="background: #6366f2; color: #ffffff">Owner</button>`;
         }

          return   `<button class="custella-btn-white text-12 mx-2 makeownerClass" id="button-${record.id}">Make Owner</button>`;
        }
      }
      ],
      cellEdit : false,
      startDate  : new Date(this.data.startDateTime2.getFullYear(), this.data.startDateTime2.getMonth(), this.data.startDateTime2.getDate(), 2),
      endDate    : new Date(this.data.startDateTime2.getFullYear(), this.data.startDateTime2.getMonth(), this.data.startDateTime2.getDate(), 23),//should not give>23),
      rowHeight : 40,
      barMargin : 5,
      features : {
        resourceTimeRanges : true,
        cellEdit : false,
        eventTooltip : {
          showOnClick: false,
          // bodyCls : {
          //   'b-resize-monitors'     : 1,
          //   },
          // template returns a custom element created in app.module.ts
          template : ({ eventRecord }:{ eventRecord: any }): any => {
            console.log(eventRecord.data)
            // setTimeout(() => {  
            //   $('body').addClass('b-resize-monitored');
            // }, 500);
            var startTime = moment.tz(eventRecord.data.startDate,this.auth.getUserTimezone()).format('DD-MM-YYYY');
            var startDateTime =  moment(eventRecord.data.startDate).format("hh:mm a")
            var endDateTime =  moment(eventRecord.data.endDate).format("hh:mm a")
            var location = eventRecord.data.location ? eventRecord.data.location:'-'
            if(eventRecord.data.eventStyle == "line"){
              let displayData = '';

              if (eventRecord?.originalData?.totalTravelTime) {
                displayData = `
                <div class="custella-form-container m-0 h-100 rounded-2">
                  <div class="custella-form-container-title">
                      <span class="text-12 font-semiBold" id="title_TaskCheckAvailTravTime">Travel Time - ${Math.floor(eventRecord?.originalData?.totalTravelTime / 60)} mins</span>
                  </div>
              </div>
                </div>`
              } else {
                displayData = `
                <div class="custella-form-container m-0 h-100 rounded-2">
                  <div class="custella-form-container-title" style="padding-left: 0.75rem; padding-right: 0.75rem;">
                      <div class="flex-between">
                        <span class="text-12 font-semiBold me-2" id="value_TaskCheckAvailDuration">${startDateTime} - ${endDateTime}</span>
                      </div>
                  </div>
                </div>`
              }


  
              return displayData;
            
              }else{
                console.log('EVENT TOOLTIP', eventRecord)
                if (eventRecord?.originalData?.isBuffer) {
                  let buffer = 0;
                  
                  if (eventRecord?.originalData?.startBuffer) {
                    buffer = eventRecord?.originalData?.startBuffer;
                  }

                  if (eventRecord?.originalData?.endBuffer) {
                    buffer = eventRecord?.originalData?.endBuffer;
                  }
                  
                  return `
                  <div class="custella-form-container position-relative d-block rounded-2 m-0 h-100">
                    <div class="custella-form-container-title rounded-top">
                        <div class="font-medium text-14" id="title_TaskCheckAvailTaskName">${eventRecord.name}</div>
                    </div>

                    <div class="custella-form-container-content-border-bottom font-black d-flex justify-content-center">
                        <div class="custella-content-row p-0">
                            <div class="custella-content-row-input">
                              <div class="d-flex align-items-center">
                                  <i class="pi pi-clock" id="img_TaskCheckAvailDuration"></i>
                                  <span class="text-12 font-medium ms-2" id="value_TaskCheckAvailDuration">${buffer} min</span>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>`;
                      
                } else {
                  return `
                  <div class="custella-form-container position-relative d-block rounded-2 m-0 h-100">
                    <div class="custella-form-container-title rounded-top">
                        <div class="font-medium text-14" id="title_TaskCheckAvailTaskName">${eventRecord.name}</div>
                    </div>
                    <!-- Name and Email -->
                    <div class="custella-form-container-content-border-bottom font-black">
                        <div class="custella-content-row">
                            <div class="custella-content-row-input">
                              <div class="d-flex align-items-center">
                                  <i class="pi pi-clock"  id="img_TaskCheckAvailDuration"></i>
                                  <span class="text-12 font-medium ms-2" id="value_TaskCheckAvailDuration">${startDateTime} - ${endDateTime}</span>
                              </div>
                            </div>
                            <div class="custella-content-row-input">
                              <div class="d-flex align-items-center">
                                  <i class="pi pi-calendar" id="img_TaskCheckAvailDurStart"></i>
                                  <span class="text-12 font-medium ms-2" id="value_TaskCheckAvailDurStart">${startTime}</span>
                              </div>
                            </div>
                        </div>
                        <div class="custella-content-row">
                            <div class="custella-content-row-input col-12">
                              <div class="d-flex align-items-baseline">
                                  <i class="pi pi-map" id="img_TaskCheckAvailLocation"></i>
                                  <span class="text-12 font-medium ms-2 text-wrap" id="value_TaskCheckAvailLocation">${location}</span>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>`
                      ;
                }

              }
          }
      }

        },
        listeners : {
          eventClick(e: any) {
              console.log('eventClick', e);
          },
        }
      };
    }
 /***************************************** Bryntum Cell Click Event**********************************************/
     
      onCellClick({ record, cellElement, column }:any): void {
        console.log(record);
        // Show a custom editor when dbl clicking a client cell
        console.log(column.field,"====",record.data)
        let inputs: any = document.getElementById(record.data.id);
        
        if(column.field === 'checkbox' && this.selectedUsers.length > 4){
          inputs.disabled = true
          inputs.checked = false
        }
        console.log(this.selectedUsers.length,"=====",inputs)
        if (column.field === 'makeOwner' && record.data && !inputs.disabled) {

          var galleries: any = document.getElementsByClassName("makeownerClass");
          var len =  galleries.length;
          
          for(var i=0 ; i<len; i++){
             galleries[i].style.backgroundColor="#fff";
             galleries[i].style.color="#000";
             galleries[i].innerText="Make Owner";
          }
          this.ownerSelected = record.data.id
          if(record.data){
            var moButton: any = document.getElementById("button-"+record.data.id)
            moButton.style.background = "#6366f2";
            moButton.style.color = "#ffffff";
            moButton.innerText = "Owner";
            inputs.checked = true
            this.selectMember(record.data)
            this.makeOwner(record.data)
          }
        }else if(column.field === 'checkbox' && inputs.checked){
          console.log(inputs.checked,"----",this.selectedUsers.length)
         
            if(this.selectedUsers.length == 0){
              
              this.ownerSelected = record.data.id
              if(record.data){
                var moButton: any = document.getElementById("button-"+record.data.id)
                moButton.style.background = "#6366f2";
                moButton.style.color = "#ffffff";
                moButton.innerText = "Owner";
                inputs.checked = true
                this.selectMember(record.data)
                this.makeOwner(record.data)
              }
            }else{
              this.selectMember(record.data)
            }



        }else if(column.field === 'checkbox' && !inputs.checked){
          console.log(inputs.checked,"----",record.data)
         
          this.selectedUsers.forEach((element : any) => {
            if(element.id == record.data.id){ 
              element.owner = false 
              var moButton: any = document.getElementById("button-"+element.id)
              moButton.style.background = "#ffffff";
              moButton.style.color = "#000";
              moButton.innerText = "Make Owner";
            }
            console.log(element)
          });

          this.removeSelectedMember(record.data)
        }
    }

    setMakeOwner() {
      let resourceOwner;

      for (let r = 0; r < this.resources.length; r++) {
        this.assignTask.userNames.forEach((user: any) => {
          if (user.id === this.resources[r].userId) {
            if (user.owner) {
              resourceOwner = this.resources[r];
            }
          }
         })
      }

      if (resourceOwner) {
        this.selectMember(resourceOwner)
        this.makeOwner(resourceOwner);
      }
    }
    
    private dateRangeOverlaps(a_start: Date, a_end: Date, b_start: Date, b_end: Date) {
      if (a_start < b_start && b_start < a_end) return true; // b starts in a
      if (a_start < b_end   && b_end   < a_end) return true; // b ends in a
      if (b_start <  a_start && a_end   <  b_end) return true; // a in b

      return false;
    }
     
    getLanguageData() {
      this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
        if(Object.keys(data).length != 0){
          this.translate = data
          this.getBryConfig()
        }
      }))
      console.log("translate",this.translate)
      //this.mainLabel = this.translate?.accessories.label.plural
    }
  
    push(obs:any) {
      super.push(obs);
    }

}
