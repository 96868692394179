import { of as throwError, Observable, Subject } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable, EventEmitter } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AuthenticationService } from "../auth/authentication.service";
import { Http, RequestOptions, Headers } from "@angular/http";
@Injectable()
export class CustomersService {
  public refreshList$: EventEmitter<any>;
  public refreshSalesOrderList$: EventEmitter<any>;
  public refreshJobOrderList$: EventEmitter<any>;
  public refreshPurchaseOrderList$: EventEmitter<any>;
  public refreshPaymentOrderList$: EventEmitter<any>;
  public refreshDeliveryOrderList$: EventEmitter<any>;
  public refreshJMP$: EventEmitter<any>;
  public refreshPartOrderList$: EventEmitter<any>;
  public refreshHpFormList$: EventEmitter<any>;
  public refresherPickList$: EventEmitter<any>;

  private customerItemsSource = new Subject<any>();
  customeritemsHandler = this.customerItemsSource.asObservable();

  private ENDPOINT_LIST_CUSTOMERS = "/customers";
  private ENDPOINT_CREATE_CUSTOMER = "/v4/customers";
  private ENDPOINT_CREATE_CONTACT = "/contacts";
  private ENDPOINT_DELETE_CONTACT = "/contacts/";
  private ENDPOINT_LIST_CUSTOMER_CONTACTS = "/v4/contacts/";
  private ENDPOINT_DELETE_CUSTOMER = "/customers/";
  private ENDPOINT_CUSTOMER_BY_ID = "/v4/customers/";
  private ENDPOINT_CUSTOMER_SEARCH = "/customers/search";
  private ENDPOINT_UPLOAD_CUSTOMERS = "/csv/create-customers";
  private ENDPOINT_INDUSTRY_LIST = "/industries";
  private ENDPOINT_JOB_ORDERS_BY_CUSTOMER = "/job-orders/by-customer/";
  private ENDPOINT_SALES_ORDERS_BY_CUSTOMER = "/sales-orders/by-customer/";
  private ENDPOINT_SALES_ORDERS_BY_ID = "/sales-orders/";
  private ENDPOINT_ALL_SALES_ORDERS = "/sales-orders";
  private ENDPOINT_ALL_JOB_ORDERS = "/job-orders";
  private ENDPOINT_JOB_ORDERS_BY_ID = "/job-orders/";
  private ENDPOINT_JOB_ORDERS_BY_SALES_ID = "/job-orders/sales-order/";
  private ENDPOINT_FILTER_SALES_ORDERS = "/v2/sales-orders/filter";
  private ENDPOINT_FILTER_JOB_ORDERS = "/v3/job-orders";
  private ENDPOINT_UPLOAD_JO_ATTACHMENTS =
    "/attachments/upload?parentType=JO_ATTACHENT&parentId=";
  private ENDPOINT_DELETE_JO_ATTACHMENTS = "/attachments/";
  private ENDPOINT_JOB_ORDER_TYPES = "/job-order-types";
  private ENDPOINT_JOB_ORDER_PRODUCT_STATUSES = "/job-order-product-statuses";
  private ENDPOINT_CUSTOMER_PURCHASE_HISTORY =
    "/v4/customer-purchase-histories/filter";
  private ENDPOINT_CREATE_PURCHASE_HISTORY = "/customer-purchase-histories";
  private ENDPOINT_FILTER_PAYMENTS = "/customer-payment-histories/filter";
  private ENDPOINT_CUSTOMERS = "/v4-1/customers";
  private ENDPOINT_CUSTOMERS_HISTORY_BY_SALESORDER_ID =
    "/v4/customer-purchase-histories/filter/";
  private ENDPOINT_SET_AUTO_GEOFENCE = "/v4/customers/geo-fence/";
  private ENDPOINT_GET_ATTACHMENTS_BY_CUSTOMER_ID = "/v4/attachments/customer/";
  private ENDPOINT_GET_ATTACHMENTS_BY_CUSTOMER_PAYMENT_ID = "/v4/attachments/customerPaymentHistory/";

  private ENDPOINT_UPLOAD_CUSTOMER_ATTACHMENTS =
    "/attachments/customer/multi-upload?parentType=CUSTOMER_ATTACHMENTS&parentId=";
  private ENDPOINT_LIST_DELIVERY_ORDERS =
    environment.base_url + "/delivery-orders";
  private ENDPOINT_UPLOAD_DO_ATTACHMENTS =
    "/attachments/upload?parentType=DO_ATTACHMENT&parentId=";
  private ENDPOINT_DELETE_DO_ATTACHMENTS = "/attachments/";
  private ENDPOINT_LIST_DO_ORDER_HISTORY =
    "/delivery-order-audit-histories?deliveryOrderId=";
  private ENDPOINT_LIST_DO_POLYLINE = "/delivery-orders/line-item/polyline/";
  private ENDPOINT_GET_ALL_SERVICE_ZONES = "/service-zones/pick-list";
  //SNL user
  private ENDPOINT_CREATE_CONTACT_CUSTOMER_USER = "/contacts-and-customer-user";
  private ENDPOINT_USER_ID = "/contacts-and-customer-user/";
  private ENDPOINT_REMOVE_DELIVERY_ORDERS_VEHICLE =
    environment.base_url + "/route-planner/remove?";
  private ENDPOINT_UPLOAD_DO_INVOICE_ATTACHMENTS =
    "/attachments/upload?parentType=DO_INVOICE&parentId=";
  private ENDPOINT_UPLOAD_DO_PACKING_ATTACHMENTS =
    "/attachments/upload?parentType=DO_PACKAGE_LIST&parentId=";
  private _jsonURL = "/assets/hills-data.json";

  //Part Order
  private ENDPOINT_PART_ORDER_GET_ALL = "/part-orders/get-all?jobOrderId=";
  private ENDPOINT_PART_ORDER_BY_ID = "/part-orders/get-by/";
  private ENDPOINT_PART_ORDER_BY_LINE_ITEM =
    "/job-order-products/by-part-order/";
  
  private ENDPOINT_CUSTOMER_OVERVIEW = "/customers/overview"
  private ENDPOINT_CUSTOMER_TIMELINE = "/customers/summary"

  private _googleThemeJsonURL = "/assets/json/google-theme-data.json";
  private customertemsSource = new Subject<any>();
  productitemsHandler = this.customertemsSource.asObservable();

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private httpOnly: Http
  ) {
    this.refreshList$ = new EventEmitter();
    this.refreshSalesOrderList$ = new EventEmitter();
    this.refreshJobOrderList$ = new EventEmitter();
    this.refreshDeliveryOrderList$ = new EventEmitter();
    this.refreshPurchaseOrderList$ = new EventEmitter();
    this.refreshPaymentOrderList$ = new EventEmitter();
    this.refreshJMP$ = new EventEmitter();
    this.refreshPartOrderList$ = new EventEmitter();
    this.refreshHpFormList$ = new EventEmitter();
    this.refresherPickList$ = new EventEmitter();
  }

  setItems(items: any) {
    this.customertemsSource.next(items);
  }

  //************************************* Get Customer List *************************************************************************/
  public getCustomerList(search: any, parameters: any) {
    let params = new HttpParams();
    if (search !== "" && search != null) {
      params = params.set("search", search);
    }
    if (parameters)
      //console.log(parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);

    return this.http
      .get(environment.base_url + this.ENDPOINT_CUSTOMERS, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  //************************************* Get Service Zone *************************************************************************/
  public getServiceZones() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_GET_ALL_SERVICE_ZONES, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp.body;
        }),
        catchError((error) => {
          return throwError(error.status);
        })
      );
  }
  //************************************* Get Csutomer Type *************************************************************************/
  public getAllCustomerTypes(active: any = null) {
    var active1 = "";
    if (active) {
      active1 = "?isActive=true";
    }
    return this.http.get(`${environment.base_url}/customer-types${active1}`).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        ////console.log(error);
        return throwError(error.status);
      })
    );
  }



  public createCustomerType(body: any) {
    return this.http
      .post(environment.base_url + "/customer-types", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public updateCustomerType(body: any) {
    return this.http
      .put(environment.base_url + "/customer-types", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getCustomerTypeById(id: any) {
    return this.http.get(environment.base_url + "/customer-types/" + id).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
  }
  public deleteCustomerType(id: any) {
    return this.http
      .delete(environment.base_url + "/customer-types/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherPickList$.emit("Deleted Customer Type");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  //************************************* Get GEO Fences List *************************************************************************/
  public getGeofencePickList() {
    return this.http.get(`${environment.base_url}/geo-fences/pick-list`).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        ////console.log(error);
        return throwError(error.status);
      })
    );
  }
  //************************************* Get Industry List *************************************************************************/
  public getIndustryList() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_INDUSTRY_LIST)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error.status);
        })
      );
  }
  //************************************* Get Category List *************************************************************************/
  public getAllCustomerCategoryType(active: any = null) {
    var active1 = "";
    if (active) {
      active1 = "?isActive=true";
    }
    return this.http
      .get(environment.base_url + "/category-types/all" + active1)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error.status);
        })
      );
  }

  public createCategoryType(body: any) {
    return this.http
      .post(environment.base_url + "/category-types", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public createCustomerSector(body: any) {
    return this.http
      .post(environment.base_url + "/customer-sectors", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public updateCustomerSector(body: any) {
    return this.http
      .put(environment.base_url + "/customer-sectors", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public createCustomerIndustry(body: any) {
    return this.http
      .post(environment.base_url + "/industries", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public updateCustomerIndustry(body: any) {
    return this.http
      .put(environment.base_url + "/industries", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public createCustomerSubSector(body: any) {
    return this.http
      .post(environment.base_url + "/customer-sub-sectors", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public updateCustomerSubSector(body: any) {
    return this.http
      .put(environment.base_url + "/customer-sub-sectors", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }


  public updateCategoryType(body: any) {
    return this.http
      .put(environment.base_url + "/category-types", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getCategoryTypeById(id: any) {
    return this.http.get(environment.base_url + "/category-types/" + id).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
  }
  public deleteCategoryType(id: any) {
    return this.http
      .delete(environment.base_url + "/category-types/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherPickList$.emit("Deleted Category Type");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }


  public getCustomerSectorById(id: any) {
    return this.http.get(environment.base_url + "/customer-sectors/" + id).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
  }
  public deleteCustomerSector(id: any) {
    return this.http
      .delete(environment.base_url + "/customer-sectors/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherPickList$.emit("Deleted Customer Sector");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getCustomerIndustryById(id: any) {
    return this.http.get(environment.base_url + "/industries/" + id).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
  }
  public deleteCustomerIndustry(id: any) {
    return this.http
      .delete(environment.base_url + "/industries/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherPickList$.emit("Deleted Customer Industry");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }


  public getCustomerSubSectorById(id: any) {
    return this.http.get(environment.base_url + "/customer-sub-sectors/" + id).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
  }
  public deleteCustomerSubSector(id: any) {
    return this.http
      .delete(environment.base_url + "/customer-sub-sectors/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherPickList$.emit("Deleted Customer Sub Sector");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }


  /************************************* Get Customer Sector *************************************************************************/
  public getAllCustomerSector() {
    return this.http.get(`${environment.base_url}/customer-sectors`).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        ////console.log(error);
        return throwError(error.status);
      })
    );
  }

  public getAllCustomerIndustry() {
    return this.http.get(`${environment.base_url}/industries`).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        ////console.log(error);
        return throwError(error.status);
      })
    );
  }

  /************************************* Get Customer Sub-sector *************************************************************************/
  public getAllCustomerSubSector(customerSectorId: any = null) {
    var url;
    if (customerSectorId) {
      url =
        environment.base_url +
        "/customer-sub-sectors?customerSectorId=" +
        customerSectorId;
    } else {
      url = environment.base_url + "/customer-sub-sectors";
    }

    return this.http.get(url).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        ////console.log(error);
        return throwError(error.status);
      })
    );
  }

  public getAllCustomerSectors(active: any = null) {
    var active1 = "";
    if (active) {
      active1 = "?isActive=true";
    }
    return this.http.get(`${environment.base_url}/customer-sectors${active1}`).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        ////console.log(error);
        return throwError(error.status);
      })
    );
  }

  /************************************* Create Custome API *************************************************************************/
  public createCustomer(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_CREATE_CUSTOMER, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(body);
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }
  /************************************* Edit Customer Info *************************************************************************/
  public editCustomer(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_CREATE_CUSTOMER, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(body);
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }
  /************************************* Delete Customer Data By Id *************************************************************************/
  public deleteCustomer(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_DELETE_CUSTOMER + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Deleted Customer");
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }
  /************************************* Get Customer Info By ID *************************************************************************/
  public getCustomerById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_CUSTOMER_BY_ID + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

  /************************************* Get Customer Attachments Info By ID *************************************************************************/
  public getAttachmentsByCustomerId(id: any) {
    let params = new HttpParams();
    params = params.set("customerId", id);
    return this.http
      .get(environment.base_url + this.ENDPOINT_GET_ATTACHMENTS_BY_CUSTOMER_ID,
        {params : params})
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }

   /************************************* Get Customer Attachments Info By ID *************************************************************************/
   public getAttachmentsByCustomerPaymentId(id: any) {
    let params = new HttpParams();
    params = params.set("paymentId", id);
    return this.http
      .get(environment.base_url + this.ENDPOINT_GET_ATTACHMENTS_BY_CUSTOMER_PAYMENT_ID,
        {params : params})
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }


  /************************************* Get Customer Payment History *************************************************************************/

  public getPaymentsByCustomerIdV2(
    parameters: any,
    customerId: any,
    dueStartDate: any,
    dueEndDate: any,
    status: any,
    completionStartDate: any,
    completionEndDate: any,
    searchValue: any
  ) {
    let params = new HttpParams();

    params = params.set("origin", "customer");

    if (dueStartDate !== "" && dueEndDate !== "") {
      params = params
        .set("dueStartDate", dueStartDate)
        .set("dueEndDate", dueEndDate);
    }
    if (status !== "") {
      params = params.set("status", status);
    }
    if (searchValue !== "" && searchValue != null) {
      params = params.set("search", searchValue);
    }
    if (customerId !== "" && customerId != null) {
      params = params.set("customerId", customerId);
    }
    if (completionStartDate !== "" && completionEndDate !== "") {
      params = params
        .set("completionStartDate", completionStartDate)
        .set("completionEndDate", completionEndDate);
    }
    if (parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);

    return this.http
      .get(environment.base_url + "/v4/customer-payment-histories/filter", {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /************************************* Get Customer Purchase History *************************************************************************/
  public getPurchasesByCustomerIdV2(
    searchValue: any,
    parameters: any,
    id: any
  ) {
    let params = new HttpParams();

    if (id !== "" && id != null) {
      params = params.set("customerId", id);
    }
    if (searchValue !== "" && searchValue != null) {
      params = params.set("search", searchValue);
    }
    params = params.set("origin", "customer");

    if (parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort)
        .set("customerId", id);

    return this.http
      .get(
        environment.base_url +
          "/v4/customer-purchase-histories/filter/",
        { params: params }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /************************************* Create Purchase History *************************************************************************/

  public createPurchaseHistory(body: any) {
    return this.http
      .post(
        environment.base_url + this.ENDPOINT_CREATE_PURCHASE_HISTORY,
        body,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refreshPurchaseOrderList$.emit(body);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /************************************* Edit Purchase History *************************************************************************/
  public editPurchaseHistory(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_CREATE_PURCHASE_HISTORY, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshPurchaseOrderList$.emit(body);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /************************************* Delete Purchase History *************************************************************************/
  public deletePurchaseHistory(id: any) {
    return this.http
      .delete(
        environment.base_url + this.ENDPOINT_CREATE_PURCHASE_HISTORY + "/" + id,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refreshPurchaseOrderList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /************************************* Get Customer Sales Order By Id *************************************************************************/

  public getSalesOrderByCustomerV2Picklist(id: any) {
    let params = new HttpParams();
    params = params.set("customerId",id);
    return this.http
      .get(
        environment.base_url + "/v4/sales-orders/", 
        { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /************************************* Create Payment History *************************************************************************/

  public createPaymentHistory(body: any) {
    return this.http
      .post(environment.base_url + "/customer-payment-histories", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshPaymentOrderList$.emit(body);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /************************************* Update Payment History*************************************************************************/

  public editPaymentHistory(body: any) {
    return this.http
      .put(environment.base_url + "/customer-payment-histories", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshPaymentOrderList$.emit(body);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /************************************* get Purchase History Detail By ID *************************************************************************/
  public getPurchaseHistoryById(id: any) {
    return this.http
      .get(environment.base_url + "/customer-purchase-histories/" + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }

  /************************************* Delete Payment History *************************************************************************/

  public deletePaymentHistory(id: any) {
    return this.http
      .delete(environment.base_url + "/customer-payment-histories" + "/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshPaymentOrderList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

 /************************************* Get Cutomer Geofence *************************************************************************/
  public getCustomerGeofence(lon:  number, lat:  number, ) {
    let params = new HttpParams();
     params = params.set("latitude", lat);
     params = params.set("longitude", lon);
    return this.http
      .get(environment.base_url + this.ENDPOINT_SET_AUTO_GEOFENCE, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /************************************* Create Payment Detail By ID *************************************************************************/
  public getPaymentById(id: any) {
    return this.http
      .get(environment.base_url + "/v4/customer-payment-histories/" + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /************************************* Get Customer By Name *************************************************************************/

  public getAllCustomerByName(name: string) {
    return this.http
      .get(environment.base_url + "/customers/pick-list?name=" + name)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /*****************************************Get the list of contacts by customer id ********************************************************************/
  public getCustomerContactsById(id: any) {
    let params = new HttpParams().set("customerId", id);
    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_CUSTOMER_CONTACTS, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /*****************************************Delete Contact*********************************************************************/
  public deleteContact(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_DELETE_CONTACT + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Deleted Contact");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /*****************************************Get Contact info by Id for Customer*********************************************************************/
  public getUserId(contactId: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_USER_ID + contactId, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  /*****************************************Create Customer Contact*********************************************************************/
  public createContactUser(body: any) {
    return this.http
      .post(
        environment.base_url + this.ENDPOINT_CREATE_CONTACT_CUSTOMER_USER,
        body,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(body);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /*****************************************Edit Contact By customer*********************************************************************/
  public editContactUser(body: any) {
    return this.http
      .put(
        environment.base_url + this.ENDPOINT_CREATE_CONTACT_CUSTOMER_USER,
        body,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(body);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  /*****************************************Set Customer Var*********************************************************************/
  public setCustomer(customer: any) {
    this.customerItemsSource.next(customer);
  }

  public getCustomer() {
    return this.customerItemsSource;
  }
  public getAllV2Picklist() {
    return this.http.get(environment.base_url + "/v4/customers/pick-list").pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
  }
  /*****************************************Upload Customer Attchment *********************************************************************/
  public uploadAttachmentByCustomerId(id: any, formData: any) {
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });
    return this.httpOnly
      .post(
        environment.base_url + this.ENDPOINT_UPLOAD_CUSTOMER_ATTACHMENTS + id,
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getPurchaseHistoryBySalesOrder(id: any) {
    let params = new HttpParams();
    params = params.set("salesOrderId", id);
    return this.http
      .get(
        environment.base_url +
          this.ENDPOINT_CUSTOMERS_HISTORY_BY_SALESORDER_ID,{
            params : params
          }
      )
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getPurchaseHistoryByCustomer(id: any) {
    let params = new HttpParams();
    params = params.set("customerId", id);
    return this.http
      .get(environment.base_url + this.ENDPOINT_CUSTOMER_PURCHASE_HISTORY,{
        params: params
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit(resp);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /*****************************************New Geofence *********************************************************************/
  public addNewGeofence(formData: any) {
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });
    return this.httpOnly
      .post(
        environment.base_url + "/csv/create-geo-fence?id=",
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          if (resp.status == 200) {
            this.refreshList$.emit(resp);
          }
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /*****************************************Update Geofence *********************************************************************/
  public updateGeofence(formData: any, id: any) {
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });
    return this.httpOnly
      .post(
        environment.base_url + "/csv/create-geo-fence?id=" + id,
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          if (resp.status == 200) {
            this.refreshList$.emit("geo Update");
          }
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /*****************************************get All Geofence *********************************************************************/
  public getAllGeofences() {
    return this.http.get(`${environment.base_url}/geo-fences`).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
  }
  /*****************************************get Geofence by ID *********************************************************************/
  public getGeofenceById(id: any) {
    return this.http.get(`${environment.base_url}/geo-fences/${id}`).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
  }
  /*****************************************Delete Geofence *********************************************************************/
  public deleteGeofence(id: any) {
    return this.http
      .delete(environment.base_url + "/geo-fences/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Deleted Geofence");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /*****************************************get Geo Geofence by customer *********************************************************************/
  public getCustomersByGeofence(id: any) {
    return this.http
      .get(`${environment.base_url}/customers/search-by-geo-fence/${id}`)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  concatDisplayName(field: any, data: any) {
    console.log(data);
    data.forEach((element: any) => {
      if (field.displayColumn) {
        const keys = Object.keys(element);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          // console.log(key,"==============",field.displayColumn,"-------", element[field.displayColumn]);
          element.displayColumn = element[field.displayColumn];
        }
      } else {
        element.displayColumn = element.name;
      }
      // if(field.name == "jobOrder"){
      //   element.name = element.jobOrderNumber
      // }
    });

    return data;
  }
  public getCustomerSearch(search: any) {
    let params = new HttpParams();
    params = params.set("page", "1");
    params = params.set("perPage", "20");
    params = params.set("search", search);
    params = params.set("columnName", "name").set("sortOrder", "asc");
    return this.http
      .get(environment.base_url + this.ENDPOINT_CUSTOMERS, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getDeport() {
    return this.http.get(`${environment.base_url}/depots`).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
  }

  public getTenantAddons(id: any) {
    return this.http
      .get(
        environment.base_url +
          "/tenant-addons/by-tenant/" +
          id +
          "/by-addon/CUSTOM_JO_COMPLETION_FORM"
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getCustomersForm(id: any) {
    return this.http
      .get(`${environment.base_url}/check-forms-by-job-order-web/${id}`)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public downloadCustomerForm(id: any) {
    return this.http
      .get(`${environment.base_url}/test-forms/download-by-job-order/${id}`, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public downloadCustomerChecklist(id: any) {
    return this.http
      .get(
        `${environment.base_url}/job-order-checklists/download-by-job-order/${id}`,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getOrderWay(){
    return this.http.get( `${environment.base_url}/delivery-order-ways?active=true` ).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return throwError(error);
      })
    );
}
public getGoogleTheme(){
  return this.http.get(this._googleThemeJsonURL).pipe(
    map((resp: any) => {
      return resp;
    }),
    catchError((error) => {
      //console.log(error);
      return throwError(error);
    })
  );
}
//**================================== Inventory => Location Bin  =========================*/
public getAllCustomerByContact(lookupUrl: any, searchValue: any, lookupFilter: any){
  return this.http.get(environment.base_url+'/'+lookupUrl+'startFrom=0&perPage=50&search='+searchValue+'&columnName=name&sortOrder=asc'+lookupFilter ).pipe(map( (resp:any) => {
     return resp;
   }
 ),catchError( error => {
     return throwError( error );
   }),);
 }

 public getCusOverviewById(cusId: any) {
  return this.http
    .get(environment.base_url + this.ENDPOINT_CUSTOMER_OVERVIEW+"?customerId="+cusId, {
      observe: "response",
    })
    .pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
}


public getCusSummeryById(cusId: any) {
  return this.http
    .get(environment.base_url + this.ENDPOINT_CUSTOMER_TIMELINE+"?customerId="+cusId, {
      observe: "response",
    })
    .pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
}

}
