import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";

import * as _ from "lodash";
import { UsersService } from "src/app/_services/admin/users.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-user-selection",
  templateUrl: "./user-selection.component.html",
  styleUrls: ["./user-selection.component.scss"],
})
export class UserSelectionComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  translate: any;
  @Input() set allUsers(value: any) {
    if (value) {
      this.allTheUsers = JSON.parse(JSON.stringify(value));
    }
  }

  allTheUsers: any[] = [];
  searchedUsers: any[] = [];

  showDropdown: boolean = false;
  showNoUser: boolean = false;

  searchValue = "";

  @Output() closeView = new EventEmitter();
  @Output() selectedUserSelection = new EventEmitter();

  constructor(private userService: UsersService,
    private languageTranslateService: LanguageTranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getLanguageData();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Get Search User ******************************************/
  public getSearchedUserList(event: any) {
    if (event) {
      this.push(
        this.userService.getUsersBySearch(event).subscribe((users) => {
          this.searchedUsers = users;

          if (this.searchedUsers.length) {
            this.showDropdown = true;
            this.showNoUser = false;
          } else {
            this.showNoUser = true;
          }
        })
      );
    } else {
      this.showDropdown = false;
    }
  }

  /******************************************** Add User ******************************************/
  public addUser(user: any) {
    var checkExistingUser = _.find(this.allTheUsers, (o) => {
      return o.id == user.id;
    });

    if (!checkExistingUser) {
      this.allTheUsers.push(user);

      this.searchValue = "";
    }
    this.showDropdown = false;
  }

  /******************************************** Remove User ******************************************/
  public removeUser(user: any) {
    _.remove(this.allTheUsers, (o: any) => {
      return o.id == user.id;
    });
  }

  /******************************************** Close ******************************************/
  public close() {
    this.closeView.emit(true);
  }

  /******************************************** Confirm Selection ******************************************/
  public confirmSelection() {
    this.selectedUserSelection.emit({
      selectedUsers: this.allTheUsers,
    });
  }

  getLanguageData() {
   // this.spinner.show()
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
        if(Object.keys(data).length != 0){
          this.translate = data
         
        }
      }))
    console.log("translate",this.translate)
    //this.mainLabel = this.translate?.site.label.plural
  }
  
}
