import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { GridOptions, IServerSideGetRowsParams } from "ag-grid-community";

import * as moment from "moment-timezone";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { ServerRequest } from "src/app/_models/global.data.model";
import { JobOrdersService } from "src/app/_services/job-orders.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { DynamicTableColumnsService } from "src/app/_services/dynamic-table-column.service";
import { Global } from "src/app/_utilities/global";
var dateFormateChange: any;
var statusesJS: any = [];
@Component({
  selector: "app-jo-table",
  templateUrl: "./jo-table.component.html",
  styleUrls: ["./jo-table.component.scss"],
})
export class JoTableComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  @Input() searchValue = "";
  @Input() translate: any;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any = [];
  defaultColDef: any;
  gridParams: any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions!: Partial<GridOptions>;
  overlayNoRowsTemplate!: string;

  dateFormateChange: any;

  statusesJS: any[] = [];
  jobOrders: any = [];
  totalRecord: any;

  gotData: boolean = false;
  currentRecordNumber: any;

  loadTable: any = 0;

  sortBy: any;
  sortOrder: any;

  constructor(
    private auth: AuthenticationService,
    private jobOrdersService: JobOrdersService,
    private spinner: NgxSpinnerService,
    private errorUtil: ErrorUtil,
    private router: Router,
    private util: UtilServiceService,
    private dynamicTableColumnsService: DynamicTableColumnsService
  ) {
    super();

    this.preInit();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.searchValue.previousValue !== changes.searchValue.currentValue
    ) {
      this.onGridReady(this.gridParams);
    }
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.defaultColDef = {
      enableRowGroup: false, //ag-Grid-Enterprise
      enablePivot: false, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.overlayNoRowsTemplate =
      '<span style="padding: 10px; font-size: 14px;">Record not found</span>';
  }

  /******************************************** init ******************************************/
  private init() { 
    this.spinner.show();
    dateFormateChange = this.auth.getUserTimezone();

    this.push(
      this.jobOrdersService.getJobOrderStatuses().subscribe((res: any) => {
        res.forEach((element: any) => {
          statusesJS.push(element);
        });
      })
    );

    this.push(
      this.jobOrdersService.getListColumn("job_order").subscribe((res: any) => {
        this.sortBy = res.sortBy
        this.sortOrder = res.sortOrder

        var columnDefs: any = this.gridOptions.columnDefs;
        res.tableColumnSetups.forEach((element: any) => {
          this.cellRenderer(element);

          columnDefs.push(element);
          this.gridOptions.api!.setColumnDefs(columnDefs);
        });
        this.gridOptions.api!.setColumnDefs(columnDefs);

        this.loadTable = 1;
        this.onGridReady(this.gridParams);
      })
    );
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** render cell table data ******************************************/
  private cellRenderer(element: any) {
    var dateFormat: any = localStorage.getItem("date_format");
    var getUserTimezone: any = this.auth.getUserTimezone();

    element.headerTooltip = "#";
    if (element.field === "customer.name") {
      element.headerComponentParams =  Global.setHeaderComponentParams(element,'Tbl_HeadJobCustomer'),
      
      element.cellRenderer = function (params: any) {
        if (params.data) {
          return '<p id="' + params.data.rowId2 + '">' +  params.data && params.data.customer ? params.data.customer.name : "-" + '</p>';
        }
      };
     
     
    }else
    if (element.dataType == "date") {
      element.valueFormatter = function (params: any) {
        var dateFormate: any = localStorage.getItem("date_format");
        if (params.data && params.data.installationTime) {
          return moment
            .tz(params.data.installationTime, dateFormateChange)
            .format("hh:mm A" + ", " + dateFormate.toUpperCase());
        } else if (
          params.data &&
          !params.data.installationTime &&
          params.data.installationDate
        ) {
          return moment
            .tz(params.value, dateFormateChange)
            .format(dateFormate.toUpperCase());
        }
      };
    } else if (element.field == "jobOrderNumber") {
      element.headerComponentParams =  Global.setHeaderComponentParams(element,'Tbl_HeadJobNo'),
      element.cellRenderer = function (params: any) {
        // if (params.data) {
        //   if (params.data.redFlag) {
        //     return (
        //       params.data.jobOrderNumber +
        //       +'<img class="ml-2" style="height: 12px" src="../../../../assets/images/3.0/redFlag.png" alt="">'
        //     );
        //   } else {
        //     return params.data.jobOrderNumber;
        //   }
        // }
        if (params && params.data && params.data.jobOrderNumber) {
          const hasRedFlag = params.data.redFlag;

          const number = params.data.jobOrderNumber;

          let final = `${number}`;

          if (hasRedFlag) {
            final += '  <i class="pi pi-flag" style="color: red"></i>';
          }

          return '<p id="' + params.data.rowId + '">' + final + '</p>';
        }
      };
    } else if (element.field == "status") {
      element.cellRenderer = function (params: any) {
        if (params.data) {
          if (params.data.status) {
            var data = { colorCode: "", fieldLabel: "" };
            for (var i = 0; i < statusesJS.length; i++) {
              if (String(params.data.status) == String(statusesJS[i].name)) {
                data = statusesJS[i];
                return (
                  ' <span class="p-mr-2  p-tag p-component" style="background-color:' +
                  data.colorCode +
                  "50 !important ;color:" +
                  "#212121" +
                  '"><span class="p-tag-value">' +
                  data.fieldLabel +
                  "</span></span>"
                );
              }
            }
          }
        }
      };
    }else if (element.field == "installationDate"  || element.field == "installationTime") {
      element.headerName = "Order Date & Time";
      element.cellRenderer = (params: any) => {
        if (params.data) {
        
            return (  moment(params.data.installationTime).format(  localStorage.getItem("date_format")?.toUpperCase() ) +  ", " + moment(params.data.installationTime).format("hh:mm A") );
          
        }
      };
    }else if (element.dataType == 'datetime') {
      element.cellRenderer = function (params: any) {
            console.log(params.value ,"===>>>>",getUserTimezone,"===>>>>",dateFormat.toUpperCase())
              if (params && params.value) {
                return moment.tz(params.value, getUserTimezone) .format(dateFormat.toUpperCase() + ", " + "hh:mm A");
              
              }else{
                return '-'
              }
            };
          
        }else if (element.dataType == "boolean") {
          element.cellRenderer = function (params: any) {
              if (params && params.value) {
                return 'Yes'
                }else{
                  return 'No'
                }
              }  
        } 
    
  }

  /******************************************** on click data ******************************************/
  public onCellClicked($event: any) {
    this.jobOrdersService.setSelectItem($event.data);
  }

  /******************************************** on grid ready ******************************************/
  public onGridReady(params: any) {
    if (params != undefined) {
      this.gridParams = params;

      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      var dataSource = {
        getRows: (params: IServerSideGetRowsParams) => {
          if (this.loadTable === 1) {
            this.getServerSideData(params);
          }
        },
      };

      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  /******************************************** get server side data ******************************************/
  private getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    

    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    var count: any = serverRequest.startRow;

    this.push(
      this.jobOrdersService
        .getAllJobOrders_2(
          serverRequest.startRow,
          serverRequest.pageSize,
          "",
          "",
          "",
          null,
          null,
          this.searchValue,
          serverRequest,
          "jo",
          null,
          null,
          null
        )
        .subscribe(
          (data) => {
            this.jobOrders = [];
            data.data.forEach((element: any) => {
              this.jobOrders.push(element.jobOrder);
            });


            this.jobOrders.forEach((element: any) => {
              count++
              element.rowId = 'Tbl_RecJobOrderbNo' + count
              element.rowId2 = 'Tbl_RecJobOrderCustomer' + count
              element.rowId3 = 'Tbl_RecJobOrderAssets' + count 
              element.buttonEdit = 'Btn_JobEdit' + count
              element.buttonDelete = 'Btn_JobDel' + count
            });

            this.totalRecord = data.total;

            this.setGridData(
              agGridGetRowsParams,
              this.jobOrders,
              this.totalRecord,
              data.status
            );

              this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.errorUtil.setErrorMessage(error);
          }
        )
    );
  }

  /******************************************** set grid data ******************************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    if (status === "NO_DATA") {
      this.gotData = false;
    } else {
      this.gotData = true;

      if (resultItems.length === 0) {
        this.gridApi.showNoRowsOverlay();
      } else {
        agGridGetRowsParams.successCallback(resultItems, totalCount);
        ///CS-4758 this.gridApi.sizeColumnsToFit();
      }
    }
  }

  /******************************************** get server request ******************************************/
  private getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
     this.parameters = { colId: this.sortBy, sort: this.sortOrder };

      agGridRequest.sortModel.push(this.parameters);
    }

    this.currentRecordNumber = agGridRequest.startRow;
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }

 

  /******************************************** right click cell context menu ******************************************/
  public getContextMenuItems = (params: any) => {
    return this.dynamicTableColumnsService.rightClick(params);
  };
   push(obs:any) {
    super.push(obs);
  }
}
