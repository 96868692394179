import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerSurveyService } from 'src/app/_services/customer-survey.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { AppComponent } from 'src/app/app.component';
import { Tooltip } from "primeng/tooltip";
import { AssetService } from 'src/app/_services/asset.service';

import { DynamicLabelsService } from 'src/app/_services/dynamic-labels.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
@Component({
  selector: 'app-survey-link',
  templateUrl: './survey-link.component.html'
})
export class SurveyLinkComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  curveyLinkForm!: UntypedFormGroup;
  curveyLinkForm2!: UntypedFormGroup;
  submitTouched: boolean = false;
  products: any[] = [];
  customerSurveyConfigs: any[] = [];
  curveyLinkData: any = {
    jobOrder: { id: null },
    customerSurveyConfig: { id: null },
    sentTOEmails: null,
    toContact: null,
    toType: "CUSTOM" //ORDER_CONTACT/CUSTOMER_CONTACT/
  }
  mode: any;
  data: any;
  sendTo: any = 'order'
  custCont: boolean = false;
  /** SO Variable */
  displaycustomerSurveyConfigTable: any;
  showcustomerSurveyConfigDropDown: any;
  customerSurveyConfigName: any


  customerSurveyDetails: any
  buttonLabel: any
  tooltipText!: string;
  @ViewChild(Tooltip) tooltip!: Tooltip;

  isSend: boolean = false;
  toContact: { id: null } | any = { id: null };
  contacts: any[] = [];
  isSubmited = false;
  isContactsDisabled = true;
  @ViewChild("toEmails") el: ElementRef | any;
  @ViewChild("toEmails2") el2: ElementRef | any;
  translate: any;

  constructor(public modalRef: BsModalRef,
    private customerSurveyService: CustomerSurveyService,
    private spinner: NgxSpinnerService,
    private errorUtil: ErrorUtil,
    private cd: ChangeDetectorRef,
    private assetService: AssetService,    
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
    this.getLanguageData() 
    this.curveyLinkForm = new UntypedFormGroup({
      'customerSurveyConfig': new UntypedFormControl(null),
      'sentTOEmails': new UntypedFormControl(null),
    });
  }

  ngOnInit(): void {
    console.log(this.data)
    this.init();
  }

  init() {
    this.curveyLinkData.jobOrder.id = this.data.jobOrder.id
    this.buttonLabel =  this.translate?.general.buttons.save.singular
    this.customerSurveyDetails = this.data.customerSurveyDetails
    // this.buttonLabel =  this.customerSurveyDetails ? 'Send':'Generate Link'
    if (this.customerSurveyDetails?.id) {
      this.curveyLinkData.sentTOEmails = this.customerSurveyDetails.sentTOEmails;
      this.curveyLinkData.toType = this.customerSurveyDetails.toType;
      this.curveyLinkData.toContact = this.customerSurveyDetails.toContact;
    } else {
      this.curveyLinkData.sentTOEmails = this.data.jobOrder.contactPersonEmail;
      this.curveyLinkData.toType = 'ORDER_CONTACT';
    }

    this.curveyLinkData.customerSurveyConfig.id = this.customerSurveyDetails ? this.customerSurveyDetails.customerSurveyConfig.id : null
    this.customerSurveyConfigName = this.customerSurveyDetails ? this.customerSurveyDetails.customerSurveyConfig : null
    this.isSend = (this.customerSurveyDetails?.link != null && this.customerSurveyDetails?.link != '') && !this.customerSurveyDetails?.submitted;
    if (this.isSend) {
      this.curveyLinkForm.controls["customerSurveyConfig"].clearValidators();
      this.curveyLinkForm.controls["customerSurveyConfig"].updateValueAndValidity();
    } else {
      this.curveyLinkForm.controls["sentTOEmails"].clearValidators();
      this.curveyLinkForm.controls["sentTOEmails"].updateValueAndValidity();

    }
    this.sendTo = this.curveyLinkData.toType == 'CUSTOMER_CONTACT' ? 'cust' : this.curveyLinkData.toType == 'ORDER_CONTACT' ? 'order' : 'email';
    this.custCont = this.curveyLinkData.toType == 'CUSTOMER_CONTACT';
    this.getContactList();
  }

  gotSelectedcustomerSurveyConfig(val: any) {
    this.selectValuecustomerSurveyConfig(val);
    this.displaycustomerSurveyConfigTable = false
  }
  closecustomerSurveyConfigView() {
    this.displaycustomerSurveyConfigTable = false
  }
  showcustomerSurveyConfigTable() {
    this.displaycustomerSurveyConfigTable = !this.displaycustomerSurveyConfigTable;
  }
  selectValuecustomerSurveyConfig(value: any) {
    console.log(value, "====", this.customerSurveyConfigName)
    this.curveyLinkData.customerSurveyConfig = { id: value.id };
    this.isSend =this.customerSurveyDetails?.customerSurveyConfig?.id == value?.id;
    this.customerSurveyConfigName = value;
    this.customerSurveyConfigs = [];
    console.log(value, "====", this.customerSurveyConfigName)
  }
  clearcustomerSurveyConfigValue() {
    this.curveyLinkData.customerSurveyConfig = { id: null };
    this.customerSurveyConfigName = null;
    this.customerSurveyConfigs = [];
    this.isSend = false;
  }
  onvalidcustomerSurveyConfig() {
    if (this.curveyLinkData.customerSurveyConfig.id == null) {
      this.customerSurveyConfigName = null;
    }
  }
  getcustomerSurveyConfigIdsFirstWay(event: any) {
    console.log(event, "====", this.customerSurveyConfigName)
    var query = event.query

    if (query && query.length > 2) {
      var serverRequest: any = {
        startRow: 0,
        pageSize: 25,
        filterModel: null,
        sortModel: [{ colId: "id", sort: "asc" }]
      }
      this.customerSurveyService.getAllCS(query, serverRequest).subscribe((response: any) => {
        this.customerSurveyConfigs = response.body.data;
        console.log(response)
      })

    }
  }

  /******************************************** Get Contacts List ******************************************/
  getContactList() {
    if (this.data.jobOrder?.customer?.id)
      this.assetService.getCustodians(this.data.jobOrder?.customer?.id)
        .subscribe((res: any) => {
          if (res.ok) {
            this.contacts = res.body;
            const usr = this.contacts.map((user: any) => {
              return {
                id: user.id,
                name: user.fullName,
                label: user.fullName,
                email: user.email,
              };
            }).filter(c => c.email != null && c.email != '');
            this.contacts = usr;
            this.isContactsDisabled = this.contacts.length < 2;
            if (this.curveyLinkData.toType == 'CUSTOMER_CONTACT') {
              let c = this.contacts.filter(c => c.email == this.curveyLinkData.sentTOEmails);
              if (c.length > 0) { this.toContact = { id: c[0].id }; this.curveyLinkData.sentTOEmails = c[0].email; }
              else if (this.contacts.length > 0) {
                this.toContact = { id: this.contacts[0].id }; this.curveyLinkData.sentTOEmails = this.contacts[0].email;
              }
              // this.contacts.forEach(c => {
              //   if (c.email == this.curveyLinkData.sentTOEmails) this.toContact = { id: c.id }
              // });
            }
          }
        });
  }

  onchangeContacts(ev: any) {
    // console.log(this.calendlyLinkData.toEmails)
    if (ev.value) {
      let c = this.contacts.filter(c => c.email == ev.value);
      if (c.length > 0) { this.toContact = { id: c[0].id }; this.curveyLinkData.sentTOEmails = c[0].email; }
      else if (this.contacts.length > 0) {
        this.toContact = { id: this.contacts[0].id }; this.curveyLinkData.sentTOEmails = this.contacts[0].email;
      }
      // this.contacts.forEach(c => {
      //   if (c.email == this.curveyLinkData.sentTOEmails) this.toContact = { id: c.id }
      // });
    } else { this.toContact = null; this.curveyLinkData.sentTOEmails = null }
    this.cd.detectChanges();
  }


  // end (customerSurveyConfig search component)
  customerSurveyGenarate() {
    this.curveyLinkForm.controls["customerSurveyConfig"].setValidators([Validators.required]);
    this.curveyLinkForm.controls["customerSurveyConfig"].updateValueAndValidity();
    this.curveyLinkForm.controls["sentTOEmails"].clearValidators();
    this.curveyLinkForm.controls["sentTOEmails"].updateValueAndValidity();
    this.submitTouched = true;
    this.isSubmited = true;
    if (!this.curveyLinkForm.valid) {
      for (var i in this.curveyLinkForm.controls) {
        this.curveyLinkForm.controls[i].markAsTouched();
      }
      this.submitTouched = false;
      return;
    }
    this.curveyLinkData.toContact = this.curveyLinkData.toType == 'CUSTOMER_CONTACT' && this.toContact.id ? this.toContact : null;
    let {customerSurveyConfig, jobOrder, toContact, toType, sentTOEmails} = this.curveyLinkData;
    let payLoad = {customerSurveyConfig, jobOrder};
    let $this = this;
    this.spinner.show();
    this.customerSurveyService.generateSurveyLink(payLoad).subscribe((res: any) => {
      this.spinner.hide();
      this.submitTouched = false;
      this.isSubmited = false;
      if (res.status == 200 || res.status == 201) {
        this.errorUtil.setErrorMessage(200, this.translate?.general.messages.savedSuccessfully, null, 'success', 1000);
        $this.customerSurveyDetails = {...$this.customerSurveyDetails, ...res.body, customerSurveyConfig: $this.customerSurveyConfigName,  toContact, toType, sentTOEmails };
        this.reset();
      } else {
        this.errorUtil.setErrorMessage(res.status, null, res.error.title, 'error', 3000);
      }
    }, err => {
      this.spinner.hide();
      this.submitTouched = false;
      this.isSubmited = false;
      this.errorUtil.setErrorMessage(err.status, null, err.error.title, 'error', 3000);
    });

    this.submitTouched = false;
    this.isSubmited = false;
  }

  reset(){
    this.curveyLinkData.customerSurveyConfig.id = this.customerSurveyDetails ? this.customerSurveyDetails.customerSurveyConfig.id : null
    this.isSend = (this.customerSurveyDetails?.link != null && this.customerSurveyDetails?.link != '') && !this.customerSurveyDetails?.submitted;
    if (this.isSend) {
      this.curveyLinkForm.controls["customerSurveyConfig"].clearValidators();
      this.curveyLinkForm.controls["customerSurveyConfig"].updateValueAndValidity();
    } else {
      this.curveyLinkForm.controls["sentTOEmails"].clearValidators();
      this.curveyLinkForm.controls["sentTOEmails"].updateValueAndValidity();
    }
    this.sendTo = this.curveyLinkData.toType == 'CUSTOMER_CONTACT' ? 'cust' : this.curveyLinkData.toType == 'ORDER_CONTACT' ? 'order' : 'email';
    this.custCont = this.curveyLinkData.toType == 'CUSTOMER_CONTACT';
    this.getContactList();
  }

  onSubmitForm() {
    this.curveyLinkForm.controls["customerSurveyConfig"].clearValidators();
    this.curveyLinkForm.controls["customerSurveyConfig"].updateValueAndValidity();
    this.curveyLinkForm.controls['sentTOEmails'].setValidators([Validators.required]);
    this.curveyLinkForm.controls['sentTOEmails'].updateValueAndValidity();
    if (this.curveyLinkData.toType == 'CUSTOM') {
      if (this.curveyLinkData.sentTOEmails && this.isInValidEmails()) this.curveyLinkForm.controls['sentTOEmails'].setErrors({ pattern: 'invalid_email_format' });
    } else {
      this.curveyLinkForm.controls['sentTOEmails'].setValidators([Validators.required, Validators.pattern(AppComponent.emailPattern)]);
      this.curveyLinkForm.controls['sentTOEmails'].updateValueAndValidity();
    }
    this.submitTouched = true;
    this.isSubmited = true;
    if (!this.curveyLinkForm.valid) {
      for (var i in this.curveyLinkForm.controls) {
        this.curveyLinkForm.controls[i].markAsTouched();
      }
      this.submitTouched = false;
      return;
    }
    delete this.curveyLinkData.customerSurveyConfig;
    delete this.curveyLinkData.jobOrder;
    this.curveyLinkData.id = this.customerSurveyDetails.id;
    this.curveyLinkData.toType = this.sendTo == 'cust' ? 'CUSTOMER_CONTACT' : this.sendTo == 'order' ? 'ORDER_CONTACT' : 'CUSTOM';
    this.curveyLinkData.toContact = this.curveyLinkData.toType == 'CUSTOMER_CONTACT' && this.toContact.id ? this.toContact : null;
    this.spinner.show();
    this.customerSurveyService.sendSurveyLink(this.curveyLinkData).subscribe((res: any) => {
      this.spinner.hide();
      this.submitTouched = false;
      this.isSubmited = false;
      if (res.status == 200 || res.status == 201) {
        this.errorUtil.setErrorMessage(200, this.translate?.general.messages.sentSuccessfully, null, 'success', 1000);
        this.modalRef.hide()
      } else {
        this.errorUtil.setErrorMessage(res.status, null, res.error.title, 'error', 3000);
      }
    }, err => {
      this.spinner.hide();
      this.submitTouched = false;
      this.isSubmited = false;
      this.errorUtil.setErrorMessage(err.status, null, err.error.title, 'error', 3000);
    });
  }

  sendEmailTo(sendTo: any) {
    this.sendTo = sendTo;
    this.custCont = false;
    if (sendTo == 'order') {
      this.curveyLinkData.sentTOEmails = this.data.jobOrder.contactPersonEmail
      this.curveyLinkData.toType = 'ORDER_CONTACT';
    } else if (sendTo == 'cust') {
      this.curveyLinkData.sentTOEmails = this.data.jobOrder.customer?.email
      this.custCont = true;
      this.curveyLinkData.toType = 'CUSTOMER_CONTACT';
      if (this.curveyLinkData.sentTOEmails && this.curveyLinkData.sentTOEmails != '') {
        let c = this.contacts.filter(c => c.email == this.curveyLinkData.sentTOEmails);
        if (c.length > 0) { this.toContact = { id: c[0].id }; this.curveyLinkData.sentTOEmails = c[0].email; }
        else if (this.contacts.length > 0) {
          this.toContact = { id: this.contacts[0].id }; this.curveyLinkData.sentTOEmails = this.contacts[0].email;
        }
        // this.contacts.forEach(c => {
        //   if (c.email == this.curveyLinkData.sentTOEmails) this.toContact = { id: c.id }
        // });
      } else {
        this.curveyLinkData.sentTOEmails = this.contacts?.length > 0 ? this.contacts[0]?.email : null;
        this.toContact = this.contacts?.length > 0 ? { id: this.contacts[0]?.id } : null;
      }
    } else {
      this.curveyLinkData.sentTOEmails = null;
      this.curveyLinkData.toType = 'CUSTOM';
    }
    this.curveyLinkForm.controls["sentTOEmails"].clearValidators();
    this.curveyLinkForm.controls["sentTOEmails"].updateValueAndValidity();
    // if (this.isSubmited && this.curveyLinkData.toType == 'CUSTOM' && this.isSend) {
    //   this.curveyLinkForm.controls["sentTOEmails"].setValidators([Validators.required]);
    //   this.curveyLinkForm.controls["sentTOEmails"].updateValueAndValidity();
    // } else if (this.isSubmited && this.isSend) {
    //   this.curveyLinkForm.controls["sentTOEmails"].setValidators([Validators.required, Validators.pattern(AppComponent.emailPattern)]);
    //   this.curveyLinkForm.controls["sentTOEmails"].updateValueAndValidity();
    // }
    this.cd.detectChanges();
  }

  /******************************************** Copy URL Link ******************************************/
  public copyURL() {
    this.tooltipText = "Link Copied";
    this.tooltip.activate();
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = this.customerSurveyDetails.link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    console.log("changd to " + this.tooltipText);

    setTimeout(() => {
      this.tooltip.deactivate();
    }, 3000);


    setTimeout(() => {
      this.tooltipText = "Copy Link";
    }, 1500);
  }

  isInValidEmails() {
    let emails = this.curveyLinkData.sentTOEmails.split(',');
    // console.log(emails)
    let invalidEmails = [];

    for (let i = 0; i < emails.length; i++) {
      if (!this.validateEmail(emails[i].trim())) {
        invalidEmails.push(emails[i].trim())
      };
    };
    return invalidEmails.length > 0;
  }

  validateEmail(email: string) {
    let re: any = /^[A-Za-z0-9._%+-]+@[a-z0-9.?-]+[\.]+[a-z]{2,4}$/;// /[a-zA-Z0-9_.+-,;]+@(?:(?:[a-zA-Z0-9-]+\.,;)?[a-zA-Z]+\.,;)?(7-11)\.com/;//
    return re.test(email);
  }


getLanguageData() {
  this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
  console.log("translate",this.translate)
  //this.mainLabel = this.translate?.site.label.plural
}

push(obs:any) {
  super.push(obs);
}

ngOnDestroy(): void {
  super.ngOnDestroy();
}

}
