import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AssetService } from "src/app/_services/asset.service";

import * as _ from "lodash";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

@Component({
  selector: "app-cancel-contract",
  templateUrl: "./cancel-contract.component.html",
  styleUrls: ["./cancel-contract.component.scss"],
})
export class CancelContractComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  @Output() modalEvent = new EventEmitter();

  data: any;
  contractItemsAssets: any = [{ bin: { id: "", name: "" } }];
  contractItemsAccessories: any = [{ bin: { id: "", name: "" } }];
  bins: any;

  public allStatus = [
    {
      value: "ON_RENT",
      label: "on_rent",
    },
    {
      value: "RETURNED",
      label: "returned",
    },
  ];
  translate: any;

  constructor(
    public modalRef: BsModalRef,
    private assetService: AssetService,
    private errorUtil: ErrorUtil,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
    this.getLanguageData()
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    if (this.modalEvent) {
      this.modalEvent.unsubscribe();
    }

    super.ngOnDestroy();
  }

  /******************************************** init ******************************************/
  private init() {
    this.contractItemsAssets = [];
    this.contractItemsAccessories = [];

    if (this.data.contractItems) {
      for (let i = 0; i <= this.data.contractItems.length - 1; i++) {
        if (this.data.contractItems[i].assets) {
          this.data.contractItems[i].status = "RETURNED";
          this.data.contractItems[i].bin = !this.data.contractItems[i].bin
            ? { id: "", name: "" }
            : this.data.contractItems[i].bin;
          this.contractItemsAssets.push(this.data.contractItems[i]);
        } else if (this.data.contractItems[i].accessory) {
          this.data.contractItems[i].status = "RETURNED";
          this.data.contractItems[i].bin = !this.data.contractItems[i].bin
            ? { id: "", name: "" }
            : this.data.contractItems[i].bin;
          this.contractItemsAccessories.push(this.data.contractItems[i]);
        }
      }
    }

    super.push(
      this.assetService.getBins().subscribe((res: any) => {
        const defaultNull = [
          {
            id: null,
            name: "Select a bin",
          },
        ];

        this.bins = [...defaultNull, ...res.body];
      })
    );
  }

  /******************************************** asset bin ******************************************/
  public selectBin(index: any) {
    if (this.contractItemsAssets[index].bin.id) {
      this.contractItemsAssets[index].updateInventory = true;
    }
  }

  /******************************************** accessories bin ******************************************/
  public selectBin1(index: any) {
    if (this.contractItemsAccessories[index].bin.id) {
      this.contractItemsAccessories[index].updateInventory = true;
    }
  }

  /******************************************** on submit ******************************************/
  public onSubmit() {
    let formData;
    formData = JSON.parse(JSON.stringify(this.data));
    formData.contractItems = JSON.parse(
      JSON.stringify(this.contractItemsAccessories)
    );

    for (let i = 0; i <= this.contractItemsAssets.length - 1; i++) {
      formData.contractItems.push(this.contractItemsAssets[i]);
    }

    if (formData.contractItems.length == 1) {
      if (
        formData.contractItems[0].assets &&
        !formData.contractItems[0].assets.id
      ) {
        formData.contractItems = null;
      } else if (
        formData.contractItems[0].accessory &&
        !formData.contractItems[0].accessory.id
      ) {
        formData.contractItems = null;
      }
    }

    if (formData.contractItems) {
      for (let i = 0; i <= formData.contractItems.length - 1; i++) {
        if (
          formData.contractItems[i].assets &&
          !formData.contractItems[i].assets.id
        ) {
          formData.contractItems[i].assets = null;
        }

        if (
          formData.contractItems[i].accessory &&
          !formData.contractItems[i].accessory.id
        ) {
          formData.contractItems[i].accessory = null;
        }

        if (
          formData.contractItems[i].bin &&
          formData.contractItems[i].bin.id === null
        ) {
          formData.contractItems[i].bin = null;
        }
      }
    }

    super.push(
      this.assetService
        .cancelContract(formData.contractItems, this.data.id)
        .subscribe((res: any) => {
          if (res.status === 200 || res.status === 201) {
            this.modalEvent.next({
              message: "success",
            });

            this.modalRef.hide();
            this.errorUtil.setErrorMessage(
              200,
              this.translate?.general.messages.savedSuccessfully,
              null,
              "success",
              2000
            );
          } else {
            this.errorUtil.setErrorMessage(
              null,
              res.error.title,
              null,
              "error",
              2000
            );
          }
        })
    );
  }

  /******************************************** return status ******************************************/
  public returnStatus(status: any) {
    let theStatusLabel = "";
    const label = this.allStatus.find(
      (theStatus) => theStatus.value === status
    )?.label;

    if (label) {
      theStatusLabel = label;
    }

    return theStatusLabel;
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    console.log("translate",this.translate)
    //this.mainLabel = this.translate?.accessories.label.plural
  }

  push(obs:any) {
    super.push(obs);
  }

}
