import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { GridOptions, IServerSideGetRowsParams } from "ag-grid-community";
import { NgxSpinnerService } from "ngx-spinner";
import { PermissionService } from "src/app/auth/permission.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { CustomersService } from "src/app/_services/customers.service";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { SalesOrdersService } from "src/app/_services/sales-orders.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";

var salesOrderStatus: any[] = [];

import * as moment from "moment-timezone";
import { PERSISTANT_ITEM } from "src/app/_models/global.data.model";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { SettingService } from "src/app/_services/admin/setting.service";
import { DynamicTableColumnsService } from "src/app/_services/dynamic-table-column.service";
import { Global } from "src/app/_utilities/global";
import { DynamicFormService } from "src/app/_services/dynamic-form.service";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
var dateFormateChange: any;
@Component({
  selector: "app-sales-orders-table",
  templateUrl: "./sales-orders-table.component.html",
  styleUrls: ["./sales-orders-table.component.scss"],
})
export class SalesOrdersTableComponent
  extends SubscriptionUtil
  implements OnInit, OnChanges, OnDestroy
{
  @Output() noDataEvent = new EventEmitter();
  @Input() data: any;
  @ViewChild('myCalendar', { static: false }) 
  private myCalendar: any;

  public filterToggle = false;

  noSalesOrders: boolean = false;

  // selectedRange: any;
  dateConfig: any;
  dateRange!: any;
  filter: any = { status: "", customerId: null, fromDate: "", toDate: "" };
  customers: any;
  dateFormat: any;
  remove!: boolean;
  update!: boolean;
  create!: boolean;
  salesOrders: any[] = [];
  viewCust!: boolean;
  allowViewSalesorderPrice!: boolean;
  //aggrid
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any[] = [];
  defaultColDef: any;
  frameworkComponents: any;
  @Input() searchValue = "";
  gridParams: any;
  startFrom: any = 0;
  salesOrder: any;
  parameters: any;
  pageSize: any = 25;
  public gridOptions!: GridOptions;
  overlayNoRowsTemplate: any;
  statuses: any;
  currency: any;

  public totalRecord: any;

  public addonFeature: any;

  public showingTable = {
    customer: false,
  };

  selectedCustomer: any;

  private filterAPILoaded = false;

  public loadTable: any = 0;

  deleteHasPushed = false
  sortBy: any;
  sortOrder: any;
  actionData:any;
  @Input() translate: any;
  tableContent: any;

  constructor(
    private salesOrderService: SalesOrdersService,
    private customerService: CustomersService,
    private router: Router,
    private perm: PermissionService,
    private spinner: NgxSpinnerService,
    private deleteRecordService: DeleteRecordService,
    private util: UtilServiceService,
    private auth: AuthenticationService,
    private settingService: SettingService,
    private dynamicTableColumnsService: DynamicTableColumnsService,
    private dynamicFormService: DynamicFormService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
     this.preInit();
  }

  ngOnInit() {
      
    this.init();
  }
 /******************************************** Pre Init ******************************************/
 private getTableColumn() {
   
  this.push(
    this.dynamicFormService.getTableColumn("sales_order")
      .subscribe((res: any) => {
        this.tableContent = res
        this.sortBy = res.sortBy
        this.sortOrder = res.sortOrder
       
          var columnDefs: any = this.gridOptions.columnDefs;
          res.tableColumnSetups.forEach((element: any) => {
            this.cellRenderer(element);
  
            //console.log(element)
            columnDefs.push(element);
            this.gridOptions.api!.setColumnDefs(columnDefs);
          });
          //this.configureColumns(res.tableColumnSetups);
          this.overlayNoRowsTemplate ="<div class='not-found'><span>"+this.translate?.general.messages.noRecordsFound+"</span> </div>";
          this.actionData.headerName = this.translate?.general.buttons.action.singular;
          this.columnDefs.push(this.actionData);
      
          //}
          this.gridOptions.api!.setColumnDefs(columnDefs);
          this.loadTable = 1;
          this.onGridReady(this.gridParams);
      })
  );

}

  /********************************** Render Cell *****************************/
  private cellRenderer(element: any) {
    console.log(element,"===",element.dataType)
    
    var dateFormat: any = localStorage.getItem("date_format");

    if(element.translatePath){
      element.headerName = this.languageTranslateService.getNestedValue(this.translate, element.translatePath);
     } 
    element.headerTooltip = "#";
    

    if (element.field === "salesOrderNumber") {
      element.headerComponentParams =  Global.setHeaderComponentParams(element,'Tbl_HeadSaleNo'),
      element.cellRenderer = function (params: any) {
        
        if(params.data){
          
          return  Global.setCellRenderer(params,params.data.rowId, params.data.salesOrderNumber)
        }
      };
    }else if (element.field === "orderAmount") {
      element.valueFormatter = this.currencyFormatter.bind(this)
      //   },
    }else if (element.field === "customer?.name") {
      element.headerComponentParams =  Global.setHeaderComponentParams(element,'Tbl_HeadSaleCustName'),
      element.cellRenderer = function (params: any) {
        if(params.data){
          return  Global.setCellRenderer(params,params.data.rowId2, params.data.customer?.name)
        }
      };
    }else if (element.field == "status") {
      
      element.cellRenderer = function (params: any) {
        
              if (params.data) {
                if (params.data.status) {
                  var soData = null;
                  console.log(salesOrderStatus)
                  
                  for (var i = 0; i < salesOrderStatus.length; i++) {
                    if (params.data.status == salesOrderStatus[i].name) {
                      soData = salesOrderStatus[i];
                    }
                  }
    
                  let status = "";
    
                  if (params.data && soData) {
                    status =
                      ' <span class="p-mr-2  p-tag p-component" style="background-color:' +
                      soData.colorCode +
                      "50 !important ;color:" +
                      soData.colorCode +
                      '"><span class="p-tag-value text-dark">' +
                      soData.fieldLabel +
                      "</span></span>";
                  }
    
                  return status;
                }
              }
            
      };
    }else if (element.field === "warranty") {
      element.cellRenderer = function (params: any) {
        if(params.data){
          return params.data.warranty ? params.data.warranty + " month(s)" : "0 month(s)";
        }
      };  
    }else if (element.dataType == 'date' || element.dataType == 'datetime' || element.dataType == "text" || element.dataType == 'boolean') {
      element = Global.setColumnByDataType(element,this.auth.getUserTimezone(),dateFormat)
     }
     else if (element.field === "totalPrice") {
      element.valueFormatter = this.currencyFormatter.bind(this)
      //   },
    }
  
      
     
  }

  ngAfterViewInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.searchValue &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue) {
      this.refresh();
    }

    if (
      changes &&
      changes.searchValue &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue &&
      changes.searchValue.currentValue.length === 0
    ) {
      this.refresh();
    }
     /************************** Check translate object changed or not:  Language Translated Dynamically (Preeti) ************************************/
     if (
      changes &&
      changes.translate && changes.translate.previousValue != undefined && Object.keys(changes.translate.previousValue).length !== 0 &&
      changes.translate.previousValue !== changes.translate.currentValue
    ) {
      debugger
     this.setOnlyColumn();
    }
  }
/************************** Set Table Header:  Language Translated Dynamically (Preeti) ************************************/
private setOnlyColumn() {
  // Reset columnDefs to avoid duplication
  this.gridOptions.columnDefs = [];
      console.log(this.tableContent);
         var columnDefs: any = this.gridOptions.columnDefs;
         this.tableContent.tableColumnSetups.forEach((element: any) => {
           this.cellRenderer(element);
            columnDefs.push(element);
           this.gridOptions.api!.setColumnDefs(columnDefs);
         });
         this.actionData.headerName = this.translate?.general.buttons.action.singular;
         columnDefs.push(this.actionData);
         this.gridOptions.api!.setColumnDefs(columnDefs);
         setTimeout(() => { this.gridApi.sizeColumnsToFit(); },500);
}

  ngOnDestroy() {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.push(
      this.salesOrderService
        .getSalesOrderStatuses(true)
        .subscribe((res: any) => {
          this.statuses = res.sort((elementA: any, elementB: any) => {
            if (elementA?.order > elementB?.order) {
              return 1;
            }
  
            if (elementA?.order < elementB?.order) {
              return -1;
            }
  
            return 0;
          });
  

          res.forEach((element: any) => {
            salesOrderStatus.push(element);
          });
        })
    );

    this.settingService.getOrgSettings(1).subscribe((res: any) => {
      this.currency = res.currency;
    })
    
    


    this.allowViewSalesorderPrice = this.perm.capable(
      "allow view sales order price",
      "create"
    );

    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.addonFeature = this.auth.getAddonFeatureLists();

    this.gridOptions = {
      cacheBlockSize: 25,
      paginationPageSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.actionData = {
          headerName: this.translate?.general.buttons.action.singular,
          filter: false,
          lockPinned: true,
          width: 150,
          headerTooltip: "#",
          headerClass: "marging-auto hide-action-border",
          cellStyle: { "text-align": "center" },
          cellRenderer: "buttonRenderer",
          cellRendererParams: {
            onClick: this.edit.bind(this),
            permissions: {
              update: this.perm.capable("sales order", "update"),
              remove: this.perm.capable("sales order", "remove"),
            },
            hidden: {
              update: !this.perm.capable("sales order", "update"),
              remove: !this.perm.capable("sales order", "remove"),
            },
          },
        }

    this.defaultColDef = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.overlayNoRowsTemplate =
      '<span style="padding: 10px; font-size: 14px;">Record not found</span>';

      
  }

  /******************************************** Init ******************************************/
  private init() {
    this.dateConfig = {
      rangeInputFormat: localStorage.getItem("date_format")!.toUpperCase(),
    };

    if (this.data?.customerId) {
      const index = this.columnDefs.findIndex(
        (cd) =>
          cd.field === "customer.name" && cd.headerName === "Customer Name"
      );

      if (index !== -1) {
        this.columnDefs.splice(index, 1);
      }
    }

    this.dateFormat = localStorage.getItem("date_format")!;
    this.create = this.perm.capable("sales order", "create");
    this.update = this.perm.capable("sales order", "update");
    this.remove = this.perm.capable("sales order", "remove");
    this.viewCust = this.perm.capable("customers", "read");
    // this.push(
    //   this.customerService.getAllV2Picklist().subscribe((res: any) => {
    //     this.customers = res;
    //   })
    // );
    this.getTableColumn()
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** convert currency ******************************************/
  private currencyFormatter(params: any) {
    if (params && params.data) {
      var usdFormate = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      // console.log("qwerty",params.data.totalPrice," ==", this.currency, "==",usdFormate)
      return `${this.currency}${usdFormate.format(params.data.totalPrice)}`;
    }
  }

  /******************************************** edit/delete ******************************************/
  private async edit(e: any) {
    if (e.action === "edit") {
      let data = null;

      for (let i = 0; i < this.salesOrders.length; i++) {
        if (this.salesOrders[i].id === e.rowData.id) {
          data = this.salesOrders[i];
          break;
        }
      }

      if (data) {
        this.util.setPersistantItem(PERSISTANT_ITEM.SALES_ORDER, data);

        this.router.navigate(["/dashboard/salesOrders/update"]);
      }
    } else if (e.action === "delete") {
      const item = {
        deleteHeader : this.translate.salesOrder.label.singular,
        deleteType: "Sales Order",
        id: e.rowData.id,
        message:  this.translate?.general.messages.confirmDelete+" "+ ` ${
          e.rowData.salesOrderNumber
        } ${e.rowData.orderName ? "-" : ""} ${
          e.rowData.orderName ? e.rowData.orderName : ""
        }?`,
      };

      if (!this.deleteHasPushed) {
        this.push(
          this.deleteRecordService.getItems(item).subscribe((data) => {
            if (data.status === 200) {
              this.refresh();
            }
          })
        );

        this.deleteHasPushed = true;
      } else {
        this.deleteRecordService.getItems(item);
      }
    }
  }

  /******************************************** on search ******************************************/
  public quickSearch() {
    if (this.searchValue.length > 2) {
      this.refresh();
    }

    if (this.searchValue.length === 0) {
      this.refresh();
    }
  }

  /******************************************** on grid table ready ******************************************/
  public onGridReady(params: any) {
    if (params) {
      this.gridParams = params;
      this.searchValue = this.searchValue ? this.searchValue : "";
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      var dataSource = {
        getRows: (params: any) => {
          if (this.loadTable === 1) {
            if (!this.data) {
              this.getAllSalesOrders(params);
            } else {
              this.getAllSalesOrdersByCustomer(params);
            }
          }
        },
      };

      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
        this.gridApi.setDatasource(dataSource);
      }
    }
  }

  /******************************************** Get All Sales Order ******************************************/
  private getAllSalesOrders(params: any) {
    this.startFrom = params.startRow ?? 0;

    if (params.sortModel.length <= 0) {
      this.parameters ={ colId: this.sortBy, sort: this.sortOrder }
      params.sortModel.push(this.parameters);
    }

    let fullParams = {
      startRow: this.startFrom,
      search: this.searchValue,
      perPage: 25,
      columnName: params.sortModel[0].colId,
      sortOrder: params.sortModel[0].sort,
    };

    var count: any=fullParams.startRow;
    


    if (this.filter.fromDate !== "" && this.filter.toDate !== "") {
      const dateParams = {
        fromDate: this.filter.fromDate ?? "",
        toDate: this.filter.toDate ?? "",
      };

      fullParams = Object.assign(fullParams, dateParams);
    }
    
    this.push(
      this.salesOrderService.getAllSalesOrders(fullParams).subscribe((data) => {
        if(data){
          data.data.forEach((element: any) => {
            count++
            element.rowId='Tbl_RecSaleNo'+count
            element.rowId2='Tbl_RecSaleCustName'+count
            element.buttonEdit='Btn_SaleEdit'+count
            element.buttonDelete='Btn_SaleDel'+count
          });
        }
        this.setGridData(params, data.data, data.total, data.status);
      })
    );
  }

  /******************************************** Get All Sales Order By Customer ******************************************/
  private getAllSalesOrdersByCustomer(params: any) {
    this.startFrom = params.startRow ?? 0;

    if (params.sortModel.length <= 0) {
      this.parameters = { colId: "salesOrderNumber", sort: "asc" };
      params.sortModel.push(this.parameters);
    }

    let customerId = null;

    if (this.data && this.data.customerId) {
      customerId = this.data.customerId;
    }

    this.push(
      this.salesOrderService
        .getSalesOrderByCustomerV2(this.searchValue, params, customerId)
        .subscribe((data) => {
          this.setGridDataSalesOrderCustomer(
            params,
            data.body.data,
            data.body.total,
            data.body.status
          );         
        })
    );
  }

  /******************************************** set grid data ******************************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    this.gridApi.hideOverlay();

    this.totalRecord = totalCount;

    if (status === "NO_DATA") {
      this.noSalesOrders = true;
//this.filterToggle = false;
      this.gridApi.showNoRowsOverlay();
      agGridGetRowsParams.successCallback([], totalCount);
    } else {
      this.noSalesOrders = false;

      if (resultItems.length === 0) {
        this.noSalesOrders = false;

        agGridGetRowsParams.successCallback([], totalCount);

        this.gridApi.showNoRowsOverlay();
      }

      const finalItems: any[] = [];

      resultItems.forEach((salesOrder) => {
        
        salesOrder.buttonDelete = salesOrder.buttonDelete
        salesOrder.buttonEdit = salesOrder.buttonEdit
        salesOrder.rowId = salesOrder.rowId
        salesOrder.rowId2 = salesOrder.rowId2
        finalItems.push(salesOrder);
      });
      console.log("salesFE", resultItems)
      this.salesOrders = [...this.salesOrders, ...resultItems];

      agGridGetRowsParams.successCallback(finalItems, totalCount);
      this.gridApi.sizeColumnsToFit();
    }
    this.noDataEvent.emit(this.noSalesOrders);
  }

  /******************************************** Set Grid Data For Customer Sales Orders ******************************************/
  private setGridDataSalesOrderCustomer(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    this.gridApi.hideOverlay();

    this.totalRecord = totalCount;

    if (status === "NO_DATA") {
      this.noSalesOrders = true;
      //this.filterToggle = false;
      this.gridApi.showNoRowsOverlay();
    } else {
      this.noSalesOrders = false;

      if (resultItems.length === 0) {
        this.gridApi.showNoRowsOverlay();
      }

      const finalItems: any[] = [];

      resultItems.forEach((salesOrder) => {
        finalItems.push(salesOrder);
      });

      this.salesOrders = [...this.salesOrders, ...resultItems];

      agGridGetRowsParams.successCallback(finalItems, totalCount);
     ///CS-4758  this.gridApi.sizeColumnsToFit();
    }

    this.noDataEvent.emit(this.noSalesOrders);
  }

  /******************************************** on view sales order ******************************************/
  public viewSalesOrder(e: any) {
    this.util.resetTabView();

    if (e.colDef.field === "salesOrderNumber") {
      if(e.data.customer?.id){
      this.router.navigate(["dashboard/salesOrders/view/" + e.data.id], {
        queryParams: {
          customerId: e.data.customer.id,
          salesOrderId: e.data.id,
        },
      });
    }else{
      this.router.navigate(["dashboard/salesOrders/view/" + e.data.id], {
        queryParams: {
          salesOrderId: e.data.id,
        },
      });
    }
    } else if (e.colDef.field === "customer.name" && e.data.customer?.id) {
      this.router.navigate(["dashboard/customers/view/" + e.data.customer.id], {
        queryParams: { id: e.data.customer.id },
      });
    }
  }

  /******************************************** on date change ******************************************/
  public onDateChange(event: any) {
    this.filter.fromDate = moment(this.dateRange[0]).format("YYYY-MM-DD");
    this.filter.toDate = moment(this.dateRange[1]).format("YYYY-MM-DD");
    if (this.dateRange && this.dateRange[0] && this.dateRange[1]) {
      this.myCalendar.hideOverlay();
      this.filterResults();
    }
  }

  /******************************************** filter results ******************************************/
  public filterResults() {
    var dataSource = {
      getRows: (params: any) => {
        this.startFrom = params.startRow;

        if (params.sortModel.length <= 0) {
          this.parameters = { colId: "salesOrderNumber", sort: "asc" };
          params.sortModel.push(this.parameters);
        }

        let customerId = null;

        if (this.data && this.data.customerId) {
          customerId = this.data.customerId;
        }

        if (this.filter.customerId) {
          customerId = this.filter.customerId;
        }

        this.push(
          this.salesOrderService
            .filterSalesOrder(
              this.startFrom,
              25,
              this.filter.fromDate,
              this.filter.toDate,
              this.searchValue,
              this.filter.status,
              customerId,
              params,
              "customer"
            )
            .subscribe((data: any) => {
              this.setGridData(params, data.data, data.total, data.status);
            })
        );
      },
    };

    if (this.gridApi) {
      this.gridApi.setDatasource(dataSource);
    }
  }

  /******************************************** reset search ******************************************/
  public clearAll() {
    this.dateRange = null;
    this.filter.fromDate = "";
    this.filter.toDate = "";
    this.filter = {
      status: "",
      customerId: null,
    };

    if (this.gridParams) {
      this.onGridReady(this.gridParams);
    }
  }

  /******************************************** refresh ******************************************/
  public refresh(): void {
    this.onGridReady(this.gridParams);
  }

  /******************************************** on filter view ******************************************/
  public toggleFilterView() {
    this.filterToggle = !this.filterToggle;

    if (this.filterAPILoaded === false) {
      // APIS
      this.filterAPILoaded = true;
    }
  }

  /******************************************** Right Click Context Menu ******************************************/
  public getContextMenuItems = (params: any) => {
    params.node.data.field = params.column.colId;
    let url = "";
    let data = "";

    let results = [];

    if (params.column.colId == "salesOrderNumber") {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }

    if (params.column.colId == "customer.name") {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }

    return results;
  };

  /******************************************** Redirect To ******************************************/
  private redirectToNewTab(data: any) {
    let url;
    this.util.resetTabView();
    if (data.field === "salesOrderNumber") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(["dashboard/salesOrders/view/" + data.id], {
          queryParams: {
            customerId: data.customer.id,
            salesOrderId: data.id,
          },
        })
      );
    }

    if (data.field === "customer.name") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["dashboard/customers/view/" + data.customer.id],
          {
            queryParams: { id: data.customer.id },
          }
        )
      );
    }

    if (url) {
      url = url.replace("%23", "#");
      this.util.openItemToNewTab(url);
    }
  }

  /******************************************** On Customer Select Value ******************************************/
  public onCustomerSelect(event: any) {
    this.filter.customerId = event.id;
    this.selectedCustomer = event;

    this.filterResults();

    this.closeTable("customer");
  }

  /******************************************** Show Table Dynamic Picklist ******************************************/
  public showTable(what: string) {
    if (what === "customer") {
      this.showingTable.customer = true;
    }
  }

  /******************************************** Close Table Dynamic Picklist ******************************************/
  public closeTable(what: string) {
    if (what === "customer") {
      this.showingTable.customer = false;
    }
  }

  /******************************************** Clear Value Dynamic Picklist ******************************************/
  public clearValue(what: string) {
    if (what === "customer") {
      this.filter.customerId = null;
      this.selectedCustomer = null;
    }

    this.filterResults();
  }

  /******************************************** Get All Customer By Query ******************************************/
  public getCustomer(event: any) {
    const query = event.query.replace(
      /[&\/\\#,+()$~%!.„'":*‚^_¤?<>|@ª{«»§}©®™ ]/g,
      ""
    );

    if (query && query.length > 2) {
      this.push(
        this.customerService
          .getAllCustomerByName(query)
          .subscribe((customers: any) => {
            this.customers = customers;
          })
      );
    }
  }
  /******************************************** Add ALl the Subscription ******************************************/
  push(obs: any) {
    super.push(obs);
  }


 
}
