import { Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { AssetService } from "src/app/_services/asset.service";

import * as _ from "lodash";
import * as moment from "moment-timezone";
import { JobOrdersService } from "src/app/_services/job-orders.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { PERSISTANT_ITEM } from "src/app/_models/global.data.model";
import { Location } from "@angular/common";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { TranslateService } from "@ngx-translate/core";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

@Component({
  selector: "app-add-edit-scheduled-maintenance",
  templateUrl: "./add-edit-scheduled-maintenance.component.html",
})
export class AddEditScheduledMaintenanceComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  public defaultDate = new Date();

  public allStatus : any;

  public allTypes = [
    {
      value: "BREAKDOWN",
      label: "Break down",
    },
    {
      value: "PREVENTIVE",
      label: "Preventive",
    },
    {
      value: "ON_DEMAND",
      label: "On demand",
    },
    {
      value: "OTHERS",
      label: "Others",
    },
  ];

  // public allServiceTypes = [
  //   {
  //     value: "Minor",
  //     label: "Minor",
  //   },
  //   {
  //     value: "Major",
  //     label: "Major",
  //   },
  // ];
  // Commented because of not Using 2024-09-21

  addScheduledMaintenanceForm!: UntypedFormGroup;
  scheduledMaintenance: any = {
    id: null,
    type: "",
    maintenance: { name: "", id: null },
    jobOrder: { name: "", id: null },
    status: "PENDING",
    assets: { name: "", id: null },
    serviceZone: { name: "", id: null },
    serviceType: null,
    jobOrderOwner: { id: null },
    jobOrderType: { id: null },
    preventativeTemplate: {id: null, name:""},
    preventativeFrequency: {id: null, name:""}
  };
  maintenances: any;
  frequencies: any;
  contracts: any;
  mode: any;
  dateConfig: any;
  data: any;
  jobOrders: any;
  assets: any;
  title: any;
  minDate: Date | undefined;
  submitTouched: boolean = false;
  hasAssetsData:boolean = false;
  selectedAsset: any;

  displayAssetTable: any;

  // JO picklist
  displayTable1: boolean = false;
  selectedJobOrder: any;
  jobOrder: any[] = [];
  users: any;

  public addonFeature: any;
  mainLabel :any;
  selectedMaintenance: any;
  translate: any;
  constructor(
    private assetService: AssetService,
    private spinner: NgxSpinnerService,
    private auth: AuthenticationService,
    private router: Router,
    private el: ElementRef,
    private jobOrderService: JobOrdersService,
    private errorUtil: ErrorUtil,
    private breadCrumb: BreadcrumbService,
    private route: ActivatedRoute,
    private util: UtilServiceService,
    private location: Location,
    private languageTranslateService: LanguageTranslateService,
) {
  super();
 

    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.onDestory();
  }

  /******************************************** pre init ******************************************/
  private preInit() {
    this.addScheduledMaintenanceForm = new UntypedFormGroup({
      assetName: new UntypedFormControl(null, [Validators.required]),
      maintenanceDate: new UntypedFormControl(null, [Validators.required]),
      type: new UntypedFormControl(null, [Validators.required]),
      status: new UntypedFormControl(null, [Validators.required]),
      user: new UntypedFormControl(null),
    });

    this.addonFeature = this.auth.getAddonFeatureLists();
  }

  /******************************************** init ******************************************/
  private init() {
    if (this.route.snapshot.data["mode"]) {
      this.mode = this.route.snapshot.data["mode"];
      this.addonFeature = this.auth.getAddonFeatureLists();
      this.getLanguageData()
      // if(this.addonFeature.addonMS == 'MACHINE_SPECIALIST'){
      //   this.mainLabel = 'Machine'
      // }
      // if (this.mode === "add") {
      //   this.breadCrumb.setItems([
      //     { label:this.mainLabel, routerLink: ["/dashboard/assets"] },
      //     {
      //       label: "Preventative Maintenace",
      //       routerLink: ["/dashboard/preventative-maintenance"],
      //     },
      //     { label: "New Preventative Maintenace" },
      //   ]);
      // } else {
      //   this.breadCrumb.setItems([
      //     { label: this.mainLabel, routerLink: ["/dashboard/assets"] },
      //     {
      //       label: "Preventative Maintenace",
      //       routerLink: ["/dashboard/preventative-maintenance"],
      //     },
      //     { label: "Edit Preventative Maintenace" },
      //   ]);
      // }


  
    }

    if (this.addonFeature?.addonDYNAMICMAINTENANCE) {
      this.jobOrderService.getJobOrderTypes(true).subscribe((resp) => {
        this.allTypes = resp.sort((elementA: any, elementB: any) => {
          if (elementA?.order > elementB?.order) {
            return 1;
          }

          if (elementA?.order < elementB?.order) {
            return -1;
          }

          return 0;
        });
      });
    }

    const data = this.util.getPersistantItem(
      PERSISTANT_ITEM.SCHEDULE_MAINTENANCE
    );

    if (data) {
      this.data = data;
    }

    this.dateConfig = {
      dateInputFormat: localStorage.getItem("date_format")!.toUpperCase(),
      rangeInputFormat: localStorage.getItem("date_format")!.toUpperCase(),
    };

    this.push(
      this.assetService.getActiveUsers().subscribe((res) => {
        this.users = res;
      })
    );

    this.push(
      this.assetService.getDataByAsset("").subscribe((res: any) => {
        this.assets = res.body;
      })
    );
    if (this.data && this.data.assetId) {
      this.push(
        this.assetService
          .getMaintenanceByAssetIdPicklist(this.data.assetId)
          .subscribe((res: any) => {
            this.maintenances = res.body;
            console.log("maintenance by assetId", this.maintenances)
            if (this.mode == "add") {
              if (this.maintenances && this.data.maintenance) {
                this.scheduledMaintenance.maintenance = this.data?.maintenance
                console.log("have id 1",this.scheduledMaintenance)
              }
              if (this.scheduledMaintenance.maintenance.id) {
                console.log("have id")
                this.getMaintenanceById(this.scheduledMaintenance.maintenance.id);
              }
            }

          })
      );
    }

    if (this.data && this.data.asset) {
      this.selectedAsset = this.data.asset;
    }

    if (this.mode == "add") {
      ////debugger
      if (this.data) {
        if(this.data.maintenance)
        {
        this.push(
          this.assetService.getMaintenanceFrequencyPicklist(this.data.maintenance.id).subscribe((res: any) => {
            this.frequencies = res.body;
          })
        );
        }
        if(this.data.assetId){
          this.assetService.getAssetsById(this.data.assetId).subscribe((res: any) =>{
            if(res)
            {
              this.scheduledMaintenance.assets.id = res.id
              this.selectedAsset =  res
              this.hasAssetsData = true;
            }
          })
          
        }
        


        this.scheduledMaintenance.serviceZone = this.data.serviceZone
          ? this.data.serviceZone
          : { id: "", name: "" };

        if (this.data.maintenance) {
          this.scheduledMaintenance.preventativeTemplate = this.data.maintenance.preventativeTemplate
            ? this.data.maintenance.preventativeTemplate
            : { id: "", name: "" };
        }
      }
    }

    if (this.mode == "edit") {
      this.push(
        this.assetService
          .getViewScheduledMaintenance(this.data.id)
          .subscribe((res: any) => {

            this.scheduledMaintenance = res.body;
            console.log("resss", this.scheduledMaintenance)
            this.scheduledMaintenance.generateJobOrder = false;

            if (this.scheduledMaintenance.assets) {
              this.selectedAsset = this.scheduledMaintenance.assets;
              this.changeAsset(this.scheduledMaintenance.assets.id);
            }

            if(this.scheduledMaintenance.maintenance){
              this.push(
                this.assetService.getMaintenanceFrequencyPicklist(this.scheduledMaintenance.maintenance.id).subscribe((res: any) => {
                  this.frequencies = res.body;
                })
              );
            }

            if (!this.scheduledMaintenance.maintenance) {
              this.scheduledMaintenance.maintenance = { id: "" };
            }

            if (!this.scheduledMaintenance.jobOrderOwner) {
              this.scheduledMaintenance.jobOrderOwner = { id: null };
            }

            if (!this.scheduledMaintenance.assets) {
              this.scheduledMaintenance.assets = { id: "" };
            }

            if (!this.scheduledMaintenance.serviceZone) {
              this.scheduledMaintenance.serviceZone = { id: "", name: "" };
            }

            ////debugger
            this.scheduledMaintenance.preventativeFrequency = this.scheduledMaintenance?.preventativeMaintenanceFrequency || { id: "", name: "" };
            this.scheduledMaintenance.preventativeTemplate = this.scheduledMaintenance.preventativeMaintenanceFrequency?.preventativeMaintenanceTemplate || { id: "", name: "" };

            if (!this.scheduledMaintenance.jobOrder) {
              this.scheduledMaintenance.jobOrder = { id: "" };
            } else {
              this.selectedJobOrder = this.scheduledMaintenance.jobOrder;
            }

            if (this.scheduledMaintenance.scheduledDateTime) {
              this.scheduledMaintenance.scheduledDateTime = moment(
                this.scheduledMaintenance.scheduledDateTime
              ).toDate();
            }

            if (this.scheduledMaintenance.actualCompletionDateTime) {
              this.scheduledMaintenance.actualCompletionDateTime = moment(
                this.scheduledMaintenance.actualCompletionDateTime
              ).toDate();
            }

            if (!this.scheduledMaintenance.jobOrderType) {
              this.scheduledMaintenance.jobOrderType = { id: null };
            }
          })
      );
    }
  }

  /******************************************** On Destroy ******************************************/
  private onDestory() {
    this.breadCrumb.setItems([]);

    super.ngOnDestroy();

    this.util.clearPersistantItem(PERSISTANT_ITEM.SCHEDULE_MAINTENANCE);
  }

  /******************************************** change asset ******************************************/
  private changeAsset(assetId: any) {
    this.push(
      this.assetService
        .getMaintenanceByAssetIdPicklist(assetId)
        .subscribe((res: any) => {
          this.maintenances = res.body;
        })
    );

    this.push(
      this.assetService.getEditObj(assetId).subscribe((res: any) => {
        if (res.body.serviceZone) {
          this.scheduledMaintenance.serviceZone.name =
            res.body.serviceZone.name;
          this.scheduledMaintenance.serviceZone.id = res.body.serviceZone.id;
        } else {
          this.scheduledMaintenance.serviceZone = { id: "", name: "" };
        }
      })
    );
  }

  /******************************************** change maintenance ******************************************/
  public changeMaintenance(id: any) {
   
    if(id) {
      this.push(
        this.assetService.getMaintenanceByAssets(id).subscribe((res: any) => {
          this.scheduledMaintenance.assets.id = res.body.id;
        })
      );
      this.push(
        this.assetService.getMaintenanceFrequencyPicklist(id).subscribe((res: any) => {
          this.frequencies = res.body;
        })
      );
      this.getMaintenanceById(id);
    } else {
      if(this.addonFeature?.addonDYNAMICMAINTENANCE)
      {
        if(this.mode == "add")
        {
          this.scheduledMaintenance.preventativeTemplate.name = null;
          this.scheduledMaintenance.preventativeFrequency.id = null;
          this.frequencies = null
        }
        if(this.mode == "edit")
        {
          this.scheduledMaintenance.preventativeTemplate.name = null;
          this.frequencies = null;
          this.scheduledMaintenance.preventativeFrequency.id = null

        }

      }
      // this.push(
      //   this.assetService.getMaintenanceByAssets(this.data.id).subscribe((res: any) => {
      //     this.scheduledMaintenance.assets.id = res.body.id;
      //   })
      // );
      // this.push(
      //   this.assetService.getMaintenanceFrequencyPicklist(this.data.id).subscribe((res: any) => {
      //     this.frequencies = res.body;
      //   })
      // );
    }
  }

   /******************************************** change maintenance ******************************************/
   public changeMaintenanceFrequency(id: any) {

   }

  /******************************************** status change ******************************************/
  public statusChange() {
    if (this.scheduledMaintenance.status === "COMPLETED") {
      if (
        this.scheduledMaintenance.scheduledDateTime &&
        this.scheduledMaintenance.scheduledDateTime > new Date()
      ) {
        // this.scheduledMaintenance.actualCompletionDateTime =
        //   this.scheduledMaintenance.scheduledDateTime;
        this.scheduledMaintenance.actualCompletionDateTime = new Date();
      } else {
        this.scheduledMaintenance.actualCompletionDateTime = new Date();
      }
    } else {
      this.scheduledMaintenance.actualCompletionDateTime = "";
    }
  }

  /******************************************** on maintenance date change ******************************************/
  public maintenanceDateChange(date: any) {
    if (date) {
      let completionDate = moment(
        this.scheduledMaintenance.actualCompletionDateTime,
        localStorage.getItem("date_format")?.toUpperCase()
      ).toDate();

      // if (
      //   this.scheduledMaintenance.actualCompletionDateTime &&
      //   completionDate < new Date(date)
      // ) {
      //   this.scheduledMaintenance.actualCompletionDateTime = date;
      // }

      this.scheduledMaintenance.actualCompletionDateTime = new Date();

      this.minDate = date;
    }
  }

  /******************************************** on job order checked ******************************************/
  public jobOrderChecked() {
    if (this.scheduledMaintenance.generateJobOrder) {
      this.addScheduledMaintenanceForm.controls["user"].setValidators([
        Validators.required,
      ]);
      this.addScheduledMaintenanceForm.controls[
        "user"
      ].updateValueAndValidity();
    } else {
      this.addScheduledMaintenanceForm.controls["user"].clearValidators();
      this.addScheduledMaintenanceForm.controls[
        "user"
      ].updateValueAndValidity();
    }
  }

  /******************************************** scroll to invalid form ******************************************/
  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector("form .ng-invalid");
    firstInvalidControl.focus(); //without smooth behavior
  }

  /******************************************** on submit ******************************************/
  public onSubmit() {
    this.submitTouched = true;

    if (!this.addScheduledMaintenanceForm.valid) {
      for (const i in this.addScheduledMaintenanceForm.controls) {
        this.addScheduledMaintenanceForm.controls[i].markAsTouched();
      }

      if (this.scheduledMaintenance.generateJobOrder) {
        this.addScheduledMaintenanceForm.controls["user"].setValidators([
          Validators.required,
        ]);
        this.addScheduledMaintenanceForm.controls[
          "user"
        ].updateValueAndValidity();
      }

      this.scrollToFirstInvalidControl();

      this.errorUtil.setErrorMessage(
        "200",
        null,
        this.translate?.general.messages.fillAllRequiredFields,
        "error",
        2000
      );

      this.submitTouched = false;
      return false;
    }

    // if (
    //   moment(
    //     this.scheduledMaintenance.scheduledDateTime,
    //     localStorage.getItem("date_format")!.toUpperCase()
    //   ).valueOf() >
    //   moment(
    //     this.scheduledMaintenance.actualCompletionDateTime,
    //     localStorage.getItem("date_format")!.toUpperCase()
    //   ).valueOf()
    // ) {
    //   this.errorUtil.setErrorMessage(
    //     "200",
    //     null,
    //     "Maintenance date should not be greater than Actual Completion Date",
    //     "error",
    //     2000
    //   );

    //   this.submitTouched = false;
    //   return false;
    // }

    this.submitTouched = true;
    this.spinner.show();

    var formData = _.cloneDeep(this.scheduledMaintenance);

    if (!this.scheduledMaintenance.jobOrder.id) {
      formData.jobOrder = null;
    }

    if (!this.scheduledMaintenance.assets.id) {
      formData.assets = null;
    }

    if (!this.scheduledMaintenance.maintenance.id) {
      formData.maintenance = null;
    }

    if (!this.scheduledMaintenance.jobOrderOwner.id) {
      formData.jobOrderOwner = null;
    }

    if (!this.scheduledMaintenance.serviceZone.id) {
      formData.serviceZone = null;
    }

    if (
      !this.scheduledMaintenance.jobOrderType ||
      !this.scheduledMaintenance.jobOrderType.id
    ) {
      formData.jobOrderType = null;
    }

    if (this.addonFeature?.addonDYNAMICMAINTENANCE) {
      formData.preventativeMaintenanceFrequency = this.scheduledMaintenance?.preventativeFrequency?.id? this.scheduledMaintenance?.preventativeFrequency : null
    }

    //arfan need to explain how we can pass template while saving 


    if (this.mode == "add") {
      this.push(
        this.assetService
          .createScheduledMaintenance(formData)
          .subscribe((res: any) => {
            if (res.status === 200 || res.status === 201) {
              this.util.resetTabView();

              this.errorUtil.setErrorMessage(
                200,
                this.translate?.general.messages.savedSuccessfully,
                null,
                "success",
                2000
              );

              this.router.navigate(
                [
                  "/dashboard/preventative-maintenance/view/" +
                    res.body.id,
                ],
                {
                  queryParams: { id: res.body.id },
                }
              );
            }  else {

              var errorTitle = res.error.title
              if (res.error.type == 'dynamic-lang-error') {
                var data = res.error.instance + ".validationBE." + res.error.detail;
                var message = this.languageTranslateService.getNestedValue(this.translate, data);
                errorTitle = message
              }
    
              this.errorUtil.setErrorMessage(
                res.status,
                null,
                errorTitle,
                "error",
                2000
              );
            }
            this.spinner.hide();
            this.submitTouched = false;
          })
      );
    } else if (this.mode === "edit") {
      let scheduledDateTime = moment(
        formData.scheduledDateTime,
        localStorage.getItem("date_format")!.toUpperCase()
      );

      formData.scheduledDateTime = moment.tz(
        scheduledDateTime,
        this.auth.getUserTimezone()
      );

      let actualCompletionDateTime = moment(
        formData.actualCompletionDateTime,
        localStorage.getItem("date_format")!.toUpperCase()
      );

      formData.actualCompletionDateTime = moment.tz(
        actualCompletionDateTime,
        this.auth.getUserTimezone()
      );

      this.push(
        this.assetService
          .editScheduledMaintenance(formData)
          .subscribe((res: any) => {
            if (res.status === 200 || res.status === 201) {
              this.util.resetTabView();

              this.errorUtil.setErrorMessage(
                200,
                this.translate?.general.messages.updatedSuccessfully,
                null,
                "success",
                2000
              );
              this.router.navigate(
                [
                  "/dashboard/preventative-maintenance/view/" +
                    res.body.id,
                ],
                {
                  queryParams: { id: res.body.id },
                }
              );
            }  else {

          var errorTitle = res.error.title
          if (res.error.type == 'dynamic-lang-error') {
            var data = res.error.instance + ".validationBE." + res.error.detail;
            var message = this.languageTranslateService.getNestedValue(this.translate, data);
            errorTitle = message
          }

          this.errorUtil.setErrorMessage(
            res.status,
            null,
            errorTitle,
            "error",
            2000
          );
        }
            this.spinner.hide();
          })
      );
    }
  }

  ///////////////////////////////////// start(JO search component) /////////////////////
  /******************************************** on select job order ******************************************/
  public gotSelectedJobOrder(val: any) {
    this.selectValue1(val);
    this.displayTable1 = false;
  }

  /******************************************** close job order view ******************************************/
  public closeJobOrderView() {
    this.displayTable1 = false;
  }

  /******************************************** show job table ******************************************/
  public showJoTable() {
    this.displayTable1 = !this.displayTable1;
  }

  /******************************************** on select job order  ******************************************/
  public selectValue1(value: any) {
    this.scheduledMaintenance.generateJobOrder = false;
    this.scheduledMaintenance.jobOrder.id = value.id;
    this.scheduledMaintenance.jobOrder.name = value.jobOrderName;
    this.selectedJobOrder = value;

    console.log(this.selectedJobOrder);
  }

  /******************************************** select  ******************************************/
  public selectJoNumber(value: any) {
    this.scheduledMaintenance.generateJobOrder = false;
    this.scheduledMaintenance.jobOrder.id = value.id;
    this.scheduledMaintenance.jobOrder.name = value.jobOrderName;
    this.selectedJobOrder = value;

    console.log(this.selectedJobOrder);
  }

  /******************************************** on remove job order ******************************************/
  public clearJoValue() {
    this.scheduledMaintenance.jobOrder.id = null;
    this.selectedJobOrder = null;
    this.jobOrder = [];

    console.log(this.selectedJobOrder);
  }

  /******************************************** get job order by query ******************************************/
  public getJobOrderIdsFirstWay(event: any) {
    const query = event.query;

    this.push(
      this.jobOrderService
        .getAllJobOrders_2(
          1,
          50,
          "",
          "",
          null,
          null,
          null,
          query,
          {
            startFrom: 1,
            perPage: 50,
            sortModel: [{ colId: "jobOrderNumber", sort: "desc" }],
          },
          null,
          null,
          null,null
        )
        .subscribe((data: any) => {
          let rowData1 = [];
          for (let i = 0; i <= data.data.length - 1; i++) {
            rowData1.push(data.data[i].jobOrder);
          }

          this.jobOrders = rowData1;
        })
    );
  }
  // end (JO search component)

  /////////////////////////////// start(asset search component)////////////////////////////
  /******************************************** on select asset ******************************************/
  public gotSelectedAsset(val: any) {
    this.assetService.getAssetsById(val.id).subscribe((res: any) =>{
      if(res)
      {
        this.selectValueAsset(val);
      }
    })
    
    this.displayAssetTable = false;
  }

  /******************************************** close asset table asset ******************************************/
  public closeAssetView() {
    this.displayAssetTable = false;
  }

  /******************************************** Toggle Asset Visibility ******************************************/
  public showTable1() {
    this.displayAssetTable = !this.displayAssetTable;
  }

  /******************************************** On Select Asset ******************************************/
  public selectValueAsset(value: any) {
    this.scheduledMaintenance.assets = value
    this.selectedAsset = value;
    ////debugger
    this.changeAsset(value.id); //code picklist
    this.assets = [];
  }

  /******************************************** Clear Value1 ******************************************/
  public clearValue1() {
    this.scheduledMaintenance.assets.id = null;
    this.selectedAsset = null;

    this.assets = [];
    this.scheduledMaintenance.serviceZone = { name: "", id: "" };
  }

  /******************************************** Get Asset By Query ******************************************/
  public getAssetIdsFirstWay(event: any) {
    const query = event.query;

    this.push(
      this.assetService.getAssetsByName(query).subscribe((data) => {
        this.assets = data.data;
      })
    );
  }
  // end (asset search component)

  /******************************************** Return Value ******************************************/
  public returnValue(what: string, value: string) {
    let label = "";
    if (what === "status") {
      label = this.allStatus.find((status) => status.value === value)!.label;
    }

    if (what === "type") {
      label = this.allTypes.find((type) => type.value === value)!.label;
    }

    if (what === "user") {
      let user = this.users.find((user: any) => user.id === value);

      label = `${user.firstName} ${user.lastName}`;
    }

    return label;
  }

  /******************************************** Cancel ******************************************/
  public cancel() {
    this.location.back();
  }

  push(obs: any) {
    super.push(obs);
  }

  private getMaintenanceById(id: any) {
    this.assetService.getViewMaintenance(id).subscribe((res: any) => {
      if (res.status === 200 || res.status === 201) {
        this.selectedMaintenance = res.body;
        console.log(this.selectedMaintenance);
        if(this.selectedMaintenance && this.selectedMaintenance.preventativeTemplate)
        {
          this.scheduledMaintenance.preventativeTemplate.name = this.selectedMaintenance.preventativeTemplate.name

        }
      }
    });
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        this.breadCrumb.setItems([
          { label: this.translate?.asset.label.plural, routerLink: ["/dashboard/assets"]  },
          { label: this.translate?.preventiveMaintenance.label.plural, routerLink: ["/dashboard/preventative-maintenance"], },
          { label: this.mode == "add" ?  this.translate?.general.buttons.new.singular + " " +this.translate?.preventiveMaintenance.label.singular :  this.translate?.general.buttons.edit.singular + " " +this.translate?.preventiveMaintenance.label.singular },
        ]);
        this.allStatus  = [
          {
            label: this.translate?.general.status.pending.singular,
            value: "PENDING",
          },
          {
            label: this.translate?.general.status.completed.singular,
            value: "COMPLETED",
          },
          {
            label: this.translate?.general.status.overdue.singular,
            value: "OVER_DUE",     
          },
          {
            label: this.translate?.general.status.cancelled.singular,
            value: "CANCELLED",
          }
        ];
      }
    }))
    console.log("translate",this.translate)
    //this.mainLabel = this.translate?.preventiveMaintenance.label.plural
  }

}
