import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { TicketsService } from 'src/app/_services/tickets.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';

@Component({
  selector: 'app-sla-condition',
  templateUrl: './sla-condition.component.html',
  styleUrls: ['./sla-condition.component.scss']
})
export class SlaConditionComponent extends SubscriptionUtil implements OnInit, OnDestroy {

  valueArray: any[] = [];
  valueArrayAny: any[] = [];
  submitTouched: boolean = false;
  mode: any;
  data: any;
  
  selectedAttrs: any[] = [];
  selectedOperators: any[] = [];
  selectedAttr: any = null;
  selectedOper: any = null;
  selectedValue : any = null;
  dateConfig : any;
  filterArray : any[] = [];
  selectedCriteria: any[]=[];
  selectedDate = new Date();
  selectedDate2 = new Date();
  filterAttrs : any[] = [];
  config = {
    displayKey:"stringValue",
    search:true,
  };

  addFilterForm!: UntypedFormGroup;
  option: any[]=[];
   req : any = {
     id:null,
    name:null,
    durationMinutes:null,
    atRiskMinutes: null,
    violationMinutes: null,
    type:null,
    startCriteria: {
        matchAll: null,
        matchAny: null
    },
    pauseCriteria:{
      matchAll: null,
      matchAny: null
  },
    endCriteria: {
      matchAll: null,
      matchAny: null
  }
  }
  displayOperators: any [] = []
  displayOperatorsAny: any [] = []
 
  minDate!: Date;
  minDateAny!: Date;
  
/*====================Match Any */
  selectedOperatorsAny: any[] = [];
  selectedAttrAny: any = null;
  selectedOperAny: any = null;
  selectedValueAny : any = null;
  dateConfigAny : any;
  filterArrayAny : any[] = [];
  selectedCriteriaAny: any[]=[];
  selectedDateAny = new Date();
  selectedDateAny2 = new Date();
  filterAttrsAny : any[] = [];
  configAny = {
    displayKey:"stringValue",
    search:true,
  };

  addAnyFilterForm!: UntypedFormGroup;
  optionAny: any[]=[];
  selectedTime: any;
  selectedTime2: any;
  selectedTimeAny: any;
  selectedTimeAny2: any;
  errorMessage:any;
  errorMessageAny:any;
  timezone: any;
  dateFormat: any;
  addonFeatureINVENTORY: any;
  optionList: any=[];
  optionListAny: any=[];
  dropdownSettings = {};
  filterFinalData : any[] = [];
  translate: any;


  constructor(
    public modalRef: BsModalRef, 
    private util: UtilServiceService,
    private ticketsService:TicketsService,
    private spinner : NgxSpinnerService,
    public auth: AuthenticationService,
    private languageTranslateService: LanguageTranslateService,
    private errorUtil: ErrorUtil)
    { 
      super()
      this.addFilterForm = new UntypedFormGroup({
        'attr': new UntypedFormControl(null),
        'operator': new UntypedFormControl(null),
        'value': new UntypedFormControl(null),
        'value2': new UntypedFormControl(null),
      });
      this.addAnyFilterForm = new UntypedFormGroup({
        'attrAny': new UntypedFormControl(null),
        'operatorAny': new UntypedFormControl(null),
        'valueAny': new UntypedFormControl(null),
        'valueAny2': new UntypedFormControl(null),
      });
      
    }

  ngOnInit() {
    this.getLanguageData()
    this.dropdownSettings = { 
      singleSelection: false, 
      text:"Select Value",
      selectAllText:'Select All',
      unSelectAllText:'Unselect All',
      enableSearchFilter: true,
      classes:"myclass custom-class",
       };    
    $('.select-all input[type="checkbox"]').removeAttr('id');
   
    this.dateFormat = localStorage.getItem('date_format');
    this.timezone = this.auth.getUserTimezone();
    this.addonFeatureINVENTORY = this.auth.getAddonFeatureLists().addonINVENTORY;
    this.minDate = new Date();
    //console.log(this.data)
    this.req.id=this.data.id
    this.req.name=this.data.name
    this.req.durationMinutes=this.data.durationMinutes
    this.req.atRiskMinutes=this.data.atRiskMinutes
    this.req.violationMinutes= this.data.violationMinutes
    this.req.type=this.data.type
    var index=0
    this.ticketsService.getCriteriaDetail(this.data.type).subscribe((res:any) => {
     
      //this.selectedCriteria = res;
      this.selectedCriteria = _.cloneDeep(res);
        var myClonedArray:any = _.cloneDeep(res);
      if(this.addonFeatureINVENTORY != 'INVENTORY'){
        var inde = _.findIndex(myClonedArray, (row:any) => { return row.modelName == 'assets';  });
        myClonedArray.splice(inde, 1);
        }
         console.log(myClonedArray,"=======",this.selectedCriteria)
  
         myClonedArray.forEach((element :any)=> {
          if(element.modelFieldList.length){
            element.valueName= element.modelName.replace('_', ' ');
            element.valueName=element.valueName.charAt(0).toUpperCase() + element.valueName.slice(1).toLowerCase();
            element.value = element.modelName
           
                element.modelFieldList.forEach((modelFieldListElement: any) => {
                 
                  if(element.modelName == 'job_order'){
                    modelFieldListElement.valueName= modelFieldListElement.value.replace("Job order", ' ');
                    }else if(element.modelName == 'customer'){
                      modelFieldListElement.valueName= modelFieldListElement.value.replace("Customer", ' ');
                    }else if(element.modelName == 'assets'){
                      modelFieldListElement.valueName= modelFieldListElement.value.replace("Asset", ' ');
                    }else if(element.modelName == 'ticket'){
                      modelFieldListElement.valueName= modelFieldListElement.value.replace("Ticket", ' ');
                    }
                      

              });
              element.items=element.modelFieldList
              element={...element,modelFieldList:element.modelFieldList}
              this.filterAttrs.push(element); 
              this.filterAttrsAny.push(element);
             console.log(this.filterAttrs,"---->>>>>")
            }
        });

        this.displayCriterias(this.data)
  })
  console.log(this.filterFinalData,"====================================================================",index)
  }
  /*============================MAtch All ======================= */
    resetData(){
      this.selectedValue = null;
      this.selectedOper = null;
      this.valueArray = []
      this.displayOperators = []
      this.selectedAttr = null;
      this.addFilterForm.controls['operator'].clearValidators();
      this.addFilterForm.controls['value'].clearValidators();
      this.addFilterForm.controls['operator'].updateValueAndValidity()
      this.addFilterForm.controls['value'].updateValueAndValidity();
      this.selectedValue = null
      this.selectedDate = new Date();
      this.selectedDate2 =new Date();
      this.selectedTime= moment(this.selectedDate).format('HH:mm')
      this.selectedTime2= moment(this.selectedDate).format('HH:mm')
      console.log("We are clear")
    }
  setAttr(attr: any){
    console.log("Before set reset variabler ",this.selectedDate);
   this.resetData()
   this.resetDataAny()
   this.errorMessage=""
   this.optionList = []
   
    this.selectedAttr = attr.value
    console.log(this.selectedAttr)
    this.selectedOperators = attr.value.eligibleOperatorList;
    this.displayOperator()
    if(this.selectedAttr.type == 'DATE' || this.selectedAttr.type == 'DATE_TIME'){
      
      this.selectedValue = moment(this.selectedDate).format('YYYY/MM/DD');
      
      this.selectedTime = moment(this.selectedDate).format('HH:mm')
      this.selectedTime2 = moment(this.selectedDate).format('HH:mm')
    }
    if(this.selectedAttr.type == 'MULTIPLE_CHOICE'){
   
      console.log(this.selectedAttr.availableOptionList)
      this.selectedAttr.availableOptionList.forEach((element: any) => {
          let temp={ "id": element.id, "itemName": element.stringValue }
          this.optionList.push(temp)
      })
    }
    console.log(this.optionList)
      console.log(this.selectedValue)
    this.addFilterForm.controls['operator'].setValidators([Validators.required]);
    this.addFilterForm.controls['value'].setValidators([Validators.required]);
    this.addFilterForm.controls['operator'].updateValueAndValidity();
    this.addFilterForm.controls['value'].updateValueAndValidity();
  }

  displayOperator(){
      console.log(this.selectedOperators)
      this.displayOperators=[]

      this.selectedOperators.forEach(element => {
       var addOpt;
        console.log(element)

        if(element == 'Contains'){

         addOpt={
            name : "contains",
            value : element
          }
        }else if(element == 'StartsWith'){
          addOpt={
            name : "starts with",
            value : element
          }

        }else if(element == 'EndsWith'){
          addOpt={
            name : "ends with",
            value : element
          }

        }else if(element == 'IS NOT NULL'){
          addOpt={
            name : 'not equal to " "',
            value : element
          }

        }else if(element == 'IS NULL'){
          addOpt={
            name : 'equal to " "',
            value : element
          }
        }else if(element == 'IN'){
          addOpt={
            name : "in",
            value : element
          }
        }else if(element == 'BETWEEN'){
          addOpt={
            name : "between",
            value : element
          }
        }else if(element == '>'){
          addOpt={
            name : "greater than",
            value : element
          }
        }else if(element == '<'){
          addOpt={
            name : "less than",
            value : element
          }
        }else if(element == '>='){
          addOpt={
            name : "greater than or equal to",
            value : element
          }
        }else if(element == '<='){
          addOpt={
            name : "less than or equal to",
            value : element
          }
        }

        this.displayOperators.push(addOpt);

      });
      console.log(this.displayOperators)

  }
 
  setOperator(operator : any){
    console.log(operator)
    this.errorMessage=""
    this.selectedAttr.operator = operator.value;
    if(this.selectedAttr.operator == 'BETWEEN' && this.selectedAttr.type == 'DATE_TIME'){
      this.selectedValue = new Date(this.selectedDate)
    
      this.addFilterForm.controls['value2'].setValidators([Validators.required]);
      this.addFilterForm.controls['value2'].updateValueAndValidity();

    } else if(this.selectedAttr.operator == 'BETWEEN' && this.selectedAttr.type == 'DATE'){
      this.selectedValue =  new Date(this.selectedDate)
      //this.onDateSelect2(this.selectedDate2, this.selectedAttr.type);
      this.addFilterForm.controls['value2'].setValidators([Validators.required]);
      this.addFilterForm.controls['value2'].updateValueAndValidity();
    }else{
      this.addFilterForm.controls['value2'].clearValidators();
      this.addFilterForm.controls['value2'].updateValueAndValidity();
    
    }

    if(this.selectedAttr.operator == 'IS NOT NULL' || this.selectedAttr.operator == 'IS NULL'){
      console.log("Correct")
      this.selectedValue = '';
      this.addFilterForm.controls['value'].clearValidators();
      this.addFilterForm.controls['value'].updateValueAndValidity();
    }else{
      console.log("Sorry")
      this.addFilterForm.controls['value'].setValidators([Validators.required]);
      this.addFilterForm.controls['value'].updateValueAndValidity();
    }

     console.log("Selected attr", this.selectedAttr);
  }
 
  onValidFrom(newDate: any, type: any) {
    this.selectedDate = newDate
    this.selectedValue = moment(newDate).format('YYYY/MM/DD');
      if(this.selectedDate2 !=undefined && newDate>=this.selectedDate2 && this.selectedDate2 !=null){
        this.minDate = newDate
        this.selectedDate2=newDate
        console.log('ETA date should be after collection date');
      }
      
    }

  onValidToDate(newDate: any, type: any) {
    this.selectedDate2=newDate
    console.log("====",this.selectedDate2)
    this.onDateSelect2(newDate, type)
    
  }


  onDateSelect2(event: any, type: any){
    console.log("=====================================================================================")
    var tempVal = this.selectedValue;
    if(tempVal[0].length > 1){
      tempVal.splice(1,1);
    }
    console.log(tempVal)
    this.selectedValue = [];
    if(Array.isArray(tempVal)){
      tempVal.forEach(element => {
        this.selectedValue.push(element);
      });
    } else {
      this.selectedValue.push(tempVal);
    }
   
    this.selectedValue.push(moment(event).format('YYYY/MM/DD')); 
      console.log(this.selectedValue)
  }
  
  addNewFilter(){
    if(!this.addFilterForm.valid){
      for (var i in this.addFilterForm.controls) {
        this.addFilterForm.controls[i].markAsTouched();
      }
      return false;
    }
    //console.log(this.selectedAttr)
    if(!_.isEmpty(this.selectedAttr)){
      // if(this.filterArray.length < 5){
        if(this.selectedAttr.operator == 'IS NOT NULL' || this.selectedAttr.operator == 'IS NULL'){
         
          //console.log("Final Step 2....")
          this.selectedAttr.modelFieldFilterList.push({value : this.selectedValue});
        
        }else{
            console.log(this.selectedValue.constructor)
              if(this.selectedValue.constructor === Array){
                //console.log("Final Step Array")
                if(this.selectedAttr.type == 'DATE_TIME' || this.selectedAttr.type == 'DATE'){
                  //console.log(this.selectedValue)
                  this.selectedValue.forEach((element,index) => {
                    if(this.selectedOper == 'BETWEEN'){
                    //console.log(index,"**********************",element,"=====",this.selectedTime,"=====",this.selectedTime2)
                        if(index == 0){
                            element =moment(moment(element).format('YYYY/MM/DD') + ' ' +this.selectedTime).format("YYYY/MM/DD HH:mm")
                        }else{
                          element =moment(moment(element).format('YYYY/MM/DD') + ' ' +this.selectedTime2).format("YYYY/MM/DD HH:mm")
                        }
                      }
  
                    this.selectedAttr.modelFieldFilterList.push({value : element});
                  });  
                } else {
                  //console.log("Final Step1")
                  this.selectedValue.forEach(element => {
                    this.selectedAttr.modelFieldFilterList.push({value : element.id, stringValue : element.stringValue});
                    this.option = [];
                  });
                }
              } else {
                console.log(this.selectedAttr.type,"=======",this.selectedValue)
                  if(this.selectedAttr.type == 'DATE_TIME'){
  
                        const tempTime = this.selectedDate
                          const time = moment(tempTime).format("HH:mm:ss");

                          const date = moment(this.selectedDate).format(
                            "YYYY-MM-DD"
                          );

                          let isoTimestamp = `${date}T${time}`;

                          this.selectedValue = moment(isoTimestamp).toDate();
                        
                    //console.log("Final Step For Date")
                    let selectedDateTime=this.selectedValue
                    //console.log(selectedDateTime)
                    this.selectedAttr.modelFieldFilterList.push({value : selectedDateTime});
                    //console.log(this.selectedAttr)
                  }else{
                    //console.log("Final Step....")
                    this.selectedAttr.modelFieldFilterList.push({value : this.selectedValue});
                  }
                  
              
              }
  
        }
        if(this.selectedAttr.modelName == 'Job order'){
          this.selectedAttr.valueName= this.selectedAttr.value.replace('Job order', 'Job order - ');
         }else if(this.selectedAttr.modelName == 'Customer'){
          this.selectedAttr.valueName= this.selectedAttr.value.replace('Customer', 'Customer - ');
         }else if(this.selectedAttr.modelName == 'Assets'){
          this.selectedAttr.valueName= this.selectedAttr.value.replace('Asset', 'Asset - ');
         }else if(this.selectedAttr.modelName == 'Ticket'){
          this.selectedAttr.valueName= this.selectedAttr.value.replace('Ticket', 'Ticket - ');
         }
  
        var operatorName = this.getOperator(this.selectedAttr.operator)
        this.selectedAttr.operatorName = operatorName
        this.filterArray.push(this.selectedAttr);
        console.log("After Filter Attr ", this.filterAttrs);
        this.filterAttrs.forEach((element : any) => {
          
          _.remove(element.modelFieldList, (n: any) => {
            //console.log(n.indexNumber," == ",this.selectedAttr.indexNumber ," && ", n.key," == ", this.selectedAttr.key)
            return n.indexNumber == this.selectedAttr.indexNumber && n.key == this.selectedAttr.key;
          });
        });
       
        this.removeSelectedOption()
      // } else {
      //   this.toaster.warn('', 'Maximum number of allowed filters is 5');
      // }
     
    }
  }

  removeSelectedOption(){
    console.log("Clear ALl Filter ", this.filterAttrs);
    this.selectedValue = null;
    this.selectedOper = null;
    this.selectedAttr = null;
  
    this.addFilterForm.controls['attr'].markAsUntouched();
    this.addFilterForm.controls['operator'].markAsUntouched();
    this.addFilterForm.controls['value'].markAsUntouched();
    this.addFilterForm.controls['operator'].clearValidators();
    this.addFilterForm.controls['value'].clearValidators();
    this.addFilterForm.controls['operator'].updateValueAndValidity();
    this.addFilterForm.controls['value'].updateValueAndValidity();
    this.errorMessage=""
  
  }

  getOperator(element: any){
    
     var addOpt;
      console.log(element)

      if(element == 'Contains'){
       addOpt="contains"
      }else if(element == 'StartsWith'){
        addOpt="starts with"
       }else if(element == 'EndsWith'){
        addOpt="ends with"
      }else if(element == 'IS NOT NULL'){
        addOpt='not equal to " "'
      }else if(element == 'IS NULL'){
        addOpt='equal to " " '
      }else if(element == 'IN'){
        addOpt="in"
      }else if(element == 'BETWEEN'){
        addOpt="between"
      }else if(element == '>'){
        addOpt="greater than"
      }else if(element == '<'){
        addOpt = "less than"
      }else if(element == '>='){
        addOpt = "greater than or equal to"
      }else if(element == '<='){
        addOpt = "less than or equal to"
      }
    return addOpt
   

}


  removeFilterOption(option: any){
    // var findFilterMatch = _.find(this.filterAttrs, (o) => { return o.indexNumber == option.indexNumber && o.key == option.key; })
    // console.log(findFilterMatch)
 
    var findFilterMatch:any =  this.filterAttrs.forEach((elementData : any) => {
      _.find(elementData.modelFieldList, (o: any) => {
        console.log(o.indexNumber," == ",option.indexNumber," && o",o.key ,"== ",option.key)
        return o.indexNumber == option.indexNumber && o.key == option.key;
      });
    });

    this.filterArray = _.remove(this.filterArray, (o) => {
      return o.indexNumber != option.indexNumber && o.key != option.key;
    });

    if(!findFilterMatch){
      console.log(option)
      this.filterAttrs=[]
      this.rearrangeData()
     }
 
  }

  rearrangeData(){
    var index=0
    console.log(this.data.slaDataDisplay,"%%%%%%%%%%")
    this.selectedCriteria.forEach((element :any)=> {
      if(element.modelFieldList.length){
        element.valueName= element.modelName.replace('_', ' ');
        element.valueName=element.valueName.charAt(0).toUpperCase() + element.valueName.slice(1).toLowerCase();
        element.value = element.modelName
       
            element.modelFieldList.forEach((modelFieldListElement: any) => {
             
              if(element.modelName == 'job_order'){
                modelFieldListElement.valueName= modelFieldListElement.value.replace("Job order", ' ');
                }else if(element.modelName == 'customer'){
                  modelFieldListElement.valueName= modelFieldListElement.value.replace("Customer", ' ');
                }else if(element.modelName == 'assets'){
                  modelFieldListElement.valueName= modelFieldListElement.value.replace("Asset", ' ');
                }else if(element.modelName == 'ticket'){
                  modelFieldListElement.valueName= modelFieldListElement.value.replace("Ticket", ' ');
                }
                console.log(this.filterAttrs,"====>>>>>")
             // this.filterAttrs.push(modelFieldListElement);
              

          });
          element.items=element.modelFieldList
          this.filterAttrs.push(element); 
          this.filterAttrsAny.push(element);
         console.log(this.filterAttrs,"---->>>>>")
        }
    });

    ///this.displayCriterias2()
  }

  displayCriterias2(){
    if(!_.isEmpty(this.filterArray)){
      this.filterArray.forEach(element => {
        console.log(element)
        // _.remove(this.filterAttrs, (n) => {
        //   return n.indexNumber == element.indexNumber && n.key == element.key;
        // });

        this.filterAttrs.forEach((elementData : any) => {
          _.remove(elementData.modelFieldList, (n: any) => {
            return n.indexNumber == element.indexNumber && n.key == element.key;
          });
        });

      });
    }
    if(!_.isEmpty(this.filterArrayAny)){
      this.filterArrayAny.forEach(element => {
        // _.remove(this.filterAttrs, (n) => {
        //   return n.indexNumber == element.indexNumber && n.key == element.key;
        // });

        this.filterArrayAny.forEach((elementData : any) => {
          _.remove(elementData.modelFieldList, (n: any) => {
            return n.indexNumber == element.indexNumber && n.key == element.key;
          });
        });

      });
       // console.log("After Filter Attr ", this.filterAttrs);
        //console.log("Filter Attr ", this.filterArrayAny);
       
    }
  }


  public doSelectOptions = (options: any) => {
    console.log(options)
    this.selectedValue = [];
    console.log(this.valueArray)
     console.log('SingleDemoComponent.doSelectOptions', options);
    for(let i=0;i<this.valueArray.length;i++){

              this.selectedAttr.availableOptionList.forEach((element: any, ind: any) => {
                if(element.id == this.valueArray[i].id)
                this.selectedValue.push({id : element.id, stringValue : element.stringValue});
                });
      }
    
    if(this.valueArray.length){
      this.addFilterForm.controls['value'].clearValidators();
      this.addFilterForm.controls['value'].updateValueAndValidity();
    }
    else {
      this.addFilterForm.controls['value'].setValidators([Validators.required]);
      this.addFilterForm.controls['value'].updateValueAndValidity();
    }
    console.log(this.selectedValue)
  }
  
  displayCriterias(criteriaType: any){
   
    console.log(criteriaType.matchAll)
    if(!_.isEmpty(criteriaType.matchAll)){
        


      criteriaType.matchAll.modelFieldList.forEach((element: any) => {
        console.log(element)
        var operatorName = this.getOperator(element.operator)

          console.log(operatorName)
          element.operatorName = operatorName

        this.filterArray.push(element);
        _.remove(this.filterAttrs, (n) => {
          return n.indexNumber == element.indexNumber && n.key == element.key;
        });

      });
        
        console.log("After Filter Attr ", this.filterAttrs);
        
        console.log("Filter Attr ", this.filterArray);
       
    }

    if(!_.isEmpty(criteriaType.matchAny)){
        


      criteriaType.matchAny.modelFieldList.forEach((element : any)=> {
       
        console.log(element)
        var operatorName = this.getOperator(element.operator)
        element.operatorName = operatorName
        this.filterArrayAny.push(element);
        _.remove(this.filterAttrs, (n) => {
          return n.indexNumber == element.indexNumber && n.key == element.key;
        });

      });
        
        console.log("After Filter Attr ", this.filterAttrs);
        
        console.log("Filter Attr ", this.filterArrayAny);
       
    }
  }




   /*============================MAtch Any ======================= */
    resetDataAny(){
      this.selectedValueAny = null;
        this.selectedOperAny = null;
        this.valueArrayAny = []
        this.displayOperatorsAny = []
        this.selectedAttrAny = null;
        this.addAnyFilterForm.controls['operatorAny'].clearValidators();
        this.addAnyFilterForm.controls['valueAny'].clearValidators();
        this.addAnyFilterForm.controls['operatorAny'].updateValueAndValidity()
        this.addAnyFilterForm.controls['valueAny'].updateValueAndValidity();
        this.selectedValueAny = ''
        this.selectedDateAny = new Date();
        this.selectedDateAny2 = new Date()
        this.selectedTimeAny = moment(this.selectedDate).format('HH:mm')
        this.selectedTimeAny2= moment(this.selectedDate).format('HH:mm')
    }
   setAttrAny(attr: any){
    console.log(attr)
    console.log("Before set reset variabler ");
    this.resetData()
    this.resetDataAny()
    this.errorMessageAny=""
    this.optionListAny=[]

    this.selectedAttrAny = attr.value
    console.log(this.selectedAttrAny)
    this.selectedOperatorsAny = attr.value.eligibleOperatorList;
    this.displayOperatorAny()
    if(this.selectedAttrAny.type == 'DATE' || this.selectedAttrAny.type == 'DATE_TIME'){
      this.selectedValueAny = moment(this.selectedDateAny).format('YYYY/MM/DD');
      this.selectedTimeAny = moment(this.selectedDate).format('HH:mm')
      this.selectedTimeAny2= moment(this.selectedDate).format('HH:mm')
    }
    if(this.selectedAttrAny.type == 'MULTIPLE_CHOICE'){
   
      console.log(this.selectedAttrAny.availableOptionList)
      this.selectedAttrAny.availableOptionList.forEach((element: any) => {
          let temp={ "id": element.id, "itemName": element.stringValue }
          this.optionListAny.push(temp)
      })
    }
    console.log(this.optionList)
      console.log(this.selectedValueAny)
    this.addAnyFilterForm.controls['operatorAny'].setValidators([Validators.required]);
    this.addAnyFilterForm.controls['valueAny'].setValidators([Validators.required]);
    this.addAnyFilterForm.controls['operatorAny'].updateValueAndValidity();
    this.addAnyFilterForm.controls['valueAny'].updateValueAndValidity();
  }
 
  setOperatorAny(operator: any){
    console.log(operator)
    this.errorMessageAny=""
    this.selectedAttrAny.operator = operator.value;
    
    if(this.selectedAttrAny.operator == 'BETWEEN' && this.selectedAttrAny.type == 'DATE_TIME'){
      this.selectedValueAny =  moment(this.selectedDateAny).format('YYYY/MM/DD');
    //  this.onDateSelect2Any(this.selectedDateAny2, this.selectedAttrAny.type);
    this.addAnyFilterForm.controls['valueAny2'].setValidators([Validators.required]);
      this.addAnyFilterForm.controls['valueAny'].updateValueAndValidity();
    } else if(this.selectedAttrAny.operator == 'BETWEEN' && this.selectedAttrAny.type == 'DATE'){
      this.selectedValueAny =  moment(this.selectedDateAny).format('YYYY/MM/DD');
      //this.onDateSelect2Any(this.selectedDateAny2, this.selectedAttrAny.type);

      this.addAnyFilterForm.controls['valueAny2'].setValidators([Validators.required]);
      this.addAnyFilterForm.controls['valueAny2'].updateValueAndValidity();

    }else{
      this.addAnyFilterForm.controls['valueAny2'].clearValidators();
      this.addAnyFilterForm.controls['valueAny2'].updateValueAndValidity();
    }

    if(this.selectedAttrAny.operator == 'IS NOT NULL' || this.selectedAttrAny.operator == 'IS NULL'){
      console.log("Correct")
      this.selectedValueAny = ''
      this.addAnyFilterForm.controls['valueAny'].clearValidators();
      this.addAnyFilterForm.controls['valueAny'].updateValueAndValidity();
    }else{
      console.log("Sorry")
      this.addAnyFilterForm.controls['valueAny'].setValidators([Validators.required]);
      this.addAnyFilterForm.controls['valueAny'].updateValueAndValidity();
    }

     console.log("Selected attr", this.selectedAttr);
  }
  displayOperatorAny(){
    console.log(this.selectedOperatorsAny)
    this.displayOperators=[]

    this.selectedOperatorsAny.forEach(element => {
     var addOpt;
      console.log(element)

      if(element == 'Contains'){

       addOpt={
          name : "contains",
          value : element
        }
      }else if(element == 'StartsWith'){
        addOpt={
          name : "starts with",
          value : element
        }

      }else if(element == 'EndsWith'){
        addOpt={
          name : "ends with",
          value : element
        }

      }else if(element == 'IS NOT NULL'){
        addOpt={
          name : 'not equal to " " ',
          value : element
        }

      }else if(element == 'IS NULL'){
        addOpt={
          name : 'equal to " "',
          value : element
        }
      }else if(element == 'IN'){
        addOpt={
          name : "in",
          value : element
        }
      }else if(element == 'BETWEEN'){
        addOpt={
          name : "between",
          value : element
        }
      }else if(element == '>'){
        addOpt={
          name : "greater than",
          value : element
        }
      }else if(element == '<'){
        addOpt={
          name : "less than",
          value : element
        }
      }else if(element == '>='){
        addOpt={
          name : "greater than or equal to",
          value : element
        }
      }else if(element == '<='){
        addOpt={
          name : "less than or equal to",
          value : element
        }
      }

      this.displayOperatorsAny.push(addOpt);

    });
    console.log(this.displayOperatorsAny)

}


  onValidFromAny(newDate: any, type: any) {
    console.log(newDate)
    this.selectedDateAny = newDate
    this.selectedValueAny = moment(newDate).format('YYYY/MM/DD');
    console.log(newDate,"===>=",this.selectedDateAny2)
      if(this.selectedDateAny2 !=undefined && newDate>=this.selectedDateAny2 && this.selectedDateAny2 !=null){
        this.minDateAny = newDate
        this.selectedDateAny2=newDate
        console.log('ETA date should be after collection date');
      }
      
    }

  onValidToDateAny(newDate: any, type: any) {
    this.selectedDateAny2=newDate
    console.log("====",this.selectedDateAny2)
    this.onDateSelect2Any(newDate, type)
    
  }


  onDateSelect2Any(event: any, type: any){
    console.log("=====================================================================================")
    var tempVal = this.selectedValueAny;
    if(tempVal[0].length > 1){
      tempVal.splice(1,1);
    }
    console.log(tempVal)
    this.selectedValueAny = [];
    if(Array.isArray(tempVal)){
      tempVal.forEach(element => {
        this.selectedValueAny.push(element);
      });
    } else {
      this.selectedValueAny.push(tempVal);
    }
   
    this.selectedValueAny.push(moment(event).format('YYYY/MM/DD')); 
      console.log(this.selectedValueAny)
  }

  
  addNewFilterAny(){
    if(!this.addAnyFilterForm.valid){
      for (var i in this.addAnyFilterForm.controls) {
        console.log(this.addAnyFilterForm.controls[i])
        this.addAnyFilterForm.controls[i].markAsTouched();
      }
      return false;
    }
    console.log(this.selectedAttrAny)
    if(!_.isEmpty(this.selectedAttrAny)){

        if(this.selectedAttrAny.operator == 'IS NOT NULL' || this.selectedAttrAny.operator == 'IS NULL'){
          
          console.log("Final Step Any 2....")
          this.selectedAttrAny.modelFieldFilterList.push({value : this.selectedValueAny});
        
        }else{

            if(this.selectedValueAny.constructor === Array){
              console.log("Final Step Array Any")
              if(this.selectedAttrAny.type == 'DATE_TIME' || this.selectedAttrAny.type == 'DATE'){
                console.log(this.selectedValueAny)
                this.selectedValueAny.forEach((element,index) => {
                  if(this.selectedOperAny == 'BETWEEN'){
                  console.log(index,"**********************",element,"=====",this.selectedTimeAny,"=====",this.selectedTimeAny2)
                      if(index == 0){
                          element =moment(moment(element).format('YYYY/MM/DD') + ' ' +this.selectedTimeAny).format("YYYY/MM/DD HH:mm")
                      }else{
                        element =moment(moment(element).format('YYYY/MM/DD') + ' ' +this.selectedTimeAny2).format("YYYY/MM/DD HH:mm")
                      }
                    }

                  this.selectedAttrAny.modelFieldFilterList.push({value : element});
                });  
              } else {
                console.log("Final Step Any")
                this.selectedValueAny.forEach(element => {
                  this.selectedAttrAny.modelFieldFilterList.push({value : element.id, stringValue : element.stringValue});
                  this.option = [];
                });
              }
            } else {
              console.log(this.selectedAttrAny.type)
                if(this.selectedAttrAny.type == 'DATE_TIME'){

                  console.log("Final Step For selectedAttrAny")
                  let selectedDateTime = moment(moment(this.selectedValueAny).format('YYYY/MM/DD') + ' ' +this.selectedTimeAny).format("YYYY/MM/DD HH:mm")
                  console.log(selectedDateTime)
                  this.selectedAttrAny.modelFieldFilterList.push({value : selectedDateTime});
                  console.log(this.selectedAttrAny)
                }else{
                  console.log("Final Step Any....")
                  this.selectedAttrAny.modelFieldFilterList.push({value : this.selectedValueAny});
                }
            
            }
        }
        if(this.selectedAttrAny.modelName == 'Job order'){
          this.selectedAttrAny.valueName= this.selectedAttrAny.value.replace("Job order", 'Job order - ');
         }else if(this.selectedAttrAny.modelName == 'Customer'){
          this.selectedAttrAny.valueName= this.selectedAttrAny.value.replace("Customer", 'Customer - ');
         }else if(this.selectedAttrAny.modelName == 'Assets'){
          this.selectedAttrAny.valueName= this.selectedAttrAny.value.replace("Asset", 'Asset - ');
         }else if(this.selectedAttrAny.modelName == 'Ticket'){
          this.selectedAttrAny.valueName= this.selectedAttrAny.value.replace("Ticket", 'Ticket - ');
         }

        var operatorName = this.getOperator(this.selectedAttrAny.operator)
        this.selectedAttrAny.operatorName = operatorName

        this.filterArrayAny.push(this.selectedAttrAny);
        _.remove(this.filterAttrs, (n) => {
          return n.indexNumber == this.selectedAttrAny.indexNumber && n.key == this.selectedAttrAny.key;
        });
        console.log("After Filter Attr ", this.filterAttrs);
        this.removeSelectedOptionAny()
    }
  }
  removeSelectedOptionAny(){
    this.selectedValueAny = null;
    this.selectedOperAny = null;
    this.selectedAttrAny = null;
    // console.log("Filter Attr ", this.selectedAttr);
   
    this.addAnyFilterForm.controls['attrAny'].markAsUntouched();
    this.addAnyFilterForm.controls['operatorAny'].markAsUntouched();
    this.addAnyFilterForm.controls['valueAny'].markAsUntouched();
    this.addAnyFilterForm.controls['operatorAny'].clearValidators();
    this.addAnyFilterForm.controls['valueAny'].clearValidators();
    this.addAnyFilterForm.controls['operatorAny'].updateValueAndValidity();
    this.addAnyFilterForm.controls['valueAny'].updateValueAndValidity();
  
    this.errorMessageAny=""
  }

  removeFilterOptionAny(option: any){
    var findFilterMatch = _.find(this.filterAttrs, (o) => { return o.indexNumber == option.indexNumber && o.key == option.key; })
    this.filterArrayAny = _.remove(this.filterArrayAny, (o) => {
      return o.indexNumber != option.indexNumber && o.key != option.key;
    });
    if(!findFilterMatch){
      // option.modelFieldFilterList = [];
      // option.operator = null;
      // this.filterAttrs.push(option);
      this.filterAttrs=[]
      this.rearrangeData()
    }
   
    
  }
 


  public doSelectOptionsAny = (options: any) => {
    this.selectedValueAny = [];
    // console.log('SingleDemoComponent.doSelectOptions', options);
    // options.value.forEach(element => {
    //   this.selectedValueAny.push({id : element.id, stringValue : element.stringValue});
    // });

    for(let i=0;i<this.valueArrayAny.length;i++){

      this.selectedAttrAny.availableOptionList.forEach((element: any, ind: any) => {
        if(element.id == this.valueArrayAny[i].id)
        this.selectedValueAny.push({id : element.id, stringValue : element.stringValue});
        });
}

    if(options.value && options.value.length){
      this.addAnyFilterForm.controls['valueAny'].clearValidators();
      this.addAnyFilterForm.controls['valueAny'].updateValueAndValidity();
    }
    else {
      this.addAnyFilterForm.controls['valueAny'].setValidators([Validators.required]);
      this.addAnyFilterForm.controls['valueAny'].updateValueAndValidity();
    }
  }

  checkValidation(){
    if(this.selectedAttr != null  && this.selectedOper != null){
      
            if(this.selectedAttr.operator != 'BETWEEN' && (this.selectedAttr.type == 'DATE_TIME' || this.selectedAttr.type == 'DATE')){
              console.log(this.selectedDate,"=======",this.selectedDate2)
                  if(this.selectedDate !=null){
                    this.errorMessage='Please click on "Add" button'
                  }else{
                    this.errorMessage=""
                  }
              
              console.log("DATE Good job") 
            }else  if(this.selectedAttr.operator == 'BETWEEN' && (this.selectedAttr.type == 'DATE_TIME' || this.selectedAttr.type == 'DATE')){
              
                    if(this.selectedDate !=null && this.selectedDate2 !=null){
                      this.errorMessage='Please click on "Add" button '
                    }else{
                      this.errorMessage=""
                    }

              }else if(this.selectedAttr.type == 'MULTIPLE_CHOICE' && this.valueArray.length != 0 ){
                console.log(this.valueArray)

                  this.errorMessage='Please click on "Add" button '
                  console.log("MULTIPLE_CHOICE Good job") 
                
              }else if(this.selectedAttr.type == 'INPUT_FIELD' && this.selectedValue !=null){
                
                this.errorMessage='Please click on "Add" button '
                console.log("INPUT_FIELD Good job") 

              
            }else{
              console.log(this.selectedAttr,"======",this.selectedOper,"=======",this.valueArray,"====",this.selectedValue,"======",this.selectedDate,"=======",this.selectedDate2)
        
              this.errorMessage=""
            }
      }else{
        console.log(this.selectedAttr,"======",this.selectedOper,"=======",this.valueArray,"====",this.selectedValue,"======",this.selectedDate,"=======",this.selectedDate2)
  
        this.errorMessage=""
      }
      ////////////////////////////// Validation of Match Any
      if(this.selectedAttrAny != null  && this.selectedOperAny != null){
      
        if(this.selectedAttrAny.operator != 'BETWEEN' && (this.selectedAttrAny.type == 'DATE_TIME' || this.selectedAttrAny.type == 'DATE')){
          console.log(this.selectedDateAny,"=======")
              if(this.selectedDateAny2 !=null){
                this.errorMessageAny='Please click on "Add" button'
              }else{
                this.errorMessageAny=""
              }
          
          console.log("DATE Good job") 
        }else  if(this.selectedAttrAny.operator == 'BETWEEN' && (this.selectedAttrAny.type == 'DATE_TIME' || this.selectedAttrAny.type == 'DATE')){
          
          console.log(this.selectedDateAny,"=======",this.selectedDateAny2)
          
                if(this.selectedDateAny !=null && this.selectedDateAny2 !=null){
                  this.errorMessageAny='Please click on "Add" button '
                }else{
                  this.errorMessageAny=""
                }

          }else if(this.selectedAttrAny.type == 'MULTIPLE_CHOICE' && this.valueArrayAny.length != 0 ){
            console.log(this.valueArrayAny)

              this.errorMessageAny='Please click on "Add" button '
              console.log("MULTIPLE_CHOICE Good job") 
            
          }else if(this.selectedAttrAny.type == 'INPUT_FIELD' && this.selectedValueAny !=null){
            
            this.errorMessageAny='Please click on "Add" button '
            console.log("INPUT_FIELD Good job") 

          
        }else{
          console.log(this.selectedAttrAny,"======",this.selectedOperAny,"=======",this.valueArrayAny,"====",this.selectedValueAny,"======",this.selectedDateAny,"=======",this.selectedDateAny2)
    
          this.errorMessageAny=""
        }
  }else{
    console.log(this.selectedAttrAny,"======",this.selectedOperAny,"=======",this.valueArrayAny,"====",this.selectedValueAny,"======",this.selectedDateAny,"=======",this.selectedDateAny2)
    
    this.errorMessageAny=""
  }



console.log(this.errorMessage,"=====",this.errorMessageAny)
      if(this.errorMessage == "" && this.errorMessageAny == ""){
        this.saveCriteria()
      }

  }
  saveCriteria(){
   console.log(this.filterArray)
    console.log(this.filterArrayAny)
      this.spinner.show();
    console.log(this.req)

    let arr=[]
    let arrAny=[]

    let matchAll=null
    let matchAny=null
  if(!_.isEmpty(this.filterArray)){
    arr.push(this.filterArray)

    matchAll={
      modelName: this.data.type,
      modelFieldList:this.filterArray
    }
  }

  if(!_.isEmpty(this.filterArrayAny)){
    arrAny.push(this.filterArrayAny)

    matchAny={
      modelName: this.data.type,
      modelFieldList:this.filterArrayAny
    }
  }
 
    this.req.matchAll=matchAll
    this.req.matchAny=matchAny
  

      console.log(this.req)
      
      this.ticketsService.updateSlaStepPackage(this.req).subscribe((res:any) => {
        if(res.status == 200){
          this.modalRef.hide();
            this.errorUtil.setErrorMessage(200,  this.translate?.general.messages.savedSuccessfully,  null, 'success',1000);
          
        }   else {

          var errorTitle = res.error.title
          if (res.error.type == 'dynamic-lang-error') {
            var data = res.error.instance + ".validationBE." + res.error.detail;
            var message = this.languageTranslateService.getNestedValue(this.translate, data);
            errorTitle = message
          }

          this.errorUtil.setErrorMessage(
            res.status,
            null,
            errorTitle,
            "error",
            2000
          );
        }
        this.submitTouched = false;
        this.spinner.hide();
        
      });
    
  
    }
    
    onSelectAll(items: any){
      console.log(items);
      console.log(this.valueArray)
      this.doSelectOptions(items)
     
    }
    
//////Multi select Option (Any)

OnItemDeSelectAny(item:any){
  console.log(item);
  console.log(this.valueArrayAny)
  this.doSelectOptionsAny(item)
 
}
onSelectAllAny(items: any){
  console.log(items);
  console.log(this.valueArrayAny)
  this.doSelectOptionsAny(items)
 
}
getLanguageData() {
  this.spinner.show()
  this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        this.spinner.hide()
      }
    }))
  console.log("translate",this.translate)
  //this.mainLabel = this.translate?.site.label.plural
}

push(obs:any) {
  super.push(obs);
}

ngOnDestroy(): void {
  super.ngOnDestroy();
}

}

