export class LanguageDataModel {        
  moduleData: any[]=[];
 
constructor(data,addonFeature) {
  
  this.moduleData =  this.convertNestedToObjectArray(data,addonFeature);
  
}


  /*******  Function to convert nested objects to arrays ***************/
  convertNestedToObjectArray(jsonData,addonFeature) {
    const arrayOfObjects = Object.keys(jsonData).map((key) => {
      if(jsonData[key].setupVisible){
       return {
        key: key,
        label: jsonData[key].label ? jsonData[key].label.singular:'-',
        singular: jsonData[key].label ? jsonData[key].label.singular:'-',
        plural: jsonData[key].label ? jsonData[key].label.plural:'-', // Use the key as the name for the object
        original: jsonData[key].label ? jsonData[key].label.original:'-',
        setupVisible:jsonData[key].setupVisible,
        setupEdit:jsonData[key].setupEdit ? jsonData[key].setupEdit:false,
        lastModifiedDate:jsonData[key].lastModifiedDate,
        data: jsonData[key] // Add the data to the object
       };
      }
    });
    return arrayOfObjects;
  }
  
  
}

export class LanguageDataModelByModule {
  moduleData: any[]=[];
 
  constructor(data) {
    
    this.moduleData =  this.convertNestedToObjectArrayForModule(data);
    
  }
 
    // Function to convert nested objects to arrays
    convertNestedToObjectArrayForModule(jsonData) {
      const arrayOfObjects = Object.keys(jsonData).map((key) => {
        if( key != "setupVisible"){
        return {
          name: key, // Use the key as the name for the object
          setupVisible:jsonData[key].setupVisible,
          data: jsonData[key], // The value of the key becomes the data property
        };
      }
      });
      return arrayOfObjects;
     
    }
  
}



export class NewLanguageDataModel {
  moduleData:any;
  
  constructor(data, selectedData) {
    selectedData.label.plural = data.plural
    selectedData.label.singular = data.singular
    this.moduleData = selectedData
    }
}

export const  EngLangData: any = {
  "login": {
    "setupVisible": false,
    "label": {
      "original": "Log In",
      "singular": "Log In",
      "plural": "",
      "setupEdit": true,
      "setupVisible": false
    },
    "sections": {
      "setupVisible": false,
      "sec0": {
        "original": "Log In",
        "singular": "Log In",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      }
    },
    "fields": {
      "setupVisible": false,
      "username": {
        "original": "Username",
        "singular": "Username",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "password": {
        "original": "Password",
        "singular": "Password",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "rememberMe": {
        "original": "Remember Me",
        "singular": "Remember Me",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "forgotPassword": {
        "original": "Forgot Your Password?",
        "singular": "Forgot Your Password?",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      }
    },
    "messages": {
      "mostFeaturesMobileApp": "For the most features and functionality of FieldEx on the go, please download our mobile app.",
      "invalidCredentials": "Invalid credentials. Please try again",
      "passwordsDoNotMatch": "Passwords do not match. Please try again.",
      "somethingWentWrong": "Looks like something went wrong, please try again.",
      "ensureNoBlankSpacesUsername": "Please ensure you have no blank spaces before or after your username",
      "passwordCriteriaNotMet": "Please ensure your password meets all the specified criteria.",
      "passwordsDoNotMatchTryAgain": "The passwords you entered do not match. Please try again.",
    },
    "validationBE": {
    "invalidCredentials": "Please provide correct credentials and try again",
    "subscriptionExpired": "Your subscription has expired, please contact your admin.",
    "userNotActive": "User was not activated",
    "userNotFound": "User not found",
    "userProfileNotFound": "Current user has no profile attached",
    "driverNotAllowed": "You are not allowed to login to the web application. Please proceed with Mobile App",
    "userLoginNotFound": "User's login not found",
    "userNameExists": "Username already in use"
  },
    "buttons":{
      "login": {
        "original": "Log In",
        "singular": "Log In",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      }
    }
  },
  "resetPassword": {
    "setupVisible": false,
    "label": {
      "original": "Reset Password",
      "singular": "Reset Password",
      "plural": "",
      "setupEdit": true,
      "setupVisible": false
    },
    "sections": {
      "setupVisible": false,
      "sec0": {
        "original": "Reset Password",
        "singular": "Reset Password",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "sec1": {
        "original": "Create New Password",
        "singular": "Create New Password",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "sec2": {
        "original": "Resend Email",
        "singular": "Resend Email",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      }
    },
    "fields": {
      "setupVisible": false,
      "username": {
        "original": "Username",
        "singular": "Username",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "oldPassword": {
        "original": "Old Password",
        "singular": "Old Password",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "newPassword": {
        "original": "New Password",
        "singular": "New Password",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "confirmPassword": {
        "original": "Confirm Password",
        "singular": "Confirm Password",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "confirmNewPassword": {
        "original": "Confirm New Password",
        "singular": "Confirm New Password",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "returnLogin": {
        "original": "Return to login",
        "singular": "Return to login",
        "plural": "Return to login",
        "setupEdit": true,
        "setupVisible": false
      }
    },
    "messages": {
      "minimumChars": "8 characters minimum",
      "oneLowercase": "1 lowercase letter (a-z)",
      "oneUppercase": "1 uppercase letter (A-Z)",
      "oneNumeric": "1 number (0-9)",
      "oneSymbol": "1 symbol (!, @, #, $, etc)",
      "passwordRequirement": "Password must contain at least",
      "usernameExclusion": "must not contain part of your username",
      "anEmailWillBeSentTo": "An email will be sent to",
      "doYouWishProceed": "Do you wish to proceed",
      "passwordsDoNotMatch": "Passwords do not match. Please try again.",
      "passwordCriteriaNotMet": "Please ensure your password meets all the specified criteria.",
      "passwordResetSuccess":"Password succesfully reset. Proceed to login",
    },
    "validationFE": {
      "passwordsDoNotMatch": "Password does not match"
    },
    "validationBE": {
      "userNotFound": "User not found",
      "invalidPassword": "Invalid password",
      "activationLinkExpired": "Activation link has expired after 24 hours or has already been used."
    },
    "buttons":{
      "resetPassword": {
        "original": "Reset Password",
        "singular": "Reset Password",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      }
    }
  },
  "createProfile": {
    "setupVisible": false,
    "label": {
      "original": "Create your profile",
      "singular": "Create your profile",
      "plural": "",
      "setupEdit": true,
      "setupVisible": false
    },
    "sections": {
      "setupVisible": false,
      "sec0": {
        "original": "Create your profile",
        "singular": "Create your profile",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      }
    },
    "fields": {
      "setupVisible": false,
      "firstName": {
        "original": "First Name",
        "singular": "First Name",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "lastName": {
        "original": "Last Name",
        "singular": "Last Name",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "email": {
        "original": "Email",
        "singular": "Email",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "phone": {
        "original": "Phone",
        "singular": "Phone",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      }
    },
    "buttons":{
      "create": {
        "original": "Create",
        "singular": "Create",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "uploadImage": {
        "original": "Upload Image",
        "singular": "Upload Image",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "resetPassword": {
        "original": "Reset Password",
        "singular": "Reset Password",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      }
    }
  },
  "general": {
    "buttons":{
      "save": {
        "original": "Save",
        "singular": "Save",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "cancel": {
        "original": "Cancel",
        "singular": "Cancel",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "close": {
        "original": "Close",
        "singular": "Close",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      }
    },
    "validationFE": {
      "invalid": "Invalid",
      "invalidEmail": "Invalid Email",
      "invalidEmailFormat": "Invalid Email Format",
      "invalidFax": "Invalid Fax Number",
      "invalidFaxFormat": "Invalid Fax Format",
      "invalidPhoneNumber": "Invalid Phone Number",
      "invalidPrice": "Invalid Price",
      "invalidQuantity": "Invalid Quantity",
      "invalidRadius": "Invalid Radius",
      "invalidUsername": "Invalid Username",
      "invalidUsernameFormat": "Invalid Username Format",
      "invalidYearFormat": "Invalid Year Format",
      "onlyAlphabetsAllowed": "Only alphabets allowed",
      "requiredField": "Required Field",
      "pleaseSignBeforeProceed": "Please sign before proceed",
      "charactersExceed": "Text exceeds character limit (255)."
    },
    "messages":{
      "none": "None",
      "confirmAction": "Are you sure?"
    }
  },
  "setup": {
    "integrations": {
      "setupVisible": false,
      "sections": {
        "setupVisible": false,
        "sec0": {
          "original": "Location Details",
          "singular": "Location Details",
          "plural": "",
          "setupEdit": true,
          "setupVisible": false
        }
      },
      "fields": {
        "setupVisible": false,
        "clientId": {
          "original": "Client ID",
          "singular": "Client ID",
          "plural": "",
          "setupEdit": true,
          "setupVisible": false
        },
        "clientSecret": {
          "original": "Client Secret",
          "singular": "Client Secret",
          "plural": "",
          "setupEdit": true,
          "setupVisible": false
        }
      },
      "validationBE": {},
      "messages": {
        "selectDateTime":"Select a Date & Time - Calendly",
        "connecting":"Connecting",
        "windowCloseIn":"This window will close in",
        "seconds":"seconds...",
        "invalidLink":"Invalid Link",
        "responseSubmit":"A response has already been submitted for this survey link"
      }
    }
  }
}
export const  MalayLangData : any = {
  "login": {
    "setupVisible": true,
    "label": {
      "original": "Log In",
      "singular": "Log Masuk",
      "plural": "",
      "setupEdit": true,
      "setupVisible": true
    },
    "sections": {
      "setupVisible": true,
      "sec0": {
        "original": "Log In",
        "singular": "Log Masuk",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      }
    },
    "fields": {
      "setupVisible": true,
      "username": {
        "original": "Username",
        "singular": "Nama Pengguna",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "password": {
        "original": "Password",
        "singular": "Kata Laluan",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "rememberMe": {
        "original": "Remember Me",
        "singular": "Ingat Saya",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "forgotPassword": {
        "original": "Forgot Your Password?",
        "singular": "Lupa Kata Laluan Anda?",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      }
    },
    "validationBE": {
      "userNotAllowedInMobile": "Anda tidak dibenarkan untuk log masuk ke Aplikasi Mudah Alih. Sila teruskan dengan aplikasi web",
      "deviceNotAllowed": "Peranti ini tidak dibenarkan untuk log masuk. Sila hubungi pentadbir anda",
      "invalidCredentials": "Sila berikan kelayakan yang betul dan cuba lagi",
      "subscriptionExpired": "Langganan anda telah tamat tempoh, sila hubungi pentadbir anda.",
      "userNotActive": "Pengguna tidak diaktifkan",
      "userNotFound": "Pengguna tidak dijumpai",
      "userProfileNotFound": "Pengguna semasa tidak mempunyai profil yang dilampirkan",
      "driverNotAllowed": "Anda tidak dibenarkan untuk log masuk ke aplikasi web. Sila teruskan dengan Aplikasi Mudah Alih",
      "userLoginNotFound": "Log masuk pengguna tidak dijumpai",
      "userNameExists": "Nama pengguna sudah digunakan"
  
    },
    "messages": {
      "mostFeaturesMobileApp": "Untuk ciri-ciri dan fungsi penuh FieldEx di mana-mana, sila muat turun aplikasi mudah alih kami.",
      "invalidCredentials": "Kelayakan tidak sah. Sila cuba lagi",
      "passwordsDoNotMatch": "Kata laluan tidak sepadan. Sila cuba lagi.",
      "somethingWentWrong": "Nampaknya ada yang tidak kena, sila cuba lagi.",
      "ensureNoBlankSpacesUsername": "Sila pastikan tiada ruang kosong sebelum atau selepas nama pengguna anda",
      "passwordCriteriaNotMet": "Sila pastikan kata laluan anda memenuhi semua kriteria yang ditetapkan.",
      "passwordsDoNotMatchTryAgain": "Kata laluan yang anda masukkan tidak sepadan. Sila cuba lagi.",
    },
    "buttons":{
      "login": {
        "original": "Log In",
      "singular": "Log Masuk",
      "plural": "",
      "setupEdit": true,
      "setupVisible": true
    }
    }
  },
  "resetPassword": {
    "setupVisible": true,
    "label": {
      "original": "Reset Password",
      "singular": "Tetapkan Semula Kata Laluan",
      "plural": "",
      "setupEdit": true,
      "setupVisible": true
    },
    "sections": {
      "setupVisible": true,
      "sec0": {
        "original": "Reset Password",
        "singular": "Tetapkan Semula Kata Laluan",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "sec1": {
        "original": "Create New Password",
        "singular": "Cipta Kata Laluan Baru",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "sec2": {
        "original": "Resend Email",
        "singular": "Hantar Semula E-mel",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      }
    },
    "fields": {
      "setupVisible": true,
      "username": {
        "original": "Username",
        "singular": "Nama Pengguna",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "newPassword": {
        "original": "New Password",
        "singular": "Kata Laluan Baru",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "oldPassword": {
        "original": "Old Password",
        "singular": "Kata Laluan Lama",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "confirmNewPassword": {
        "original": "Confirm New Password",
        "singular": "Sahkan Kata Laluan Baru",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "confirmPassword": {
        "original": "Confirm New Password",
        "singular": "Sahkan Kata Laluan",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "returnLogin": {
        "original": "Return to login",
        "singular": "Kembali ke log masuk",
        "plural": "Kembali ke log masuk",
        "setupEdit": true,
        "setupVisible": true
      }
    },
    "messages": {
      "minimumChars": "Sekurang-kurangnya 8 aksara",
      "oneLowercase": "1 huruf kecil (a-z)",
      "oneUppercase": "1 huruf besar (A-Z)",
      "oneNumeric": "1 nombor (0-9)",
      "oneSymbol": "1 simbol (!, @, #, $, dll)",
      "passwordRequirement": "Kata Laluan mesti mengandungi sekurang-kurangnya",
      "usernameExclusion": "tidak boleh mengandungi bahagian dari nama pengguna anda",
      "anEmailWillBeSentTo": "E-mel akan dihantar kepada",
      "doYouWishProceed": "Adakah anda ingin meneruskan",
      "passwordsDoNotMatch": "Kata laluan tidak sepadan. Sila cuba lagi.",
      "passwordCriteriaNotMet": "Sila pastikan kata laluan anda memenuhi semua kriteria yang ditetapkan.",
      "passwordResetSuccess": "Kata laluan berjaya ditetapkan semula. Teruskan untuk log masuk"
    },
    "validationFE": {
      "passwordsDoNotMatch": "Kata laluan tidak sepadan"
    },
    "validationBE": {
      "userNotFound": "Pengguna tidak dijumpai",
      "invalidPassword": "Kata laluan tidak sah",
      "activationLinkExpired": "Pautan pengaktifan telah tamat tempoh selepas 24 jam atau telah digunakan."
    },
    "buttons":{
      "resetPassword": {
        "original": "Reset Password",
        "singular": "Tetapkan Semula Kata Laluan",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      }
    }
  },
  "createProfile": {
    "setupVisible": true,
    "label": {
      "original": "Create your profile",
      "singular": "Cipta profil anda",
      "plural": "",
      "setupEdit": true,
      "setupVisible": true
    },
    "sections": {
      "setupVisible": true,
      "sec0": {
        "original": "Create your profile",
        "singular": "Cipta profil anda",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      }
    },
    "fields": {
      "setupVisible": true,
      "firstName": {
        "original": "First Name",
        "singular": "Nama Pertama",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "lastName": {
        "original": "Last Name",
        "singular": "Nama Akhir",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "email": {
        "original": "Email",
        "singular": "Emel",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "phone": {
        "original": "Phone",
        "singular": "Telefon",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      }
    },
    "buttons":{
      "create": {
        "original": "Create",
        "singular": "Cipta",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "uploadImage": {
        "original": "Upload Image",
        "singular": "Muat Naik Gambar",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "resetPassword": {
        "original": "Reset Password",
        "singular": "Tetapkan Semula Kata Laluan",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      }
    }
  },
  "general": {
    "buttons": {
      "save": {
        "original": "Save",
        "singular": "Simpan",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "cancel": {
        "original": "Cancel",
        "singular": "Batal",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "close": {
        "original": "Close",
        "singular": "Tutup",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      }
    },
    "validationFE": {
      "invalid": "Tidak sah",
      "invalidEmail": "E-mel tidak sah",
      "invalidEmailFormat": "Format E-mel tidak sah",
      "invalidFax": "Nombor faks tidak sah",
      "invalidFaxFormat": "Format faks tidak sah",
      "invalidPhoneNumber": "Nombor telefon tidak sah",
      "invalidPrice": "Harga tidak sah",
      "invalidQuantity": "Kuantiti tidak sah",
      "invalidRadius": "Radius tidak sah",
      "invalidUsername": "Nama pengguna tidak sah",
      "invalidUsernameFormat": "Format nama pengguna tidak sah",
      "invalidYearFormat": "Format tahun tidak sah",
      "onlyAlphabetsAllowed": "Hanya huruf dibenarkan",
      "requiredField": "Medan diperlukan",
      "pleaseSignBeforeProceed": "Sila tandatangan sebelum meneruskan",
      "charactersExceed": "Teks melebihi had aksara (255)."
    },
    "messages": {
      "none": "Tiada",
      "confirmAction": "Adakah anda pasti?"
    }
  },
  "setup": {
    "integrations": {
      "setupVisible": false,
      "sections": {
        "setupVisible": false,
        "sec0": {
          "original": "Location Details",
          "singular": "Butiran Lokasi",
          "plural": "",
          "setupEdit": true,
          "setupVisible": false
        }
      },
      "fields": {
        "setupVisible": false,
        "clientId": {
          "original": "Client ID",
          "singular": "ID Klien",
          "plural": "",
          "setupEdit": true,
          "setupVisible": false
        },
        "clientSecret": {
          "original": "Client Secret",
          "singular": "Rahsia Klien",
          "plural": "",
          "setupEdit": true,
          "setupVisible": false
        }
      },
      "validationBE": {},
      "messages": {
        "selectDateTime": "Pilih Tarikh & Masa - Calendly",
        "connecting": "Menyambung",
        "windowCloseIn": "Tetingkap ini akan ditutup dalam",
        "seconds": "saat...",
        "invalidLink": "Pautan tidak sah",
        "responseSubmit": "Respons telah dihantar untuk pautan tinjauan ini"
      }
    }
  }
}
export const  chineseLangData : any = {
  "login": {
    "setupVisible": true,
    "label": {
      "original": "Log In",
      "singular": "登录",
      "plural": "",
      "setupEdit": true,
      "setupVisible": true
    },
    "sections": {
      "setupVisible": true,
      "sec0": {
        "original": "Log In",
        "singular": "登录",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      }
    },
    "fields": {
      "setupVisible": true,
      "username": {
        "original": "Username",
        "singular": "用户名",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "password": {
        "original": "Password",
        "singular": "密码",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "rememberMe": {
        "original": "Remember Me",
        "singular": "记住我",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "forgotPassword": {
        "original": "Forgot Your Password?",
        "singular": "忘记密码？",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      }
    },
    "validationBE": {
      "userNotAllowedInMobile": "您不允许登录移动应用程序。请使用网页应用程序",
      "deviceNotAllowed": "此设备不允许登录。请联系您的管理员",
      "invalidCredentials": "请提供正确的凭据并重试",
      "subscriptionExpired": "您的订阅已过期，请联系您的管理员。",
      "userNotActive": "用户未激活",
      "userNotFound": "未找到用户",
      "userProfileNotFound": "当前用户没有关联的个人资料",
      "driverNotAllowed": "您不允许登录网页应用程序。请使用移动应用程序继续",
      "userLoginNotFound": "未找到用户的登录信息",
      "userNameExists": "用户名已被使用"
    },
    "messages": {
      "mostFeaturesMobileApp": "要获得FieldEx的所有功能，请下载我们的移动应用程序。",
      "invalidCredentials": "凭证无效。请再试一次",
      "passwordsDoNotMatch": "密码不匹配。请再试一次。",
      "somethingWentWrong": "看起来出了点问题，请再试一次。",
      "ensureNoBlankSpacesUsername": "请确保您的用户名前后没有空格",
      "passwordCriteriaNotMet": "请确保您的密码符合所有指定的标准。",
      "passwordsDoNotMatchTryAgain": "您输入的密码不匹配。请再试一次。",
    },
    "buttons": {
      "login": {
        "original": "Log In",
        "singular": "登录",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      }
    }
  },
  "resetPassword": {
    "setupVisible": true,
    "label": {
      "original": "Reset Password",
      "singular": "重置密码",
      "plural": "",
      "setupEdit": true,
      "setupVisible": true
    },
    "sections": {
      "setupVisible": true,
      "sec0": {
        "original": "Reset Password",
        "singular": "重置密码",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "sec1": {
        "original": "Create New Password",
        "singular": "创建新密码",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "sec2": {
        "original": "Resend Email",
        "singular": "重新发送邮件",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      }
    },
    "fields": {
      "setupVisible": true,
      "username": {
        "original": "Username",
        "singular": "用户名",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "oldPassword": {
        "original": "Old Password",
        "singular": "旧密码",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "newPassword": {
        "original": "New Password",
        "singular": "新密码",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "confirmPassword": {
        "original": "Confirm New Password",
        "singular": "确认密码",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "confirmNewPassword": {
        "original": "Confirm New Password",
        "singular": "确认新密码",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "returnLogin": {
        "original": "Return to login",
        "singular": "返回登录",
        "plural": "返回登录",
        "setupEdit": true,
        "setupVisible": true
      }
    },
    "messages": {
      "minimumChars": "至少8个字符",
      "oneLowercase": "1个小写字母 (a-z)",
      "oneUppercase": "1个大写字母 (A-Z)",
      "oneNumeric": "1个数字 (0-9)",
      "oneSymbol": "1个符号 (!, @, #, $, 等)",
      "passwordRequirement": "密码必须至少包含",
      "usernameExclusion": "不能包含用户名的部分",
      "anEmailWillBeSentTo": "将发送电子邮件至",
      "doYouWishProceed": "您希望继续吗",
      "passwordsDoNotMatch": "密码不匹配。请再试一次。",
      "passwordCriteriaNotMet": "请确保您的密码符合所有指定的标准。",
      "passwordResetSuccess": "密码重置成功。继续登录"
    },
    "validationFE": {
      "passwordsDoNotMatch": "密码不匹配"
    },
    "validationBE": {
    "userNotFound": "未找到用户",
    "invalidPassword": "密码无效",
    "activationLinkExpired": "激活链接已在24小时后过期或已被使用。"
  },
    "buttons": {
      "resetPassword": {
        "original": "Reset Password",
        "singular": "重置密码",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      }
    }
  },
  "createProfile": {
    "setupVisible": true,
    "label": {
      "original": "Create your profile",
      "singular": "创建您的个人资料",
      "plural": "",
      "setupEdit": true,
      "setupVisible": true
    },
    "sections": {
      "setupVisible": true,
      "sec0": {
        "original": "Create your profile",
        "singular": "创建您的个人资料",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      }
    },
    "fields": {
      "setupVisible": true,
      "firstName": {
        "original": "First Name",
        "singular": "名字",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "lastName": {
        "original": "Last Name",
        "singular": "姓氏",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "email": {
        "original": "Email",
        "singular": "电子邮件",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      },
      "phone": {
        "original": "Phone",
        "singular": "电话",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      }
    },
    "buttons": {
      "create": {
        "original": "Create",
        "singular": "创建",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "uploadImage": {
        "original": "Upload Image",
        "singular": "上传图片",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "resetPassword": {
        "original": "Reset Password",
        "singular": "重置密码",
        "plural": "",
        "setupEdit": true,
        "setupVisible": true
      }
    }
  },
  "general": {
    "buttons": {
      "save": {
        "original": "Save",
        "singular": "保存",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "cancel": {
        "original": "Cancel",
        "singular": "取消",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      },
      "close": {
        "original": "Close",
        "singular": "关闭",
        "plural": "",
        "setupEdit": true,
        "setupVisible": false
      }
    },
    "validationFE": {
      "invalid": "无效",
      "invalidEmail": "无效的电子邮件",
      "invalidEmailFormat": "无效的电子邮件格式",
      "invalidFax": "无效的传真号码",
      "invalidFaxFormat": "无效的传真格式",
      "invalidPhoneNumber": "无效的电话号码",
      "invalidPrice": "无效的价格",
      "invalidQuantity": "无效的数量",
      "invalidRadius": "无效的半径",
      "invalidUsername": "无效的用户名",
      "invalidUsernameFormat": "无效的用户名格式",
      "invalidYearFormat": "无效的年份格式",
      "onlyAlphabetsAllowed": "只允许字母",
      "requiredField": "必填字段",
      "pleaseSignBeforeProceed": "请先签名再继续",
      "charactersExceed": "文本超过字符限制（255）。"
    },
    "messages": {
      "none": "无",
      "confirmAction": "你确定吗？"
    }
  },
  "setup": {
    "integrations": {
      "setupVisible": false,
      "sections": {
        "setupVisible": false,
        "sec0": {
          "original": "Location Details",
          "singular": "位置详情",
          "plural": "",
          "setupEdit": true,
          "setupVisible": false
        }
      },
      "fields": {
        "setupVisible": false,
        "clientId": {
          "original": "Client ID",
          "singular": "客户ID",
          "plural": "",
          "setupEdit": true,
          "setupVisible": false
        },
        "clientSecret": {
          "original": "Client Secret",
          "singular": "客户密钥",
          "plural": "",
          "setupEdit": true,
          "setupVisible": false
        }
      },
      "validationBE": {},
      "messages": {
        "selectDateTime": "选择日期和时间 - Calendly",
        "connecting": "连接中",
        "windowCloseIn": "此窗口将在",
        "seconds": "秒后关闭...",
        "invalidLink": "无效的链接",
        "responseSubmit": "此调查链接已提交过响应"
      }
    }
  }
}

interface LanguageOption {
  value: string;
  flag: string;
}

export const languageOptions: Record<string, LanguageOption> = {
  english: { value: 'English', flag: 'us' },
  malay: { value: 'Bahasa Melayu', flag: 'my' },
  chinese: { value: '简体中文', flag: 'cn' },
};

export const languagePcklistData = [{
  "value": "en",
  "label": "English"
},
{
  "value": "my",
  "label": "Bahasa Melayu"
},
{
  "value": "zh",
  "label": "简体中文"
}]





