<!-- TabMenu -->
<div class="position-relative d-flex" style="height: calc(100% - 78px);" >
    <div class="ag-grid-table-full" [ngClass]="{ ' ag-grid-table': filterView }" style="height: 100%;" [hidden]="noContracts">
        <ag-grid-angular
            #agGrid
            style="height: 100%;"
            class="ag-theme-balham"
            [columnDefs]="columnDefsContracts"
            [gridOptions]="gridOptionsContracts"
            [defaultColDef]="defaultColDef"
            [cacheOverflowSize]="2"
            [maxConcurrentDatasourceRequests]="-1"
            [infiniteInitialRowCount]="1"
            [frameworkComponents]="frameworkComponents"
            (cellClicked)="viewContract($event)"
            (gridReady)="onGridReadyContract($event)"
            [getContextMenuItems]="getContextMenuItems"
        ></ag-grid-angular>

        <div class="col-12">
            <p class="ag-record-row-summary-panel padds text-end" id="label_ContraTableRec">
                {{ totalRecord }}&nbsp;{{translate?.general.messages.records}}
            </p>
        </div>
        
    </div>
    <div *ngIf="noContracts" class="col-12 mt-5 py-4">
        <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
            <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
            <p class="text-6 my-1 font-weight-bold" >
                {{translate?.general.messages.noRecordsFound}}
            </p>
        </div>
    </div>
    <div class="custella-form-container-content-pane layout-filter" [ngClass]="{ 'layout-filter-active': filterView }" *ngIf="filterView">
        <div class="custella-form-container-content-pane-title">
            <span class="text-14 font-medium" id="label_ContraFilter">{{ translate?.general.messages.filterBy }}</span>
            <i class="fa-regular fa-arrow-right-from-line"  [pTooltip]="translate?.general.buttons.closeFilter.singular" tooltipPosition="left" (click)="toggleFilterView()" id="btn_ContraClose"></i>
        </div>
        <div class="custella-content">
            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_ContraStatus">{{ translate?.contracts.fields.status.singular }}</span>
                <p-dropdown [placeholder]="translate?.general.messages.none" id="picklist_ContraStatusField" [options]="allStatus" [showClear]="true" [filter]="true" optionLabel="label" optionValue="value" name="status" [(ngModel)]="statuses" (onChange)="refresh()">
                    <ng-template pTemplate="selectedItem">
                        <span class="" *ngIf="statuses">
                            {{ returnStatus(statuses)  }}
                        </span>
                    </ng-template>
                    <ng-template let-status pTemplate="item">
                        {{ status.label  }}
                    </ng-template>
                </p-dropdown>
            </div>
            <div>
                <span href="#" class="clear" (click)="clearAll()" id="subtitle_ContraClear">{{translate?.general.buttons.clearAll.singular }}</span>
            </div>
        </div>
    </div>
</div>