import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-lock-renderer',
  template: `
  <img [hidden]="this.params?.node?.data?.locked" class="cursor-pointer mr-2" style="height: 15px" src="../../../../assets/images/planning-hub/ic-lock-white.png" alt="" (click)="onClick($event, 'lock')"><img [hidden]="!this.params?.node?.data?.locked" (click)="onClick($event,'unlock')" class="cursor-pointer mr-2" src="../../../../assets/images/planning-hub/ic-lock-black.png" style="height: 15px;">
    `
})

export class LockRendererComponent implements ICellRendererAngularComp {
  params: any;
  label: any;
  hidden!: boolean;
  
  agInit(params: any): void {
    ////console.log(params)
    this.params = params;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event: any,action: any) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        action:action
        // ...something
      }
      this.params.onClick(params);
    }
  }
}