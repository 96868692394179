import { Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ButtonRendererComponent } from 'src/app/renderer/button-renderer.component';
import { ErrorUtil } from 'src/app/_utilities/error';
import { Subscription } from 'rxjs';
import { GetContextMenuItemsParams, GridOptions, IServerSideGetRowsParams, MenuItemDef } from 'ag-grid-community';
import "ag-grid-enterprise"; 
import { TasksService } from 'src/app/_services/tasks.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import * as moment from 'moment';
import { ServerRequest } from 'src/app/_models/global.data.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { ModalServiceService } from 'src/app/_services/modal-service.service';
import { PermissionService } from 'src/app/auth/permission.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { TableHeaderToolTipComponent } from 'src/app/shared/table-header-tool-tip/table-header-tool-tip.component';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { DynamicFormService } from 'src/app/_services/dynamic-form.service';
import { DynamicTableColumnsService } from 'src/app/_services/dynamic-table-column.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
var dateFormateChange: any;
var router: Router;

@Component({
  selector: 'app-all-team-task',
  templateUrl: './all-team-task.component.html',
  styleUrls: ['./all-team-task.component.scss']
})
export class AllTeamTaskComponent extends SubscriptionUtil implements OnInit, OnDestroy,OnChanges {
  
  taskData: any;
 
  @Input() mode: any;
  @Input() taskDateRange: any
  @Input() status: any;
  @Input() selectedTeam: any
  filter: any = {status : '', fromDateTime : '', toDateTime : ''};
 
  statuses: any;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any = [];
  defaultColDef: any;
  frameworkComponents: any;
  gridParams : any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions: GridOptions;
  overlayNoRowsTemplate: string;
  productsFamilies: any;
  productsType: any;
  searchValue: any
  currentRecordNumber: any;
  totalRecord: any;

  noTask: boolean = false;
  noTaskIcon: boolean = false;
  refresher!: Subscription


  filterActive: boolean = false;
  filterClick: boolean = false;
  myGroup: any;
  taskStatus: any=[
    {label:'Open',value:'OPEN'},
    {label:'Pending',value:'PENDING'},
    {label:'In Progress',value:'IN_PROGRESS'},
    {label:'Completed',value:'COMPLETED'},
    {label:'In Completed',value:'IN_COMPLETED'}
  ]
  fromDateTime: any;
  toDateTime: any;

  createTask: any;
/********** View Task Variable ***************/
visibleSidebarTask:boolean = false
newTaskId: any;
showCloseIcon:boolean=false

sortBy: any;
  sortOrder: any;
  private loadTable = 0;
  dateFormat:any
  translate: any;

  constructor(
    private router: Router,
    private spinner : NgxSpinnerService,
    private taskService: TasksService,
    private errorUtil: ErrorUtil,
    private deleteRecordService: DeleteRecordService,
    private route: ActivatedRoute,
    private modalService2 : ModalServiceService,
    private auth : AuthenticationService,
    private perm: PermissionService,
    private util : UtilServiceService,
    private dynamicFormService: DynamicFormService,
    private dynamicTableColumnsService: DynamicTableColumnsService,
    private languageTranslateService: LanguageTranslateService
    ) {
      super();
      this.languageTranslateService.menuSrc$.subscribe(data => {
        if(Object.keys(data).length != 0){ this.translate = data}
      })
      // this.refresher = taskService.refresherLocation$.subscribe(item => {
      //   this.onGridReady(this.gridParams);
      // });
      this.refresher = this.taskService.refreshList$.subscribe((o:any) => {
        this.onGridReady(this.gridParams);
      });
      this.frameworkComponents = {
        buttonRenderer: ButtonRendererComponent,
      }
      this.gridOptions = {
        cacheBlockSize: 25,
        rowModelType: 'infinite',
        sortingOrder : ["desc", "asc"],
        onGridSizeChanged: (params) => {
          params.api.sizeColumnsToFit();
          },
          tooltipShowDelay: 100,
        }
        // this.columnDefs = [
        //   { headerName: 'Task Name', field: 'taskName', width: 250, cellClass: function (params: any) { return ['table_default_color'];}, headerTooltip: "#", },
        //   {headerName: "Task Date",field: "startDateTime", width: 200,
        //   headerTooltip: "#",
        //     cellRenderer: function (params: any) {
        //       if (params.data && params.data.startDateTime) {
        //         return ( moment.tz(params.data.startDateTime, dateFormateChange).format(localStorage.getItem("date_format")!.toUpperCase())
        //         );
        //       }
        //     },
        //   },
        //   { headerName: 'Task Time', field: 'startDateTime', width: 250,filter: false,
        //   headerTooltip: "#",
        //   cellRenderer: function (params: any) {
        //     if(params.data){
        //       return  moment.tz( params.data.startDateTime, dateFormateChange).format("hh:mm A")+' - '+moment.tz( params.data.endDateTime, dateFormateChange).format("hh:mm A");
        //     }
        //   }
        // },
        // { headerName: 'Location', field: 'location', width: 220, headerTooltip: "#",},
        // { headerName: 'Assigned To', field: 'assignedTo.fullName', width: 200, headerTooltip: "#",},
        // { headerName: 'Assigned By', field: 'assignedBy.fullName', width: 200, headerTooltip: "#",},
        // { headerName: 'Team', field: 'team.teamName', width: 200, headerTooltip: "#",},
        // { headerName: "Task Status",field: "taskStatus", width: 200,headerClass: 'marging-auto hide-action-border-left',
        // headerTooltip: "#",
        //   cellRenderer: function (params: any) {
        //     if (params.data) {
        //       if (params.data.taskStatus == "OPEN") {
        //         return ('<span class="p-mr-2 p-tag p-component p-tag-open"><span class="p-tag-value text-dark">Open</span></span>' );
        //       } else if (params.data.taskStatus == "PENDING") {
        //         return (
        //           '<span class="p-mr-2  p-tag p-component p-tag-pending" ><span class="p-tag-value text-dark">Pending</span></span> ');
        //       } else if ( params.data.taskStatus == "IN PROGRESS" || params.data.taskStatus == "IN_PROGRESS") {
        //         return '<span class="p-mr-2  p-tag p-component p-tag-in-progress" ><span class="p-tag-value text-dark">In Progress</span></span>';
             
        //       } else if (params.data.taskStatus == "COMPLETED") {
        //         return ('<span class="p-mr-2  p-tag p-component p-tag-completed" ><span class="p-tag-value text-dark">Completed</span></span>');
              
        //       } else if ( params.data.taskStatus == "INCOMPLETE" ||  params.data.taskStatus == "IN_COMPLETED") {
        //         return '<span class="p-mr-2  p-tag p-component p-tag-incomplete" ><span class="p-tag-value text-dark">Incomplete</span></span>';
        //       } else if (params.data.taskStatus == "CANCELLED") {
        //         return ('<span class="p-mr-2  p-tag p-component p-tag-cancel" ><span class="p-tag-value text-dark">Incomplete</span></span>');
        //       }
        //     }
        //   },
        // },
        // ]
      this.defaultColDef = {
        enableRowGroup: true,//ag-Grid-Enterprise
        enablePivot: true,//ag-Grid-Enterprise
        enableValue: true,//ag-Grid-Enterprise
        sortable: true,
        resizable: true,
        filter: false,
        tooltipComponent: TableHeaderToolTipComponent
      };
      //this.overlayNoRowsTemplate = "<div class='not-found'><span>{{'general.messages.noRecordsFound'}}</span> </div>";
      this.myGroup = new UntypedFormGroup({
        taskDateRange: new UntypedFormControl(''),
        status:new UntypedFormControl('')
      });
    }
    
  ngOnInit() {
    router = this.router;
    this.createTask = this.perm.capable("tasks", "create");
    
    dateFormateChange = this.auth.getUserTimezone();
    if (this.route.snapshot.data['mode']) {
      this.mode = this.route.snapshot.data['mode']
      
      // if(this.mode == 'today'){
      //   var currentDate: any = new Date()
     
      //   var currentDate: any = moment(new Date()).format('YYYY-MM-DD')
      //   var fromDateTime = moment(currentDate+ ' ' + " 12:00 AM");
      //   this.fromDateTime = moment.tz(fromDateTime, this.auth.getUserTimezone()).utc().format();
       
      //   var toDateTime: any = moment(currentDate+ ' ' + " 11:59 PM");
      //   this.toDateTime = moment.tz(toDateTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
      //   console.log( this.fromDateTime,"======",this.toDateTime);
  
      //   }else if(this.mode == 'upcoming'){
      //     var currentDate: any = new Date()
      //     currentDate.setDate(currentDate.getDate() + 1);
      //     var newDate : any = new Date(currentDate)
      //     var newDate: any = moment(newDate).format('YYYY-MM-DD')
      //     var fromDateTime = moment(newDate+ ' ' + " 12:00 AM");
      //     this.fromDateTime = moment.tz(fromDateTime, this.auth.getUserTimezone()).utc().format();
         
      //     var toDateTime: any = moment(newDate+ ' ' + " 11:59 PM");
      //     this.toDateTime = moment.tz(toDateTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
      //     console.log( this.fromDateTime,"======",this.toDateTime);
  
      // }else if(this.mode == 'completed'){
      //   this.fromDateTime =  null;
      //   this.toDateTime = null
      //   this.filter.status = 'COMPLETED'
        
      //   }
      }
      this.preInit();
   this.spinner.show()
  }


  /********************************** PreInit *****************************/
  private preInit() {
    this.dateFormat = localStorage.getItem("date_format");
    dateFormateChange = this.auth.getUserTimezone();

    this.dynamicFormService.getTableColumn("task").subscribe((res: any) => {
      this.sortBy = res.sortBy
      this.sortOrder = res.sortOrder
     
      var columnDefs: any = this.gridOptions.columnDefs;
      res.tableColumnSetups.forEach((element: any) => {
        this.cellRenderer(element);

        columnDefs.push(element);
        this.gridOptions.api!.setColumnDefs(columnDefs);
      });
     // columnDefs.push(this.actionData);
      this.gridOptions.api!.setColumnDefs(columnDefs);

      this.loadTable = 1;

      this.onGridReady(this.gridParams);
    });
  }
    /********************************** Render Cell *****************************/
    private cellRenderer(element: any) {
      console.log(element,"===",element.dataType)
      
      var dateFormat: any = localStorage.getItem("date_format");
  
      
      element.headerTooltip = "#";
  
      if (element.field === "taskNumber") {
        element.headerComponentParams = {
          template:
              '<div class="ag-cell-label-container" role="presentation" id="Tbl_HeadAssetNo">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>'
      },
       // element.headerName = "Asset No.";
        element.cellClass = function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color-top"];
        };
        element.cellRenderer = function (params: any) {
          if(params.data){
            if(params.data && params.data.taskNumber != null){
              return '<p id="'+params.data.rowId+'">'+params.data.taskNumber+'</p>';
             }else {
               return '<span class="p-tag-value text-dark"> ' + "-"+ '</span>';
               
             }
          }
        };
      }
  
      else if (element.field === "customer.name") {
       // element.headerName = "Customer";
        element.headerComponentParams = {
          template:
              '<div class="ag-cell-label-container" role="presentation" id="Tbl_HeadAssetCus">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>'
      },
        element.cellClass = function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color-top"];
        };
        element.cellRenderer = function (params: any) {
          if(params.data){
            if ( params.data && params.data.customer != null) {
              return '<p id="'+params.data.rowId2+'">'+params.data.customer?.name+'</p>';
            } else if(params.data && params.data.customer == null ) {
              return '<span class="p-tag-value text-dark"> ' + "-"+ '</span>';
            }
          
          }
        };
      }
  
      else if (element.field === "taskStatus") {
      
         element.cellRenderer = function (params: any) {
          if (params.data) {
            if (params.data.taskStatus == "OPEN") {
              return ('<span class="p-mr-2 p-tag p-component p-tag-open"><span class="p-tag-value text-dark">Open</span></span>' );
            } else if (params.data.taskStatus == "PENDING") {
              return (
                '<span class="p-mr-2  p-tag p-component p-tag-pending" ><span class="p-tag-value text-dark">Pending</span></span> ');
            } else if ( params.data.taskStatus == "IN PROGRESS" || params.data.taskStatus == "IN_PROGRESS") {
              return '<span class="p-mr-2  p-tag p-component p-tag-in-progress" ><span class="p-tag-value text-dark">In Progress</span></span>';
           
            } else if (params.data.taskStatus == "COMPLETED") {
              return ('<span class="p-mr-2  p-tag p-component p-tag-completed" ><span class="p-tag-value text-dark">Completed</span></span>');
            
            } else if ( params.data.taskStatus == "INCOMPLETE" ||  params.data.taskStatus == "IN_COMPLETED") {
              return '<span class="p-mr-2  p-tag p-component p-tag-incomplete" ><span class="p-tag-value text-dark">Incomplete</span></span>';
            } else if (params.data.taskStatus == "CANCELLED") {
              return ('<span class="p-mr-2  p-tag p-component p-tag-cancel" ><span class="p-tag-value text-dark">Incomplete</span></span>');
            }
          }
         };
       }
   
      
       else  if (element.dataType == 'date') {
        element.valueFormatter = function (params: any) {
         
            if (params && params.value) {
              return moment.tz(params.value, dateFormateChange).format(localStorage.getItem("date_format")!.toUpperCase());
            }
          };
        
      }
      else if (element.dataType == 'datetime') {
        element.cellRenderer = function (params: any) {
            if (params && params.value) {
              return moment.tz(params.value, dateFormateChange) .format(localStorage.getItem("date_format")!.toUpperCase()+ ", " + "hh:mm A");
            }else{
              return '-'
            }
          };
      }
      else if (element.field === "assignedTo") {
        element.cellRenderer = function (params: any) {
          if (params && params.data) {
                    var assignedUsersList = "";
                    if(params.data.assignedUsersList.length > 1){
                      let coun=params.data.assignedUsersList.length -1
                      assignedUsersList= '<div style="background: #26363e;height:20px;padding: 5px;margin-left: 4px; margin-top: 2.5px; border-radius: 5px" class="d-flex align-items-center"><span style="color: #fff">+'+coun+'</span></div>';
                    }
      
                    var nameData=  params.data.assignedTo.firstName + " " + params.data.assignedTo.lastName
                    return ("<div class='d-flex'>" + nameData + assignedUsersList + "</div>" );
                  }
        };
      }
      else if (element.field === "priority") {
      
        element.cellRenderer = function (params: any) {
         if (params.data) {
           if (params.data.priority == "Medium") {
             return ('<span class="p-mr-2 p-tag p-component p-tag-medium"><span class="p-tag-value text-dark">Medium</span></span>' );
           
            } else if (params.data.priority == "Low") {
             return ( '<span class="p-mr-2  p-tag p-component p-tag-low" ><span class="p-tag-value text-dark">Low</span></span> ');
          
            } else if ( params.data.priority == "High") {
             return '<span class="p-mr-2  p-tag p-component p-tag-in-high" ><span class="p-tag-value text-dark">High</span></span>';
          
           }
         }
        };
      }
      else if (element.dataType == "text") {
        element.valueFormatter = function (params: any) {
          if (params && params.value && params.value != null) {
              return params.value
            }else{
              return '-'
            }
          };
        
        }
        else if (element.dataType == "boolean") {
          element.cellRenderer = function (params: any) {
              if (params && params.value) {
                return 'Yes'
                }else{
                  return 'No'
                }
              }  
        }
    }

    
  setDate(){
    this.filter.fromDateTime = moment(this.taskDateRange).format('YYYY-MM-DD');
    this.filter.toDateTime = moment(this.taskDateRange).format('YYYY-MM-DD');

    var currentDate: any = moment(this.filter.fromDateTime).format('YYYY-MM-DD')
    var fromDateTime = moment(currentDate+ ' ' + " 12:00 AM");
    this.fromDateTime = moment.tz(fromDateTime, this.auth.getUserTimezone()).utc().format();
   
    var currentDate: any = moment(this.filter.toDateTime).format('YYYY-MM-DD')
    var toDateTime: any = moment(currentDate+ ' ' + " 11:59 PM");
    this.toDateTime = moment.tz(toDateTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
    console.log( this.fromDateTime,"======",this.toDateTime);
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes,"=====",this.taskDateRange)
    if (changes) {
      this.setDate()
      this.getFilter()
  
    }
  }

/********************************** Search Data **************************/
quickSearch() {
  ///console.log(this.searchValue.length)
  ///if (this.searchValue == '' || this.searchValue.length > 2) {
    this.onGridReady(this.gridParams);
  /// }         
 }
 getFilter(){
  this.onGridReady(this.gridParams);
 }
 /********************************** View Singal Record **************************/
 onGridReady(params:any) {
  //console.log(params)
  if(params){
  this.gridParams = params;

 this.gridApi = params.api;
 this.gridApi.sizeColumnsToFit();
  var dataSource = {
    getRows: (params: IServerSideGetRowsParams) => {
      // this.getServerSideData(params);
      if (this.loadTable === 1) { this.getServerSideData(params); }
    }
   }

  this.gridApi.sizeColumnsToFit();
  this.gridApi.setDatasource(dataSource);
 }
}
/********************************** Call Customer API to Display data **************************/
 getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
  this.gridApi.hideOverlay();
  const serverRequest = this.getRequestParams(agGridGetRowsParams);
  var count: any=serverRequest.startRow;
 console.log('Making a server request: ', serverRequest);

 this.push(
    this.taskService.getAllStaffTask(this.searchValue, serverRequest , this.fromDateTime, this.toDateTime, this.filter.status,null,true, null, null, this.selectedTeam, null, null).subscribe((data: any) => {
       console.log(data)
       this.spinner.hide()
       if(data.status === 200 || data.status === 201){  
        this.taskData = data.body.data;
        this.totalRecord = data.body.total
        this.taskData.forEach((element: any) => {
          count++
          element.rowId = 'Tbl_RecTaskNo' + count
          element.rowId2 = 'Tbl_RecTaskCust' + count
          element.buttonEdit = 'Btn_SitEdit' + count
          element.buttonDelete = 'Btn_SitDel' + count
        });

        this.setGridData(agGridGetRowsParams, this.taskData, data.body.total,data.body.status);
      }else{
          
        this.errorUtil.setErrorMessage(data.status, null);
      }
      }))
   
}
/********************************** Set Data for Grid Table **************************/
private setGridData(agGridGetRowsParams: IServerSideGetRowsParams, resultItems: any[], totalCount: number, status: string) {
  //if (resultItems) {

    if(status === 'NO_DATA'){
      this.noTask = false;
      this.noTaskIcon = true;
      this.gridParams.api.showNoRowsOverlay();
    }else{
      this.noTask = true;
      this.noTaskIcon = false;
    }
    if(resultItems.length === 0){
      this.gridParams.api.showNoRowsOverlay();
    }else{
      agGridGetRowsParams.successCallback(resultItems, totalCount);
    }

    
}
/********************************** Request Parameter for api **************************/

getRequestParams(agGridRequest: any): ServerRequest {
 
   if(agGridRequest.sortModel.length <= 0){
        this.parameters ={ colId: this.sortBy, sort: this.sortOrder }
        agGridRequest.sortModel.push(this.parameters);
      }

  //console.log(agGridRequest)
  this.currentRecordNumber = agGridRequest.startRow
  return {
    startRow: agGridRequest.startRow,
    pageSize: 25,
    filterModel: null,
    sortModel: agGridRequest.sortModel
  }
}
/********************************** View Singal Record **************************/

 viewCustomer(e:any) {
  if (e.colDef.field == "taskNumber") {
    this.util.openTaskVIewPage(e.data.id)
    // this.router.navigate(['dashboard/tasks/view'], { queryParams: { id: e.data.id}});
  }else if (e.colDef.field == "taskName") {
    this.util.openTaskVIewPage(e.data.id)
    }else{
      this.dynamicTableColumnsService.redirectURL(e)
    }
 }

ngOnDestroy() {
  //this.refresher.unsubscribe()
  super.ngOnDestroy();
}


optimize(){
  this.router.navigate(['dashboard/tasks/optimize'])
}

clone(){
  const data={
    fromDateTime: this.fromDateTime,
    toDateTime: this.toDateTime
  }
  this.modalService2.openModal('taskClone', data);
  
}

resetPaymentSearch(){
  // this.dueDateRange = [];
  // this.taskDateRange = [];
  this.myGroup.get('taskDateRange').setValue(null);
  this.filter = {
    status : '',
    dueStartDate : '',
    dueEndDate : '',
    fromDateTime : '',
    toDateTime : ''
  }
  this.fromDateTime = null
  this.toDateTime = null
  this.onGridReady(this.gridParams);
   
}
onCompletionDateChange(event: any){
  console.log(event,"====",this.taskDateRange)
  if(this.taskDateRange[0] != null && this.taskDateRange[1] !== null){
    // this.selectedRange = event;
    this.filter.fromDateTime = moment(this.taskDateRange[0]).format('YYYY-MM-DD');
    this.filter.toDateTime = moment(this.taskDateRange[1]).format('YYYY-MM-DD');

    // this.fromDateTime =  moment.tz(this.filter.fromDateTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
    // this.toDateTime = moment.tz(this.filter.toDateTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
    var currentDate: any = moment(this.filter.fromDateTime).format('YYYY-MM-DD')
    var fromDateTime = moment(currentDate+ ' ' + " 12:00 AM");
    this.fromDateTime = moment.tz(fromDateTime, this.auth.getUserTimezone()).utc().format();
   
    var currentDate: any = moment(this.filter.toDateTime).format('YYYY-MM-DD')
    var toDateTime: any = moment(currentDate+ ' ' + " 11:59 PM");
    this.toDateTime = moment.tz(toDateTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
    console.log( this.fromDateTime,"======",this.toDateTime);


    this.getFilter()
  } 
 
}


onFilteruttonClick(event: any) {
  this.filterActive = !this.filterActive;
  this.filterClick = !this.filterClick;
  event.preventDefault();
}
onClick(event: any) {
  console.log(event.target.classList.contains('p-ripple'),"====",event.target.classList.contains('p-ripple'),event.target.classList.contains('p-highlight')); 
 if(!event.target.classList.contains('p-ripple') && !event.target.classList.contains('p-element') && !event.target.classList.contains('p-highlight')){
  console.log("taskDateRange*****",this.taskDateRange); 
     if(this.taskDateRange !== undefined && this.taskDateRange !== null ){
      console.log(this.taskDateRange,"===@@@@@"); 
      if(this.taskDateRange[1] == null ){
      this.myGroup.get('taskDateRange').setValue(null);
      this.filter.fromDateTime = null
      this.filter.toDateTime = null

      this.fromDateTime = null
      this.toDateTime = null
    } }}

}
openMapLocation(){
  if(this.mode == 'all'){
  this.router.navigate(['dashboard/tasks/teamTaskMapView'])
  }else if(this.mode == 'today'){
    this.router.navigate(['dashboard/tasks/todayTeamTaskMapView'])
  }else{
    this.router.navigate(['dashboard/tasks/upcomingTeamTaskMapView']) 
  }
}
openModal(modalView: any, data : any) {
  data={mode : 'add' }
  this.modalService2.openModal(modalView, data);
}
public clearDate() {
  this.taskDateRange = [];

  this.myGroup.get('taskDateRange').setValue(null);

  this.filter.dueStartDate = "";
  this.filter.dueEndDate = "";
  this.filter.fromDateTime = "";
  this.filter.toDateTime = "";

  this.fromDateTime = null
  this.toDateTime = null

  this.onGridReady(this.gridParams);

}
push(obs:any) {
  super.push(obs);
}
/******************************************** Right Click Context Menu ******************************************/
// public getContextMenuItems = (params: any) => {
//   params.node.data.field = params.column.colId;
//   let url = "";
//   let data = "";

//   let results = [];

//   if (params.column.colId == "taskName") {
//     const data = params.node.data;

//     results.push({
//       name: "Open link in new tab",
//       action: () => this.redirectToNewTab(data),
//     });
//   }

//   return results;
// };
public getContextMenuItems = (params: any) => {
  params.node.data.field = params.column.colId;

  return this.dynamicTableColumnsService.rightClick(params);
};

/******************************************** Redirect To ******************************************/
private redirectToNewTab(data: any) {
  let url;
  if (data.field === "taskName") {
    url = this.router.serializeUrl(
      this.router.createUrlTree(
        ['/dashboard/tasks/view'], { queryParams: { id: data.id}}
      )
    );
  }
  if (url) {
    url = url.replace("%23", "#");
    this.util.openItemToNewTab(url);
  }
}
}

