<p-tabView class="tabs-low-user" #tabView (onChange)="handleChange($event,tabView)" [(activeIndex)]="activeIndexAccess" id="tabView">
    <p-tabPanel [header]="loadTabs[0].headerName">
        <div class="custella-setup-body pt-0" *ngIf="translate">
            <div class="d-flex button-box-top-right"> 
                <p-button *ngIf="editForm && profile?.status != 'DEACTIVE'" id="btn_SetUserSubmit" styleClass="ms-2 py-2 px-4 text-nowrap h-28" [label]="translate?.general.buttons.save.singular"(onClick)="saveProfile()"></p-button>
                <p-button *ngIf="!editForm && profile?.status != 'DEACTIVE'" id="btn_SetUserEdit" styleClass="ms-2 py-2 px-4 text-nowrap h-28" [label]="translate?.general.buttons.edit.singular"  (onClick)="clickEdit()"></p-button>
                <button  *ngIf="editForm && profile?.status != 'DEACTIVE'" id="btn_SetUserCancel" class="custella-btn-white text-12 ms-2 h-28 pt-1" (click)="clickEdit()">{{translate?.general.buttons.cancel.singular}}</button>
                 
                <!-- <button pButton class="h-30" [ngClass]="{'p-button-secondary': editForm }" label="{{editForm ? 'Cancel':'Edit'}}" (click)="clickEdit()" ></button>            -->
               
               <div class="dropdown " *ngIf="profile?.status != 'DEACTIVE'">
                    <div class="" data-bs-toggle="dropdown"  >
                            <button class="no-border btn-28 rounded-1 ms-2" id="btn_SetUserDropdown"> <i class="pi pi-ellipsis-v pt-04"></i> </button>
                    </div>
                    <ul class="dropdown-menu dropdown-collaps p-0">
                           <li class="dropdown-item px-3 py-3 cursor-pointer" id="btn_SetUserResetPwd" (click)="resetPassword()" *ngIf="profile?.status == 'ACTIVE' ">{{translate?.general.buttons.resetPassword.singular}}</li>
                           <li class="dropdown-item px-3 py-3 cursor-pointer" id="btn_SetUserResendEmail" (click)="resendEmail()" *ngIf="profile?.status == 'PENDING_ACTIVATION'" >{{translate?.general.buttons.resendEmail.singular}}</li>
                     </ul>
                </div>
            </div>
            
            <div class="custella-form-container left-right-space rounded-2" *ngIf="profile?.id">
                    <!-- Personal Info -->
                    <div class="custella-form-container-title text-14 font-medium rounded-top flex-between" id="title_SetUserPersonalInfo" >
                        <p class="m-0">{{ translate?.setup.users.users.sections.sec0.singular  }}</p> 
                        <!-- User Status Dropdown -->
                        <div class="dropdown ps-2" *ngIf="sharedVar.id != auth.getUserId()">
                            <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown" id="btn_SetUserStatusDropdown" >
                                <div class="d-flex align-items-center">
                                    <span *ngIf="profile?.status == 'ACTIVE'"  class="p-mr-2 p-tag-success p-tag p-component"><span id="label_SetUserStatusActive" class="p-tag-value">{{translate?.general.status.active.singular}}</span></span>
                                    <span *ngIf="profile?.status == 'SUSPENDED'" class="p-mr-2 p-tag-Suspended p-tag p-component"><span id="label_SetUserStatusSuspendActive" class="p-tag-value">{{translate?.general.status.suspended.singular}}</span></span>
                                    <span *ngIf="profile?.status == 'DEACTIVE'" class="p-mr-2 p-tag-Deactivate p-tag p-component"><span id="label_SetUserStatusDeactive" class="p-tag-value">{{translate?.general.status.deactivated.singular}}</span></span>
                                    <div *ngIf="profile?.status == 'PENDING_ACTIVATION'"  class="p-mr-2 p-tag-Pending-Activation p-tag p-component"><span id="label_SetUserStatusPending" class="p-tag-value">{{translate?.general.status.pendingActivation.singular}}</span></div>
                                    <div *ngIf="profile?.status == 'PENDING_ACTIVATION' || profile?.status == 'SUSPENDED' || profile?.status == 'ACTIVE'" role="button" aria-haspopup="listbox" class="p-dropdown-trigger ms-2">
                                        <span class="p-dropdown-trigger-icon  pi pi-chevron-down" id="btn_SetUserStatusArrowDown"></span>
                                    </div>
                                </div>
                                <ul class="dropdown-menu dropdown-collaps p-0">
                                    <li class="dropdown-item px-3 py-2 text-12" *ngIf="profile?.status == 'SUSPENDED'" id="btn_SetUserActivate" (click)="openModal3('activateUser', profile);$event.stopPropagation();">
                                        <span>{{translate?.general.status.activate.singular}}</span>
                                    </li>
                                    <li class="dropdown-item px-3 py-2 text-12" id="btn_SetUserSuspend" *ngIf="profile?.status == 'ACTIVE'" (click)="openModal3('suspendUser', profile);$event.stopPropagation();">
                                        <span>{{translate?.general.status.suspend.singular}}</span>
                                    </li>
                                    <li class="dropdown-item px-3 py-2 text-12" *ngIf="profile?.status != 'DEACTIVE'" id="btn_SetUserDeactivate" (click)="openModal3('deactivateUser', profile);$event.stopPropagation();">
                                        <span>{{translate?.general.status.deactivate.singular}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="custella-form-container-content-border-bottom">
                        <div class="custella-content-row">
                            <div class="custella-content-row-input col-12">
                                <div class="custella-vp-picture d-flex align-items-center">
                                    <p-avatar [image]="photoUrl" styleClass="mr-2 mt-1" id="img_SetUserAvatar" size="xlarge"></p-avatar>
                                    <!-- <div *ngIf="editForm" class="flex-between" >
                                        <button (click)="openModal('uploadPhoto',profile)" pButton type="button" icon="pi pi-upload" label="Upload Image" class="p-button-outlined p-button-secondary mx-1"></button>
                                       </div> -->
        
                                    <!-- Edit Photo Dropdown  -->
                                    <div *ngIf="editForm" class="flex-between">
                                        <div class="dropdown ps-2">
                                            <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown" id="btn_SetUserPhotoDropdown" >
                                                <button pButton type="button" icon="pi pi-pencil" [label]="translate?.general.buttons.edit.singular" id="btn_SetUserPhotoEdit" class="p-button-outlined p-button-secondary mx-1" style="background: white !important; cursor: pointer;" [disabled]="!update"></button>
                                            </div>
                                            <ul class="dropdown-menu dropdown-collaps p-0">
                                                <li class="dropdown-item px-3 py-3 cursor-pointer" id="btn_SetUserPhotoRemove" (click)="openModal3('removePhoto',profile)">{{translate?.general.buttons.removePhoto.singular}}</li>
                                                <li class="dropdown-item px-3 py-3 cursor-pointer" id="btn_SetUserPhotoUpload" (click)="openModal('uploadPhoto',profile)">{{translate?.general.buttons.uploadPhoto.singular}}</li>
                                            </ul>
                                        </div>
                                   </div>
        
                                   
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div class="custella-form-container-content-border-bottom">
                        <div class="custella-content-row">
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title color-var-500" id="label_SetUserFirstName" [ngClass]="{'mandatory': editForm}" > {{ translate?.setup.users.users.fields.firstName.singular  }} </span>
                                <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserFirstName">{{ profile?.firstName }}</span>
                                <input *ngIf="editForm" type="text" pInputText autocomplete="off" placeholder="" [(ngModel)]="profile.firstName" id="input_SetUserFirstNameField"/>
                            </div>
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title color-var-500" [ngClass]="{'mandatory': editForm}" id="label_SetUserLastName" >{{ translate?.setup.users.users.fields.lastName.singular  }}</span>
                                <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserLastName">{{ profile?.lastName }}</span>
                                <input *ngIf="editForm" type="text" pInputText autocomplete="off" placeholder="" [(ngModel)]="profile.lastName" id="input_SetUserLastNameField" />
                            </div>
                        </div>
                    </div>
                    <div class="custella-form-container-content-border-bottom">
                        <div class="custella-content-row">
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title color-var-500" id="label_SetUserEmail" [ngClass]="{'mandatory': editForm}" >{{ translate?.setup.users.users.fields.email.singular  }}</span>
                                <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserEmail">{{profile?.email}}</span>
                                <input *ngIf="editForm" type="text" pInputText autocomplete="off" id="input_SetUserEmailField" placeholder="" [(ngModel)]="profile.email" />
                            </div>
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title color-var-500" id="label_SetUserPhone" >{{ translate?.setup.users.users.fields.phone.singular  }}</span>
                                <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserPhone">{{profile.phone ? profile.phone:'-'}}</span>
                                <input *ngIf="editForm" type="text" pInputText autocomplete="off" id="input_SetUserPhoneField" placeholder="" [(ngModel)]="profile.phone" />
                            </div>
                        </div>
                    </div>
                     <!-- Login Info -->
                     <div class="custella-form-container-title-two text-14 font-medium" id="title_SetUserLoginReport" >{{ translate?.setup.users.users.sections.sec17.singular  }}</div>
                     <div class="custella-form-container-content-border-bottom">
                         <div class="custella-content-row">
                             <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title color-var-500" id="label_SetUserUserName" >{{ translate?.setup.users.users.fields.username.singular  }}</span>
                                <span class="custella-content-row-input-title-three" id="value_SetUserUserName">{{ profile.login }}</span>
                                <!-- <input *ngIf="editForm" type="email" pInputText autocomplete="off" placeholder="" [(ngModel)]="profile.login"  /> -->
                             </div>
                             <div class="custella-content-row-input col-6">
                                 <span class="custella-content-row-input-title color-var-500" id="label_SetUserManager" >{{ translate?.setup.users.users.fields.manager.singular  }}</span>
                                 <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserManager">{{profile.superVisorName ? profile.superVisorName:'-'}}</span>
                                
                                 <p-dropdown *ngIf="editForm" 
                                    [disabled]="!update"
                                    [placeholder]="translate?.general.messages.none"
                                    [options]="users"
                                    [showClear]="true"
                                    optionLabel="fullname"
                                    optionValue="id"
                                    name="superVisor"
                                    [(ngModel)]="profile.superVisorId"
                                   [ngModelOptions]="{ standalone: true }"
                                   id="picklist_SetUserManagerField"
                                    > </p-dropdown>
        
                             </div>
                         </div>
                     </div>
                     
                    <!-- Access and Hierarchy -->
                    <div class="custella-form-container-title-two text-14 font-medium" id="title_SetUserAccessHierarchy" >{{ translate?.setup.users.users.sections.sec2.singular  }}</div>
                    <div class="custella-form-container-content-border-bottom">
                        <div class="custella-content-row">
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title color-var-500" id="label_SetUserAccess" [ngClass]="{'mandatory': editForm}" >{{ translate?.setup.users.users.fields.access.singular  }}</span>
                                
                                <span *ngIf="!editForm && update" class="custella-content-row-input-title-three-link" id="link_SetUserAccessProfile" [ngClass]="read ? 'custella-content-row-input-title-three-link' : 'custella-content-row-input-title-three'" (click)="navigateTo('access', profile.profileId)">{{profile.profileName}}</span>
                                <span *ngIf="!editForm && !update" class="custella-content-row-input-title-three" id="value_SetUserAccessProfile">{{profile.profileName}}</span>
                                
                                <span *ngIf="!editForm && !profile.profileId" class="custella-content-row-input-title-three" id="value_SetUserAccessProfile">-</span>
                                
                                <p-dropdown *ngIf="editForm" 
                                [disabled]="!update"
                                [placeholder]="translate?.general.messages.none "
                                [options]="profiles"
                                [showClear]="true"
                                optionLabel="name"
                                optionValue="id"
                                name="profileName"
                                [(ngModel)]="profile.profileId"
                                id="picklist_SetUserAccessField"
                                 ></p-dropdown>
                            </div>
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title color-var-500" id="label_SetUserHierarchy" [ngClass]="{'mandatory': editForm}" >{{ translate?.setup.users.users.fields.hierarchy.singular  }}</span>
                                <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserHierarchyRole">{{profile.roleName}}</span>
                                <p-dropdown *ngIf="editForm" 
                                [disabled]="!update"
                                [placeholder]="translate?.general.messages.none "
                                [options]="roles"
                                [showClear]="true"
                                optionLabel="name"
                                optionValue="id"
                                name="profileName"
                                [(ngModel)]="profile.roleId"
                                id="picklist_SetUserHierarchyField"
                                 ></p-dropdown>
                            </div>
                        </div>
                    </div>
        
                    <!-- Additional Details -->
                    <div class="custella-form-container-title-two text-14 font-medium" id="title_SetUserAdditionalDets" >{{ translate?.setup.users.users.sections.sec3.singular  }}</div>
                    <div class="custella-form-container-content-border-bottom">
                        <div class="custella-content-row">
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title color-var-500" id="label_SetUserEmployeeNo" >{{ translate?.setup.users.users.fields.employeeNo.singular  }}</span>
                                <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserEmployeeNo">{{profile.employeeNo ? profile.employeeNo:'-'}}</span>
                                <input *ngIf="editForm"  pInputText autocomplete="off" type="text" id="input_SetUserEmployeeNoField" maxlength="255"  name="employeeNo" [(ngModel)]="profile.employeeNo"  />
                         
                            </div>
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title color-var-500" id="label_SetUserGeofence" >{{ translate?.setup.geofence.label.singular  }}</span>
                                <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserGeofence">{{ profile?.geoFence?.id ? profile?.geoFence?.geoFenceName:'-' }}</span>
                                <p-dropdown *ngIf="editForm" 
                                [placeholder]="translate?.general.messages.none "
                                [options]="geofences"
                                [showClear]="true"
                                optionLabel="geoFenceName"
                                optionValue="id"
                                name="geoFenceName"
                                [(ngModel)]="profile.geoFence.id"
                                id="picklist_SetUserGeofenceField"
                                ></p-dropdown>
                                
                            </div>
                        </div>
                    </div>
                    
                    <div class="custella-form-container-content-border-bottom">
                        <div class="custella-content-row">
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title color-var-500" id="label_SetUserDepot" >{{ translate?.setup.users.users.fields.depot.singular  }}</span>
                                <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserDepot">{{profile?.defaultDepot?.id ? profile?.defaultDepot?.name:'-'}}</span>
                                <p-dropdown *ngIf="editForm && sharedVar?.defaultDepot" 
                                [disabled]="!update"
                                filterBy="name"
                                [options]="allDepots"
                                [showClear]="true"
                                optionLabel="name"
                                optionValue="id"
                                name="dateFormatString"
                                [(ngModel)]="profile.defaultDepot.id"
                                [placeholder]="translate?.general.messages.none "
                                id="picklist_SetUserDepotField"
                                ></p-dropdown>
        
                              
                            </div>
                           
                           
                        </div>
                    </div>
                   
                    
                    <!-- Preferences -->
                    <div class="custella-form-container-title-two text-14 font-medium" id="title_SetUserPreference" >{{ translate?.setup.users.users.sections.sec8.singular  }}</div>
                    <div class="custella-form-container-content-border-bottom">
                        <div class="custella-content-row">
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title color-var-500" id="label_SetUserBufferTime" [ngClass]="{'mandatory': editForm}" >{{ translate?.setup.users.users.fields.bufferTime.singular  }}</span>
                                <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserBufferTimeDuration">{{ profile.startBuffer }}&nbsp;{{ translate?.setup.users.users.fields.mins.singular  }} - {{ profile.endBuffer }}&nbsp;{{ translate?.setup.users.users.fields.mins.singular  }}</span>
                                    <div *ngIf="editForm" class="p-0 d-flex align-items-center">
                                        <p-dropdown filterBy="label" [options]="bufferMinutes" class="col" id="picklist_SetUserBufferStartTimeField" [showClear]="true" optionLabel="label" optionValue="value" name="startBuffer" [(ngModel)]="profile.startBuffer" [placeholder]="translate?.general.messages.none " ></p-dropdown>
                                        <span  class=" mx-2 custella-content-row-input-title-three"> - </span>
                                        <p-dropdown filterBy="label" [options]="bufferMinutes" class="col" id="picklist_SetUserBufferEndTimeField" [showClear]="true" optionLabel="label" optionValue="value" name="endBuffer" [(ngModel)]="profile.endBuffer" [placeholder]="translate?.general.messages.none " ></p-dropdown>
                                    </div>
        
                            </div>
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title color-var-500" id="label_SetUserDefMobileView" [ngClass]="{'mandatory': editForm}" >{{ translate?.setup.users.users.fields.defaultMobileView.singular  }}</span>
                                <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserDefMobileView">{{profile.defaultDashboard ? profile.defaultDashboard:'-'}}</span>
                                <p-dropdown *ngIf="editForm" 
                                filterBy="label"
                                [options]="defaultDashboards"
                                [showClear]="true"
                                optionLabel="label"
                                optionValue="value"
                                name="defaultDashboard"
                                [(ngModel)]="profile.defaultDashboard"
                                [placeholder]="translate?.general.messages.none"
                                id="picklist_SetUserDefMobileViewField" 
                                ></p-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="custella-form-container-content-border-bottom">
                        <div class="custella-content-row">
                            <div class="custella-content-row-input col-6">
                                
                                <span class="custella-content-row-input-title color-var-500" id="label_SetUserTimezone"  [ngClass]="{'mandatory': editForm}" >{{ translate?.setup.users.users.fields.timeZone.singular  }}</span>
                                <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserTimezone"> {{profile.timezone}}</span>
                                <p-dropdown  *ngIf="editForm"
                                    [filter]="true"
                                    filterBy="label"
                                    [options]="timeZoneData"
                                    [showClear]="true"
                                    optionLabel="label"
                                    optionValue="value"
                                    name="timezone"
                                    [(ngModel)]="profile.timezone"
                                    [placeholder]="translate?.general.messages.none"
                                    id="picklist_SetUserTimezoneField"
                                    ></p-dropdown>
                            </div>
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title color-var-500" id="label_SetUserDateFormat" [ngClass]="{'mandatory': editForm}" >{{ translate?.setup.users.users.fields.dateFormat.singular  }}</span>
                                <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserDateFormat">{{profile.dateFormatString}}</span>
                                <p-dropdown  *ngIf="editForm"
                                    filterBy="label"
                                    [options]="dateformatList"
                                    [showClear]="true"
                                    optionLabel="label"
                                    optionValue="value"
                                    name="dateFormatString"
                                    [(ngModel)]="profile.dateFormatString"
                                    [placeholder]="translate?.general.messages.none"
                                    id="picklist_SetUserDateFormatField"
                                    ></p-dropdown>
                            </div>
                        </div>
                    </div>
                    <!-- 15/8 Adding Default Language Selection -->
                    <div class="custella-form-container-content-border-bottom">
                        <div class="custella-content-row">
                            <div class="custella-content-row-input col-6">
                                
                                <span class="custella-content-row-input-title color-var-500" id="label_SetUserDefaultLang" >{{ translate?.setup.users.users.fields.defaultLanguage.singular  }}</span>
                                <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserDefaultLang" >
                                    <span *ngFor="let lan of languageList">
                                        <span *ngIf="lan.value == profile.language ">{{lan.label}}</span>
                                    </span> </span>
                                <p-dropdown  *ngIf="editForm" [(ngModel)]="profile.language" [options]="languageList" [placeholder]="translate?.general.buttons.select2.singular"id="picklist_SetUserDefaultLangField" ></p-dropdown>
                            </div>
                        </div>
                    </div>
        
                    <!-- Working Hours -->
                    <div class="custella-form-container-title-two text-14 font-medium" id="label_SetUserWorkHrs" >{{ translate?.setup.general.workingHours.label.singular  }}</div>
                    <div class="custella-form-container-content-border-bottom">
                    <div class="custella-content-row">
                        <div class="custella-content-row-input col-6">
                            <div class="d-flex align-items-center me-3">
                                <div class="custella-content-row-input me-2">
                                    <span *ngIf="!editForm && profile.shiftWorking == '0'" id="label_SetUserWorkBasedShift" class="mb-0 normal-font fw-bold fst-italic">{{ translate?.setup.users.users.sections.sec9.singular  }}</span>
                                    <button [disabled]="!update"  pButton *ngIf="editForm" id="btn_SetUserWorkBasedShift" type="button" class="rounded-default-button py-1" [ngClass]="{'rounded-chosen-button':profile.shiftWorking == '0'}" (click)="shiftBasedChange(0)" >{{ translate?.setup.users.users.sections.sec9.singular  }}</button>
                                </div>
                                <div class="custella-content-row-input">
                                    <span *ngIf="!editForm && profile.shiftWorking == '1'" id="label_SetUserWorkBasedTime" class="mb-0 normal-font fw-bold fst-italic">{{ translate?.setup.users.users.sections.sec10.singular  }}</span>
                                    <button [disabled]="!update" pButton type="button" id="btn_SetUserWorkBasedTime" *ngIf="editForm" class="rounded-default-button py-1" [ngClass]="{'rounded-chosen-button':profile.shiftWorking == '1'}" (click)="shiftBasedChange(1)">{{ translate?.setup.users.users.sections.sec10.singular  }}</button>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="custella-content-row" *ngIf="profile.shiftWorking == '0'">
                        <!-- Shift Based -->
                        <div class="custella-content-row-input col-6">
                                <div class="custella-content-row-input col-12">
                                    <span class="custella-content-row-input-title color-var-500" id="label_SetUserWorkMaxHrs" >{{ translate?.setup.users.users.fields.maxWorkingHours.singular  }}</span>
                                    <div class="d-flex align-items-center col-12 p-0">
                                        <input  (input)="detectIsUserCalenderUpdated()" [disabled]="!update" *ngIf="editForm" style="width: 100px;" id="input_SetUserWorkMaxHrsField" type="text" pInputText autocomplete="off" [(ngModel)]="profile.maxShiftHousrsPerDay">
                                        <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserWorkMaxHrs">{{profile.maxShiftHousrsPerDay}}</span>
                                        <p class="custella-content-row-input-title-three px-3 mb-0" id="label_SetUserWorkMaxHrsPerHour" >{{ translate?.setup.users.users.fields.hoursPer.singular  }}</p>
                                        <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserWorkMaxHrsPerHour">{{profile.maxShiftHourType | titlecase}}</span>
                                        <p-dropdown (onChange)="detectIsUserCalenderUpdated()" [disabled]="!update" *ngIf="editForm" id="picklist_SetUserWorkMaxHrsPerHourField" [(ngModel)]="profile.maxShiftHourType" filterBy="label" [options]="maxShiftHourType" [showClear]="false" optionLabel="label" optionValue="value" name="startBuffer" [placeholder]="translate?.general.messages.none"> </p-dropdown>
                                    </div>
                                </div>
                            
                        </div>
                        
                    </div>
                    <!-- Working Hours -->
                        <div class="custella-content-row" *ngIf="profile.shiftWorking == '1'">
                            <div class="custella-content-row-input col-6">
                                <div class="custella-content-row-two my-2">
                                    <div class="custella-content-row my-2 align-items-center">
                                        <div class="w-150">
                                            <div class="d-flex position-relative">
                                                <div *ngIf="editForm" class="p-field-checkbox">
                                                    <p-checkbox [binary]="true" [ngModelOptions]="{standalone: true}"
                                                        (onChange)="selectWorkingDays('monday', profile.userCalendar.monday)" id="check_SetUserShiftMon"
                                                        [(ngModel)]="profile.userCalendar.monday" (onChange)="detectIsUserCalenderUpdated()"></p-checkbox>
                                                </div>
                                                <span class="text-12 ms-3" id="label_SetUserShiftMon">{{translate?.setup.general.workingHours.fields.monday.singular }}</span>
                                            
                                            </div>
                                        </div>
                                        <span *ngIf="!editForm && !profile?.userCalendar?.mondayAllDay" id="value_SetUserShiftMonDateTime" class="text-12 ms-3 w-200" >{{profile.userCalendar.mondayStart  | momentDate : ' hh:mm a' :  timezone}} - {{profile.userCalendar.mondayEnd  | momentDate : ' hh:mm a' :  timezone}}</span>
                                        <span *ngIf="!editForm && profile?.userCalendar?.mondayAllDay" class="text-12 ms-3 w-200" id="value_SetUserShiftMonAllDay">{{translate?.setup.general.workingHours.fields.allDay.singular }}</span>
                                        <div class="w-200" *ngIf="editForm">
                                            <p-calendar [disabled]="!profile.userCalendar.monday || profile.userCalendar.mondayAllDay"
                                                [(ngModel)]="profile.userCalendar.mondayStart" [ngModelOptions]="{standalone: true}"
                                                pattern="hh:mm a" [stepMinute]="15" hourFormat="12" [timeOnly]="true" id="picklist_SetUserShiftMonStartTimeField"
                                                inputId="timeonly" (onSelect)="detectIsUserCalenderUpdated()" appendTo="body"></p-calendar>
                                        </div>
                                        <div class="px-2" *ngIf="editForm" > - </div>
                                        <div class="w-200" *ngIf="editForm">
                                            <p-calendar [disabled]="!profile.userCalendar.monday || profile.userCalendar.mondayAllDay"
                                                [(ngModel)]="profile.userCalendar.mondayEnd" [ngModelOptions]="{standalone: true}" id="picklist_SetUserShiftMonEndTimeField"
                                                pattern="hh:mm a" [stepMinute]="15" hourFormat="12" [timeOnly]="true" (onSelect)="detectIsUserCalenderUpdated()" appendTo="body"></p-calendar>
                                        </div>
        
                                        <div class="w-130" *ngIf="editForm">
                                            <div class="d-flex position-relative justify-content-center">
                                                <div class="p-field-checkbox">
                                                    <p-checkbox [binary]="true" [disabled]="!profile.userCalendar.monday"
                                                        (onChange)="nullify('monday',profile.userCalendar.mondayAllDay)"
                                                        [(ngModel)]="profile.userCalendar.mondayAllDay" id="check_SetUserShiftMonAllDay"
                                                        [ngModelOptions]="{standalone: true}" (onChange)="detectIsUserCalenderUpdated()"></p-checkbox>
                                                </div>
                                                <span class="text-12 ms-3" id="label_SetUserShiftMonAllDay">{{translate?.setup.general.workingHours.fields.allDay.singular }}</span>
                                            </div>
                                        </div>
        
                                    </div>
                                </div>
        
                                <div class="custella-content-row-two my-2">
                                    <div class="custella-content-row my-2 align-items-center">
                                        <div class="w-150">
                                            <div class="d-flex position-relative">
                                                <div *ngIf="editForm" class="p-field-checkbox">
                                                    <p-checkbox [binary]="true" [ngModelOptions]="{standalone: true}" id="check_SetUserShiftTue"
                                                        (onChange)="selectWorkingDays('tuesday', profile.userCalendar.tuesday)"
                                                        [(ngModel)]="profile.userCalendar.tuesday" (onChange)="detectIsUserCalenderUpdated()"></p-checkbox>
                                                </div>
                                                <span class="text-12 ms-3" id="label_SetUserShiftTue">{{translate?.setup.general.workingHours.fields.tuesday.singular }}</span>
                                            </div>
                                        </div>
                                        <span *ngIf="!editForm && !profile?.userCalendar?.tuesdayAllDay" id="value_SetUserShiftTueDateTime" class="text-12 ms-3 w-200" >{{profile.userCalendar.tuesdayStart  | momentDate : ' hh:mm a' :  timezone}} - {{profile.userCalendar.tuesdayEnd  | momentDate : ' hh:mm a' :  timezone}}</span>
                                        <span *ngIf="!editForm && profile?.userCalendar?.tuesdayAllDay" id="value_SetUserShiftTueAllDay" class="text-12 ms-3 w-200">{{translate?.setup.general.workingHours.fields.allDay.singular }}</span>
                                        <div class="w-200" *ngIf="editForm">
                                            <p-calendar
                                                [disabled]="!profile.userCalendar.tuesday || profile.userCalendar.tuesdayAllDay"
                                                [(ngModel)]="profile.userCalendar.tuesdayStart" [ngModelOptions]="{standalone: true}"
                                                pattern="hh:mm a" [stepMinute]="15" hourFormat="12" [timeOnly]="true" id="picklist_SetUserShiftTueStartTimeField"
                                                inputId="timeonly" (onSelect)="detectIsUserCalenderUpdated()"></p-calendar>
                                        </div>
                                        <div class="px-2" *ngIf="editForm"> - </div>
                                        <div class="w-200" *ngIf="editForm">
                                            <p-calendar
                                                [disabled]="!profile.userCalendar.tuesday || profile.userCalendar.tuesdayAllDay" id="picklist_SetUserShiftTueEndTimeField"
                                                [(ngModel)]="profile.userCalendar.tuesdayEnd" [ngModelOptions]="{standalone: true}"
                                                pattern="hh:mm a" [stepMinute]="15" hourFormat="12" [timeOnly]="true" (onSelect)="detectIsUserCalenderUpdated()"></p-calendar>
                                        </div>
        
                                        <div class="w-130" *ngIf="editForm" >
                                            <div class="d-flex position-relative justify-content-center">
                                                <div class="p-field-checkbox">
                                                    <p-checkbox [binary]="true" [disabled]="!profile.userCalendar.tuesday"
                                                        (onChange)="nullify('tuesday',profile.userCalendar.tuesdayAllDay)"
                                                        [(ngModel)]="profile.userCalendar.tuesdayAllDay" id="check_SetUserShiftTueAllDay"
                                                        [ngModelOptions]="{standalone: true}" (onChange)="detectIsUserCalenderUpdated()"></p-checkbox>
                                                </div>
                                                <span class="text-12 ms-3" id="label_SetUserShiftTueAllDay">{{translate?.setup.general.workingHours.fields.allDay.singular }}</span>
                                            </div>
                                        </div>
        
                                    </div>
                                </div>
        
                                <div class="custella-content-row-two my-2">
                                    <div class="custella-content-row my-2 align-items-center">
                                        <div class="w-150">
                                            <div class="d-flex position-relative">
                                                <div class="p-field-checkbox" *ngIf="editForm" >
                                                    <p-checkbox [binary]="true" [ngModelOptions]="{standalone: true}" id="check_SetUserShiftWed"
                                                        (onChange)="selectWorkingDays('wednesday', profile.userCalendar.wednesday)"
                                                        [(ngModel)]="profile.userCalendar.wednesday" (onChange)="detectIsUserCalenderUpdated()"></p-checkbox>
                                                </div>
                                                <span class="text-12 ms-3" id="label_SetUserShiftWed">{{translate?.setup.general.workingHours.fields.wednesday.singular }}</span>
                                            </div>
                                        </div>
                                        <span *ngIf="!editForm && !profile?.userCalendar?.wednesdayAllDay" id="value_SetUserShiftWedDateTime" class="text-12 ms-3 w-200" >{{profile.userCalendar.wednesdayStart  | momentDate : ' hh:mm a' :  timezone}} - {{profile.userCalendar.wednesdayEnd  | momentDate : ' hh:mm a' :  timezone}}</span>
                                        <span *ngIf="!editForm && profile?.userCalendar?.wednesdayAllDay" id="value_SetUserShiftWedAllDay" class="text-12 ms-3 w-200">{{translate?.setup.general.workingHours.fields.allDay.singular }}</span>
                                        <div class="w-200" *ngIf="editForm">
                                            <p-calendar
                                                [disabled]="!profile.userCalendar.wednesday || profile.userCalendar.wednesdayAllDay"
                                                [(ngModel)]="profile.userCalendar.wednesdayStart" id="picklist_SetUserShiftWedStartTimeField"
                                                [ngModelOptions]="{standalone: true}" pattern="hh:mm a" [stepMinute]="15"
                                                hourFormat="12" [timeOnly]="true" inputId="timeonly" (onSelect)="detectIsUserCalenderUpdated()"></p-calendar>
                                        </div>
                                        <div class="px-2" *ngIf="editForm"> - </div>
                                        <div class="w-200" *ngIf="editForm">
                                            <p-calendar
                                                [disabled]="!profile.userCalendar.wednesday || profile.userCalendar.wednesdayAllDay"
                                                [(ngModel)]="profile.userCalendar.wednesdayEnd" [ngModelOptions]="{standalone: true}" id="picklist_SetUserShiftWedEndTimeField"
                                                pattern="hh:mm a" [stepMinute]="15" hourFormat="12" [timeOnly]="true" (onSelect)="detectIsUserCalenderUpdated()"></p-calendar>
                                        </div>
        
                                        <div class="w-130" *ngIf="editForm">
                                            <div  class="d-flex position-relative justify-content-center">
                                                <div class="p-field-checkbox">
                                                    <p-checkbox [binary]="true" [disabled]="!profile.userCalendar.wednesday"
                                                        (onChange)="nullify('wednesday',profile.userCalendar.wednesdayAllDay)"
                                                        [(ngModel)]="profile.userCalendar.wednesdayAllDay" id="check_SetUserShiftWedAllDay"
                                                        [ngModelOptions]="{standalone: true}" (onChange)="detectIsUserCalenderUpdated()"></p-checkbox>
                                                </div>
                                                <span class="text-12 ms-3" id="label_SetUserShiftWedAllDay">{{translate?.setup.general.workingHours.fields.allDay.singular }}</span>
                                            </div>
                                        </div>
        
                                    </div>
                                </div>
        
                                <div class="custella-content-row-two my-2">
                                    <div class="custella-content-row my-2 align-items-center">
                                        <div class="w-150">
                                            <div class="d-flex position-relative">
                                                <div class="p-field-checkbox" *ngIf="editForm" >
                                                    <p-checkbox [binary]="true" [ngModelOptions]="{standalone: true}" id="check_SetUserShiftThurs"
                                                        (onChange)="selectWorkingDays('thursday', profile.userCalendar.thursday)"
                                                        [(ngModel)]="profile.userCalendar.thursday" (onChange)="detectIsUserCalenderUpdated()"></p-checkbox>
                                                </div>
                                                <span class="text-12 ms-3" id="label_SetUserShiftThurs">{{translate?.setup.general.workingHours.fields.thursday.singular }}</span>
                                            </div>
                                        </div>
                                        <span *ngIf="!editForm && !profile?.userCalendar?.thursdayAllDay" id="value_SetUserShiftThursDateTime" class="text-12 ms-3 w-200" >{{profile.userCalendar.thursdayStart  | momentDate : ' hh:mm a' :  timezone}} - {{profile.userCalendar.thursdayEnd  | momentDate : ' hh:mm a' :  timezone}}</span>
                                        <span *ngIf="!editForm && profile?.userCalendar?.thursdayAllDay" id="value_SetUserShiftThursAllDay" class="text-12 ms-3 w-200">{{translate?.setup.general.workingHours.fields.allDay.singular }}</span>
        
                                        <div class="w-200" *ngIf="editForm">
                                            <p-calendar
                                                [disabled]="!profile.userCalendar.thursday || profile.userCalendar.thursdayAllDay"
                                                [(ngModel)]="profile.userCalendar.thursdayStart" id="picklist_SetUserShiftThursStartTimeField"
                                                [ngModelOptions]="{standalone: true}" pattern="hh:mm a" [stepMinute]="15"
                                                hourFormat="12" [timeOnly]="true" inputId="timeonly" (onSelect)="detectIsUserCalenderUpdated()"></p-calendar>
                                        </div>
                                        <div class="px-2" *ngIf="editForm"> - </div>
                                        <div class="w-200" *ngIf="editForm">
                                            <p-calendar
                                                [disabled]="!profile.userCalendar.thursday || profile.userCalendar.thursdayAllDay" id="picklist_SetUserShiftThursEndTimeField"
                                                [(ngModel)]="profile.userCalendar.thursdayEnd" [ngModelOptions]="{standalone: true}"
                                                pattern="hh:mm a" [stepMinute]="15" hourFormat="12" [timeOnly]="true" (onSelect)="detectIsUserCalenderUpdated()"></p-calendar>
                                        </div>
        
                                        <div class="w-130" *ngIf="editForm">
                                            <div class="d-flex position-relative justify-content-center">
                                                <div class="p-field-checkbox">
                                                    <p-checkbox [binary]="true" [disabled]="!profile.userCalendar.thursday"
                                                        (onChange)="nullify('thursday',profile.userCalendar.thursdayAllDay)"
                                                        [(ngModel)]="profile.userCalendar.thursdayAllDay" id="check_SetUserShiftThursAllDay"
                                                        [ngModelOptions]="{standalone: true}" (onChange)="detectIsUserCalenderUpdated()"></p-checkbox>
                                                </div>
                                                <span id="label_SetUserShiftThursAllDay" class="text-12 ms-3">{{translate?.setup.general.workingHours.fields.allDay.singular }}</span>
                                            </div>
                                        </div>
        
                                    </div>
                                </div>
        
                                <div class="custella-content-row-two my-2">
                                    <div class="custella-content-row my-2 align-items-center">
                                        <div class="w-150">
                                            <div class="d-flex position-relative">
                                                <div class="p-field-checkbox" *ngIf="editForm" >
                                                    <p-checkbox [binary]="true" [ngModelOptions]="{standalone: true}" id="check_SetUserShiftFri"
                                                        (onChange)="selectWorkingDays('friday', profile.userCalendar.friday)"
                                                        [(ngModel)]="profile.userCalendar.friday" (onChange)="detectIsUserCalenderUpdated()"></p-checkbox>
                                                </div>
                                                <span class="text-12 ms-3" id="label_SetUserShiftFri">{{translate?.setup.general.workingHours.fields.friday.singular }}</span>
                                            </div>
                                        </div>
                                        <span *ngIf="!editForm && !profile.userCalendar?.fridayAllDay" id="value_SetUserShiftFriDateTime" class="text-12 ms-3 w-200" >{{profile.userCalendar.fridayStart  | momentDate : ' hh:mm a' :  timezone}} - {{profile.userCalendar.fridayEnd  | momentDate : ' hh:mm a' :  timezone}}</span>
                                        <span *ngIf="!editForm && profile.userCalendar?.fridayAllDay" id="value_SetUserShiftFriAllDay" class="text-12 ms-3 w-200">{{translate?.setup.general.workingHours.fields.allDay.singular }}</span>
        
                                        <div class="w-200" *ngIf="editForm">
                                            <p-calendar [disabled]="!profile.userCalendar.friday || profile.userCalendar.fridayAllDay"
                                                [(ngModel)]="profile.userCalendar.fridayStart" [ngModelOptions]="{standalone: true}"
                                                pattern="hh:mm a" [stepMinute]="15" hourFormat="12" [timeOnly]="true" id="picklist_SetUserShiftFriStartTimeField"
                                                inputId="timeonly" (onSelect)="detectIsUserCalenderUpdated()"></p-calendar>
                                        </div>
                                        <div class="px-2" *ngIf="editForm"> - </div>
                                        <div class="w-200" *ngIf="editForm">
                                            <p-calendar [disabled]="!profile.userCalendar.friday || profile.userCalendar.fridayAllDay"
                                                [(ngModel)]="profile.userCalendar.fridayEnd" [ngModelOptions]="{standalone: true}" id="picklist_SetUserShiftFriEndTimeField"
                                                pattern="hh:mm a" [stepMinute]="15" hourFormat="12" [timeOnly]="true" (onSelect)="detectIsUserCalenderUpdated()"></p-calendar>
                                        </div>
        
                                        <div class="w-130" *ngIf="editForm">
                                            <div class="d-flex position-relative justify-content-center">
                                                <div class="p-field-checkbox">
                                                    <p-checkbox [binary]="true" [disabled]="!profile.userCalendar.friday"
                                                        (onChange)="nullify('friday',profile.userCalendar.fridayAllDay)"
                                                        [(ngModel)]="profile.userCalendar.fridayAllDay" id="check_SetUserShiftFriAllDay"
                                                        [ngModelOptions]="{standalone: true}" (onChange)="detectIsUserCalenderUpdated()"></p-checkbox>
                                                </div>
                                                <span id="label_SetUserShiftFriAllDay" class="text-12 ms-3">{{translate?.setup.general.workingHours.fields.allDay.singular }}</span>
                                            </div>
                                        </div>
        
                                    </div>
                                </div>
        
                                <div class="custella-content-row-two my-2">
                                    <div class="custella-content-row my-2 align-items-center">
                                        <div class="w-150">
                                            <div class="d-flex position-relative">
                                                <div class="p-field-checkbox" *ngIf="editForm" >
                                                    <p-checkbox [binary]="true" [ngModelOptions]="{standalone: true}" id="check_SetUserShiftSat"
                                                        (onChange)="selectWorkingDays('saturday', profile.userCalendar.saturday)"
                                                        [(ngModel)]="profile.userCalendar.saturday" (onChange)="detectIsUserCalenderUpdated()"></p-checkbox>
                                                </div>
                                                <span class="text-12 ms-3" id="label_SetUserShiftSat">{{translate?.setup.general.workingHours.fields.saturday.singular }}</span>
                                            </div>
                                        </div>
                                        <span *ngIf="!editForm && !profile?.userCalendar?.saturdayAllDay" id="value_SetUserShiftSatDateTime" class="text-12 ms-3 w-200" >{{profile.userCalendar.saturdayStart  | momentDate : ' hh:mm a' :  timezone}} - {{profile.userCalendar.saturdayEnd  | momentDate : ' hh:mm a' :  timezone}}</span>
                                        <span *ngIf="!editForm && profile?.userCalendar?.saturdayAllDay" id="value_SetUserShiftSatAllDay" class="text-12 ms-3 w-200">{{translate?.setup.general.workingHours.fields.allDay.singular }}</span>
        
                                        <div class="w-200" *ngIf="editForm">
                                            <p-calendar
                                                [disabled]="!profile.userCalendar.saturday || profile.userCalendar.saturdayAllDay"
                                                [(ngModel)]="profile.userCalendar.saturdayStart" id="picklist_SetUserShiftSatDateStartTimeField"
                                                [ngModelOptions]="{standalone: true}" pattern="hh:mm a" [stepMinute]="15"
                                                hourFormat="12" [timeOnly]="true" inputId="timeonly" (onSelect)="detectIsUserCalenderUpdated()"></p-calendar>
                                        </div>
                                        <div class="px-2" *ngIf="editForm"> - </div>
                                        <div class="w-200" *ngIf="editForm">
                                            <p-calendar
                                                [disabled]="!profile.userCalendar.saturday || profile.userCalendar.saturdayAllDay"
                                                [(ngModel)]="profile.userCalendar.saturdayEnd" [ngModelOptions]="{standalone: true}" id="picklist_SetUserShiftSatDateEndTimeField"
                                                pattern="hh:mm a" [stepMinute]="15" hourFormat="12" [timeOnly]="true" (onSelect)="detectIsUserCalenderUpdated()"></p-calendar>
                                        </div>
        
                                        <div class="w-130" *ngIf="editForm">
                                            <div class="d-flex position-relative justify-content-center">
                                                <div class="p-field-checkbox">
                                                    <p-checkbox [binary]="true" [disabled]="!profile.userCalendar.saturday"
                                                        (onChange)="nullify('saturday',profile.userCalendar.saturdayAllDay)"
                                                        [(ngModel)]="profile.userCalendar.saturdayAllDay" id="check_SetUserShiftSatAllDay"
                                                        [ngModelOptions]="{standalone: true}" (onChange)="detectIsUserCalenderUpdated()"></p-checkbox>
                                                </div>
                                                <span class="text-12 ms-3" id="label_SetUserShiftSatAllDay">{{translate?.setup.general.workingHours.fields.allDay.singular }}</span>
                                            </div>
                                        </div>
        
                                    </div>
                                </div>
        
                                <div class="custella-content-row-two my-2">
                                    <div class="custella-content-row my-2 align-items-center">
                                        <div class="w-150">
                                            <div class="d-flex position-relative">
                                                <div class="p-field-checkbox" *ngIf="editForm" >
                                                    <p-checkbox [binary]="true" [ngModelOptions]="{standalone: true}" id="check_SetUserShiftSun"
                                                        (onChange)="selectWorkingDays('sunday', profile.userCalendar.sunday)"
                                                        [(ngModel)]="profile.userCalendar.sunday" (onChange)="detectIsUserCalenderUpdated()"></p-checkbox>
                                                </div>
                                                <span class="text-12 ms-3" id="label_SetUserShiftSun">{{translate?.setup.general.workingHours.fields.sunday.singular }}</span>
                                            </div>
                                        </div>
                                        <span *ngIf="!editForm && !profile.userCalendar?.sundayAllDay" id="label_SetUserShiftSunDateTime" class="text-12 ms-3 w-200" >{{profile.userCalendar.sundayStart  | momentDate : ' hh:mm a' :  timezone}} - {{profile.userCalendar.sundayEnd  | momentDate : ' hh:mm a' :  timezone}}</span>
                                        <span *ngIf="!editForm && profile.userCalendar?.sundayAllDay" id="label_SetUserShiftSunAllDay" class="text-12 ms-3 w-200">{{translate?.setup.general.workingHours.fields.allDay.singular }}</span>
        
                                        <div class="w-200" *ngIf="editForm">
                                            <p-calendar [disabled]="!profile.userCalendar.sunday || profile.userCalendar.sundayAllDay"
                                                [(ngModel)]="profile.userCalendar.sundayStart" [ngModelOptions]="{standalone: true}"
                                                pattern="hh:mm a" [stepMinute]="15" hourFormat="12" [timeOnly]="true" id="picklist_SetUserShiftSunStartTimeField"
                                                inputId="timeonly" (onSelect)="detectIsUserCalenderUpdated()"></p-calendar>
                                        </div>
                                        <div class="px-2" *ngIf="editForm"> - </div>
                                        <div class="w-200" *ngIf="editForm">
                                            <p-calendar [disabled]="!profile.userCalendar.sunday || profile.userCalendar.sundayAllDay"
                                                [(ngModel)]="profile.userCalendar.sundayEnd" [ngModelOptions]="{standalone: true}" id="picklist_SetUserShiftSunEndTimeField"
                                                pattern="hh:mm a" [stepMinute]="15" hourFormat="12" [timeOnly]="true" (onSelect)="detectIsUserCalenderUpdated()" ></p-calendar>
                                        </div>
        
                                        <div class="w-130" *ngIf="editForm">
                                            <div class="d-flex position-relative justify-content-center">
                                                <div class="p-field-checkbox">
                                                    <p-checkbox [binary]="true" [disabled]="!profile.userCalendar.sunday"
                                                        (onChange)="nullify('sunday',profile.userCalendar.sundayAllDay)"
                                                        [(ngModel)]="profile.userCalendar.sundayAllDay" id="check_SetUserShiftSunAllDay"
                                                        [ngModelOptions]="{standalone: true}" (onChange)="detectIsUserCalenderUpdated()"></p-checkbox>
                                                </div>
                                                <span class="text-12 ms-3" id="label_SetUserShiftSunAllDay">{{translate?.setup.general.workingHours.fields.allDay.singular }}</span>
                                            </div>
                                        </div>
        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <!-- Skills -->
                    <div class="custella-form-container-title-two text-14 font-medium" id="title_SetUserSkills" >{{ translate?.setup.users.skills.label.singular  }}</div>
                    <div class="custella-form-container-content-border-bottom">
                        <div class="custella-content-row pe-0" *ngIf="editForm" >
                            <div class="custella-content-row-input col-12">
                                <div class="d-flex justify-content-between">
                                    <div class="custella-content-row-input col">
                                        <span class="custella-content-row-input-title color-var-500" id="label_SetUserDept" >{{translate?.setup.users.skills.fields.department.singular }}</span>
                                        <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserDept">-</span>
                                        <p-dropdown *ngIf="editForm" 
                                        [placeholder]="translate?.general.messages.none " 
                                        [options]="departments" 
                                        optionLabel="name" 
                                        [(ngModel)]="selectedDepartment"
                                        [ngModelOptions]="{ standalone: true }"
                                         (onChange)="getRolesByDepartment(selectedDepartment)"
                                         id="picklist_SetUserDeptField"
                                         > </p-dropdown>
                                    </div>
                                    <div class="custella-content-row-input col">
                                        <span class="custella-content-row-input-title color-var-500" id="label_SetUserRole" >{{translate?.setup.users.skills.fields.role.singular }}</span>
                                        <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserRole">-</span>
                                        <p-dropdown *ngIf="editForm" 
                                        [placeholder]="translate?.general.messages.none " 
                                        [options]="skillRoles" 
                                        optionLabel="name" 
                                        [(ngModel)]="selectedRole"
                                        [ngModelOptions]="{ standalone: true }"
                                        (onChange)="getLevelsByRole(selectedRole)"
                                        id="picklist_SetUserRoleField"
                                        > </p-dropdown>
                                    </div>
                                    <div class="custella-content-row-input col">
                                        <span class="custella-content-row-input-title color-var-500" id="label_SetUserLevel" >{{translate?.setup.users.skills.fields.levels.singular }}</span>
                                        <span *ngIf="!editForm" class="custella-content-row-input-title-three" id="value_SetUserLevel">-</span>
                                        <div *ngIf="editForm" class="d-flex align-items-center">
                                            <p-dropdown [placeholder]="translate?.general.messages.none " 
                                            [options]="levels" 
                                            optionLabel="name" 
                                            [(ngModel)]="selectedLevel"
                                            [ngModelOptions]="{ standalone: true }" 
                                            id="picklist_SetUserLevelField"
                                            class="w-100" ></p-dropdown>
                                        </div>
                                    </div>
                                    <button type="button" class="lookup-button py-2 mx-0" style="margin-top: 22px;" id="btn_SetUserLevelAdd" (click)="addSkill()"><i class="pi pi-plus"></i></button>
                                </div>
                            </div>
                        </div>
                         <div class="custella-content-row pe-0">
                            <div class="custella-grey-box-user">
                                <div class="">
                                    <div class="filter-item mr-2 mb-2 d-inline-flex" *ngFor="let skill of profile.userDynamicSkillList;let i = index ">
                                        <div class="rounded-5 bg-purple px-2 py-1 mx-1 d-flex align-items-center">
                                            <div class="d-flex align-items-center">
                                                <span class="text-12 font-white text-nowrap px-1" id="value_SetUserSkillNameEach">{{skill?.label}}</span>
                                            </div>
                                          <i *ngIf="editForm"  class="pi pi-times-circle font-white" id="btn_SetUserSkillNameRemove" (click)="removeSkill(i)"></i>
                                        </div>
                                    </div>
                                 </div>                          
                            </div>
                        </div>
                    </div>
        
           <!-- 2nd Section -->
           <div class="custella-form-container-title-two">{{ translate?.setup.users.access.sections.sec9.singular }}</div>
           <div class="custella-form-container-content-border-bottom" *ngIf="googleEnable?.enabled">
               <div class="mt-2 mb-3 me-3">
                   <div class="flex-align-center me-3 col-12">
                       <div class="flex-align-center p-2">
                           <img src="../../../../assets/svg/google-calendar.svg" alt="Google Calendar logo">
                           <div class="text-left mx-3">
                               <span class="text-12 font-medium color-var-500" >{{translate?.setup.users.users.fields.integratesGoogle.singular}}</span>
                               <span class="d-block text-12 text-wrap" >{{translate?.setup.users.users.descriptions.connectToGoogleCalendar.singular}}</span>
                           </div>
                       </div>
                       <div class="ms-auto">
                           <p-button styleClass="ms-2 text-nowrap connect" *ngIf="!googleCalenderConnected" [disabled]="!canConnectGoogleCalender"
                           (onClick)="connectGoogleCalender()" [label]="translate?.general.buttons.connect.singular"></p-button>
        
                           <p-button *ngIf="googleCalenderConnected" [disabled]="!canConnectGoogleCalender"
                           (onClick)="disConnectGoogleCalender()" styleClass="ms-2 text-nowrap connect p-button-danger" [label]="translate?.general.buttons.disconnect.singular"></p-button>
                       </div>
                   </div>
               </div>
           </div>
           <div class="custella-form-container-content-border-bottom" *ngIf="microsoftEnable?.enabled">
               <div class="mt-2 mb-3 me-3">
                   <div class="flex-align-center me-3 col-12">
                       <div class="flex-align-center p-2">
                           <img src="../../../../assets/svg/office365.svg" alt="Office365 logo">
                           <div class="text-left mx-3">
                               <span class="text-12 font-medium color-var-500" >{{translate?.setup.users.users.fields.integratesMicrosoft365.singular}}</span>
                               <span class="d-block text-12 text-wrap" >{{translate?.setup.users.users.descriptions.connectToMicrosoftOutlookCalendar.singular}}</span>
                           </div>
                       </div>
                       <div class="ms-auto">
                          
                           <p-button *ngIf="!microsoftCalenderConnected" [disabled]="!canConnectGoogleCalender"
                           (onClick)="connectMicrosoftCalender()" styleClass="ms-2 text-nowrap connect"
                           [label]="translate?.general.buttons.connect.singular"></p-button>
                            <p-button *ngIf="microsoftCalenderConnected" [disabled]="!canConnectGoogleCalender"
                            (onClick)="disConnectMicrosoftCalender()" styleClass="ms-2 text-nowrap connect p-button-danger" [label]="translate?.general.buttons.disconnect.singular"
                         ></p-button>
        
                       </div>
                   </div>
               </div>
           </div>
           <div class="custella-form-container-content-border-bottom"  *ngIf="calendlyEnable?.enabled">
               <div class="mt-2 mb-3 me-3">
                   <div class="flex-align-center me-3 col-12">
                       <div class="flex-align-center p-2">
                           <img src="../../../../assets/svg/calendly.svg" alt="Calendly logo" style="width: 37px;">
                           <div class="text-left mx-3">
                               <span class="text-12 font-medium color-var-500" >{{translate?.setup.users.users.fields.integratesCalendly.singular}}</span>
                               <span class="d-block text-12 text-wrap" >{{translate?.setup.users.users.descriptions.connectToCalendly.singular}}</span>
                           </div>
                       </div>
                       <div class="ms-auto">
        
                           <p-button *ngIf="!calendlyCalenderConnected" [disabled]="!canConnectGoogleCalender"
                           (onClick)="connectCalendlyCalender()" styleClass="ms-2 text-nowrap connect"
                           [label]="translate?.general.buttons.connect.singular"></p-button>
                            <p-button *ngIf="calendlyCalenderConnected" [disabled]="!canConnectGoogleCalender"
                            (onClick)="disConnectCalendlyCalender()" styleClass="ms-2 text-nowrap connect p-button-danger" [label]="translate?.general.buttons.disconnect.singular"
                         ></p-button>
        
        
                       </div>
                   </div>
               </div>
           </div>
        
           <div *ngIf="!calendlyEnable?.enabled && !microsoftEnable?.enabled && !googleEnable?.enabled ">
            <div class="mt-2 mb-3 me-3">
                <div class="d-block position-relative me-3 col-12">
                    <div class="d-flex align-items-center p-2">
                        <div class="text-left mx-3">
                            <span class="text-12 font-medium color-var-500" id="label_AlertAllowOnline"
                                >{{translate?.setup.users.users.messages.noIntegrationsEnabled}}&nbsp;{{translate?.setup.users.users.messages.goTo}}&nbsp;<a class="color-primary" tabindex="0" href="javascript:void(0)" routerLinkActive="p-menuitem-active" (click)="onClickIntegrations()" >
                            <span class="p-menuitem-text" >{{ translate?.setup.users.access.sections.sec9.singular }}</span>
                            </a>&nbsp;{{translate?.setup.users.users.messages.toSetUpOrContactAdmin}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
                    <!-- Addresses -->
                    <div class="custella-form-container-title-two text-14 font-medium" id="title_SetUserAddr" >{{ translate?.setup.users.access.sections.sec11.singular }}</div>
                    <div class="d-flex">
                        <div class="custella-form-container-title-two-grey w-100" id="label_SetUserHomeAddr">{{ translate?.setup.users.access.sections.sec12.singular }}</div>
                        <div class="custella-form-container-title-two-grey-right w-100" id="label_SetUserOfficeAddr">{{ translate?.setup.users.access.sections.sec13.singular }}</div>
                    </div>
                    <div class="custella-form-container-content-border-bottom" *ngIf="!editForm">
                        <div class="custella-content-row px-0">
                            <div class="custella-content-row-input col-6 p-0">
                               <app-view-google-map  *ngIf="homeData" [locationDetail]="homeData"></app-view-google-map>
                            </div>
                            <div class="custella-content-row-input col-6 p-0">
                                <app-view-google-map *ngIf="officeData"  [locationDetail]="officeData"></app-view-google-map>
                            </div>
                        </div>
                    </div>
                    <div class="custella-form-container-content-border-bottom px-2" *ngIf="editForm">
                        <div  class="custella-content-row mt-0 pe-0">
                            <div class="custella-content-row-input col-6 p-0 me-0">
                                <app-search-google-map  (addressData)="gotSelectedAddress($event)" [data]="homeData" [showLocateBtn]="false"></app-search-google-map>
                                <!-- <app-search-google-map *ngIf="profile.homeLatitude" (addressData)="gotSelectedAddress($event)" [data]="homeData"></app-search-google-map> -->
                            </div>
                            <div class="custella-content-row-input col-6 p-0 me-0 border-start">
                                <app-search-google-map  (addressData)="gotSelectedOfficeAddress($event)" [data]="officeData" [showLocateBtn]="false"></app-search-google-map>
                                <!-- <app-search-google-map *ngIf="profile.officeLatitude" (addressData)="gotSelectedOfficeAddress($event)" [data]="officeData"></app-search-google-map> -->
                            </div>
                        </div>
                    </div>
        
                    <!-- Alerts -->
                    <div class="custella-form-container-title-two text-14 font-medium" id="label_SetUserAlert" >{{ translate?.setup.users.users.sections.sec14.singular }}</div>
                    <div class="custella-form-container-content-border-bottom">
                        <div class="custella-content-row">
                            <div class="custella-content-row-input col-12">
                                <div class="d-flex align-items-center p-2">
                                    <div *ngIf="editForm" class="p-field-checkbox d-flex me-3">
                                        <p-checkbox [binary]="true" [(ngModel)]="profile.isTaskAlert" id="check_SetUserTaskAlert"></p-checkbox>
                                    </div>
                                    <div class="d-flex align-items-center me-2 mt-1" *ngIf="!editForm">
                                        <i *ngIf="profile.isTaskAlert" class="pi pi-check font-green" id="img_SetUserTaskAlertGreen"></i>
                                        <i *ngIf="!profile.isTaskAlert"  class="pi pi-times font-red" id="img_SetUserTaskAlertRed"></i>
                                    </div>
                                    <div class="text-left mx-2">
                                        <div class="d-flex align-items-center">
                                            <span class="text-12 font-medium color-var-500" id="label_SetUserNotifyMe" >{{translate?.setup.users.users.fields.notifyMeBeforeTask.singular}}</span>
                                            <span *ngIf="!editForm && profile.isTaskAlert" id="value_SetUserNotifyMe" class="text-12 font-medium color-var-500 ms-2 fst-italic">({{sharedVar.alertForTaskTime}} mins)</span>
                                        </div>
                                        <span *ngIf="editForm" class="d-block text-12 text-wrap" id="value_SetUserNotifyMeDesc" >{{translate?.setup.users.users.descriptions.aNotificationWillBe.singular}}</span>
                                    </div>
                                    <div *ngIf="editForm" class="p-field-checkbox d-flex ms-3">
                                        <p-dropdown filterBy="label" [options]="alertMinutes" id="picklist_SetUserNotifyMeAlertField" [showClear]="false" optionLabel="label" optionValue="value" name="alertForTaskTime" [(ngModel)]="profile.alertForTaskTime" [placeholder]="translate?.general.messages.none" ></p-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               
            </div>
        </div>
</p-tabPanel>
<p-tabPanel [header]="loadTabs[1].headerName">
    <app-user-list-view *ngIf="params?.id && profileData" [profile]="profileData" [profileId]="params.id" class="custella-setup-body"></app-user-list-view>
</p-tabPanel>
<p-tabPanel [header]="loadTabs[2].headerName" *ngIf="params?.id == auth.getUserId()">
    <app-user-notification *ngIf="params?.id && profileData" [profile]="profileData" [profileId]="params.id" class="custella-setup-body-not"></app-user-notification>
</p-tabPanel>
</p-tabView>