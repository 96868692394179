<div class="modal-header p-dialog-header flex-between">
    <div>
        <span class="text-16 font-medium ms-3" id="title_TaskHazard">{{title}}</span>
    </div>
    <div class="d-flex align-items-center">
        <p-button [label]="translate?.general.buttons.delete.singular" styleClass="ms-2 h-28" id="btn_TaskHazDelete" (onClick)="openModal()"></p-button>
        <i class="pi pi-times w-24px ms-2" [pTooltip]="translate?.general.buttons.close.singular" (click)="modalRef.hide()" id="btn_TaskHazClose"></i>
    </div>
</div>
<div class="modal-body height-80 p-0">
    <div class="p-0" *ngIf="take5Form">
        <div class="custella-form-container m-0" style="box-shadow: none;">
            <!-- Order Details -->
            <div class="custella-form-container-title flex-between rounded-0 py-2">
                <div class="font-medium text-14" id="title_TaskHazardDets" >{{ translate?.task.sections.sec4.singular }}</div>
                <div class="d-flex ms-auto">
                    <button type="button" *ngIf="take5Form?.pdfForm == null" id="btn_TaskHazGenerate" (click)="generateChubbPfd(take5Form)" [pTooltip]="translate?.general.buttons.generatePdf.singular" tooltipPosition="bottom" class="d-flex h-30 rounded-1 px-2 py-2 ms-1" style="background: #4b5357; border: 1px solid #4b5357;">
                        <i class="pi pi-file-pdf text-white"></i>
                    </button>
                    <button type="button" *ngIf="take5Form?.pdfForm !== null" id="btn_TaskHazGenerate2" (click)="downloadChubbPfd(take5Form?.pdfForm?.attachmentUrl)"  [pTooltip]="translate?.general.buttons.generatePdf.singular" tooltipPosition="bottom" class="d-flex h-30 rounded-1 px-2 py-2 ms-1" style="background: #4b5357; border: 1px solid #4b5357;">
                        <i class="pi pi-file-pdf text-white"></i>
                    </button>
                </div>
            </div>
            <div class="custella-form-container-content-border-bottom text-12 font-medium">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title-two" id="label_TaskHazName" >{{ translate?.task.fields.name.singular }}</span>
                        <p *ngIf="take5Form?.submittedByUser" id="value_TaskHazName" class="custella-content-row-input-title-three">{{take5Form?.submittedByUser?.firstName}} {{take5Form?.submittedByUser?.lastName}}</p>
                        <p *ngIf="!take5Form?.submittedByUser" id="value_TaskHazName" class="custella-content-row-input-title-three">-</p>
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title-two" id="label_TaskHazJob" >{{ translate?.jobOrder.label.singular }}</span>
                        <p class="custella-content-row-input-title-three-link" id="link_TaskHazJob" (click)="viewJoborder(take5Form?.task?.jobOrder)">{{take5Form?.task?.jobOrder?.jobOrderNumber}}</p>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content-border-bottom text-12 font-medium">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title-two" id="label_TaskHazTask" >{{ translate?.task.label.singular }}</span>
                        <p class="custella-content-row-input-title-three" id="value_TaskHazTask">{{take5Form?.task?.taskName ? take5Form?.task?.taskName : '-'}}</p>
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title-two" id="label_TaskHazDateTime" >{{ translate?.task.fields.dateTime.singular }}</span>
                        <p class="custella-content-row-input-title-three" id="value_TaskHazDateTime">{{take5Form?.submittedDate | momentDate : ' hh:mm a' :  timezone}} & {{take5Form?.submittedDate | momentDate : dateFormat :  timezone}}</p>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content-border-bottom text-12 font-medium">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title-two" id="label_TaskHazLoc" >{{ translate?.task.fields.location.singular }}</span>
                        <p class="custella-content-row-input-title-three" id="value_TaskHazLoc">{{take5Form?.task?.location ? take5Form?.task?.location : '-'}}</p>
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title-two" id="label_TaskHazSupervisor" >{{ translate?.task.fields.supervisorChargehand.singular }}</span>
                        <p class="custella-content-row-input-title-three" id="value_TaskHazSupervisor">{{take5Form?.submittedByUser?.superVisor ? take5Form?.submittedByUser?.superVisor?.fullNam : '-'}}</p>
                    </div>
                </div>
            </div>
            <!-- Client Details -->
            <div class="custella-form-container-title-two">
                <div class="font-medium text-14" id="title_TaskHazCritical" >{{ translate?.task.fields.criticalHazards.singular }}</div>
            </div>
            <div class="custella-form-container-content-border-bottom text-12 font-medium mt-2">
                <div class="row">
                    <div class="custella-content-row-input col-12" *ngIf="hazardsCtr.length != 0">
                        <p class="custella-content-row-input-title-three" id="label_TaskHazCriticalName" *ngFor="let hazard of hazardsCtr">
                            <img src="assets\images\hazard\{{hazard.img}}.png" id="img_TaskHazCriticalName" class="w-28px me-2" alt="Hazards">
                            {{hazard.name}}
                        </p>
                    </div>
                </div>
            </div>
            <!-- Job Summary -->
            <div class="custella-form-container-title-two">
                <div class="font-medium text-14" id="title_TaskHazControls" >{{ translate?.task.fields.controls.singular }}</div>
            </div>
            <!-- Devices -->
            <!-- Title -->
            <div class="custella-form-container-content m-0 p-0">
                <p-table [value]="take5Form?.controls" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr class="">
                            <th class="col" id="label_TaskHazCriticalNo">{{ translate?.task.fields.criticalHazardsNo.singular }}</th>
                            <th class="col-4 text-center" id="label_TaskHazControlImpl">{{ translate?.task.fields.controlImplement.singular }}</th>
                            <th class="col-4 text-center" id="label_TaskHazControlEffect">{{ translate?.task.fields.areControlsEffective.singular }}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-control>
                        <tr class="text-12 font-medium">
                            <td id="value_TaskHazCriticalNo">{{control.hazardNo ? control.hazardNo : '-'}}</td>
                            <td class="col-4 text-center" id="value_TaskHazControlImpl">{{control.control ? control.control : '-'}}</td>
                            <td class="col-4 text-center" id="value_TaskHazControlEffect">{{control.controlEffective ? control.controlEffective : '-'}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <!-- Job Status -->
            <div class="custella-form-container-title-two flex-between">
                <div class="font-medium text-14" id="title_TaskHazAccess" >{{ translate?.task.fields.access.singular }}</div>
            </div>
            <div class="custella-form-container-content-border-bottom text-12 font-medium">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-12">
                        <span class="custella-content-row-input-title-two" id="label_TaskHazProcedurePermit" >{{ translate?.task.tooltips.procedureWorkInstructionOrPermitRequired.singular }}</span>
                        <p class="custella-content-row-input-title-three" id="value_TaskHazProcedurePermit">{{take5Form?.assessItem1 ? take5Form?.assessItem1 : '-'}}</p>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content-border-bottom text-12 font-medium">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-12">
                        <span class="custella-content-row-input-title-two" id="label_TaskHazCorrectPpe" >{{ translate?.task.fields.haveCorrectPpeEquip.singular }}</span>
                        <p class="custella-content-row-input-title-three" id="value_TaskHazCorrectPpe">{{take5Form?.assessItem2}}</p>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content-border-bottom text-12 font-medium">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-12">
                        <span class="custella-content-row-input-title-two" id="label_TaskHazAffectWork" >{{ translate?.task.fields.haveCheckedAffectsOtherPeople.singular }}</span>
                        <p class="custella-content-row-input-title-three" id="value_TaskHazAffectWork">{{take5Form?.assessItem3}}</p>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content-border-bottom text-12 font-medium">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-12">
                        <span class="custella-content-row-input-title-two" id="label_TaskHazCommunicate" >{{ translate?.task.fields.haveCommunicatedHazard.singular }}</span>
                        <p class="custella-content-row-input-title-three" id="value_TaskHazCommunicate">{{take5Form?.assessItem4}}</p>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content-border-bottom text-12 font-medium">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-12">
                        <span class="custella-content-row-input-title-two" id="label_TaskHazCompleteSafely" >{{ translate?.task.fields.haveCompleteThisTaskSafely.singular }}</span>
                        <p class="custella-content-row-input-title-three" id="value_TaskHazCompleteSafely">{{take5Form?.assessItem5}}</p>
                    </div>
                </div>
            </div>
            <!-- Job Status -->
            <div class="custella-form-container-title-two flex-between py-2">
                <div class="font-medium text-14" id="title_TaskHazNotes" >{{ translate?.task.fields.notes.singular }}</div>
                <div>
                    <p-button *ngIf="addBtnShow && !editForm" [label]="translate?.general.buttons.addNotes.singular" id="btn_TaskHazAddNotes" styleClass="ms-2 h-28" (onClick)="clickEdit()"></p-button>
                    <p-button *ngIf="!addBtnShow && !editForm" [label]="translate?.general.buttons.editNotes.singular" id="btn_TaskHazEditNotes" styleClass="ms-2 h-28" (onClick)="clickEdit()"></p-button>
                    <p-button *ngIf="editForm" [label]="translate?.general.buttons.saveNotes.singular" styleClass="ms-2 h-28" id="btn_TaskHazSaveNotes" (onClick)="saveNote()"></p-button>
                </div>
            </div>
            <div class="custella-form-container-content-border-bottom text-12 font-medium">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title-two" id="label_TaskHazComplaint" >{{ translate?.task.fields.complaint.singular }}</span>
                        <p class="custella-content-row-input-title-three" id="value_TaskHazComplaint" *ngIf="!editForm">{{take5Form?.review ? take5Form?.review : '-'}}</p>
                        <input type="text" pInputText autocomplete="off" id="input_TaskHazComplaintField" [(ngModel)]="take5Form.review" name="medicalRecordNumber" *ngIf="editForm"/>
                    </div>
                </div>
            </div>
            <!-- System Information -->
            <app-system-information class="hz-control" [createdByUser]="take5Form?.createdByUser" [createdDate]="take5Form?.createdDate" [modifiedByUser]="take5Form?.modifiedByByUser" [lastModifiedDate]="take5Form?.lastModifiedDate"></app-system-information>
        </div>
    </div>
</div>
