import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MenuItemPlus } from "src/app/_services/breadcrumb.service";
import { NavigationBreadCrumbService } from "src/app/_services/navigation-bread-crumb.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-navi-bar",
  templateUrl: "./navi-bar.component.html",
  styleUrls: ["./navi-bar.component.scss"],
})
export class NaviBarComponent
  extends SubscriptionUtil
  implements OnInit, AfterViewInit, OnDestroy
{
  items: any[] = [];

  private elementInner: HTMLDivElement | null | undefined;
  private navigationContainer: HTMLDivElement | null | undefined;

  private intervalTimer: any;

  private triggerOnce = false;

  constructor(
    private navibarService: NavigationBreadCrumbService,
    private router: Router,
    private util: UtilServiceService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit() {
    this.afterViewInit();
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  /******************************************** Init ******************************************/
  private init() {
    this.navibarService.getAllNavigationItems().subscribe((resp) => {
      //console.log("FINAL RESULTS", resp);
      if (resp && resp instanceof Array) {
        this.items = [...resp];

        // FOR DEBUGGING
        // this.items = [
        //   {
        //     label: "-- START BREADCRUMB --",
        //   },
        //   {
        //     label: "TESTING LABEL BREADCRUMB",
        //   },
        //   {
        //     label: "TESTING LABEL BREADCRUMB",
        //   },
        //   {
        //     label: "TESTING LABEL BREADCRUMB",
        //   },
        //   {
        //     label: "TESTING LABEL BREADCRUMB", 
        //   },
        //   {
        //     label: "TESTING LABEL BREADCRUMB",
        //   },
        //   {
        //     label: "TESTING LABEL BREADCRUMB",
        //   },
        //   {
        //     label: "TESTING LABEL BREADCRUMB",
        //   },
        //   {
        //     label: "TESTING LABEL BREADCRUMB",
        //   },
        //   {
        //     label: "TESTING LABEL BREADCRUMB",
        //   },
        //   {
        //     label: "TESTING LABEL BREADCRUMB",
        //   },
        //   {
        //     label: "TESTING LABEL BREADCRUMB",
        //   },
        //   {
        //     label: "TESTING LABEL BREADCRUMB",
        //   },
        //   {
        //     label: "TESTING LABEL BREADCRUMB",
        //   },
        //   {
        //     label: "TESTING LABEL BREADCRUMB",
        //   },
        //   {
        //     label: "TESTING LABEL BREADCRUMB",
        //   },
        //   {
        //     label: "TESTING LABEL BREADCRUMB",
        //   },
        //   {
        //     label: "TESTING LABEL BREADCRUMB",
        //   },
        //   {
        //     label: "-- END BREADCRUMB --",
        //   },
        //   {
        //     label: "-- NO THIS IS THE LAST --",
        //   },
        //   {
        //     label: "-- HELLO THIS IS LAST --",
        //   },
        //   {
        //     label: "-- ENDING NOW NOW --",
        //   },
        // ];
      }
    });
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();

    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }

    this.navibarService.reset();
  }

  /******************************************** After View Init ******************************************/
  private afterViewInit() {
    // get inner wrapper
    this.elementInner = document.querySelector(
      ".navigation-inner"
    ) as HTMLDivElement;

    this.intervalTimer = setInterval(() => {
      this.setWidthContainer();
      this.dynamicSizeCheck();
      this.scrollToLast();
    }, 1000);
  }

  /******************************************** Dynamic Checking ******************************************/
  private dynamicSizeCheck() {
    if (this.elementInner) {
      this.elementInner = document.querySelector(
        ".navigation-inner"
      ) as HTMLDivElement;

      const containerBound = this.elementInner?.getBoundingClientRect();
      let editBtnBound: DOMRect;

      let editButton = null;
      const allButtons = document.querySelectorAll("p-button");

      for (let ab = 0; ab < allButtons.length; ab++) {
        if (allButtons[ab].textContent === "Edit") {
          editButton = allButtons[ab];
          break;
        }
      }

      // console.log(editButton);

      if (editButton) {
        editBtnBound = editButton?.parentElement!.getBoundingClientRect();

        // check if overlap
        if (editBtnBound) {
          if (containerBound && containerBound.right > editBtnBound.left) {
            // // if yes then do inner wrapper width - editBttnBound width
            const btnWidth = editBtnBound.width;
            const elementInnerWidth = Number(this.elementInner.clientWidth);

            let finalWidth = elementInnerWidth - btnWidth;

            if (finalWidth > btnWidth) {
              Math.floor(finalWidth = finalWidth - btnWidth / 1.5);
            }

            this.elementInner.style.setProperty("width", finalWidth + "px");

            setTimeout(() => {
              this.triggerOnce = true;
            }, 500);
          } else {
            // this should happens once
            if (this.triggerOnce === false) {
              this.elementInner.style.setProperty(
                "width",
                String(window.innerWidth) + "px"
              );
            }

            setTimeout(() => {
              this.triggerOnce = true;
            }, 500);
          }
        }
      } else {
        // console.log("WINDOW INNER WIDTH ", window.innerWidth);
        // console.log("WINDOW SCROLLX ", window.scrollX);
        // console.log("WINDOW SCROLLY ", window.scrollX);
        // console.log("WINDOW SCROLLY ", window.scrollX);

        if (this.elementInner) {
          const allItems = document.querySelectorAll(
            ".navigation-item"
          ) as NodeList;

          let lastItem = allItems[allItems.length - 1] as any;
          let offSet = lastItem.clientWidth;
          let extra = 50;

          let final = window.innerWidth;
          final -= offSet;
          final -= extra;

          this.elementInner.style.setProperty("width", String(final) + "px");
        }
      }
    }
  }

  /******************************************** Scroll To Last ******************************************/
  private setWidthContainer() {
    this.navigationContainer = document.querySelector(
      ".navigation-container"
    ) as HTMLDivElement;

    if (this.navigationContainer) {
      this.navigationContainer.style.width = `${window.innerWidth}px`;
    }
  }

  /******************************************** Scroll To Last ******************************************/
  private scrollToLast() {
    this.elementInner = document.querySelector(
      ".navigation-inner"
    ) as HTMLDivElement;

    // console.dir(this.elementInner);

    // console.log("CLIENT WIDTH  ", this.elementInner.clientWidth);

    // console.log("SCROLL LEFT ", this.elementInner.scrollLeft);

    // console.log("SCROLL WIDTH ", this.elementInner.scrollWidth);

    if (this.elementInner) {
      // let total = 0;
      // const allItems = document.querySelectorAll(
      //   ".navigation-item"
      // ) as NodeList;

      // allItems.forEach((item: any) => {
      //   console.log(item.clientWidth);
      //   total += item.clientWidth;
      // });

      // console.log("Total ", total);

      this.elementInner.scrollLeft = this.elementInner.scrollWidth;
    }
  }

  /******************************************** Navigate To ******************************************/
  public navigateTo($event: any, item: MenuItemPlus, index: any) {
    $event.preventDefault();
    if (item?.routerLink && item?.routerLink !== "") {
      this.util.resetTabView();

      this.navibarService.sliceOn(index);
      console.log(item.routerLink.toString(),"=====")

      this.router.navigateByUrl(item.routerLink.toString());
    }else{
      this.util.resetTabView();

      this.navibarService.sliceOn2(index,"NavClick");
      //console.log(item.routerLink.toString(),"=====")

      if (item?.routerLink && item?.routerLink !== "") this.router.navigateByUrl(item.routerLink.toString());
    }
  }
}
