import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { WorkflowService } from 'src/app/_services/workflow.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorUtil } from 'src/app/_utilities/error';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';


@Component({
  selector: 'app-add-existing-notification',
  templateUrl: './add-existing-notification.component.html'
})
export class AddExistingNotificationComponent implements OnInit {
  title: string = "Add Notification"
  selectedNotification: any[];
  data: any;
  workflowData: any;
  addNotificationForm: UntypedFormGroup;
  submitTouched: boolean=false;
  translate: any;
  constructor(
    public modalRef: BsModalRef,
    private route: Router,
    private workflowService: WorkflowService,
    private spinner: NgxSpinnerService,
    private errorUtil: ErrorUtil,
    private languageTranslateService: LanguageTranslateService
  ) {
    this.addNotificationForm = new UntypedFormGroup({
      'name': new UntypedFormControl(null, [Validators.required,Validators.maxLength(40)]),
   });
  }

  ngOnInit(): void {
    console.log(this.data)
     this.languageTranslateService.wordSrc$.subscribe(transData => { 
          if(Object.keys(transData).length != 0){
            this.translate = transData
          }
      })

    this.workflowService.getListOfWorkflowsNotification(this.data.triggerModule).subscribe((data: any) => {
      console.log(data)
        this.workflowData = data.body;
        if(this.data.workflowWorkflowAlerts){
          // Ensure `workflowData` is loaded before mapping `selectedNotification`
            this.selectedNotification = this.data.workflowWorkflowAlerts.map((alert: any) =>
              this.workflowData.find((wf: any) => wf.id === alert.id)
            );
          console.log(this.selectedNotification)
        }
      })
  }

  newNotification(){
    this.modalRef.hide()
    this.route.navigate(['dashboard/setup/action/notifications/create'])
  }
    /****************************************** Submit Data *****************************/
    onSubmitForm(){
   
   
      this.submitTouched = true;
      if(!this.addNotificationForm.valid){
        for (var i in this.addNotificationForm.controls) {
          console.log(this.addNotificationForm.controls[i])
          this.addNotificationForm.controls[i].markAsTouched();
        }
  
        this.spinner.hide();
  
        this.submitTouched = false;
        return false;
      }
     
  
      this.spinner.show();
      console.log(this.selectedNotification)
       var req = JSON.parse(JSON.stringify(this.selectedNotification));
 
      console.log(req,"req",this.data)
      this.workflowService.updateNotificationIdWorkFlow(req,this.data.id).subscribe((res:any) => {
        console.log("Response ", res);
        if(res.status == 201 || res.status == 200){
          this.errorUtil.setErrorMessage(200, 'Updated successfully' ,  null, 'success',3000);
          this.modalRef.hide();
        } else {
          this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
          this.submitTouched = false;
        }
      })
    
    this.spinner.hide();
    
    }
}
