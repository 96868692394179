<div class="d-flex vh-100 position-fixed overflow-hidden justify-content-between">
  <div class="left-setup-tix vh-100 overflow-scroll">
    <app-menu [count]="ticketCounts"></app-menu>
  </div>
  <div>
    <div class="right-setup-task h-100 position-fixed flex-fill">
      <div class="left-right-space mt-2 mb-2">
        <!-- Title -->
        <div class="flex-between">
          <div class="main-list-header" id="title_HelpTix" >
            {{ translate?.ticket.label.singular}}
          </div>
          <!-- Filter and New -->
          <div class="custella-dashboard-top-right d-flex ms-auto">
            <app-helpdesk-search-box></app-helpdesk-search-box>
          </div>
        </div>
      </div>

      <div class="">
        <div class="left-right-space">
          <app-helpdesk-tabs></app-helpdesk-tabs>
        </div>

        <!-- Route -->
        <div class="ticketViewContainer" #pageRow [hidden]="!isTicketOnView">
          <div [hidden]="ticketView.hidden" [attr.data-ticketview]="ticketView.id"
            *ngFor="let ticketView of listOfAllTicketViews">
            <template #ticketDynamicContainer></template>
          </div>
        </div>

        <!-- Table -->
        <div class="tableContainer left-right-space" #tableRow [hidden]="isTicketOnView">
          <app-helpdesk-table></app-helpdesk-table>
        </div>
      </div>

      <!-- Assign and Claim -->
      <template #componentContainer> </template>
    </div>
  </div>
</div>