import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Headers, Http, RequestOptions } from "@angular/http";
import { Observable, of, Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../auth/authentication.service";

@Injectable({
  providedIn: "root",
})
export class TicketsService {
  public refresherFaultType$: EventEmitter<any>;
  public refresherSlaPackage$: EventEmitter<any>;
  public refresherCustomer$: EventEmitter<any>;
  public refresherSlaStep$: EventEmitter<any>;
  public refresherNotification$: EventEmitter<any>;
  public refresherBusinessHour$: EventEmitter<any>;
  public refresherChannel$: EventEmitter<any>;
  public refresherTicketType$: EventEmitter<any>;
  public refresherDepartment$: EventEmitter<any>;
  public refresherTicketStatus$: EventEmitter<any>;
  public refresherResolutionType$: EventEmitter<any>;
  public refresherTicket$: EventEmitter<any>;
  public refresherPriority$: EventEmitter<any>;
  public refresherTicketConversation$: EventEmitter<any>;

  private ENDPOINT_SLA_STEP_BY_ID = "/sla-line-items/";
  private ENDPOINT_SLA_STEP_LIST = "/sla-line-items";

  private ENDPOINT_SLA_PACKAGE_BY_ID = "/sla-packages/";
  private ENDPOINT_SLA_PACKAGE_LIST = "/sla-packages";

  private ENDPOINT_SLA_STEP_NOTIFICATION_BY_ID = "/sla-notifications/";
  private ENDPOINT_SLA_STEP_NOTIFICATION_LIST = "/sla-notifications";
  private ENDPOINT_SLA_STEP_NOTIFICATION_TYPE = "/sla/notification-types";
  private ENDPOINT_BUSINESS_HOUR_LIST = "/ticket-business-hours";
  private ENDPOINT_BUSINESS_HOUR_BY_ID = "/ticket-business-hours/";
  private ENDPOINT_CHANNEL_LIST = "/channels";
  private ENDPOINT_CHANNEL_BY_ID = "/channels/";
  private ENDPOINT_TICKET_TYPE_LIST = "/ticket-types";
  private ENDPOINT_TICKET_TYPE_BY_ID = "/ticket-types/";
  private ENDPOINT_DEPARTMENT_LIST = "/departments";
  private ENDPOINT_DEPARTMENT_BY_ID = "/departments/";
  private ENDPOINT_TICKET_STATUS_LIST = "/ticket-statuses";
  private ENDPOINT_TICKET_STATUS_BY_ID = "/ticket-statuses/";
  private ENDPOINT_FAULT_TYPE_LIST = "/fault-types";
  private ENDPOINT_FAULT_TYPE_BY_ID = "/fault-types/";
  private ENDPOINT_RESOLUTION_TYPE_LIST = "/resolution-types";
  private ENDPOINT_RESOLUTION_TYPE_BY_ID = "/resolution-types/";
  private ENDPOINT_PRIORITY_LIST = "/priorities";
  private ENDPOINT_PRIORITY_BY_ID = "/priorities/";
  
  private ticketSelectSubject = new Subject();

  constructor(private http: HttpClient, private auth: AuthenticationService, private httpOld: Http) {
    this.refresherCustomer$ = new EventEmitter();
    this.refresherSlaPackage$ = new EventEmitter();
    this.refresherSlaStep$ = new EventEmitter();
    this.refresherNotification$ = new EventEmitter();
    this.refresherBusinessHour$ = new EventEmitter();
    this.refresherChannel$ = new EventEmitter();
    this.refresherTicketType$ = new EventEmitter();
    this.refresherDepartment$ = new EventEmitter();
    this.refresherTicketStatus$ = new EventEmitter();
    this.refresherFaultType$ = new EventEmitter();
    this.refresherResolutionType$ = new EventEmitter();
    this.refresherTicket$ = new EventEmitter();
    this.refresherPriority$ = new EventEmitter();
    this.refresherTicketConversation$ = new EventEmitter();
  }

  public setSeletTicket(val: any) {
    this.ticketSelectSubject.next(val);
  }

  getSelectTicket() {
    return this.ticketSelectSubject.asObservable();
  }

  public getAssets(custId: any = null, assetName: any = null) {
    let params = new HttpParams();

    if (custId !== "" && custId != null) {
      params = params.set("customerId", custId);
    }
    if (assetName !== "" && assetName != null) {
      params = params.set("name", assetName);
    }
    return this.http
      .get(`${environment.base_url}/v4/assets/pick-list`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getTypes() {
    return this.http
      .get(environment.base_url + "/sla/types", {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getCriteriaDetail(type: any) {
    return this.http.get(environment.base_url + "/sla/criteria/list?modelName=" + type).pipe(map( (resp:any) => {
      console.log(resp)
      if (resp.status == 204) {
               return false;
             } else {
              return resp;
            }
    }
  ),catchError( error => {
    //console.log(error);
      return throwError( error );
    }),);
  }
 

  public getSLAPickList(type: string) {
    return this.http
      .get(environment.base_url + "/sla-line-items/active?type=" + type, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  /*==============================================SlaStep ==================================*/

  public getAllSlaStep() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_SLA_STEP_LIST, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public createSlaStep(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_SLA_STEP_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherSlaStep$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getSlaStepById(stepId: string) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_SLA_STEP_BY_ID + stepId, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public updateSlaStep(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_SLA_STEP_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          console.log(resp);
          this.refresherSlaStep$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public deleteSlaStep(id: string) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_SLA_STEP_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherSlaStep$.emit("Deleted SlaStep");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public deleteCriteriaDetail(
    lineItemId: string,
    indexNumber: string,
    type: string,
    section: string
  ) {
    return this.http
      .put(
        environment.base_url +
          "/sla-line-items/criteria/remove?lineItemId=" +
          lineItemId +
          "&indexNumber=" +
          indexNumber +
          "&type=" +
          type +
          "&section=" +
          section,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refresherSlaStep$.emit("remove Criteria");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /*==============================================Sla Package ==================================*/

  public getAllSlaPackage() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_SLA_PACKAGE_LIST, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public createSlaPackage(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_SLA_PACKAGE_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherSlaPackage$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getSlaPackageById(packageId: string) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_SLA_PACKAGE_BY_ID + packageId, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public updateSlaPackage(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_SLA_PACKAGE_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          console.log(resp);
          this.refresherSlaPackage$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  public addLineItemSlaPackage(packageId: string, slaLineItemId: string) {
    return this.http
      .put(
        environment.base_url +
          "/sla-packages/line-item/add?packageId=" +
          packageId +
          "&slaLineItemId=" +
          slaLineItemId,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          console.log(resp);
          this.refresherSlaPackage$.emit("addLineItemSlaPackage");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  public deleteLineItemSlaPackage(packageId: string, slaLineItemId: string) {
    return this.http
      .put(environment.base_url+"/sla-packages/line-item/remove?packageId=" +
          packageId +
          "&slaLineItemId=" +
          slaLineItemId,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          console.log(resp);
          this.refresherSlaPackage$.emit("removeLineItemSlaPackage");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public deleteSlaPackage(id: string) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_SLA_PACKAGE_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherSlaPackage$.emit("Deleted SlaPackage");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /*==============================================Customer ==================================*/

  public getAllCustomer(slaPackageId: string) {
    return this.http
      .get(
        environment.base_url +
          "/sla-package-customers?slaPackageId=" +
          slaPackageId,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public createCustomer(body: any) {
    return this.http
      .post(environment.base_url + "/sla-package-customers", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherCustomer$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getCustomerById(channelId: string) {
    return this.http
      .get(environment.base_url + "/sla-package-customers/" + channelId, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public updateCustomer(body: any) {
    return this.http
      .put(environment.base_url + "/sla-package-customers", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          console.log(resp);
          this.refresherCustomer$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public deleteCustomer(id: string) {
    return this.http
      .delete(environment.base_url + "/sla-package-customers/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherCustomer$.emit("Deleted SlaPackageCustomer");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /*==============================================Notification ==================================*/
  public getAllNotificationType() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_SLA_STEP_NOTIFICATION_TYPE, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getAllNotification(id: string) {
    return this.http
      .get(
        environment.base_url +
          this.ENDPOINT_SLA_STEP_NOTIFICATION_LIST +
          "?slaLineItemId=" +
          id,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public createNotification(body: any) {
    return this.http
      .post(
        environment.base_url + this.ENDPOINT_SLA_STEP_NOTIFICATION_LIST,
        body,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refresherSlaStep$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getNotificationById(channelId: string) {
    return this.http
      .get(
        environment.base_url +
          this.ENDPOINT_SLA_STEP_NOTIFICATION_BY_ID +
          channelId,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public updateNotification(body: any) {
    return this.http
      .put(
        environment.base_url + this.ENDPOINT_SLA_STEP_NOTIFICATION_LIST,
        body,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          console.log(resp);
        //  this.refresherSlaStep$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public deleteNotification(id: string) {
    return this.http
      .delete(
        environment.base_url + this.ENDPOINT_SLA_STEP_NOTIFICATION_BY_ID + id,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refresherSlaStep$.emit("Deleted Sla Notification");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /*============================================== Business Hour ==================================*/
  public getAllBusinessHour(active: any = null) {
    var url;
    if (active == "active") {
      url = environment.base_url + this.ENDPOINT_BUSINESS_HOUR_LIST + "/active";
    } else {
      url = environment.base_url + this.ENDPOINT_BUSINESS_HOUR_LIST;
    }
    return this.http.get(url, { observe: "response" }).pipe(
      map((resp: any) => {
        if (resp.status == 204) {
          return false;
        } else {
          return resp.body;
        }
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
  public createBusinessHour(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_BUSINESS_HOUR_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherBusinessHour$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getBusinessHourById(BusinessHourId: string) {
    return this.http
      .get(
        environment.base_url +
          this.ENDPOINT_BUSINESS_HOUR_BY_ID +
          BusinessHourId,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public updateBusinessHour(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_BUSINESS_HOUR_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          console.log(resp);
          this.refresherBusinessHour$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  public deleteBusinessHour(id: string) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_BUSINESS_HOUR_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherBusinessHour$.emit("Deleted BusinessHour");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /*================================================== Channel ======================================*/

  public getAllchannel(active: any = null) {
    var url;
    if (active == "active") {
      url = environment.base_url + this.ENDPOINT_CHANNEL_LIST + "/active";
    } else {
      url = environment.base_url + this.ENDPOINT_CHANNEL_LIST;
    }
    return this.http.get(url, { observe: "response" }).pipe(
      map((resp: any) => {
        if (resp.status == 204) {
          return false;
        } else {
          return resp.body;
        }
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
  public createChannel(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_CHANNEL_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherChannel$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getChannelById(channelId: string) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_CHANNEL_BY_ID + channelId, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public updateChannel(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_CHANNEL_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          console.log(resp);
          this.refresherChannel$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  public deleteChannel(id: string) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_CHANNEL_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherChannel$.emit("Deleted Channel");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /*==============================================Ticket Type ==================================*/

  public getAllTicketType(active: any = null) {
    var url;
    if (active == "active") {
      url = environment.base_url + this.ENDPOINT_TICKET_TYPE_LIST + "/active";
    } else {
      url = environment.base_url + this.ENDPOINT_TICKET_TYPE_LIST;
    }
    return this.http.get(url, { observe: "response" }).pipe(
      map((resp: any) => {
        if (resp.status == 204) {
          return false;
        } else {
          return resp.body;
        }
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
  public createTicketType(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_TICKET_TYPE_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherTicketType$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getTicketTypeById(channelId: string) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_TICKET_TYPE_BY_ID + channelId, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public updateTicketType(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_TICKET_TYPE_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          console.log(resp);
          this.refresherTicketType$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public deleteTicketType(id: string) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_TICKET_TYPE_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherTicketType$.emit("Deleted TicketType");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /*==============================================Department ==================================*/

  public getAllDepartment(active: any = null) {
    var url;
    if (active == "active") {
      url = environment.base_url + this.ENDPOINT_DEPARTMENT_LIST + "/active";
    } else {
      url = environment.base_url + this.ENDPOINT_DEPARTMENT_LIST;
    }
    return this.http.get(url, { observe: "response" }).pipe(
      map((resp: any) => {
        if (resp.status == 204) {
          return false;
        } else {
          return resp.body;
        }
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
  public createDepartment(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_DEPARTMENT_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherDepartment$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getDepartmentById(channelId: string) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_DEPARTMENT_BY_ID + channelId, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public updateDepartment(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_DEPARTMENT_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          console.log(resp);
          this.refresherDepartment$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public deleteDepartment(id: string) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_DEPARTMENT_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherDepartment$.emit("Deleted Department");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /*==============================================TicketStatus ==================================*/

  public getAllTicketStatus(active: any = null) {
    var url;
    if (active == "active") {
      url = environment.base_url + this.ENDPOINT_TICKET_STATUS_LIST + "/active";
    } else {
      url = environment.base_url + this.ENDPOINT_TICKET_STATUS_LIST;
    }
    return this.http.get(url, { observe: "response" }).pipe(
      map((resp: any) => {
        if (resp.status == 204) {
          return false;
        } else {
          return resp.body;
        }
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  public createTicketStatus(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_TICKET_STATUS_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherTicketStatus$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getTicketStatusById(channelId: string) {
    return this.http
      .get(
        environment.base_url + this.ENDPOINT_TICKET_STATUS_BY_ID + channelId,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public updateTicketStatus(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_TICKET_STATUS_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          console.log(resp);
          this.refresherTicketStatus$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public deleteTicketStatus(id: string) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_TICKET_STATUS_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherTicketStatus$.emit("Deleted TicketStatus");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /**============================= SLA Step Criteria ================================= */
  public updateSlaStepCriteria(body: any) {
    return this.http
      .put(environment.base_url + "/sla-line-items/criteria", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          console.log(resp);
          this.refresherSlaStep$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /**============================= SLA Package ================================= */
  public updateSlaStepPackage(body: any) {
    return this.http
      .put(environment.base_url + "/sla-packages/criteria", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          console.log(resp);
          this.refresherSlaPackage$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public deleteConditionDetail(
    packageId: string,
    indexNumber: string,
    section: string
  ) {
    return this.http
      .put(
        environment.base_url +
          "/sla-packages/criteria/remove?packageId=" +
          packageId +
          "&indexNumber=" +
          indexNumber +
          "&section=" +
          section,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          console.log(resp);
          this.refresherSlaPackage$.emit("remove Criteria");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /*==============================================Fault Type ==================================*/

  public getAllFaultType() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_FAULT_TYPE_LIST, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public createFaultType(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_FAULT_TYPE_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherFaultType$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getFaultTypeId(channelId: string) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_FAULT_TYPE_BY_ID + channelId, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public updateFaultType(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_FAULT_TYPE_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          console.log(resp);
          this.refresherFaultType$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public deleteFaultType(id: string) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_FAULT_TYPE_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherFaultType$.emit("Deleted Fault Type");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /*==============================================RESOLUTION_TYPE ==================================*/

  public getAllResolutionType() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_RESOLUTION_TYPE_LIST, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public createResolutionType(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_RESOLUTION_TYPE_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherResolutionType$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getResolutionTypeById(resolutionId: string) {
    return this.http
      .get(
        environment.base_url +
          this.ENDPOINT_RESOLUTION_TYPE_BY_ID +
          resolutionId,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public updateResolutionType(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_RESOLUTION_TYPE_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          console.log(resp);
          this.refresherResolutionType$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public deleteResolutionType(id: string) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_RESOLUTION_TYPE_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherResolutionType$.emit("Deleted ResolutionType");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /*==============================================Ticket ==================================*/

  public getAllTicket(
    startDateTime: string | number | boolean,
    endDateTime: string | number | boolean,
    ticketStatusIds: string | number | boolean | null,
    customerId: string | number | boolean | null,
    siteId: string | number | boolean | null,
    projectId: string | number | boolean | null,
    searchValue: string | number | boolean | null,
    parameters: any
  ) {
    let params = new HttpParams();
    if (startDateTime !== "" && endDateTime !== "") {
      params = params
        .set("startDateTime", startDateTime)
        .set("endDateTime", endDateTime);
    }

    if (ticketStatusIds !== null) {
      params = params.set("ticketStatusIds", ticketStatusIds);
    }

    if (customerId !== "" && customerId != null) {
      params = params.set("customerId", customerId);
    }

    if (siteId !== "" && siteId != null) {
      params = params.set("siteId", siteId);
    }

    if (projectId !== "" && projectId != null) {
      params = params.set("projectId", projectId);
    }

    if (searchValue !== "" && searchValue != null) {
      params = params.set("search", searchValue);
    }

    if (parameters) {
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", (parameters.sortModel[0] as any).colId)
        .set("sortOrder", (parameters.sortModel[0] as any).sort);
    }

    return this.http
      .get(`${environment.base_url}/v4-1/tickets`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public createTicket(body: any) {
    return this.http
      .post(environment.base_url + "/tickets", body, { observe: "response" })
      .pipe(
        map((resp: any) => {
          this.refresherTicket$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getTicketById(ticketId: string) {
    return this.http
      .get(environment.base_url + "/tickets/" + ticketId, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public updateTicket(body: any) {
    return this.http
      .put(environment.base_url + "/tickets", body, { observe: "response" })
      .pipe(
        map((resp: any) => {
          console.log(resp);
          this.refresherTicket$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  public updateTicketStatusFromJO(body: any) {
    const formData = new FormData();
    return this.http
      .put(environment.base_url + "/tickets/status?" + body, formData, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          console.log(resp);
          this.refresherTicket$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public deleteTicket(id: string) {
    return this.http
      .delete(environment.base_url + "/tickets/" + id, { observe: "response" })
      .pipe(
        map((resp: any) => {
          this.refresherTicket$.emit("Deleted Ticket");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public deleteTicketForHelpDesk(id: string) {
    return this.http
      .delete(environment.base_url + "/tickets/" + id, { observe: "response" })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getContactByCystomer(customerId: string) {
    return this.http
      .get(
        environment.base_url + "/contacts/by-customer?customerId=" + customerId
      )
      .pipe(
        map((resp: any) => {
          //console.log(resp);
          // return <Admin>resp;
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /*==============================================Priority ==================================*/

  public getAllPriority(active: any = null) {
    var url;
    if (active == "active") {
      url = environment.base_url + this.ENDPOINT_PRIORITY_LIST + "/active";
    } else {
      url = environment.base_url + this.ENDPOINT_PRIORITY_LIST;
    }
    return this.http.get(url, { observe: "response" }).pipe(
      map((resp: any) => {
        if (resp.status == 204) {
          return false;
        } else {
          return resp.body;
        }
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }
  public createPriority(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_PRIORITY_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherPriority$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getPriorityById(resolutionId: string) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_PRIORITY_BY_ID + resolutionId, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public updatePriority(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_PRIORITY_LIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          console.log(resp);
          this.refresherPriority$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public deletePriority(id: string) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_PRIORITY_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherPriority$.emit("Deleted Priority");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getAllDuplicateTicket() {
    return this.http
      .get(environment.base_url + "/tickets/pick-list", { observe: "response" })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getAllSlaByTicketId(ticketId: string) {
    return this.http
      .get(environment.base_url + "/sla-tickets/ticket?ticketId=" + ticketId, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getAllTicketHistory(
    ticketId: string | number | boolean | null,
    searchValue: string | number | boolean | null,
    parameters: any
  ) {
    let params = new HttpParams();

    if (ticketId !== "" && ticketId != null) {
      params = params.set("ticketId", ticketId);
    }

    if (searchValue !== "" && searchValue != null) {
      params = params.set("search", searchValue);
    }
    console.log(parameters);
    if (parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25");
    return this.http
      .get(`${environment.base_url}/ticket-event-histories`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getAllJobOrderByTickets(
    ticketId: string | number | boolean | null,
    searchValue: string | number | boolean | null,
    parameters: { startRow: string | number | boolean }
  ) {
    let params = new HttpParams();

    if (ticketId !== "" && ticketId != null) {
      params = params.set("ticketId", ticketId);
    }
    params = params.set("ticket", "ticket");

    if (searchValue !== "" && searchValue != null) {
      params = params.set("search", searchValue);
    }
    console.log(parameters);
    if (parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25");
    return this.http
      .get(`${environment.base_url}/v3/job-orders`, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  /**=======================================getTicketConfig ================================================*/
  public getTicketConfig() {
    return this.http
      .get(environment.base_url + "/ticket-configs", { observe: "response" })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public editTicketsConfig(body: any) {
    return this.http
      .post(environment.base_url + "/ticket-configs", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherPriority$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  /**=============================Upload Attachment ======================================================*/
  public uploadAttachment(id: any, formData: any) {
    const headers = new Headers({});
    headers.append('Authorization', 'Bearer '+this.auth.getToken());
    let options = new RequestOptions( { headers: headers } )
    return this.httpOld
      .post(
        environment.base_url +
          "/attachments/upload?parentType=TICKET_ATTACHMENT&parentId=" +
          id,
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  /*==============================================conversations ==================================*/

  public getAllConversations(ticketId: string) {
    return this.http
      .get(environment.base_url + "/ticket-conversations/ticket/" + ticketId, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public getConvoById(conversationId: number) {
    return this.http
      .get(environment.base_url + "/ticket-conversations/" + conversationId, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  public createConversations(body: any) {
    return this.http
      .post(environment.base_url + "/ticket-conversations", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherTicketConversation$.emit(body);
          return resp;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public getConversationsById(resolutionId: string) {
    return this.http
      .get(
        environment.base_url +
          this.ENDPOINT_RESOLUTION_TYPE_BY_ID +
          resolutionId,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public deleteConversations(id: string) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_RESOLUTION_TYPE_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherTicketConversation$.emit("Deleted ResolutionType");
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  public uploadCommentAttachment(formData: any) {
    const headers = new HttpHeaders({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = { headers: headers };
    return this.http
      .post(
        environment.base_url +
          "/attachments/upload?parentType=TICKET_NOTE_ATTACHMENT",
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getAllFirstResponse(ticketId: string) {
    return this.http
      .get(environment.base_url + "/sla-tickets/ticket?ticketId=" + ticketId)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }

  public getAlldeHelpDeskUser(userGroup: string | number | boolean | null) {
    let params = new HttpParams();

    if (userGroup !== "" && userGroup != null) {
      params = params.set("userGroupId", userGroup);
    }

    return this.http
      .get(environment.base_url + "/v2/users/active/help-desk", {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error: any) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  public getAttachmentBlob(url: any) {
    return this.http.get(url).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error: any) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
}
