<div class="left-right-space">
    <router-outlet></router-outlet>
    
    <!-- Title -->
    <div class="flex-between mb-2">
        <div class="main-list-header" id="title_ChubbDashboard">{{translate?.dashboard.chubb.label.singular}}</div>
        <div class="custella-dashboard-top-right d-flex">
            <img src="assets\images\chubb\chubb-logo.png" alt="Chubb Logo" id="img_ChubbLogo" class="h-35" />
            <div class="divider-end mx-2"></div>
            <img src="assets\images\chubb\simedarby-logo.png" alt="Sime Darby Logo" id="img_SimeDarbyLogo" class="h-35" />
        </div>
    </div>
</div>
<!-- Total Task by Team -->
<div class="custella-form-container left-right-space rounded-2">
    <div class="custella-form-container-title flex-between">
        <span class="text-14 font-medium" id="title_ChubbTotalTaskTeam">{{translate?.dashboard.chubb.sections.sec0.singular}}</span>
        <div class="d-flex align-items-center">
            <p-button label="Slideshow" styleClass="ms-2 h-32" id="btn_ChubbTotalTaskTeamSlide" (onClick)="navigateTo('slideshow')"></p-button>
            <button type="button" (click)="refreshData('panel-1')" id="btn_ChubbTotalTaskSlideTeamRefresh" class="border rounded-1 px-04 ms-2 btn-32"><img alt="logo" src="assets\svg\refresh.svg" /></button>
        </div>
    </div>
    <app-chubb-team-task></app-chubb-team-task>
</div>
<!-- Total Task Status by Team -->
<div class="custella-form-container left-right-space rounded-2">
    <div class="custella-form-container-title flex-between">
        <span class="text-14 font-medium" id="title_ChubbTotalTaskStatusTeam" >{{translate?.dashboard.chubb.sections.sec1.singular}}</span>
        <button type="button" (click)="refreshData('panel-2')" id="btn_ChubbTotalTaskStatusTeamRefresh" class="border rounded-1 px-04 ms-2 btn-32"><img alt="logo" src="assets\svg\refresh.svg" /></button>
    </div>
    <app-chubb-team-status></app-chubb-team-status>
</div>
<!-- Technician -->
<div class="custella-form-container left-right-space rounded-2">
    <div class="custella-form-container-title flex-between">
        <span class="text-14 font-medium" id="title_ChubbTotalTechnician" >{{translate?.dashboard.chubb.fields.technician.singular}}</span>
        <div>
            <p-button [label]="translate?.general.buttons.download.singular" styleClass="ms-2 h-32" id="btn_ChubbTotalTechDownload" (onClick)="download()"></p-button>
            <button type="button" (click)="refreshData('panel-3')" id="btn_ChubbTotalTechRefresh" class="border rounded-1 px-04 ms-2 btn-32"><img alt="logo" src="assets\svg\refresh.svg" /></button>
        </div>
    </div>
    <app-chubb-technician-table></app-chubb-technician-table>
</div>
