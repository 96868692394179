import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from "src/app/app.component";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { UsersService } from "src/app/_services/admin/users.service";
import { ApiService } from "src/app/_services/api.service";
import { ProfileFeaturesService } from "src/app/_services/profile-features.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { EngLangData, MalayLangData, chineseLangData, languageOptions } from "src/app/_models/language.data.model";
import { forkJoin } from "rxjs";

import * as _ from "lodash";

@Component({
  selector: "app-password-activation",
  templateUrl: "./password-activation.component.html",
  styleUrls: ["./password-activation.component.scss"],
})
export class PasswordActivationComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  accountForm!: UntypedFormGroup;
  profile: any = {};
  token: any;
  permissions!: { role: any; roles: any[] };
  fieldTextType!: boolean;
  fieldTextType2!: boolean;
  isPasswordMatching: boolean =true;
  userName : any;
  isPasswordHasUserName!: boolean;
  previousPWValue: any = "";
  showtooltip!: boolean;
  @ViewChild('chooseLang') chooseLangEl: ElementRef;


   langData: any;
   languageList: any;
  currentLang: string;
  constructor(
    private route: ActivatedRoute,
    private userService: UsersService,
    private router: Router,
    private apiService: ApiService,
    private perm: ProfileFeaturesService,
    private util: UtilServiceService,
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private errorUtil: ErrorUtil
  ) {
    super();

    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    var langCode = localStorage.getItem('lang');
    if (langCode == 'my') this.onChangeLanguage('Malay')
    else if (langCode == 'en') this.onChangeLanguage('English')
    else if (langCode == 'zh') this.onChangeLanguage('Chinese')
    else this.onChangeLanguage('English')
    this.languageList = Object.values(languageOptions);
    this.accountForm = new UntypedFormGroup({
      password: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(AppComponent.strongPasswordRegx),this.passwordValidator
      ]),
      cPassword: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(AppComponent.strongPasswordRegx),
      ]),
    });
  }


  /******************************************** Init ******************************************/
  private init() {
    this.push(
      this.route.url.subscribe((params) => {
        if (params.length > 1) {
          this.token = params[1].path;
          // console.log("Token ", this.token);

          this.userService
            .validateActivationToken(this.token)
            .subscribe((res: any) => {
              if (res && res.status && res.status !== 200) {
                this.errorUtil.setErrorMessage(
                  400,
                  res.error.title,
                  res.error.title,
                  "error",
                  2000
                );

                this.router.navigate(["login"]);
              }
              else{
                console.log("responce activation link",res)
                this.userName = res.email;
              }

            });
        }
      })
    );
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Activate Account ******************************************/
  public activateAccount() {
    this.spinner.show();

    if (!this.accountForm.valid) {
      for (var i in this.accountForm.controls) {
        this.accountForm.controls[i].markAsTouched();
      }
      this.errorUtil.setErrorMessage(
        "400",
        null,
        this.langData.resetPassword.messages.passwordCriteriaNotMet,
        "warn",
        2000
      );
      this.spinner.hide();
      return false;
    }

    if (this.isPasswordHasUserName) {
      this.errorUtil.setErrorMessage(
        "400",
        null,
        this.langData.resetPassword.messages.passwordCriteriaNotMet,
        "warn",
        2000
      );
      this.spinner.hide();
      return false;
    }


    if (this.profile.password !== this.profile.cPassword) {
      this.errorUtil.setErrorMessage(
        "400",
        null,
        this.langData.setup.users.users.messages.invalidCredentials,
        "warn",
        2000
      );

      this.spinner.hide();
    } else {
      let data = {
        newPassword: this.profile.password,
        activationKey: this.token,
      };

      this.push(
        this.userService.activateAccount(data).subscribe((res: any) => {
          if (res.status === 200) {
            if (window.innerWidth < 720) {
              setTimeout(() => {
                this.router.navigate(["/mobile"]);
                localStorage.clear();
              }, 1000);
            } else {
              forkJoin(
                this.apiService.getProfile(),
                this.perm.getProfileFeatures()
              ).subscribe(([profileResponse, permissionResponse]) => {
                if (profileResponse) {
                  if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition((position) => {
                      this.userService
                        .updateUserLocation(
                          profileResponse.id,
                          position.coords.latitude,
                          position.coords.longitude,
                          1
                        )
                        .subscribe((res: any) => {
                          // console.log("User Location Updated");
                        });
                    });
                  }

                  localStorage.setItem("lang", "en");
                  localStorage.setItem("profile_id", btoa(profileResponse.id));
                  localStorage.setItem(
                    "user_name",
                    btoa(profileResponse.firstName)
                  );

                  if (profileResponse.licenceVM.trial) {
                    localStorage.setItem(
                      "trialEndDate",
                      profileResponse.licenceVM.validTill
                    );
                  }

                  this.util.setCompanyName(profileResponse.companyName);

                  var profileInfo = {
                    fullName:
                      profileResponse.firstName +
                      " " +
                      profileResponse.lastName,
                    email: profileResponse.email,
                    tenantId: profileResponse.tenantId,
                    firstName: profileResponse.firstName,
                    tenantCategories: profileResponse.tenantCategories,
                    createdDate: profileResponse.licenceVM.createdDate,
                    renewalDate: profileResponse.licenceVM.validTill,
                    trial: profileResponse.licenceVM.trial,
                  };

                  localStorage.setItem(
                    "profile_info",
                    btoa(JSON.stringify(profileInfo))
                  );

                  localStorage.setItem(
                    "date_format",
                    profileResponse.dateFormatString
                  );
                }

                if (permissionResponse) {
                  localStorage.setItem(
                    "role_token",
                    btoa(permissionResponse.profile.name)
                  );

                  this.permissions = {
                    role: permissionResponse.profile.name,
                    roles: [],
                  };

                  permissionResponse.moduleAccessFeatures.forEach(
                    (role: any) => {
                      var tempRole = {
                        moduleName: role.feature.name.toLowerCase(),
                        read: role.readFeature,
                        create: role.createFeature,
                        update: role.updateFeature,
                        remove: role.deleteFeature,
                      };
                      this.permissions.roles.push(tempRole);
                    }
                  );

                  permissionResponse.adminSettingsFeatures.forEach(
                    (role2: any) => {
                      if (role2.typeValue === "YES") {
                        var tempRole = {
                          moduleName: role2.feature.name.toLowerCase(),
                          read: true,
                          create: true,
                          update: true,
                          remove: true,
                        };
                      } else {
                        if (role2.feature.name === "Manage ORG Detail") {
                          var findSetup = _.filter(
                            res.moduleAccessFeatures,
                            function (o) {
                              return o.feature.name == "setup";
                            }
                          );
                          if (findSetup.length) {
                            var tempRole = {
                              moduleName: role2.feature.name.toLowerCase(),
                              read: true,
                              create: false,
                              update: false,
                              remove: false,
                            };
                          } else {
                            var tempRole = {
                              moduleName: role2.feature.name.toLowerCase(),
                              read: false,
                              create: false,
                              update: false,
                              remove: false,
                            };
                          }
                        } else {
                          var tempRole = {
                            moduleName: role2.feature.name.toLowerCase(),
                            read: false,
                            create: false,
                            update: false,
                            remove: false,
                          };
                        }
                      }
                      this.permissions.roles.push(tempRole);
                    }
                  );

                  if (permissionResponse.profile.allowAssignTask) {
                    var tempRole = {
                      moduleName: "allow assign task",
                      read: true,
                      create: true,
                      update: true,
                      remove: true,
                    };
                  } else {
                    var tempRole = {
                      moduleName: "allow assign task",
                      read: false,
                      create: false,
                      update: false,
                      remove: false,
                    };
                  }

                  this.permissions.roles.push(tempRole);

                  var menuPermissions = {
                    defaultTenantCategory:
                      permissionResponse.profile.defaultTenantCategory.toLowerCase(),
                    sales: permissionResponse.profile.sales,
                    service: permissionResponse.profile.service,
                    fleet: permissionResponse.profile.fleet,
                  };

                  localStorage.setItem(
                    "menuPermissions",
                    JSON.stringify(menuPermissions)
                  );

                  localStorage.setItem(
                    "permissions",
                    JSON.stringify(this.permissions)
                  );
                }

                this.push(
                  this.userService
                    .getUserProfile(this.authService.getUserId())
                    .subscribe((userProfileResponse: any) => {
                      if (userProfileResponse) {
                        var homeAddress = "";
                        var officeAddress = "";
                        for (let key in userProfileResponse) {
                          if (
                            key == "homeStreet" &&
                            userProfileResponse[key] !== null
                          ) {
                            homeAddress += userProfileResponse[key] + ", ";
                          }
                          if (
                            key == "homeCity" &&
                            userProfileResponse[key] !== null
                          ) {
                            homeAddress += userProfileResponse[key] + ", ";
                          }
                          if (
                            key == "homeState" &&
                            userProfileResponse[key] !== null
                          ) {
                            homeAddress += userProfileResponse[key] + ", ";
                          }
                          if (
                            key == "homePostcode" &&
                            userProfileResponse[key] !== null
                          ) {
                            homeAddress += userProfileResponse[key] + ", ";
                          }
                          if (
                            key == "homeCountry" &&
                            userProfileResponse[key] !== null
                          ) {
                            homeAddress += userProfileResponse[key];
                          }

                          if (
                            key == "officeStreet" &&
                            userProfileResponse[key] !== null
                          ) {
                            officeAddress += userProfileResponse[key] + ", ";
                          }
                          if (
                            key == "officeCity" &&
                            userProfileResponse[key] !== null
                          ) {
                            officeAddress += userProfileResponse[key] + ", ";
                          }
                          if (
                            key == "officeState" &&
                            userProfileResponse[key] !== null
                          ) {
                            officeAddress += userProfileResponse[key] + ", ";
                          }
                          if (
                            key == "officePostcode" &&
                            userProfileResponse[key] !== null
                          ) {
                            officeAddress += userProfileResponse[key] + ", ";
                          }
                          if (
                            key == "officeCountry" &&
                            userProfileResponse[key] !== null
                          ) {
                            officeAddress += userProfileResponse[key];
                          }
                        }

                        if (homeAddress != "") {
                          var homeAdd = {
                            address: homeAddress,
                            placeId: userProfileResponse.homePlaceId,
                            lat: userProfileResponse.homeLatitude,
                            lng: userProfileResponse.homeLongitude,
                          };

                          localStorage.setItem(
                            "home_address",
                            JSON.stringify(homeAdd)
                          );
                        }

                        if (officeAddress != "") {
                          var officeAdd = {
                            address: officeAddress,
                            placeId: userProfileResponse.officePlaceId,
                            lat: userProfileResponse.officeLatitude,
                            lng: userProfileResponse.officeLongitude,
                          };

                          localStorage.setItem(
                            "office_address",
                            JSON.stringify(officeAdd)
                          );
                        }

                        localStorage.setItem(
                          "buffer",
                          JSON.stringify({
                            start: userProfileResponse.startBuffer,
                            end: userProfileResponse.endBuffer,
                          })
                        );

                        localStorage.setItem(
                          "timezone",
                          userProfileResponse.timezone
                        );
                      }

                      setTimeout(() => {
                        if (profileResponse.firstTimeLogin) {
                          this.router.navigate(["/profile-info"]);
                        } else {
                          this.router.navigate(["/dashboard/home"]);
                        }
                        this.spinner.hide();
                      }, 1000);
                    })
                );
              });
            }
          } else {
            console.log(res);
            this.errorUtil.setErrorMessage(
              res.status,
              null,
              res.error.title,
              "error",
              2000
            );

            this.spinner.hide();
          }
        })
      );
    }
  }

  /******************************************** Toggle Password Show ******************************************/
  public toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  /******************************************** Password Length ******************************************/
  public getPassowrdLength(password: any) {
    
    const value = password.target.value;
    console.log(this.userName, "====", value, "====", this.passwordFormField?.get('password')?.valid)
    

   // const stringLower = password.toLowerCase();
    const substringLower = value.toLowerCase();

    // Check for full substring match
    let matchFound = false;
    // if (value.includes(stringLower)) {
    //   console.log(`Substring "${value}" is found in the string.`);
    //   this.isPasswordHasUserName = true;
    // }else{
    //   this.isPasswordHasUserName = false;
    // }
    if(this.isPasswordHasUserName)
    {console.log("has 1")
      if(substringLower && substringLower.length > 4 && this.userName && this.userName.indexOf(substringLower) !== -1)
       {
        if(substringLower && substringLower.length > 5 && this.userName && this.userName.indexOf(substringLower) == -1)
        {
        this.isPasswordHasUserName = true;
        }
       }
       else if(substringLower && substringLower.length < 5){
        this.isPasswordHasUserName = false;
       }
    }
    else{
      if(substringLower && substringLower.length > 5 && this.userName && this.userName.indexOf(substringLower) !== -1){
        this.isPasswordHasUserName = true;
       }
    }


      console.log(this.isPasswordHasUserName)
      if (this.accountForm.get('password')?.valid == false) {
        this.showtooltip = true;
      }
      else
    {
      this.showtooltip = false;
    }
    }
    
  /******************************************** Toggle Confirm Password Show ******************************************/
  public toggleFieldTextType2() {
    this.fieldTextType2 = !this.fieldTextType2;
  }

  /******************************************** Confirm Password Length ******************************************/
  public getCPassowrdLength(cPassword: any) {
    const value = cPassword.target.value;
    if(this.profile.password !== this.profile.cPassword)
    {
      this.isPasswordMatching = false;
    }
    if(this.profile.password == this.profile.cPassword)
    {
      this.isPasswordMatching = true
    }
  }

  get passwordFormField() {
    return this.accountForm.get('password');
  }

  onClickBackground() {
    console.log("click", this.accountForm.get('password')?.valid)
    if (this.accountForm.get('password')?.valid == true) {
      this.showtooltip = false;
    }
  }

  push(obs:any) {
    super.push(obs);
  }

  passwordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!this.isPasswordHasUserName) {
      return null; // No error if password dose not have username
    }
    else {
      //alert('invalid')
      return { dynamicError: { message: 'Please enter a valid value.' } };
    }
  };


  onChangeLanguage(landKey: any){
    console.log(landKey)

    if(landKey == 'English'){
        this.currentLang = languageOptions.english
        this.langData = EngLangData
        this.chooseLangEl?.nativeElement.classList.remove('show');
        localStorage.setItem('lang','en')
    }
    if(landKey == 'Malay'){
      this.currentLang = languageOptions.malay
      this.langData = MalayLangData
      this.chooseLangEl?.nativeElement.classList.remove('show');
      localStorage.setItem('lang','my')
    
  }
  if(landKey == 'Chinese'){
    this.currentLang = languageOptions.chinese
    this.langData = chineseLangData
    this.chooseLangEl?.nativeElement.classList.remove('show');
    localStorage.setItem('lang','zh')
    
}
  console.log(this.langData)
}

}
