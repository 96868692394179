import * as moment from "moment-timezone";

export const techniciansTableColumns = [
  {
    headerName: "Technicians",
    field: "technician",
    colId: "technician",
    width: 150,
    cellClass: function (params: any) {
      return ["text-1-5 font-weight-bold table_default_color"];
    },
    filter: false,
    headerTooltip: "Technicians",
  },
  {
    headerName: "Jobs In",
    field: "joCount",
    width: 80,
    filter: false,
    headerTooltip: "Jobs In",
  },
  {
    headerName: "Open",
    field: "openCount",
    width: 70,
    filter: false,
    headerTooltip: "Open",
  },
  {
    headerName: "In Progress",
    field: "inProgressCount",
    width: 100,
    filter: false,
    headerTooltip: "In Progress",
  },
  {
    headerName: "Pending",
    field: "pendingCount",
    width: 80,
    filter: false,
    headerTooltip: "Pending",
  },
  {
    headerName: "Completed",
    field: "completeCount",
    width: 100,
    filter: false,
    headerTooltip: "Completed",
  },
  {
    headerName: "Incomplete",
    field: "incompleteCount",
    width: 100,
    filter: false,
    headerTooltip: "Incomplete",
  },
  {
    headerName: "Cancelled",
    field: "cancelCount",
    width: 100,
    filter: false,
    headerTooltip: "Cancelled",
  },
  {
    headerName: "Attend Fault",
    field: "attendFault",
    width: 100,
    filter: false,
    headerTooltip: "Attend Fault",
  },
  {
    headerName: "Servicing",
    field: "service",
    width: 100,
    filter: false,
    headerTooltip: "Servicing",
  },
  {
    headerName: "Installation",
    field: "installations",
    width: 100,
    filter: false,
    headerTooltip: "Installation",
  },
  {
    headerName: "Billing Pending",
    field: "billingPending",
    width: 100,
    filter: false,
    headerTooltip: "Billing Pending",
  },
  {
    headerName: "Billing Completed",
    field: "billingComplete",
    width: 100,
    filter: false,
    headerTooltip: "Billing Completed",
  },
];

export const techniciansTaskByTeamColumns = [
  {
    headerName: "Technicians",
    field: "name",
    colId: "name",
    width: 170,
    cellClass: function (params: any) {
      return [""];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Open Tasks",
    field: "openTasks",
    colId: "openTasks",
    width: 250,
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Closed Tasks",
    field: "closedTasks",
    colId: "closedTasks",
    width: 250,
    filter: false,
    headerTooltip: "#",
  },
];

export const techniciansTotalTaskByTeamColumns = [
  {
    headerName: "Technicians",
    field: "name",
    colId: "name",
    width: 170,
    cellClass: function (params: any) {
      return [""];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Pending Tasks",
    field: "pendingTasks",
    colId: "pendingTasks",
    width: 250,
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Incomplete Tasks",
    field: "inCompleteTasks",
    colId: "inCompleteTasks",
    width: 250,
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Completed Tasks",
    field: "completeTasks",
    colId: "completeTasks",
    width: 250,
    filter: false,
    headerTooltip: "#",
  },
];

export const allTasksByTechnicianColumns = [
  {
    headerName: "Job Order Id",
    field: "joNumber",
    colId: "joNumber",
    width: 170,
    cellClass: function (params: any) {
      return ["text-1-5 font-weight-bold table_default_color"];
    },
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Order Name",
    field: "joName",
    colId: "joName",
    width: 200,
    filter: false,
    headerTooltip: "#",
  },
  {
    headerName: "Order Time & Date",
    field: "orderDateTime",
    colId: "orderDateTime",
    width: 150,
    filter: false,
    cellRenderer: function (params: any) {
      if (params.data && params.data.orderDateTime) {
        return (
          moment(params.data.orderDateTime).format("hh:mm A") +
          ", " +
          moment(params.data.orderDateTime).format(
            localStorage.getItem("date_format")!.toUpperCase()
          )
        );
      }
    },
    headerTooltip: "#",
  },
  {
    headerName: "Customer",
    field: "customer.name",
    colId: "customer",
    width: 200,
    filter: false,
    headerTooltip: "#",
  },
];
