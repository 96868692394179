import { Injectable, OnDestroy } from "@angular/core";
import {
  ActivationEnd,
  ActivationStart,
  RoutesRecognized,
} from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { Subscription } from "rxjs-compat/Subscription";
import { BreadcrumbService, MenuItemPlus } from "./breadcrumb.service";

@Injectable({
  providedIn: "root",
})
export class NavigationBreadCrumbService implements OnDestroy {
  // subject that emits data for the Navigation Items
  private subject = new BehaviorSubject<any[]>([]);

  // to store all navigation items
  private allNavigationItems: any[] = [];

  // to store current url
  private currentUrl = "";

  private isAddEdit = false;

  private breadcrumbSubscription!: Subscription;
  private breadcurmbSubscrition2!: Subscription;

  constructor(private breadcrumbService: BreadcrumbService) {
    this.preInit();
  }

  ngOnDestroy(): void {}

  private preInit() {
    this.breadcrumbSubscription = this.breadcrumbService.itemsHandler.subscribe(
      (response: any) => {
        if (response && response instanceof Array && response.length !== 0) {
         // console.log(response, this.isAddEdit);

          this.addNavigation(response);
        }
      }
    );

    this.breadcurmbSubscrition2 =
      this.breadcrumbService.setupItemsHandler.subscribe((response) => {
        if (response && response instanceof Array && response.length !== 0) {
          // this.addNavigation(response);
        }
      });
  }

  private addNavigation(response: any[]) {
    if (this.isAddEdit === false) {
      // checks last item if it is same or not
      const lastItem =
        this.allNavigationItems[this.allNavigationItems.length - 1];

        let duplicateIndexes: any = [];
        this.allNavigationItems.forEach((nav: any, i) => {
        if (nav.label == response[1].label) {
          duplicateIndexes.push(i)
        }
      })

      if (duplicateIndexes && duplicateIndexes.length >  1) {
        var smallestValue = duplicateIndexes[0];
        duplicateIndexes.forEach((el: any) => {
          if (el < smallestValue) {
            smallestValue = el
          }
        });
        this.allNavigationItems.splice(smallestValue + 1, this.allNavigationItems.length - (smallestValue + 1) )
      }
      if (duplicateIndexes && duplicateIndexes.length ==  1) {
        var smallestValue = duplicateIndexes[0];
        duplicateIndexes.forEach((el: any) => {
          if (el < smallestValue) {
            smallestValue = el
          }
        });
        this.allNavigationItems.splice(smallestValue + 1 )
      }
      
      //// console.log("dups", duplicateIndexes)
      //// console.log("all",  this.allNavigationItems)
      //// console.log("smaller",  smallestValue)
      //// console.log("res",  response)


      response.forEach((bread: MenuItemPlus) => {
        let isSame = false;

        if (lastItem) {
          if (lastItem.label === bread.label) {
            isSame = true;
          }
        }

        if (bread?.meta?.navigation?.root) {
          let hasRoot = false;

          for (let an = 0; an < this.allNavigationItems.length; an++) {
            if (this.allNavigationItems[an]?.meta?.navigation.root) {
              hasRoot = true;
              break;
            }
          }

          if (hasRoot === false && isSame === false) {
            this.allNavigationItems.push(bread);
          }
        }

        if (bread?.meta?.navigation?.include && isSame === false) {
          if (
            (!bread?.routerLink || bread?.routerLink === "") &&
            this.currentUrl &&
            this.currentUrl !== ""
          ) {
            bread.routerLink = [this.currentUrl];
          }

          this.allNavigationItems.push(bread);
        }

        const navLength = this.allNavigationItems.length

        if( navLength >= 2 && this.allNavigationItems[navLength- 1].label === this.allNavigationItems[navLength - 2].label)
        {
          this.allNavigationItems.pop();
        }

        this.savingData();
        this.updateSubject();
      });
    } else {
      this.allNavigationItems = response;
      this.updateSubject();
    }
  }

  public buildNavigation(event: any) {
    if (event instanceof RoutesRecognized) {
      // set current url
      this.currentUrl = event.url;
    }
   // console.log(event);

    if (
      event instanceof ActivationStart &&
      event.snapshot &&
      event.snapshot.data &&
      event.snapshot.data.navigation
    ) {
      const navigation = event.snapshot.data.navigation;

      if (navigation?.root) {
        this.allNavigationItems = [];

        this.savingData();
        this.updateSubject();
      }
    }

    if (event instanceof ActivationEnd && event.snapshot && event.snapshot) {
      const navigation = event?.snapshot?.data?.navigation;

      if (navigation?.isAddEdit && navigation?.isAddEdit === true) {
        this.isAddEdit = true;

        this.savingData();
      } else {
        const items = JSON.parse(sessionStorage.getItem("navibar")!) ?? null;

       // console.log(items);

        if (items && items instanceof Array) {
          this.allNavigationItems = items;
        }
      }
    }
  }

  /**
   * Returns Lists of All Navigation Items (Subject) as Observable
   *
   * @returns {Observable<any[]>}
   */
  public getAllNavigationItems() {
    return this.subject.asObservable();
  }

  /**
   * Update Subject
   *
   * Lets component that subcribe to subject know of data changes
   *
   */
  private updateSubject() {
    // pass navigation items to subject for components to receive
    this.subject.next(this.allNavigationItems);

    this.isAddEdit = false;
  }

  private savingData() {
    sessionStorage.setItem("navibar", JSON.stringify(this.allNavigationItems));
  }

  /**
   *  Slices On
   *
   *  - Slice Navigation Item from index, and any from that forward
   */
  public sliceOn(index: any) {
    this.allNavigationItems.splice(index + 1);

    sessionStorage.setItem("navibar", JSON.stringify(this.allNavigationItems));

    this.updateSubject();
  }
  public sliceOn2(index: any, type: any) {
    this.allNavigationItems.splice(index + 1);
console.log(this.allNavigationItems)

    sessionStorage.setItem("navibar", JSON.stringify(this.allNavigationItems));

    this.updateSubject();
  }
  /**
   * Reset Navigation Items
   *
   * Clear and Resets the Navigation Items
   *
   */
  public reset() {
    this.allNavigationItems = [];
    this.currentUrl = "";

    this.updateSubject();
  }
}
