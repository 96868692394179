<div class="scroll-child">
<div class="left-right-space mt-2 mb-2">
    <div class="flex-between">
        <div class="main-list-header" id="title_AssetPm" *ngIf="!nosMain">{{ translate?.preventiveMaintenance.label.singular }}</div>

        <!-- Filter and New -->
        <div class="d-flex ms-auto">
            <ng-container *ngIf="!nosMain"> 
                <button class="btn-list-icon ms-2" id="btn_AssetPmRefresh" [pTooltip]="translate?.general.buttons.refresh.singular" tooltipPosition="bottom" (click)="refresh()">
                    <i class="fa-regular fa-arrows-rotate"></i>
                </button>
                <button class="btn-list-icon ms-2" [ngClass]="{ 'btn-list-icon-active': filterActive }" id="btn_AssetListFilter"  (click)="toggleFilter($event)" [pTooltip]="translate?.general.buttons.filter.singular" tooltipPosition="bottom" *ngIf="!nosMain">
                    <i class="fa-regular fa-filter-list"></i>
                </button>
                <div class="p-inputgroup search">
                    <span class="p-input-icon-right ms-2">
                        <input type="text" class="w-100" pInputText autocomplete="off" id="input_AssetPmSearchField"  [placeholder]="translate?.general.buttons.search.singular" [(ngModel)]="search" (keydown.enter)="onSearch()" (keyup)="onSearchCheck()" />
                        <i *ngIf="searchValueData" class="p-autocomplete-clear-icon pi pi-times" id="btn_AssetPmSearchClear" (click)="onClear()"></i>
                    </span>
                    <button type="button" pButton icon="fa-regular fa-magnifying-glass" id="btn_AssetPmSearch" (click)="onSearch()"></button>      
                </div>
            </ng-container>
            <p-button [label]="translate?.general.buttons.new.singular"  icon="fa-regular fa-plus" (onClick)="new()" id="btn_AssetPmNew" styleClass="ms-2 h-32" *ngIf="create"></p-button>
        </div>
    </div>
</div>

<!-- Title -->
<div *ngIf="nosMain" class="col-12 mt-5 py-4">
    <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
        <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
        <p class="text-6 my-1 font-weight-bold" >{{translate?.general.messages.noRecordsFound}}</p>
    </div>
</div>

<!-- TabMenu -->
<div  class="position-relative fw-normal left-right-space" style="height: 100%;" [hidden]="nosMain">
    <app-scheduled-maintenance-table *ngIf="translate" [translate]="translate" (isFilterClosed)="isFilterClosed($event)" class="app-table" [searchValue]="searchValue" (noDataEvent)="onNoData($event)"></app-scheduled-maintenance-table>
</div>
</div>
