import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { SignUpService } from "src/app/_services/sign-up.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { EngLangData, MalayLangData, chineseLangData, languageOptions } from "src/app/_models/language.data.model";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  submitTouched: boolean = false;
  forgotPassword: any = {};
  forgotPasswordForm!: UntypedFormGroup;
  disableSubmitButton!: boolean;

  
  @ViewChild('chooseLang') chooseLangEl: ElementRef;


   langData: any;
   languageList: any;
  currentLang: string;

  constructor(
    private signUpService: SignUpService,
    private router: Router,
    private errorUtil: ErrorUtil
  ) {
    super();

    this.preInit();
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    var langCode = localStorage.getItem('lang');
    if (langCode == 'my') this.onChangeLanguage('Malay')
    else if (langCode == 'en') this.onChangeLanguage('English')
    else if (langCode == 'zh') this.onChangeLanguage('Chinese')
    else this.onChangeLanguage('English')
    this.languageList = Object.values(languageOptions);
    this.forgotPasswordForm = new UntypedFormGroup({
      username: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(AppComponent.emailPattern),
      ]),
    });
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Submit Forgot Password ******************************************/
  public submitForgotPassword() {
    
    this.disableSubmitButton = true
    this.submitTouched = true;
    if (!this.forgotPasswordForm.valid) {
      for (var i in this.forgotPasswordForm.controls) {
        this.forgotPasswordForm.controls[i].markAsTouched();
      }
      return false;
    }

    var formData = {
      login: this.forgotPassword.username,
    };

    this.push;
    this.signUpService.forgotPassword(formData).subscribe((res: any) => {
      this.submitTouched = false;
      if (res.status === 200 || res.status === 201) {
        this.errorUtil.setErrorMessage(
          200,
          this.langData.setup.users.users.messages.passwordResetEmailSent,
          null,
          "success",
          1000
        );
        this.goToLogin();
      } else {
        var body = JSON.parse(res._body);
        this.disableSubmitButton = false;
        this.errorUtil.setErrorMessage(
          res.status,
          null,
          body.title,
          "error",
          2000
        );
      }
    });
  }

  /******************************************** Go To Landing ******************************************/
  private goToLogin() {
    this.router.navigate(["/login"]);
  }

  onChangeLanguage(landKey: any) {
    console.log(landKey)

    if (landKey == 'English') {
      this.currentLang = languageOptions.english
      this.langData = EngLangData
      this.chooseLangEl?.nativeElement.classList.remove('show');
      localStorage.setItem('lang', 'en')
    }
    if (landKey == 'Malay') {
      this.currentLang = languageOptions.malay
      this.langData = MalayLangData
      this.chooseLangEl?.nativeElement.classList.remove('show');
      localStorage.setItem('lang', 'my')

    }
    if (landKey == 'Chinese') {
      this.currentLang = languageOptions.chinese
      this.langData = chineseLangData
      this.chooseLangEl?.nativeElement.classList.remove('show');
      localStorage.setItem('lang', 'zh')

    }
    console.log(this.langData)
  }

}

