  import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  ColDef,
  GridApi,
  GridOptions,
  GridParams,
  IGetRowsParams,
} from "ag-grid-community";
import { forkJoin } from "rxjs";
import { PermissionService } from "src/app/auth/permission.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import {
  PERSISTANT_ITEM,
  ServerRequest,
} from "src/app/_models/global.data.model";
import { CustomersService } from "src/app/_services/customers.service";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { ProjectService } from "src/app/_services/project.service";
import { SiteService } from "src/app/_services/site.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { ServiceZoneService } from "src/app/_services/service-zone.service";
import { Global } from "src/app/_utilities/global";
import { DynamicTableColumnsService } from "src/app/_services/dynamic-table-column.service";
import * as moment from "moment";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { param } from "jquery";
var translateData: any;
@Component({
  selector: "app-sites-table",
  templateUrl: "./sites-table.component.html",
  styleUrls: ["./sites-table.component.scss"],
})
export class SitesTableComponent
  extends SubscriptionUtil
  implements OnInit, OnChanges, OnDestroy
{
  // table
  public frameworkComponents!: any;
  public gridOptions!: GridOptions;
  public defaultColDef!: ColDef;
  public columnDefs: any[] = [];
  private gridParams!: GridParams;
  private gridApi!: GridApi;
  public overlayNoRowsTemplate =
    '<span style="padding: 10px; font-size: 14px;">Record not found</span>';

  public totalRecords: any;

  public noData: boolean | null = null;
  public isFilterViewToggle = false;

  @Input() searchValue = "";
  public filters = this.returnFilterObject();

  @Input() additionalData: any;
  @Input() translate: any;

  @Output() noDataEvent = new EventEmitter();

  public allSiteTypes: any[] = [];
  public allServiceZones: any[] = [];
  public allSiteCategorys: any[] = [];
  public allGeofences: any[] = [];
  public allProjects: any[] = [];

  ids = {
    customerId: null,
  };

  private filterAPILoaded = false;

  private deleteHasPushed = false;
  loadTable: any = 0;
  sortBy: any;
  sortOrder: any;

  addonFeature: any;
  tableContent: any;
  actionData: any;
  constructor(
    private perm: PermissionService,
    private deleteRecordService: DeleteRecordService,
    private router: Router,
    private util: UtilServiceService,
    private sitesService: SiteService,
    private errorUtil: ErrorUtil,
    private customerService: CustomersService,
    private projectService: ProjectService,
    private serviceZonesService: ServiceZoneService,
    private dynamicTableColumnsService:DynamicTableColumnsService,
    private auth: AuthenticationService,
    
  ) {
    super();
    this.preInit();
   
   }


  ngOnInit(): void {
    this.addonFeature = this.auth.getAddonFeatureLists();
    this.initializeColumns();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes && changes.searchValue &&
      changes.searchValue.currentValue !== changes.searchValue.previousValue
    ) {
      ////debugger;
      this.refresh();
    }

    if (
      changes.additionalData &&
      changes.additionalData.currentValue !==
        changes.additionalData.previousValue
    ) {
      ////debugger;
      this.refresh();
    }
   /************************** Check translate object changed or not:  Language Translated Dynamically (Preeti) ************************************/
   if (
    changes &&
    changes.translate && changes.translate.previousValue != undefined && Object.keys(changes.translate.previousValue).length !== 0 &&
    changes.translate.previousValue !== changes.translate.currentValue
  ) {
    translateData = changes.translate.currentValue
   this.setOnlyColumn();
  }
}
/************************** Set Table Header:  Language Translated Dynamically (Preeti) ************************************/
private setOnlyColumn() {
   // Reset columnDefs to avoid duplication
   this.gridOptions.columnDefs = [];
       console.log(this.tableContent);
          var columnDefs: any = this.gridOptions.columnDefs;
          this.tableContent.tableColumnSetups.forEach((element: any) => {
            this.cellRenderer(element);
             columnDefs.push(element);
            this.gridOptions.api!.setColumnDefs(columnDefs);
          });
          this.actionData.headerName = this.translate?.general.buttons.action.singular;
          columnDefs.push(this.actionData);
          this.gridOptions.api!.setColumnDefs(columnDefs);
          setTimeout(() => { this.gridApi.sizeColumnsToFit(); },500);
}

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.defaultColDef = {
      enableRowGroup: false, //ag-Grid-Enterprise
      enablePivot: false, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };
   
  }

  /******************************************** Init ******************************************/
  initializeColumns(){
    translateData = this.translate;
     // Reset columnDefs to avoid duplication
     this.gridOptions.columnDefs = [];
    this.sitesService.getListColumn("site").subscribe((resp) => {
      if (resp && resp.tableColumnSetups) {
        this.tableContent = resp
        this.sortBy = resp.sortBy
      this.sortOrder = resp.sortOrder

      var columnDefs: any = this.gridOptions.columnDefs;
      resp.tableColumnSetups.forEach((element: any) => {
        this.cellRenderer(element);

        console.log(element)
        columnDefs.push(element);
        this.gridOptions.api!.setColumnDefs(columnDefs);
      });

      console.log(columnDefs)
        this.actionData ={
          headerName: this.translate?.general.buttons.action.singular,
          filter: false,
          sortable: false,
          width: 150,
          headerTooltip: "#",
          headerClass: "marging-auto hide-action-border",
          cellClass: "text-center",
          cellRenderer: "buttonRenderer",
          cellRendererParams: {
            onClick: this.edit.bind(this),
            permissions: {
              update: this.perm.capable("site", "update"),
              remove: this.perm.capable("site", "remove"),
            },
            hidden: {
              update: !this.perm.capable("site", "update"),
              remove: !this.perm.capable("site", "remove"),
            },
          },
        }
        columnDefs.push(this.actionData);
              
        this.loadTable = 1;
        this.gridOptions.api!.setColumnDefs(columnDefs);
        this.onGridReady(this.gridParams);
      }
    });
  }

  /************************************************************************************/
  getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }

  /******************************************** Configure Columns ******************************************/
  private cellRenderer(element: any) {
    console.log("trrrrrrrr", translateData)
    var dateFormat: any = localStorage.getItem("date_format");
    element.headerTooltip = "#";

      if(element.translatePath){
        element.headerName = this.getNestedValue(this.translate, element.translatePath);
       } 
      var dateFormateChange: any = localStorage.getItem("date_format");
      var authUserTimezone: any = this.auth.getUserTimezone();
      
      if (element.field === "siteNumber") {
        element.headerComponentParams =  Global.setHeaderComponentParams(element,'Tbl_HeadSitTaskNo'),
        element.cellRenderer = function (params: any) {
          if(params.data){
            return  Global.setCellRenderer(params,params.data.rowId, params.data.siteNumber)
          }
        };
        
      }else  if (element.field === "customer.name") {
        element.headerComponentParams =  Global.setHeaderComponentParams(element,'Tbl_HeadSiteCust'),
        element.cellRenderer = function (params: any) {
          if(params.data){
            return  Global.setCellRenderer(params,params.data.rowId2, params.data.customer?.name)
          }
        };
        
       
        if (this.additionalData?.data?.from === "customer") {
          element.hide = true;
        }
      }else if (element.field === "active") {
        console.log("1 Log",element.field)
        element.cellRenderer = function (params: any) {
          console.log("1 Log2",params)
          if (params.data) {
            console.log("2 Log",params.data,"======" )
            if (params.data.active) {
              return '<span class="p-mr-2  p-tag p-component p-tag-success" ><span class="p-tag-value">'+translateData?.general.status.active.singular+ '</span></span>';
            } else {
              return '<span class="p-mr-2  p-tag p-component p-tag-danger" ><span class="p-tag-value text-dark">'+translateData?.general.status.inactive.singular+'</span></span>';
            }
          }
        };
      }else if (element.dataType == 'date'  || element.dataType == "text" || element.dataType == "datetime" || element.dataType == "boolean") {
        element = Global.setColumnByDataType(element,this.auth.getUserTimezone(),dateFormat)
       }
      // if (tc.field === "site_status.name") {
      //   column.hide = true;
      // }

      // if (tc.field === "siteStatus.name") {
      //   column.hide = true;
      // }

      
  }

  /******************************************** Actions ******************************************/
  public edit(e: any) {
    if (e.action === "edit") {
      this.util.setPersistantItem(PERSISTANT_ITEM.SITES, e.rowData);

      this.router.navigate(["/dashboard/sites/update/" + e.rowData.id], {
        queryParams: { id: e.rowData.id },
      });
    }

    if (e.action === "delete") {
      const item = {
        deleteHeader : this.translate.site.label.singular ,
        deleteType: "Site",
        id: e.rowData.id,
        message:  this.translate?.general.messages.confirmDelete+" "+ `${e.rowData.siteNumber} - ${e.rowData.siteName}?`,
      };

      if (!this.deleteHasPushed) {
        this.deleteRecordService.getItems(item).subscribe((resp) => {
          // refresh the table

          if (resp.status === 200) {
            ////debugger;
            this.refresh();
          }
        });
        this.deleteHasPushed = true;
      } else {
        this.deleteRecordService.getItems(item);
      }
    }
  }

  /******************************************** On Table Ready ******************************************/
  public onGridReady(params: any) {
    if (params != undefined) {
      this.gridParams = params;

      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();

      var dataSource = {
        getRows: (params: IGetRowsParams) => {
          if (this.loadTable == 1 && this.translate ) {
            this.getServerSideData(params);
            }
        },
      };

      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  /******************************************** Get Data ******************************************/
  private getServerSideData(agGridGetRowsParams: IGetRowsParams) {
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    var count: any=serverRequest.startRow;
    if (this.additionalData && this.additionalData.customer) {
      this.ids.customerId = this.additionalData.customer.id;
    }

    let from = null;

    if (
      this.additionalData &&
      this.additionalData.data &&
      this.additionalData.data.from === "project"
    ) {
      this.filters.project = Number(this.additionalData.data.id);
    }

    if (
      this.additionalData &&
      this.additionalData.data &&
      this.additionalData.data.from
    ) {
      from = this.additionalData.data.from;
    }

    if (
      this.filters.category_type ||
      this.filters.geofence ||
      this.filters.service_zone ||
      this.filters.type
    ) {
      from = null;
    }

    if (this.searchValue && this.searchValue.length > 0) {
      from = null;
    } else {
      from = this.additionalData?.data?.from;
    }

    this.sitesService
      .getAllSites(
        serverRequest,
        this.searchValue,
        this.ids.customerId,
        this.filters.project,
        this.filters.type,
        this.filters.service_zone,
        this.filters.category_type,
        this.filters.geofence,
        from
      )
      .subscribe(
        (resp) => {
          if (resp) {
            resp.data.forEach((element: any) => {
              count++
              element.rowId = 'Tbl_RecSitTaskNo' + count
              element.rowId2 = 'Tbl_RecSiteTaskCust' + count
              element.buttonEdit = 'Btn_SitEdit' + count
              element.buttonDelete = 'Btn_SitDel' + count
            });
            this.setGridData(
              agGridGetRowsParams,
              resp.data,
              resp.total,
              resp.status
            );
          }
        },
        (err) => {
          this.errorUtil.setErrorMessage(err);
        }
      );
  }

  /******************************************** Get Params ******************************************/
  private getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      const parameters =  { colId: this.sortBy, sort: this.sortOrder };
      agGridRequest.sortModel.push(parameters);
    }

    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }

  /******************************************** Set Data ******************************************/
  private setGridData(
    agGridGetRowsParams: IGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    this.gridApi.hideOverlay();
    console.log("1",agGridGetRowsParams)
    console.log("2",resultItems)
    console.log("3",totalCount)
    console.log("4",status)
    this.totalRecords = totalCount;

      if (resultItems.length === 0) {
        this.gridApi.showNoRowsOverlay();
        this.noData = true;
       // this.isFilterViewToggle = false;
      } else {
        this.noData = false
        agGridGetRowsParams.successCallback(resultItems, totalCount);
      }

     ///CS-4758  this.gridApi.sizeColumnsToFit();
    

    this.noDataEvent.emit(this.noData);
  }

  /******************************************** Refresh Table ******************************************/
  public refresh() {
    ////debugger;
    this.onGridReady(this.gridParams);
  }

  /******************************************** Toggle Filter ******************************************/
  public toggleFilter() {
    console.log(this.isFilterViewToggle)
    this.isFilterViewToggle = !this.isFilterViewToggle;

    if (this.filterAPILoaded === false) {
      const sources = [
        this.sitesService.getAllSiteTypes(true, true),
        this.serviceZonesService.getAllServiceZones(),
        this.sitesService.getAllSiteCategories(true, true),
        this.customerService.getGeofencePickList(),
        this.projectService.getAllProjectPickList(null),
      ];

      this.push(
        forkJoin(sources).subscribe(
          ([
            allSiteTypes,
            allServiceZones,
            allSiteCategories,
            allGeofences,
            allProjects,
          ]) => {
            this.allSiteTypes = allSiteTypes.sort((elementA: any, elementB: any) => {
              if (elementA?.order > elementB?.order) {
                return 1;
              }
    
              if (elementA?.order < elementB?.order) {
                return -1;
              }
    
              return 0;
            });
    
            this.allServiceZones = allServiceZones.filter((s: any) => s.active).sort((elementA: any, elementB: any) => {
              if (elementA?.order > elementB?.order) {
                return 1;
              }
    
              if (elementA?.order < elementB?.order) {
                return -1;
              }
    
              return 0;
            });
    
            this.allSiteCategorys = allSiteCategories.sort((elementA: any, elementB: any) => {
              if (elementA?.order > elementB?.order) {
                return 1;
              }
    
              if (elementA?.order < elementB?.order) {
                return -1;
              }
    
              return 0;
            });
    
            this.allGeofences = allGeofences;
            this.allProjects = allProjects;
          }
        )
      );

      this.filterAPILoaded = true;
    }
  }

  /******************************************** Clear All ******************************************/
  public clearAll() {
    this.filters = this.returnFilterObject();

    this.refresh();
  }

  /******************************************** View Cell ******************************************/
  public viewCell(e: any) {
    this.util.resetTabView();
   
    if (e.colDef.field === "siteNumber") {
      this.router.navigate(["/dashboard/sites/view/" + e.data.id], {
        queryParams: { id: e.data.id },
      });
    }else{

    // if (e.colDef.field === "customer.name") {
    //   this.router.navigate(
    //     ["/dashboard/customers/view/" + e.data.customer.id],
    //     {
    //       queryParams: { id: e.data.customer.id },
    //     }
    //   );
    // }
    this.dynamicTableColumnsService.redirectURL(e);
    }

  }

  /******************************************** Table Context Menu ******************************************/
  public getContextMenuItems = (params: any) => {
    params.node.data.field = params.column.colId;

    return this.dynamicTableColumnsService.rightClick(params);
  };

  private redirectToNewTab(data: any) {
    let url;
    console.log(data)
    if (data.field === "siteNumber") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(["/dashboard/sites/view/" + data.id],{queryParams: { id: data.id } } )
      );
    }
    if (data.field === "customer.name") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(["dashboard/customers/view/" + data.customer.id], {
          queryParams: { id: data.customer.id },
        })
      );
    }

    if (url) {
      url = url.replace("%23", "#");
      window.open(url, "_blank", "noreferrer");
    }
  }

  /******************************************** Returns Filter Object ******************************************/
  private returnFilterObject() {
    return {
      type: null,
      service_zone: null,
      category_type: null,
      geofence: null,
      project: <number | null>null,
    };
  }
  /******************************************** Add ALl the Subscription ******************************************/
  push(obs: any) {
    super.push(obs);
  }

 
}
