<div class="custella-modal">
    <form [formGroup]="addNotesForm">
        <div class="custella-modal-header align-items-center">
            <div class="custella-modal-header-text" id="title_TaskEditNote" > {{translate?.task.sections.sec2.singular}}</div>
            <i class="pi pi-times" id="btn_TaskEditNoteClose"  [pTooltip]="translate?.general.buttons.close.singular" tooltipPosition="left"  (click)="modalRef.hide()"></i>
        </div>
        <div class="custella-form-container position-relative d-block w-100 rounded-2 m-0 pt-3" style="height: fit-content;">
            <!-- Container Content -->
            <div class="custella-form-container-content m-0">
                <div class="custella-content-row mb-3 pe-0">
                    <div class="custella-content-row-input col-12 me-0">
                        <div class="flex-between"  *ngIf="mode !== 'addUserNote' && mode !== 'editUserNote'">
                            <span class="custella-content-row-input-title mandatory" id="title_TaskNotes" >{{ translate?.task.fields.note.singular}} </span>
                            <i class="fa-regular fa-paperclip" (click)="openFileUploader()" id="btn_TaskFileUpload"></i>
                           <input [hidden]="true" id="fileUploader2" [placeholder]="translate?.task.messages.typeYourNoteHere" type="file" (change)="uploadFile($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="file"/>
                        </div>
                        <textarea pInputTextarea autocomplete="off" id="input_TaskNotesField" name="description" formControlName="notes" [(ngModel)]="addTask.notes" name="notes" [maxlength]="320000"></textarea>
                    </div>
                </div>

                 <!------------------------ Attchment Preview -------------------------------------->
            <div class="custella-content-row mb-3" *ngIf="attachmentsArrayModal.length > 0">
                <div class="custella-content-row-input col-12">
                    <div class="preview col-12">
                        <div class="d-flex flex-wrap mb-2 pe-3">
                            <!-- IMAGE ATTACHMENT -->
                            <div class="me-1 mt-2" *ngFor="let attachment of attachmentsArrayModal">
                                <div class="d-flex">
                                    <img id="img_TaskAttachmentImage" *ngIf="attachment.contentContentType.indexOf('image') >= 0" [src]="attachment.attachmentUrl" alt="Attachment Preview" class="w-72px preview-attachments">
                                    <img id="img_TaskAttachmentApp" *ngIf="attachment.contentContentType.indexOf('application') >= 0 || attachment.contentContentType.indexOf('text') >= 0" src="assets\svg\fileTypes\pdf.svg" alt="Attachment Preview" class="w-72px preview-attachments">
                                    <img id="img_TaskAttachmentVid" *ngIf="attachment.contentContentType.indexOf('video') >= 0" src="assets\svg\fileTypes\mov.svg" alt="Attachment Preview" class="w-72px preview-attachments">
                                    <i class="pi pi-times preview-close" id="btn_TaskAttachmentDelete" (click)="deleteAttachment(attachment)"></i>
                                </div>
                            </div>
                           <!-- VENDID ATTACHMENT -->
                            
                        </div>
                    </div>
                </div>
            </div>
            <!------------------------ End Attchment Preview -------------------------------------->

            </div>
             <!-- Container Footer -->
             <div class="custella-form-container-footer mb-0">
                <div class="d-flex align-items-center justify-content-end">
                    <button class="custella-btn-white text-12 ms-2" id="btn_TaskNotesCancel" (click)="modalRef.hide()">{{translate?.general.buttons.cancel.singular}}</button>
                    <p-button [disabled]="submitTouched" (onClick)="onSubmit()" id="btn_TaskNotesAdd" [label]="translate?.general.buttons.add.singular" *ngIf="mode === 'add' || mode === 'addUserNote'"></p-button>
                    <p-button [disabled]="submitTouched" (onClick)="onSubmit()" id="btn_TaskNotesSubmit" [label]="translate?.general.buttons.save.singular" *ngIf="mode === 'editUserNote' || mode === 'edit'"></p-button>
                </div>
            </div>
        </div>
    </form>
</div>