<div class="left-right-space h-100-70 my-3">
    <!-- Title -->
    <div class="flex-between mt-1 ms-2 mb-2">
        <!-- Filter and New -->
        <div class="custella-dashboard-top-right d-flex ms-auto">
            <button type="button" *ngIf="!noData" class="border rounded-1 px-04 ms-2 btn-32" id="btn_ChubbTechRefresh" (click)="refresh()">
                <img alt="logo" src="assets\svg\refresh.svg" />
            </button>

            <button type="button" *ngIf="!noData" (click)="filterToggle()" class="border rounded-1 px-06 ms-2 btn-32" id="btn_ChubbTechFilter" style="color: #435861;">
                <i class="pi pi-filter pt-04"></i>
            </button>

            <span *ngIf="!noData" class="p-input-icon-left w-100 ms-2">
                <i class="pi pi-search me-3"></i>
                <input type="text" class="mx-1" pInputText autocomplete="off"  id="input_ChubbTechSearchField" [placeholder]="translate?.general.buttons.search.singular" #searchTableInput />
            </span>
            
        </div>
    </div>
    <div *ngIf="noData" class="col-12 mt-5 py-4">
        <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
            <img src="assets\svg\empty-state.svg" id="img_NoRecordsFound" />
             <p class="text-6 my-1 font-weight-bold">{{translate?.general.messages.noRecordsFound}}</p>
        </div>
    </div>

    <!-- TabMenu -->
    <div [hidden]="noData" class="position-relative fw-normal d-flex" style="height: 450px;">
        <div [hidden]="noData" class="table-space ag-grid-table-full mb-5" [ngClass]="{ ' ag-grid-table': isFilterToggleOn }">
            <ag-grid-angular
                #agGrid
                style="height: 100%;"
                class="ag-theme-balham"
                [pagination]="false"
                [cacheOverflowSize]="2"
                [suppressDragLeaveHidesColumns]="true"
                [maxConcurrentDatasourceRequests]="-1"
                [allowContextMenuWithControlKey]="false"
                [gridOptions]="techTable.table.gridOptions"
                [defaultColDef]="techTable.table.defaultColDef"
                [sideBar]="techTable.table.sidebar"
                (cellClicked)="onClickCell($event)"
                (gridReady)="onGridReady($event)"
                (gridSizeChanged)="onGridSizeChange($event)"
                [getContextMenuItems]="getContextMenuItems"
                [overlayNoRowsTemplate]="techTable.table.overlayNoRowsTemplate"
            ></ag-grid-angular>

            <div class="col-12">
                <p class="ag-record-row-summary-panel padds text-end" id="label_ChubbTechTableRec">{{totalRecord}}&nbsp;{{translate?.general.messages.records}}</p>
            </div>
        </div>

        <div class="custella-form-container-content-pane layout-filter" style="height: 407px;" *ngIf="isFilterToggleOn" [ngClass]="{ 'layout-filter-active': isFilterToggleOn }">
            <div class="custella-form-container-content-pane-title">
                <span class="text-14 font-medium" id="label_ChubbTechFilterBy">{{translate?.dashboard.chubb.fields.timeFrame.singular}}</span>
                <i class="pi pi-arrow-right" id="btn_ChubbTechFilterClose" (click)="filterToggle()"></i>
            </div>
            <div class="custella-content">
                <div class="custella-content-input col-12">
                    <span class="custella-content-input-title" id="label_ChubbTechTimeframe">{{translate?.dashboard.chubb.fields.timeframe.singular}}</span>

                    <p-dropdown
                        [placeholder]="translate?.general.messages.none"
                        [(ngModel)]="techTable.selectedTimeframe"
                        [options]="techTable.timeFrames"
                        optionValue="name"
                        optionLabel="label"
                        (onChange)="onSelectChange($event, 'durationChange')"
                        [filter]="true"
                        id="picklist_ChubbTechTimeframeField"
                    ></p-dropdown>
                </div>

                <div class="custella-content-input col-12">
                    <span class="custella-content-input-title" id="label_ChubbTechTeam">{{ translate?.team.label.singular }}</span>

                    <p-dropdown
                        [placeholder]="translate?.general.messages.none"
                        [(ngModel)]="techTable.selectedTeam"
                        [options]="techTable.teams"
                        optionLabel="teamName"
                        optionValue="id"
                        (onChange)="onSelectChange($event, 'teamChange')"
                        [filter]="true"
                        id="picklist_ChubbTechTeamField"
                    ></p-dropdown>
                </div>

                <div class="custella-content-input col-12">
                    <span class="custella-content-input-title" id="label_ChubbTechTechnician">{{ translate?.dashboard.chubb.fields.technician.singular }}</span>

                    <p-dropdown
                        [placeholder]="translate?.general.messages.none"
                        [(ngModel)]="techTable.selectedTechnician"
                        [options]="techTable.technicians"
                        optionLabel="userFirstName"
                        optionValue="userId"
                        (onChange)="onSelectChange($event, 'technicianChange')"
                        [filter]="true"
                        id="picklist_ChubbTechTechnicianField"
                    >
                    <ng-template pTemplate="selectedItem">
                        <div *ngIf="techTable.selectedTechnician" id="value_ChubbTechTechnicianField">
                          {{
                            returnValue("user", techTable.selectedTechnician)
                          }}
                        </div>
                      </ng-template>

                    <ng-template let-user pTemplate="item" id="item_ChubbTechTechnicianField">
                        {{ user.userFirstName }} {{ user.userLastName }}
                      </ng-template>
                </p-dropdown>
                </div>

                <div>
                    <a href="#" class="clear" id="subtitle_ChubbTechFilterClear" (click)="resetSearch()">{{ translate?.general.buttons.clearAll.singular }}</a>
                </div>
            </div>
        </div>
    </div>
</div>
