import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
declare var google: any;
@Component({
  selector: 'app-modal-view-map',
  templateUrl: './modal-view-map.component.html',
  styleUrls: ['./modal-view-map.component.scss']
})
export class ModalViewMapComponent implements OnInit, OnChanges {

  @Input() data: any;
  @Input() visible: any;
  @Output() setAddress = new EventEmitter();
  @Output() visibleOut = new EventEmitter();

  @ViewChild("gmap", { static: false })
  gmap!: ElementRef;
  private map: google.maps.Map | any; // Declare a map variable

  public options: any;

  overlays!: any[];
  translate: any;


  constructor(        private languageTranslateService: LanguageTranslateService) { 
    this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data}
    })
  }
  ngOnChanges(changes: SimpleChanges): void {
    const lat = this.data.latitude ?? 0;
    const lng = this.data.longitude ?? 0;


    this.options = {
      center: { lat: lat, lng: lng },
      zoom: 12,
      draggable: true,
     
    };

    // this.overlays = [
    //   new google.maps.Marker({
    //     position: { lat: lat, lng: lng },
    //     title: "Location",
    //   }),
      
    // ];
    console.log(lat," ", lng)
    this.overlays = [
      new google.maps.Marker({
        position: { lat: lat, lng: lng },
        title: "Location",
        draggable: true,
      }) ?? null,
    ];
    if((this.gmap as any) &&(this.gmap as any).map && lat > 0 && lng > 0 ){
      (this.gmap as any).map.panTo({ lat: lat, lng: lng });
    }
   
    console.log(this.overlays,"dddd ")
  }

  ngOnInit() {
    this.init();
  }

  /******************************************** Init ******************************************/
  private init() {
    // const lat = this.data.latitude;
    // const lng = this.data.longitude;

    // this.options = {
    //   center: { lat: lat, lng: lng },
    //   zoom: 12,
    //   draggable: true,
    // };

    // this.overlays = [
    //   new google.maps.Marker({
    //     position: { lat: lat, lng: lng },
    //     title: "Location",
    //     draggable: true,
    //   }),
    // ];
  }


  /**************************************** On Click Billing Map and Drag map ************************************/
  public markerDragEnd(event: any) {
    // console.log(event, "====", event.overlay.getPosition());
    var selectedPosition: any = event.overlay.getPosition();
    this.setMapData(selectedPosition.lat(), selectedPosition.lng());
    console.log(selectedPosition)
    this.setAddress.emit(selectedPosition)
  }

  /******************************************** On Billing Map Click ******************************************/
  public mapClicked($event: any) {
    // console.log($event, "====", $event.latLng.lng());
    console.log($event.latLng)
    this.setMapData($event.latLng.lat(), $event.latLng.lng());
    this.setAddress.emit($event.latLng)
  }

    /******************************************** Set Billing ******************************************/
    private setMapData(latitude: any, longitude: any) {
      this.overlays = [
        new google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          draggable: true,
        }),
      ];
      const markerShipping = new google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        draggable: true,
      });
      (this.gmap as any).map.panTo(markerShipping.position);
      var zooming = (this.gmap as any).getMap();
      zooming.setZoom(16);
    }


  onAfterHide() {
    this.visibleOut.emit(false);
    this.visible = false;
  }
  


}
