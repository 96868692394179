import { Component, OnDestroy, OnInit } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-access-button-renderer",
  templateUrl: "./access-button-renderer.component.html",
  styleUrls: ["./access-button-renderer.component.scss"],
})
export class AccessButtonRendererComponent extends SubscriptionUtil implements ICellRendererAngularComp, OnDestroy {
  params!: ICellRendererParams;
  public permissions: any;

  public showButton = true;

  private notAllowed = [0, 1, 2];
  translate: any;

  constructor(
    private languageTranslateService: LanguageTranslateService) {
      super()
    }

  /******************************************** Init ******************************************/
  agInit(params: ICellRendererParams<any, any>): void {
    // console.log("INIT");
    // console.log(this.permissions);
    this.params = params;
    // console.log(this.params);
    this.permissions = (this.params as any).permissions;

    const gotIndex = this.notAllowed.some((s) => {
      return this.params.rowIndex === s;
    });

    if ((this.params && this.params.rowIndex, gotIndex)) {
      this.showButton = false;
    }
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
  }

  ngOnInit(): void {
    this.getLanguageData()
  }

  /******************************************** Call to Top Level Col Defs that implement onClick ******************************************/
  public onClick($event: any, action: string) {
    const params = {
      event: $event,
      rowData: this.params.node.data,
      action: action,
      // ...something
    };

    if (this.params && (this.params as any).onClick instanceof Function) {
      (this.params as any).onClick(params);
    }
  }

  getLanguageData() {

    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
        if(Object.keys(data).length != 0){
          this.translate = data
       
        }
      }))

    //this.mainLabel = this.translate?.site.label.plural
  }
  push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
