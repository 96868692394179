import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ChubbDashboardAppEvents } from "src/app/_models/global.data.model";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";

@Component({
  selector: "app-chubb-dashboard",
  templateUrl: "./chubb-dashboard.component.html",
  styleUrls: ["./chubb-dashboard.component.scss"],
})
export class ChubbDashboardComponent implements OnInit {
  translate: any;
  constructor(
    private router: Router,
    private utilService: UtilServiceService,
    private languageTranslateService: LanguageTranslateService
  ) {
      this.languageTranslateService.menuSrc$.subscribe(data => {
      if(Object.keys(data).length != 0){ this.translate = data}
    })
  }

  ngOnInit() {}

  /******************************************** Notify To Refresh Data ******************************************/
  public refreshData(whichPanel: string) {
    if (whichPanel === "panel-1") {
      this.utilService.sendData({
        action: ChubbDashboardAppEvents.REFRESH_PANEL_ONE,
        data: null,
      });
    }

    if (whichPanel === "panel-2") {
      this.utilService.sendData({
        action: ChubbDashboardAppEvents.REFRESH_PANEL_TWO,
        data: null,
      });
    }

    if (whichPanel === "panel-3") {
      this.utilService.sendData({
        action: ChubbDashboardAppEvents.REFRESH_PANEL_THREE,
        data: null,
      });
    }
  }

  /******************************************** Navigate To Slideshow ******************************************/
  public navigateTo(where: string) {
    if (where === "slideshow") {
      this.router.navigate(["/dashboard/chubb/slideshow"]);
    }
  }

  /******************************************** Notify Component to Download Technician Data ******************************************/
  public download() {
    this.utilService.sendData({
      action: ChubbDashboardAppEvents.DOWNLOAD_TECHNICIAN_DATA,
      data: null,
    });
  }
}
