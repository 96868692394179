<div class="d-flex button-box-top-right"> 
    <p-button  *ngIf="!configure" [label]="translate?.general.buttons.edit.singular" id="btn_SetNotifyModuleEdit" styleClass="h-28" (onClick)="editConfigure()"></p-button>  
    <div *ngIf="configure">
        <button pButton class="p-button-secondary h-28" [label]="translate?.general.buttons.cancel.singular " id="btn_SetNotifyModuleCancel" (click)="editConfigure()"></button>
        <p-button  [label]="translate?.general.buttons.save.singular" styleClass="ms-2 h-28" (onClick)="onSubmit()" id="btn_SetNotifyModuleSubmit"></p-button>
    </div>
</div>

<div class="left-right-space mt-2">
    <div class="mb-2">
          <span class="text-20 font-semiBold" id="title_SetNotifyModule" >{{translate?.setup.notifications.sections.sec5.singular}}</span>
    </div>  
    <div class="lv-desc">
        <span class="text">{{translate?.setup.notifications.messages.notificationsAreAlerts}}</span>
    </div>
</div>
<div class="custella-form-container left-right-space mb-4" *ngFor="let notifData of notificationListData | orderBy: 'name'">
    
    <div class="custella-form-container-title flex-between rounded-top">
        <div class="font-medium text-14" id="title_SetNotifyStandard">{{notifData?.newModuleName}}&nbsp;{{translate?.setup.notifications.label.plural}}</div>
    
    </div>
   
    <div class="custella-form-container-content px-0 m-0">
        <p-table [value]="notifData.detail" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
            <ng-template pTemplate="header">
                <tr class="">
                    <th class="col-8 ps-4" id="label_SetNotifyStandNotification">{{translate?.setup.notifications.label.singular}}</th>
                    <th class="col-1 text-nowrap text-center" id="label_SetNotifyStandInApp">{{translate?.setup.notifications.fields.inApp.singular}}</th>
                    <th class="col-1 text-center" id="label_SetNotifyStandEmail">{{translate?.setup.notifications.fields.email.singular}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-notificationsData>
                <tr class="text-12">
                    <td class="col-8 ps-4">
                        <span class="text-12 font-medium" id="value_SetNotifyName">{{notificationsData?.name}}&nbsp;</span>
                        <i *ngIf="!notificationsData.active" class="fa-solid fa-triangle-exclamation font-orange" id="tooltip_SetNotifyDisabled" [pTooltip]="translate?.setup.notifications.messages.notificationsDisabled"></i>
                        <span class="d-block text-12 color-var-500" id="desc_SetNotifyName" >{{notificationsData?.description}}</span>
                    </td>
                    
                    <td class="col-1 text-center">
                        <i id="icon_SetNotifyConfigureEnable" [ngClass]="{'fade-opacity-05':!notificationsData.active}" class="pi pi-check font-green pe-none" *ngIf="!configure && notificationsData?.inApp  == true"></i>
                        <i id="icon_SetNotifyConfigureDisable" [ngClass]="{'fade-opacity-05':!notificationsData.active}" class="pi pi-times font-red pe-none" *ngIf="!configure && notificationsData?.inApp  == false"></i>
                        <span class="text-12" id="label_SetNotifyConfigureNotAvail" *ngIf="notificationsData?.inApp == null">{{translate?.general.messages.notAvailable}}</span>

                        <div class="p-field-checkbox" *ngIf="configure && notificationsData?.inApp != null">
                            <p-checkbox id="check_SetNotifyConfigureEnable" [binary]="true" [(ngModel)]="notificationsData.inApp" [disabled]="!notificationsData?.active"></p-checkbox>
                        </div>
                    </td>
                    <td class="col-1 text-center">
                        <i id="icon_SetNotifyConfigureEnable" class="pi pi-check font-green pe-none" *ngIf="!configure && notificationsData?.email  == true"></i>
                        <i id="icon_SetNotifyConfigureDisable" [ngClass]="{'fade-opacity-05':!notificationsData.active}" class="pi pi-times font-red pe-none" *ngIf="!configure && notificationsData?.email  == false"></i>
                        <span class="text-12"  id="label_SetNotifyConfigureNotAvail" *ngIf="notificationsData?.email == null">{{translate?.general.messages.notAvailable}}</span>

                        <div class="p-field-checkbox" *ngIf="configure && notificationsData?.email != null">
                            <p-checkbox id="check_SetNotifyConfigureEnable" [binary]="true" [(ngModel)]="notificationsData.email" [disabled]="!notificationsData?.active"></p-checkbox>
                        </div>
                    </td>
                  
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>



<!--=======-=======-=======-=======-=======-=======CONFIRM DIALOG-=======-=======-=======-=======-=======-=======-=======-->
<!-- <div class="custella-custom-dialog">
    <p-dialog  showEffect="fade" [modal]="true" [draggable]="false" [(visible)]="display">
        <ng-template pTemplate="header">
            <div class="d-flex align-items-center">
                <img id="img_DialogConfirmation" src="assets\svg\dialog\Confirmation-w.svg" />
                <div id="title_DialogConfirmation" class="text-16 font-medium ms-3">{{'confirm_notification_update'}}</div>
            </div>
        </ng-template>
        <div class="fst-italic text-12">
            <span>{{'changes_updated_modify'}}</span>
        </div>
        <p-footer>
            <div class="d-flex align-items-center justify-content-end">
                <button class="custella-btn-white text-12 ms-2" (click)="cancel()">{{'general.buttons.cancel.singular'}}</button>
                <p-button type="submit" [label]="'apply_all_users'" styleClass="dark" id="btn_ApproveAllUser"  ></p-button>
                <p-button type="submit" [label]="'update_new_users'" id="btn_ApproveNewUser"  (click)="newUserOnly()"></p-button>
            </div>
        </p-footer>
    </p-dialog>
</div> -->