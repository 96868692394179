<div class="left-right-space h-100">
    <div *ngIf="noSlaStep" class="col-12 mt-5 py-4">
        <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
            <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
            <p class="text-6 my-1 font-weight-bold" >{{translate?.general.messages.noRecordsFound}}</p>
        </div>
    </div>
  
    <div class="table-space ag-grid-table-full" style="height: 73vh;" [hidden]="noSlaStep" [style]="loadedDymanically ? 'height: calc(100vh - 17rem);' : 'height: calc(100% - 47px)'">
        <ag-grid-angular
            style="height: 100%;"
            class="ag-theme-balham"
            [maxConcurrentDatasourceRequests]="-1"
            [suppressDragLeaveHidesColumns]="true"
            [gridOptions]="gridOptionsSlaStep"
            [columnDefs]="columnDefsSlaStep"
            [defaultColDef]="defaultColDefSlaStep"
            (cellClicked)="viewSlaStep($event)"
            [frameworkComponents]="frameworkComponents"
            (gridReady)="onGridReadySlaStep($event)"
          [getContextMenuItems]="getContextMenuItems"
  
        ></ag-grid-angular>
        <div class="col-12">
            <p class="ag-record-row-summary-panel padds text-end" id="label_TixSlaTableRec">
                {{ totalRecord }}&nbsp;{{translate?.general.messages.records}}
            </p>
        </div>
    </div>
  </div>