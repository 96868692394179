import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TabView } from "primeng/tabview";
import { PermissionService } from "src/app/auth/permission.service";
import { PERSISTANT_ITEM } from "src/app/_models/global.data.model";
import { AssetService } from "src/app/_services/asset.service";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { TranslateService } from "@ngx-translate/core";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

@Component({
  selector: "app-view-scheduled-maintenance",
  templateUrl: "./view-scheduled-maintenance.component.html",
  styleUrls: ["./view-scheduled-maintenance.component.scss"],
})
export class ViewScheduledMaintenanceComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  public toggleEditVisibility = false;
  public update: any;
  public remove: any;

  private id: any;

  public scheduledMaintenance: any;

  public items: any[] = [];

  activeIndex: any;

  public toggleSection = {
    details: false,
  };

  @ViewChild(TabView, { static: false }) tabView!: TabView;
  addonFeature: any;
  mainLabel :any;
  translate: any;

  constructor(
    private breadCrumbs: BreadcrumbService,
    private route: ActivatedRoute,
    private perm: PermissionService,
    private assetService: AssetService,
    private utils: UtilServiceService,
    private deleteRecordService: DeleteRecordService,
    private router: Router,
    private auth: AuthenticationService,
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** init ******************************************/
  private init() {
    // this.activeIndex =
    //   localStorage.getItem("activeIndex") == undefined
    //     ? 0
    //     : Number(localStorage.getItem("activeIndex"));

    // setTimeout(() => {
    //   this.loadData(this.activeIndex, this.tabView);
    // }, 500);
    this.id = this.route.snapshot.queryParamMap.get("id");
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        console.log("translate",this.translate?.asset.label.plural)
        this.getData();
        this.items = [
          {
            id: "btn_AssetPmDelete",
            label: this.translate?.general.buttons.delete.singular,
            command: (e: any) => {
              this.menuAction("delete");
            },
            visible: this.remove,
          },
        ];
      }
    }))

    //this.id = this.route.snapshot.queryParamMap.get("id");

    this.update = this.perm.capable("scheduled maintenance", "update");
    this.remove = this.perm.capable("scheduled maintenance", "remove");

    


  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    this.breadCrumbs.setItems([]);
    super.ngOnDestroy();
  }

  /******************************************** get data ******************************************/
  private getData() {
    this.push(
      this.assetService
        .getViewScheduledMaintenance(this.id)
        .subscribe((res: any) => {
          if (res.status === 200 || res.status === 201) {
            this.scheduledMaintenance = res.body;
            this.addonFeature = this.auth.getAddonFeatureLists();
            // if(this.addonFeature.addonMS == 'MACHINE_SPECIALIST'){
            //   this.mainLabel = 'Machine'
            // }
           
    
            this.breadCrumbs.setItems([
              // { label: this.translate?.asset.label.plural, routerLink: ["/dashboard/assets"], meta: {navigation: {root: true}} },
              {
                label: this.translate?.preventiveMaintenance.label.plural,
                routerLink: ["/dashboard/preventative-maintenance"], meta: {navigation: {root: true}}
              },
              { label: this.scheduledMaintenance.scheduleNumber, meta: {navigation: {include: true}} }, 
            ]);
            
    

          }
        })
    );
  }

  /******************************************** pre set tab ******************************************/
  private loadData(e: any, tabView: TabView) {
    if (this.tabView.tabs[e]) {
      const headerValue = this.tabView.tabs[e].header;
      this.setTabHeader(headerValue);
    } else {
      const headerValue = this.tabView.tabs[0].header;
      this.setTabHeader(headerValue);
    }
  }

  /******************************************** handle on tab change ******************************************/
  public handleChange(e: any, tabView: TabView) {
    localStorage.setItem("activeIndex", e.index);
    this.activeIndex = e.index;
    const headerValue = tabView.tabs[e.index].header;

    this.setTabHeader(headerValue);
  }

  /******************************************** display tab content ******************************************/
  private setTabHeader(headerValue: any) {
    if (headerValue == "Details") this.toggleSection.details = true;

    this.handleEditVisibility();
  }

  /******************************************** handle edit visibility ******************************************/
  private handleEditVisibility() {
    if (this.activeIndex == 0) {
      this.toggleEditVisibility = true;
    } else {
      this.toggleEditVisibility = false;
    }
  }

  /******************************************** edit ******************************************/
  public edit() {
    this.utils.setPersistantItem(
      PERSISTANT_ITEM.SCHEDULE_MAINTENANCE,
      this.scheduledMaintenance
    );

    this.router.navigate(["/dashboard/preventative-maintenance/update"]);
  }

  /******************************************** delete ******************************************/
  public delete() {
    const item = {
      deleteHeader : this.translate?.preventiveMaintenance.label.singular,
      deleteType: "Preventative Maintenance",
      id: this.scheduledMaintenance.id,
      message: this.translate?.general.messages.confirmDelete+" "+ `${this.scheduledMaintenance.scheduleNumber}?`,
    };

    this.push(
      this.deleteRecordService.getItems(item).subscribe((data) => {
          this.utils.goBack()
      })
    );
  }

  /******************************************** Three Dot Actions ******************************************/
  public menuAction(action: string) {
    if (action === "delete") {
      this.delete();
    }
  }

  getLanguageData() {
    
    console.log("translate",this.translate)
  }
   
  push(obs:any) {
    super.push(obs);
  }
}
