<div class="h-95" *ngIf="loadDataDashboard == 1" #formContainer>
    <p-tabView class="px-0" (onChange)="handleChange($event, tabView)" [(activeIndex)]="activeIndex">
        
        <!-- Magnum -->
        <p-tabPanel [header]="loadTabs[0].headerName" *ngIf="addonMagnum">
            <app-job-order-dashboard *ngIf="loadTabs[0].highlight"></app-job-order-dashboard>
        </p-tabPanel>

        <!-- Hills -->
        <p-tabPanel [header]="loadTabs[1].headerName" *ngIf="addonHills">
            <app-hills-dashboard *ngIf="loadTabs[1].highlight"></app-hills-dashboard>
        </p-tabPanel>
        <p-tabPanel [header]="loadTabs[2].headerName" *ngIf="addonHills">
            <app-national-dashboard *ngIf="loadTabs[2].highlight"></app-national-dashboard>
        </p-tabPanel>

        <!-- Chubb -->
        <p-tabPanel [header]="loadTabs[3].headerName" *ngIf="addonChubb">
            <app-journey-management-plan *ngIf="loadTabs[3].highlight"></app-journey-management-plan>
        </p-tabPanel>
        <p-tabPanel [header]="loadTabs[4].headerName" *ngIf="addonChubb">
            <app-chubb-dashboard *ngIf="loadTabs[4].highlight"></app-chubb-dashboard>
        </p-tabPanel>

        <p-tabPanel [header]="loadTabs[5].headerName">
            <app-my-dashboard *ngIf="loadTabs[5].highlight" dashboardURL="/dashboards/view/9f362146-d3a0-40fc-aa7f-5ff6ae18b592/"></app-my-dashboard>
        </p-tabPanel>
         <!-- addonINVENTORY -->
        <p-tabPanel [header]="loadTabs[6].headerName" *ngIf="addonINVENTORY">
            <app-my-dashboard *ngIf="loadTabs[6].highlight" dashboardURL="/dashboards/view/56c40816-4543-45e9-a5bb-c279519335c5/"></app-my-dashboard>
        </p-tabPanel>
        <p-tabPanel [header]="loadTabs[7].headerName" *ngIf="addonINVENTORY">
            <app-my-dashboard *ngIf="loadTabs[7].highlight" dashboardURL="/dashboards/view/c4587b19-f61e-43e6-8276-bb2de5f09be0/"></app-my-dashboard>
        </p-tabPanel>
    </p-tabView>
  </div>
  