<div class="position-relative d-flex" style="height: calc(100% - 78px);">
    <div class="ag-grid-table-full" [ngClass]="{ 'ag-grid-table': filterActive }" style="height: 100%;" [hidden]="noDataFound">
        <ag-grid-angular
            #agGrid
            style="height: 100%; width: 100%;"
            class="ag-theme-balham"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            [defaultColDef]="defaultColDef"
            [maxConcurrentDatasourceRequests]="-1"
            [suppressDragLeaveHidesColumns]="true"
            [allowContextMenuWithControlKey]="true"
            [getContextMenuItems]="getContextMenuItems"
            [frameworkComponents]="frameworkComponents"
            (gridReady)="onGridReady($event)"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            (cellClicked)="viewAsset($event)"
            (cellValueChanged)="onCellValueChanged($event)"
        ></ag-grid-angular>

        <div class="col-12">
            <p class="ag-record-row-summary-panel padds text-end" id="label_AssetTableRec">
                {{ totalRecord }}&nbsp;{{translate?.general.messages.records}}
            </p>
        </div>
    </div>

    <div class="col-12 mt-5 py-4" *ngIf="noDataFound">
        <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
            <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
            <p class="text-6 my-1 font-weight-bold" >{{translate?.general.messages.noRecordsFound}}</p>
        </div>
    </div>

    <!-- Filter -->
    <div class="custella-form-container-content-pane layout-filter" [ngClass]="{ 'layout-filter-active': filterActive }" *ngIf="filterActive">
        <div class="custella-form-container-content-pane-title">
            <span class="text-14 font-medium" id="label_AssetFilter">{{translate?.general.messages.filterBy}}</span>
            <i class="fa-regular fa-arrow-right-from-line" [pTooltip]="translate?.general.buttons.closeFilter.singular" tooltipPosition="left" (click)="toggleFilter()" id="btn_AssetClose"></i>
        </div>
        <div class="custella-content" #FilterContainer>
            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_AssetNextMaintenanceDate">{{translate?.asset.fields.nextMaintenanceDate.singular}}</span>
                <span class="p-input-icon-right w-100">
                    <p-calendar
                    type="text"
                    class="custella-calendar"
                    dateFormat="dd/mm/yy"
                    name="dueDate"
                    [readonlyInput]="true"
                    [placeholder]="translate?.general.messages.none"
                    selectionMode="range"
                    [(ngModel)]="dateRange"
                    (ngModelChange)="onDateChange($event)"
                    [yearNavigator]="true"
                    yearRange="2000:2030"
                    [showButtonBar]="false"
                    id="picklist_AssetNextMaintenanceDateField"
                    #myCalendar
                appendTo="body"></p-calendar>
                <i class="fa-regular fa-calendar"></i>
                </span>
            </div>
            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_AssetTypes">{{translate?.asset.fields.type.singular}}</span>
                <span class="p-input-icon-right w-100">
                    <p-multiSelect
                    class="pe-2"
                    [(ngModel)]="filter.assetTypes"
                    name="status"
                    [options]="types"
                    optionLabel="name"
                    filterBy="name"
                    optionValue="id"
                    [placeholder]="translate?.general.messages.none"
                    (onChange)="refresh()"
                    id="picklist_AssetTypesField"
                >
                </p-multiSelect>
                </span>
            </div>
            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_AssetStatus">{{translate?.asset.fields.status.singular}}</span>
                <p-multiSelect
                class="pe-2"
                [(ngModel)]="filter.assetStatuses"  
                name="status" [options]="statuses" 
                optionLabel="name" filterBy="name" 
                optionValue="id" 
                [placeholder]="translate?.general.messages.none"
                (onChange)="refresh()"
                id="picklist_AssetStatusField"
            >
            </p-multiSelect>
            </div>

            <div>
                <span href="#" class="clear" (click)="clearAll()" id="subtitle_AssetClear">{{translate?.general.buttons.clearAll.singular }}</span>
            </div>
        </div>
    </div>
</div>
