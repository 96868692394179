import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-parent-modal',
  templateUrl: './parent-modal.component.html',
  styleUrls: ['./parent-modal.component.scss']
})
export class ParentModalComponent implements OnInit {
  public component!: Component;

  constructor() { }

  ngOnInit(): void {
  }

  public setComponent(component: Component) {
    this.component = component;

  }

}
