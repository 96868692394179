import { LanguageDataModelByModule } from "./language.data.model";

export class LanguageDataModelForTabs {
  moduleData: any[] = [];
  constructor(data) {
    this.moduleData = this.convertNestedToObjectForTabs(data);
  }
   /*******  Function to convert nested objects to arrays ***************/
   convertNestedToObjectForTabs(translate: any) {
    if (!translate || typeof translate !== 'object') {
      console.warn("Translation data is undefined or not an object");
      return [];
    }

    const arrayOfObjects = Object.keys(translate).map((key) => {
      const moduleData = translate[key];
if(key == 'setup') console.log(key,"moduleData******************", moduleData);
      return {
        key: key,
        singular: moduleData.label ? moduleData.label.singular : '-',
        plural: moduleData.label ? moduleData.label.plural : '-', // Use the key as the name for the object
        original: moduleData.label ? moduleData.label.original : '-',
        data: moduleData || {}, 
        details: moduleData.tabs ? moduleData.tabs.details : '-',
        history: moduleData.tabs ? moduleData.tabs.history : '-',
        section: moduleData.sections ? this.convertNestedToObjectArrayForModule(moduleData.sections): '-',
        subTab: key == 'setup' || key == 'dashboard' ? this.convertNestedToObjectForTabs(moduleData): '-',
        selectedSubTab: '-',
      };
    });

    return arrayOfObjects;
  }

   /******* Function to convert nested section objects to arrays ***************/
  convertNestedToObjectArrayForModule(jsonData: any) {
  //  console.log("jsonData", jsonData);
    if (!jsonData || typeof jsonData !== 'object') {
      console.warn("Section data is undefined or not an object");
      return [];
    }

    const arrayOfObjects = Object.keys(jsonData).map((key) => {
     return {
        name: key, // Use the key as the name for the object
        data: jsonData[key], // The value of the key becomes the data property
        singular: jsonData[key].singular ? jsonData[key].singular : '-',
        plural: jsonData[key].plural ? jsonData[key].plural : '-', // Use the key as the name for the object
        original: jsonData[key].original ? jsonData[key].original : '-',
      };
    });

    return arrayOfObjects;
  }
}

// Class responsible for resetting highlights across all tab details
export class TabDetailsResetter {
  static resetHighlights() {
    const tabDetailsArrays = [
      customerTabsDetails,
      contractTabDetails,
      maintenanceTabDetails,
      assetTabDetais,
      checklistTabDetails,
      homeTabDetails,
      adjustInventoryTabDetails,
      productTransferTabDetails,
      checkAvailabilityTabDetails,
      psvViewTabDetails,
      JoDynamicFormTabDetails,
      jobOrderTabDetails,
      onLoadTabDetails,
      preventiveMaintenaqnceTabDetails,
      leavesTabDetails,
      PMVTabDetails,
      productsTabDetails,
      pmFreqTabDetails,
      projectTabDetails,
      salesOrderTabDetails,
      profilesTabDetails,
      maintainenseTaskTabDetails,
      viewChecklistTabDetails,
      leavesMSTabDetails,
      groupTabDetails,
      joTemplateTabDetails,
      userTabDetails,
      viewSiteTabDetails,
      ticketTabDetails,
    ];

    tabDetailsArrays.forEach(array => {
      array.forEach((tab:any,index:any) => {
       // console.log(index,"***",tab,"------",tab.highlight);
        tab.highlight = index == 0 ? true: false;
      });
    });
  }
}


 
   
  


export const customerTabsDetails = [{ translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
{ translatePath: "salesOrder.label.plural", highlight: false, headerName: null, compareType: "plural" },
{ translatePath: "jobOrder.label.plural", highlight: false, headerName: null, compareType: "plural" },
{ translatePath: "contacts.label.plural", highlight: false, headerName: null, compareType: "plural" },
{ translatePath: "task.label.plural", highlight: false, headerName: null, compareType: "plural" },
{ translatePath: "asset.label.plural", highlight: false, headerName: null, compareType: "plural" },
{ translatePath: "contracts.label.plural", highlight: false, headerName: null, compareType: "plural" },
{ translatePath: "site.label.plural", highlight: false, headerName: null, compareType: "plural" },
{ translatePath: "project.label.plural", highlight: false, headerName: null, compareType: "plural" },
{ translatePath: "dashboard.label.plural", highlight: false, headerName: null, compareType: "plural" },
{ translatePath: "attachments.label.plural", highlight: false, headerName: null, compareType: "plural" }];


export const contractTabDetails = [
  { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
  { translatePath: "asset.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "accessories.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "contractMaintenance.label.plural", highlight: false, headerName: null, compareType: "plural" },
]

export const maintenanceTabDetails = [
  { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
  { translatePath: "preventiveMaintenance.label.plural", highlight: false, headerName: null, compareType: "plural" },
]

export const assetTabDetais = [
  { translatePath: "general.tabs.details", highlight: false, headerName: null, compareType: "tabs" },
  { translatePath: "accessories.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "contracts.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "maintenance.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "preventiveMaintenance.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "jobOrder.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "assetLog.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "vanLog.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "history.label.plural", highlight: false, headerName: null, compareType: "plural" },]

export const checklistTabDetails = [
  { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
  { translatePath: "jobOrder.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "task.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "product.label.plural", highlight: false, headerName: null, compareType: "plural" },]

export const homeTabDetails = [
  { translatePath: "jobOrder.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "dashboard.chubb.label.plural", highlight: false, headerName: null, compareType: "dashboard" },
  { translatePath: "dashboard.national.label.plural", highlight: false, headerName: null, compareType: "dashboard" },
  { translatePath: "dashboard.chubb.sections.sec16.plural", highlight: false, headerName: null, compareType: "section" },
  { translatePath: "dashboard.chubb.label.plural", highlight: false, headerName: null, compareType: "dashboard" },
  { translatePath: "dashboard.service.label.plural", highlight: true, headerName: null, compareType: "dashboard" },
  { translatePath: "inventory.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "preventiveMaintenance.label.plural", highlight: false, headerName: null, compareType: "plural" },
]

export const adjustInventoryTabDetails = [
  { translatePath: "asset.label.plural", highlight: true, headerName: null, compareType: "plural" ,original: "Asset"},
  { translatePath: "product.label.plural", highlight: false, headerName: null, compareType: "plural",original: "Product" },
]

export const productTransferTabDetails = [
  { translatePath: "product.label.plural", highlight: true, headerName: null, compareType: "plural",original: "Product" },
  { translatePath: "asset.label.plural", highlight: false, headerName: null, compareType: "plural", original: "Asset" },

]

export const checkAvailabilityTabDetails = [
  { translatePath: "product.label.plural", highlight: true, headerName: null, compareType: "plural",original: "Product" },
  { translatePath: "asset.label.plural", highlight: false, headerName: null, compareType: "plural" ,original: "Asset"},

]

export const psvViewTabDetails = [
  { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
  { translatePath: "jobOrder.fields.pesticides.plural", highlight: false, headerName: null, compareType: "plural" },
]

export const JoDynamicFormTabDetails = [
  { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
  { translatePath: "jobOrder.fields.equipment.label.plural", highlight: false, headerName: null, compareType: "plural" },
]

export const jobOrderTabDetails = [
  { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
  { translatePath: "product.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "task.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "jobOrder.sections.sec36.plural", highlight: false, headerName: null, compareType: "section" },
  { translatePath: "jobOrder.sections.sec34.plural", highlight: false, headerName: null, compareType: "section" },
  { translatePath: "jobOrder.sections.sec34.plural", highlight: false, headerName: null, compareType: "section" },
  { translatePath: "jobOrder.sections.sec34.plural", highlight: false, headerName: null, compareType: "section" },
  { translatePath: "history.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "attachments.label.plural", highlight: false, headerName: null, compareType: "plural" },]

export const onLoadTabDetails = [
  { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
  { translatePath: "jobOrder.fields.equipment.label.plural", highlight: false, headerName: null, compareType: "plural" },
]

export const preventiveMaintenaqnceTabDetails = [
  { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
  { translatePath: "jobOrder.fields.equipment.label.plural", highlight: false, headerName: null, compareType: "plural" },
]


export const leavesTabDetails = [
  { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
  { translatePath: "history.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "attachments.label.plural", highlight: false, headerName: null, compareType: "plural" },
]

export const PMVTabDetails = [
  { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
  { translatePath: "jobOrder.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "product.label.plural", highlight: false, headerName: null, compareType: "plural" },]

export const productsTabDetails = [
  { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
  { translatePath: "inventory.label.plural", highlight: false, headerName: null, compareType: "plural" },
] 

export const pmFreqTabDetails = [
  { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
  { translatePath: "setup.modules.preventiveParts.label.plural", highlight: false, headerName: null, compareType: "plural" },
]

export const projectTabDetails = [
  { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
  { translatePath: "contacts.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "site.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "jobOrder.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "task.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "ticket.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "attachments.label.plural", highlight: false, headerName: null, compareType: "plural" },]


export const salesOrderTabDetails = [
  { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
  { translatePath: "product.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "jobOrder.label.plural", highlight: false, headerName: null, compareType: "plural" },
  { translatePath: "history.label.plural", highlight: false, headerName: null, compareType: "plural" },]

  export const profilesTabDetails = [
    { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
    { translatePath: "setup.modules.listView.label.singular", highlight: false, headerName: null, compareType: "setupSubTab" },
  ]

  export const maintainenseTaskTabDetails = [
    { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
    { translatePath: "setup.modules.listView.label.singular", highlight: false, headerName: null, compareType: "plural" },
  ]

  export const viewChecklistTabDetails = [
    { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
    { translatePath: "checklist.sections.sec9.plural", highlight: false, headerName: null, compareType: "section" },
  ]

  export const leavesMSTabDetails = [
    { translatePath: "leave.sections.sec1.plural", highlight: true, headerName: null, compareType: "section" },
    { translatePath: "leave.sections.sec2.plural", highlight: false, headerName: null, compareType: "section" },
    { translatePath: "leave.sections.sec3.plural", highlight: false, headerName: null, compareType: "section" },
    { translatePath: "leave.sections.sec4.plural", highlight: false, headerName: null, compareType: "section" },
  ]

  export const groupTabDetails = [
    { translatePath: "jobOrder.label.plural", highlight: true, headerName: null, compareType: "plural" },
    { translatePath: "ticket.label.plural", highlight: false, headerName: null, compareType: "plural" },
    { translatePath: "inventory.label.plural", highlight: false, headerName: null, compareType: "plural" },
    { translatePath: "setup.notifications.label.plural", highlight: false, headerName: null, compareType: "setup" },
  ]

  export const joTemplateTabDetails = [
    { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
    { translatePath: "task.label.plural", highlight: false, headerName: null, compareType: "plural" },
    { translatePath: "product.label.plural", highlight: false, headerName: null, compareType: "plural" },
  ]

  export const userTabDetails = [
    { translatePath: "general.label.singular", highlight: true, headerName: null, compareType: "singular" },
    { translatePath: "setup.modules.listView.label.singular", highlight: false, headerName: null, compareType: "setupSubTab" },
    { translatePath: "setup.notifications.label.plural", highlight: false, headerName: null, compareType: "setup" },
  ]

  export const viewSiteTabDetails = [
    { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
    { translatePath: "contacts.label.plural", highlight: false, headerName: null, compareType: "plural" },
    { translatePath: "project.label.plural", highlight: false, headerName: null, compareType: "plural" },
    { translatePath: "asset.label.plural", highlight: false, headerName: null, compareType: "plural" },
    { translatePath: "jobOrder.label.plural", highlight: false, headerName: null, compareType: "plural" },
    { translatePath: "task.label.plural", highlight: false, headerName: null, compareType: "plural" },
    { translatePath: "ticket.label.plural", highlight: false, headerName: null, compareType: "plural" },
    { translatePath: "site.sections.sec5.plural", highlight: false, headerName: null, compareType: "section" },
    { translatePath: "attachments.label.plural", highlight: false, headerName: null, compareType: "plural" },
    { translatePath: "machineSpecialist.label.plural", highlight: false, headerName: null, compareType: "plural" },
    { translatePath: "attendance.label.plural", highlight: false, headerName: null, compareType: "plural" },
    { translatePath: "visits.label.plural", highlight: false, headerName: null, compareType: "plural" },
  ]

  export const ticketTabDetails = [
    { translatePath: "general.tabs.details", highlight: true, headerName: null, compareType: "tabs" },
    { translatePath: "ticket.sections.sec13.plural", highlight: false, headerName: null, compareType: "section" },
    { translatePath: "jobOrder.label.plural", highlight: false, headerName: null, compareType: "plural" },
    { translatePath: "history.label.plural", highlight: false, headerName: null, compareType: "plural" },
    { translatePath: "setup.sla.label.plural", highlight: false, headerName: null, compareType: "setup" },
    { translatePath: "attachments.label.plural", highlight: false, headerName: null, compareType: "plural" },
  ]

  export const requisitionTabDetails = [
    { translatePath: "requisition.label.plural", highlight: true, headerName: null, compareType: "plural" },
    { translatePath: "requisition.sections.sec0.plural", highlight: false, headerName: null, compareType: "section" },
  ]

  export const consignmentTabDetails = [
    { translatePath: "consignment.label.plural", highlight: true, headerName: null, compareType: "plural" },
    { translatePath: "consignment.sections.sec0.plural", highlight: false, headerName: null, compareType: "section" },
  ]


  