<div class="custella-form-container m-0 p-2" >
    <div class="custella-form-container-content mt-1">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-4">
                <p-dropdown [placeholder]="translate?.general.messages.none" id="picklist_MagJobCompanyField" [options]="companies" optionLabel="name" optionValue="id" (onChange)="changeRole()" [(ngModel)]="roleId"></p-dropdown>
            </div>
            <div class="d-flex col-8 z-1">
                <p-calendar
                    #myCalendar
                    [hideOnDateTimeSelect]="true"
                    name="startDate"
                    id="picklist_MagJobCompanyDateField"
                    [placeholder]="translate?.general.messages.none"
                    selectionMode="range"
                    dateFormat="mm/dd/yy"
                    yearRange="2000:2030"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    [(ngModel)]="dateRange"
                    (ngModelChange)="onDateChange($event)"
                    class="w-100 cal-dash"
                    appendTo="formContainer"></p-calendar>
                    <p-button type="button" [label]="translate?.general.buttons.reset.singular" styleClass="ms-2 h-32" (onClick)="resetSearch()"></p-button>
                </div>                
        </div>
        <div class="custella-content-row">
            <div class="custella-content-row-input col-4">
                <!-- Workshop Fault Calls -->
                <div class="custella-form-container rounded-2 m-0 pb-1">
                    <div class="custella-form-container-title d-flex align-items-center cursor-pointer">
                        <div class="d-flex justify-content-center">
                            <span class="text-14 font-medium me-2" id="label_MagJobWorkshopFault">{{translate?.dashboard.jobOrder.sections.sec0.singular}}</span>
                            <i class="pi pi-caret-up mt-1" #wfcElem (click)="toggle(wfcElem)" id="btn_MagJobWorkshopFault" data-bs-toggle="collapse" href="#wfcBox"></i>
                        </div>

                        <i class="pi pi-window-maximize ms-auto" id="btn_MagJobWorkshopFaultModal" (click)="$event.stopPropagation(); openModal('magnumDashboardTable', 'Workshop Fault Calls')"></i>
                    </div>
                    <div class="collapse show" id="wfcBox">
                        <div class="custella-form-container-title-two-grey">
                            <div class="d-flex align-items-center">
                                <span class="text-16 color-var-500 me-3" id="label_MagJobTotalFaultCalls">{{translate?.dashboard.jobOrder.sections.sec1.singular}}</span>
                                <p class="mb-0" style="border: 1px solid #adadad; height: 35px;"></p>
                                <span class="text-24 ms-3" id="value_MagJobTotalFaultCalls" *ngIf="multiPie[0]?.totalCount">{{ multiPie[0]?.totalCount }}</span>
                                <span class="text-24 ms-3" id="value_MagJobTotalFaultCalls" *ngIf="!multiPie[0]?.totalCount">0</span>
                            </div>
                        </div>

                        <div class="ms-3">
                            <ng-container *ngIf="multiPie.length <= 0">
                                <div class="custella-content-row ms-3 me-3">
                                    <div class="col-12">
                                        <div class="col-md-12 px-0 my-4 text-center">
                                            <img src="assets\svg\empty-state.svg" id="img_NoRecordsFound" alt="No Records Found" class="w-172px" />
                                            <p class="text-14 my-1 font-weight-bold" >{{translate?.general.messages.noRecordsFound}}</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="multiPie.length != 0">
                                <div class="custella-content-row ms-3 me-3">
                                    <div class="col-12">
                                        <ngx-charts-pie-chart [scheme]="colorSchemePie" [results]="multiPie" [legend]="false" [legendTitle]="''"> </ngx-charts-pie-chart>
                                    </div>
                                </div>

                                <!-- <div class="custella-content-row ms-3 me-3">
                                    <div class="col-12 d-flex flex-wrap" style="column-gap: 1.5rem; row-gap: 10px;">
                                        <div *ngFor="let color of multiPie" class="text-12 d-flex">
                                            <div *ngIf="color.colorCode" class="status-ball mt-1 mb-1 me-2" [ngStyle]="{ 'background-color': color.colorCode }"></div>
                                            <div *ngIf="color.colorCode" style="white-space: nowrap;">
                                                {{ color.name }}
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="row m-3">
                                        <div *ngFor="let color of multiPie" class="text-12 px-3 d-flex col-lg-6 col-md-6 col-sm-6 col-xs-6 mb-2">
                                            <div *ngIf="color.colorCode" class="status-ball mt-1 mb-1 me-2" [ngStyle]="{ 'background-color': color.colorCode }" id="value_MagJobTotalFaultCallsColor"></div>
                                            <div *ngIf="color.colorCode" style="white-space: nowrap;" id="value_MagJobTotalFaultCallsItem">
                                                {{ color.name }}
                                            </div>
                                        </div>
                                  </div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <!-- Overdue Fault Calls -->
                <div class="custella-content-row p-0">
                    <div class="col-12">
                        <div class="custella-form-container rounded-2 m-0 pb-1">
                            <div class="custella-form-container-title d-flex align-items-center cursor-pointer">
                                <div class="d-flex justify-content-center">
                                    <span class="text-14 font-medium me-2" id="label_MagJobOverdueFaultCalls">{{translate?.dashboard.jobOrder.sections.sec2.singular}}</span>
                                    <i class="pi pi-caret-up mt-1" #ofcElem (click)="toggle(ofcElem)" id="btn_MagJobOverdueFaultCalls" data-bs-toggle="collapse" href="#ofcBox"></i>
                                </div>

                                <i class="pi pi-window-maximize ms-auto" id="btn_MagJobOverdueFaultCallsModal" (click)="$event.stopPropagation();openModal('magnumDashboardTable', 'Overdue Fault Calls')"></i>
                            </div>

                            <div class="custella-content-row ms-3 me-3 p-0 collapse show" id="ofcBox">
                                <div class="col-12">
                                    <div style="border-radius: 12px; width: 100%;">
                                        <div class="d-flex align-items-center">
                                            <div class="d-flex align-items-center col-7 px-0 border-right" style="height: 62px; border-radius: 10px 0px 0px 10px; background-color: #db6c6c;">
                                                <p class="mb-0 ps-4 text-3 text-16" style="color: white;" id="label_MagJobOverdueFaultCallsItem">
                                                    {{ translate?.dashboard.jobOrder.fields.overdue.singular }}
                                                </p>
                                            </div>
                                            <div class="d-flex align-items-center col-5 justify-content-between px-0" style="height: 62px; border-radius: 0px 10px 10px 0px; background-color: #db6c6c;">
                                                <div class="px-2 pl-4 text-center">
                                                    <p class="mb-0 ms-2 font-weight-bold" style="color: white; font-size: 30px; line-height: 1;" id="value_MagJobOverdueFaultCallsItem">
                                                        {{ overDue }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Preventive Maintenances -->
                <div class="custella-content-row p-0">
                    <div class="col-12">
                        <div class="custella-form-container rounded-2 m-0 pb-1">
                            <div class="custella-form-container-title d-flex align-items-center cursor-pointer">
                                <div class="d-flex justify-content-center">
                                    <span class="text-14 font-medium me-2" id="label_MagJobPreventative">{{translate?.dashboard.jobOrder.sections.sec3.singular}}</span>
                                    <i class="pi pi-caret-up mt-1" #pmBox id="btn_MagJobPreventative" (click)="toggle(pmBox)" data-bs-toggle="collapse" href="#pmBox"></i>
                                </div>

                                <i class="pi pi-window-maximize ms-auto" id="btn_MagJobPreventativeModal" (click)="$event.stopPropagation();openModal('magnumDashboardTable', 'Preventive Maintenance')"></i>
                            </div>

                            <div class="collapse show" id="pmBox">
                                <div class="custella-form-container-title-two-grey-two d-flex align-items-center flex-wrap justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <span class="text-16 color-var-500" id="label_MagJobPreventAcc">{{translate?.dashboard.jobOrder.fields.accumulated.singular}}&colon;</span>
                                        <span class="text-24 ms-2" id="value_MagJobPreventAcc">{{ preventiveMaintenance?.totalAccumulated }}</span>
                                    </div>
                                    <p class="mb-0" style="border: 1px solid #adadad; height: 35px;"></p>
                                    <div class="d-flex align-items-center ps-2">
                                        <span class="text-16 color-var-500" id="label_MagJobPreventCompl">{{translate?.dashboard.jobOrder.fields.completed.singular}}&colon;</span>
                                        <span class="text-24 ms-2" id="value_MagJobPreventCompl">{{ preventiveMaintenance?.totalCompleted }}</span>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-center">
                                    <ng-container *ngIf="multiPreventiveMaintenance.length == 0">
                                        <div class="custella-content-row ms-3 me-3">
                                            <div class="col-12">
                                                <div class="col-md-12 px-0 my-4 text-center">
                                                    <img src="assets\svg\empty-state.svg" id="img_NoRecordsFound" alt="Empty data" class="w-172px" />
                                                    <p class="text-14 my-1 font-weight-bold" >{{translate?.general.messages.noRecordsFound}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="multiPreventiveMaintenance.length != 0">
                                        <div class="custella-content-row ms-3 me-3 mb-5">
                                            <div class="col-1">
                                                <div class="px-0 my-4 text-center">
                                                    <ngx-charts-pie-chart [view]="viewPie" [scheme]="colorSchemePie4" [results]="multiPreventiveMaintenance" [legend]="true" [legendTitle]="''" [legendPosition]="LegendPosition.Below">
                                                    </ngx-charts-pie-chart>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Workshop Progress -->
                <div class="custella-content-row p-0">
                    <div class="col-12">
                        <div class="custella-form-container rounded-2 m-0 pb-1">
                            <div class="custella-form-container-title d-flex align-items-center">
                                <div class="d-flex justify-content-center">
                                    <span class="text-14 font-medium me-2" id="label_MagJobWorkshop">{{translate?.dashboard.jobOrder.sections.sec4.singular}}</span>
                                    <i class="pi pi-caret-up mt-1" #wsBox id="btn_MagJobWorkshop" (click)="toggle(wsBox)" data-bs-toggle="collapse" href="#wsBox"></i>
                                </div>

                                <i class="pi pi-window-maximize ms-auto" id="btn_MagJobWorkshopModal" (click)="$event.stopPropagation();openModal('magnumDashboardTable','Workshop Progress')"></i>
                            </div>

                            <div class="collapse show" id="wsBox">
                                <div [attr.style]="multiProgressBar?.series?.length == 0 ? 'min-height: 505px;' : 'min-height: 640px;'">
                                    <div class="custella-form-container-title-two-grey">
                                        <div class="d-flex align-items-center">
                                            <span class="text-16 color-var-500 me-3" id="label_MagJobWorkshopProg">{{translate?.dashboard.jobOrder.fields.totalProgress.singular}}</span>
                                            <p class="mb-0" style="border: 1px solid #adadad; height: 35px;"></p>
                                            <span class="text-24 ms-3" id="value_MagJobWorkshopProg">{{multiProgressBar.totalFilterStatus}}/{{multiProgressBar.totalAllStatus}}</span>
                                        </div>
                                    </div>

                                    <ng-container *ngIf="multiProgressBar?.series?.length == 0">
                                        <div class="custella-content-row ms-3 me-3 mt-5">
                                            <div class="col-12">
                                                <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
                                                    <img src="assets\svg\empty-state.svg" id="img_NoRecordsFound" alt="Empty data" class="w-172px" />
                                                    <p class="text-14 my-1 font-weight-bold" >{{translate?.general.messages.noRecordsFound}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="multiProgressBar?.series?.length != 0">
                                        <div class="custella-content-row ms-3 me-3">
                                            <div class="col-12 d-flex flex-wrap" style="column-gap: 1.5rem; row-gap: 10px;">
                                                <div class="text-12 d-flex">
                                                    <div class="status-ball mt-1 mb-1 me-2" id="label_MagJobWorkshopProgStatusProgColor" style="background-color: #ffc300;"></div>
                                                    <div style="white-space: nowrap;" id="value_MagJobWorkshopProgStatusProg">
                                                        {{translate?.dashboard.jobOrder.fields.inProgress.singular}}
                                                    </div>
                                                </div>

                                                <div class="text-12 d-flex">
                                                    <div class="status-ball mt-1 mb-1 me-2" style="background-color: #839190;" id="label_MagJobWorkshopProgStatusAllColor"></div>
                                                    <div style="white-space: nowrap;" id="label_MagJobWorkshopProgStatusAll">
                                                        {{translate?.dashboard.jobOrder.fields.inProgress.singular}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="custella-content-row ms-3 me-3 pe-0 flex-wrap gap-3 height-wp" style="overflow-y: scroll; width: calc(100% - 30px);">
                                            <div class="col-12" *ngFor="let series of multiProgressBar.series;index as i">
                                                <div class="d-flex">
                                                    <span id="label_MagJobWorkshopSeries">{{series?.name}} </span>
                                                    <span class="ms-auto" id="value_MagJobWorkshopSeries">{{series?.totalForAllStatus}}</span>
                                                </div>

                                                <div class="progress magnum-progress">
                                                    <div
                                                        class="bg-color1"
                                                        attr.id="bg-color{{i}}"
                                                        [pTooltip]="series.tootltipData"
                                                        role="percentWidth"
                                                        [ngStyle]="{'width': series.percentWidth}"
                                                        [attr.aria-valuenow]="series.percent"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        tooltipPosition="top"
                                                    ></div>
                                                    <div
                                                        class="bg-color2"
                                                        role="progressbar"
                                                        [pTooltip]="series.tootltipData1"
                                                        [ngStyle]="{'width': series.remainPercentWidth}"
                                                        [attr.aria-valuenow]="series.remainPercent"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        tooltipPosition="top"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="custella-content-row-input col-8">
                <!-- Data Line Fault Calls -->
                <div class="custella-form-container rounded-2 m-0 pb-1">
                    <div class="custella-form-container-title d-flex align-items-center">
                        <div class="d-flex justify-content-center">
                            <span class="text-14 font-medium me-2" id="label_MagJobDataFault" >{{translate?.dashboard.jobOrder.sections.sec5.singular}}</span>
                            <i class="pi pi-caret-up mt-1" #dlfc (click)="toggle(dlfc)" id="btn_MagJobDataFault" data-bs-toggle="collapse" href="#dlfc"></i>
                        </div>

                        <i class="pi pi-window-maximize ms-auto" id="btn_MagJobDataFaultModal" (click)="$event.stopPropagation();openModal('magnumDashboardTable', 'Data Line Fault Calls')"></i>
                    </div>

                    <div class="collapse show" id="dlfc">
                        <div class="custella-form-container-title-two-grey">
                            <div class="d-flex align-items-center">
                                <span class="text-16 color-var-500 me-3" id="label_MagJobDataTotalJob">{{translate?.dashboard.jobOrder.fields.totalJobs.singular}}</span>
                                <p class="mb-0" style="border: 1px solid #adadad; height: 35px;"></p>
                                <span class="text-24 ms-3" *ngIf="multiBar[0]?.totalCount" id="value_MagJobDataTotalJob">{{ multiBar[0]?.totalCount }}</span>
                                <span class="text-24 ms-3" *ngIf="!multiBar[0]?.totalCount" id="value_MagJobDataTotalJob">0</span>
                            </div>
                        </div>

                        <ng-container *ngIf="multiBar.length <= 0">
                            <div class="custella-content-row ms-3 me-3">
                                <div class="col-12">
                                    <div class="col-md-12 px-0 my-4 text-center">
                                        <img src="assets\svg\empty-state.svg" id="img_NoRecordsFound" alt="Empty data" class="w-172px" />
                                        <p class="text-14 my-1 font-weight-bold" >{{translate?.general.messages.noRecordsFound}}</p>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="multiBar.length != 0">
                            <div class="custella-content-row ms-3 me-3">
                                <div class="col-12">
                                    <ngx-charts-bar-vertical
                                        [scheme]="colorSchemeBar"
                                        [results]="multiBar"
                                        [showXAxisLabel]="showXAxisLabel"
                                        [showYAxisLabel]="showYAxisLabel"
                                        [view]="viewBar"
                                        [xAxis]="showXAxis"
                                        [yAxis]="showYAxis"
                                        [legend]="false"
                                        [yAxisTickFormatting]="xAxisTickFormattingFn"
                                        [showDataLabel]="true"
                                    >
                                    </ngx-charts-bar-vertical>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <!-- Total Assignments -->
                <div class="custella-content-row p-0">
                    <div class="col-12">
                        <div class="custella-form-container rounded-2 m-0 pb-1">
                            <div class="custella-form-container-title d-flex align-items-center">
                                <div class="d-flex justify-content-center">
                                    <span class="text-14 font-medium me-2" id="label_MagJobTotalAssign" >{{translate?.dashboard.jobOrder.sections.sec6.singular}}</span>
                                    <i class="pi pi-caret-up mt-1" id="btn_MagJobTotalAssign" #ta (click)="toggle(ta)" data-bs-toggle="collapse" href="#ta"></i>
                                </div>

                                <i class="pi pi-window-maximize ms-auto" id="btn_MagJobTotalAssignModal" (click)="$event.stopPropagation();openModal('magnumDashboardTable', 'Total Assignments')"></i>
                            </div>

                            <div class="collapse show" id="ta">
                                <div class="custella-form-container-title-two-grey">
                                    <div class="d-flex align-items-center">
                                        <span class="text-16 color-var-500 me-3" id="label_MagJobTotalAssignTask">{{translate?.dashboard.jobOrder.fields.totalTasks.singular}}&colon;</span>
                                        <p class="mb-0" style="border: 1px solid #adadad; height: 35px;"></p>
                                        <span class="text-24 ms-3" id="value_MagJobTotalAssignTask">{{ totalDailyJobs }}</span>
                                    </div>
                                </div>

                                <ng-container *ngIf="jobs?.length <= 0">
                                    <div class="custella-content-row">
                                        <div class="col-12">
                                            <div class="col-md-12 px-0 my-4 text-center">
                                                <img src="assets\svg\empty-state.svg" id="img_NoRecordsFound" alt="Empty data" class="w-172px" />
                                                <p class="text-14 my-1 font-weight-bold" >{{translate?.general.messages.noRecordsFound}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container>
                                    <div class="custella-content-row" style="padding-bottom: 2rem;" [hidden]="!noAssets">
                                        <div class="col-12">
                                            <div class="position-relative fw-normal d-flex ms-3">
                                                <div class="table-space ag-grid-table-full" style="height: 350px;" [hidden]="!noAssets">
                                                    <ag-grid-angular
                                                        #agGrid
                                                        style="height: 100%;"
                                                        class="ag-theme-balham"
                                                        [columnDefs]="columnDefs"
                                                        [gridOptions]="gridOptions"
                                                        [defaultColDef]="defaultColDef"
                                                        [cacheOverflowSize]="2"
                                                        [maxConcurrentDatasourceRequests]="-1"
                                                        [frameworkComponents]="frameworkComponents"
                                                        (gridReady)="onGridReady($event)"
                                                        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                                                    ></ag-grid-angular>
                                                    <div class="col-12">
                                                        <p class="ag-record-row-summary-panel padds text-end" id="label_MagJobTotalAssignTaskTableRec">
                                                            {{ totalRecord }}&nbsp;{{translate?.general.messages.records}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Service Requests -->
                <div class="custella-content-row p-0 mt-0">
                    <div class="col-12">
                        <div class="custella-form-container rounded-2 m-0 pb-1">
                            <div class="custella-form-container-title d-flex align-items-center">
                                <div class="d-flex justify-content-center">
                                    <span class="text-14 font-medium me-2" id="label_MagJobServReq" >{{translate?.dashboard.jobOrder.sections.sec7.singular}}</span>
                                    <i class="pi pi-caret-up mt-1" #sr id="btn_MagJobServReq" (click)="toggle(sr)" data-bs-toggle="collapse" href="#sr"></i>
                                </div>

                                <i class="pi pi-window-maximize ms-auto" id="btn_MagJobServReqModal" (click)="$event.stopPropagation();openModal('magnumDashboardTable', 'Service Requests')"></i>
                            </div>

                            <div class="collapse show" id="sr">
                                <ng-container *ngIf="threePiedefaultIconShow">
                                    <div class="custella-content-row ms-3 me-3">
                                        <div class="col-12">
                                            <div class="col-md-12 px-0 my-4 text-center">
                                                <img src="assets\svg\empty-state.svg" id="img_NoRecordsFound" alt="Empty data" class="w-172px" />
                                                <p class="text-14 my-1 font-weight-bold" >{{translate?.general.messages.noRecordsFound}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="!threePiedefaultIconShow">
                                    <div class="custella-content-row ms-3 me-3">
                                        <div class="d-flex w-100">
                                            <div class="custella-dash-chart-two col-4 me-0">
                                                <div class="custella-dash-chart-two-title d-flex align-items-center">
                                                    <span class="text-14 color-var-500 me-3" id="label_MagJobServReqTechnical">{{translate?.dashboard.jobOrder.fields.technical.singular}}</span>
                                                    <p class="mb-0" style="border: 1px solid #adadad; height: 35px;"></p>
                                                    <span class="text-24 ms-3" id="value_MagJobServReqTechnical">{{ multiTechnicalPie[0]?.totalCount }}</span>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <ngx-charts-pie-chart [view]="viewPie1" [scheme]="colorSchemePie1" [results]="multiTechnicalPie" [legend]="false" [legendTitle]="''"> </ngx-charts-pie-chart>
                                                </div>
                                            </div>
                                            <div class="custella-dash-chart-two col-4 mx-0">
                                                <div class="custella-dash-chart-two-title d-flex align-items-center">
                                                    <span class="text-14 color-var-500 me-3" id="label_MagJobServReqNonTech">{{translate?.dashboard.jobOrder.fields.nonTechnical.singular}}</span>
                                                    <p class="mb-0" style="border: 1px solid #adadad; height: 35px;"></p>
                                                    <span class="text-24 ms-3" id="value_MagJobServReqNonTech">{{ multiNonTechnicalPie[0]?.totalCount }}</span>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <ngx-charts-pie-chart [view]="viewPie1" [scheme]="colorSchemePie2" [results]="multiNonTechnicalPie" [legend]="false" [legendTitle]="''"> </ngx-charts-pie-chart>
                                                </div>
                                            </div>
                                            <div class="custella-dash-chart-two col-4 ms-0">
                                                <div class="custella-dash-chart-two-title d-flex align-items-center">
                                                    <span class="text-14 color-var-500 me-3" id="label_MagJobServReqRequest">{{translate?.dashboard.jobOrder.fields.serviceRequest.singular}}</span>
                                                    <p class="mb-0" style="border: 1px solid #adadad; height: 35px;"></p>
                                                    <span class="text-24 ms-3" id="value_MagJobServReqRequest">{{ multiServiceRequestPie[0]?.totalCount }}</span>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <ngx-charts-pie-chart [view]="viewPie2" [scheme]="colorSchemePie3" [results]="multiServiceRequestPie" [legend]="false" [legendTitle]="''"> </ngx-charts-pie-chart>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="custella-content-row mx-3">
                                        <div class="row col-12 custella-dash-chart-two" >
                                            <div *ngFor="let color of multiTechnicalPie" class="text-12 px-3 d-flex col-lg-6 col-md-6 col-sm-6 col-xs-6 mb-2">
                                                <div *ngIf="color.colorCode" class="status-ball mt-1 mb-1 me-2" [ngStyle]="{ 'background-color': color.colorCode }" id="label_MagJobServReqColor"></div>
                                                <div *ngIf="color.colorCode" style="white-space: nowrap;" id="value_MagJobServReqItem">
                                                    {{ color.name }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Workshop Spare Monitoring -->
                <div class="custella-content-row p-0">
                    <div class="col-12">
                        <div class="custella-form-container rounded-2 m-0 pb-1 h-100" #containerRef>
                            <div class="custella-form-container-title d-flex align-items-center">
                                <div class="d-flex justify-content-center">
                                    <span class="text-14 font-medium me-2" id="label_MagJobWorkSpareMonitor" >{{translate?.dashboard.jobOrder.sections.sec8.singular}}</span>

                                    <i class="pi pi-caret-up mt-1" id="btn_MagJobWorkSpareMonitor" #wsm (click)="toggle(wsm)" data-bs-toggle="collapse" href="#wsm"></i>
                                </div>

                                <i class="pi pi-window-maximize ms-auto" id="label_MagJobWorkSpareMonitorModal" (click)="$event.stopPropagation();openModal('magnumDashboardTable','Workshop Spare Monitoring')"></i>
                            </div>

                            <div class="collapse show" id="wsm">
                                <div style="min-height: 350px;">
                                    <ng-container *ngIf="multiBarHorizontalStacked.length == 0">
                                        <div class="custella-content-row ms-3 me-3">
                                            <div class="col-12">
                                                <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
                                                    <img src="assets\svg\empty-state.svg" id="img_NoRecordsFound" alt="Empty data" class="w-172px" />
                                                    <p class="text-14 my-1 font-weight-bold" >{{translate?.general.messages.noRecordsFound}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="multiBarHorizontalStacked.length != 0">
                                        <div class="custella-content-row ms-3 me-3" style="display: flex; flex-flow: column; justify-content: center; align-items: flex-start; box-sizing: border-box;">
                                            <ngx-charts-bar-horizontal-stacked
                                                [scheme]="colorSchemeHorizontal"
                                                [results]="multiBarHorizontalStacked"
                                                (window:resize)="resizeChart(containerRef.offsetWidth)"
                                                [view]="viewBarHorizontalStacked"
                                                [showXAxisLabel]="showXAxisLabel"
                                                [showYAxisLabel]="showYAxisLabel"
                                                [xAxis]="showXAxis"
                                                [yAxis]="showYAxis"
                                                [legend]="true"
                                                [legendTitle]="''"
                                                [showDataLabel]="true"
                                                [legendPosition]="LegendPosition.Below"
                                            >
                                            </ngx-charts-bar-horizontal-stacked>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
